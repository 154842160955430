import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { mobileAccountPagesBranding } from 'constants/branding';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, GAMES_BASE_URL, RESPONSIBLE_BETTING_BASE_URL } from 'constants/locations';
import {
  getAccountNavigationMobileLinks,
  getCurrentAccountNavigationButtonMobile,
  MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME
} from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import {
  getIsIframeEnabled,
  getIsResponsibleBettingMenuEnabled,
  getMobileSettingsDepositBtn
} from 'redux/modules/appConfigs/selectors';
import { getAppSettings } from 'redux/modules/appSettings/selectors';
import { getIsMobileStatementItemDetails, isShowMobileBetDetails } from 'redux/modules/myBets/selectors';
import { TMobileAccountDropdownValue } from 'types';
import { getDefaultLinkTarget } from 'utils/navigation';

import MobileAccountBackBtn from './components/MobileAccountBackBtn';

import styles from './styles.module.scss';

const MobileAccountHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isResponsibleBettingMenuEnabled = useSelector(getIsResponsibleBettingMenuEnabled);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isStatementDetailsOpen = useSelector(getIsMobileStatementItemDetails);
  const depositBtn = useSelector(getMobileSettingsDepositBtn);
  const settings = useSelector(getAppSettings);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const isResponsibleBettingPage = location.pathname.includes(RESPONSIBLE_BETTING_BASE_URL);
  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isGamePage = location.pathname.includes(GAMES_BASE_URL);
  const isShowDepositLink = depositBtn && settings.length;

  const accountNavigationButtons: TMobileAccountDropdownValue[] = getAccountNavigationMobileLinks({
    isResponsibleBettingMenuEnabled,
    isMyBetsSettledTab: localStorage.getItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME) === 'true',
    isGamePage,
    isAsianViewPage
  });

  const { selectedTab } = useAccountUrlParams();
  const currentTab = `${ACCOUNT_BASE_URL}/${selectedTab}`;

  const currentPageValue = getCurrentAccountNavigationButtonMobile(
    currentTab || location.pathname,
    accountNavigationButtons
  );

  const depositLinkTarget = getDefaultLinkTarget({
    url: settings[0]?.url,
    absolutePath: true,
    isIframeEnabled,
    currentTarget: '_blank',
    openInNewTab: true
  });

  return (
    <div className={classNames(styles.container, mobileAccountPagesBranding.SUBHEADER)}>
      <MobileAccountBackBtn />
      <h4 className={styles.title}>
        {isDetailsOpen || isStatementDetailsOpen
          ? t('account.labels.details')
          : t(currentPageValue.labelTranslationKey)}
      </h4>
      {isResponsibleBettingPage && isShowDepositLink ? (
        <a href={settings[0]?.url} target={depositLinkTarget} className={styles.depositLink}>
          {t('accountNavigation.deposit')}
        </a>
      ) : (
        <div className={styles.empty} />
      )}
    </div>
  );
};

export default MobileAccountHeader;
