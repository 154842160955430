import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import SortingField from 'components/BetsTable/components/SortingField';
import CancelAllUnmatchedBets from 'components/CancelAllUnmatchedBets/CancelAllUnmatchedBets';
import useAccountProducts from 'hooks/useAccountProducts';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getMobileSettingsMyBetsLinks,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getHasCancelBetsNotification, getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { setMobileSorting } from 'redux/modules/myBets';
import { getMobileSorting, getMyBets } from 'redux/modules/myBets/selectors';
import { SortByFields, TSortByType } from 'redux/modules/myBets/type';
import { CookieNames } from 'types';
import { addDays, getDaysDifference, getPeriod, getTodayDate, getTodayEndDate } from 'utils/date';

import AccountSearch from '../AccountSearch';
import BetsPeriodTabs from '../BetsPeriodTabs';
import FiltersButton from '../FiltersButton';
import MyBetsLinks from '../MyBetsLinks/MyBetsLinks';

import styles from './styles.module.scss';

const MobileMyBetsPageHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW, CookieNames.MY_BETS_SEARCH]);

  const { totalElements } = useSelector(getMyBets);
  const { isCurrentPeriod } = useMyBetsFilters();
  const hasCancelBetsNotification = useSelector(getHasCancelBetsNotification);
  const mobileSorting = useSelector(getMobileSorting);

  const myBetsLinksMobile = useSelector(getMobileSettingsMyBetsLinks);

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState<boolean>(!!cookies.BIAB_MY_BETS_SEARCH);

  const { bettingDay, startDate, endDate, timezoneOffset, getBetData, refreshData } = useMyBetsPagesNavigation();
  const { defaultProduct, isAsianViewSelected } = useAccountProducts();

  const todayDate = getTodayEndDate(timezoneOffset || timezone, timezoneCookieEnabled || bettingDay);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);

  const currentPeriod =
    startDate && endDate
      ? getPeriod(
          addDays(startDate, -1 * dayDifference),
          addDays(endDate, -1 * dayDifference),
          undefined,
          dayDifference
        )
      : '';

  const dateParams = { hideHoursMinutes: true, divider: ',' };
  const { date: startDateFormatted } = useParseMyBetsDates({ dateToParse: startDate.getTime(), ...dateParams });
  const { date: endDateFormatted } = useParseMyBetsDates({ dateToParse: endDate.getTime(), ...dateParams });

  const periodLabel =
    currentPeriod === 'custom'
      ? `${startDateFormatted} - ${endDateFormatted}`
      : t(`account.labels.period.${currentPeriod}`);

  const setSortingTypeHandler = ({ sortBy, value }: { sortBy: string; value: 'asc' | 'desc' }) => {
    dispatch(setMobileSorting({ type: sortBy as TSortByType, order: value }));

    refreshData({ sortBy: { [sortBy]: value } });
  };

  return (
    <section className={styles.header}>
      <div className={styles.header__row}>
        <div className={styles.filters}>
          <AccountSearch
            isSearchBarOpen={isSearchBarOpen}
            setIsSearchBarOpen={setIsSearchBarOpen}
            isCurrentPeriod={isCurrentPeriod}
            getBetData={getBetData}
          />
          {!isSearchBarOpen && (
            <>
              <BetsPeriodTabs />
              <FiltersButton />
            </>
          )}
        </div>
      </div>
      {!!myBetsLinksMobile.length && (
        <div className={classNames(styles.header__row, styles.header__row__center)}>
          <MyBetsLinks />
        </div>
      )}
      <div className={styles.listHeader}>
        <div className={styles.listHeader__left}>
          <div className={styles.listHeader__title}>
            <p className={styles.periodLabel}>{periodLabel}</p>
            <p className={styles.productTypeLabel}>
              {t(`asianView.labels.account.exchangeType.${cookies.EXCHANGE_TYPE_NEW || defaultProduct}`)}
            </p>
          </div>
          {isCurrentPeriod && !isAsianViewSelected && <CancelAllUnmatchedBets />}
        </div>
        {!hasCancelBetsNotification && (
          <SortingField
            sortBy={mobileSorting?.type ?? SortByFields.PLACED_DATE}
            sortingValue={mobileSorting?.order ?? null}
            onSorting={setSortingTypeHandler}
            isDisabled={!totalElements || isCancellingBets}
          />
        )}
      </div>
    </section>
  );
};

export default MobileMyBetsPageHeader;
