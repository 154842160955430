import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

export const getToolTips = ({ tooltip }: AppState) => tooltip.tooltips;

export const getContextualHelp = ({ tooltip }: AppState) => tooltip.isContextualHelpEnabled || false;
export const getIsShowTooltipHelper = createSelector(getToolTips, tooltips =>
  Object.values(tooltips).some(value => value)
);

export const isShowTooltipHelper =
  (tooltip: string) =>
  ({ tooltip: tooltipState }: AppState) =>
    tooltipState.tooltips[tooltip];
