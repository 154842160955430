import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import {
  cancelBet,
  failureAccountProducts,
  failureFetchStatement,
  failureGetMyBetsData,
  fetchAccountProducts,
  fetchCharges,
  fetchMyBets,
  fetchPL,
  fetchPLTotal,
  fetchPLTotalForEvent,
  fetchProfitGames,
  fetchProfitSports,
  fetchStatement,
  successAccountProducts,
  successCancelBet,
  successCharges,
  successFetchMyBets,
  successPL,
  successPLTotal,
  successPLTotalForEvent,
  successProfitGames,
  successProfitSports,
  successStatement
} from 'redux/modules/myBets';

import {
  Game,
  Sport,
  SuccessAccountProduct,
  TAccountProduct,
  TBetsType,
  TCancelBetResponse,
  TMyBetsResponse,
  TPLResponse,
  TPLTotalResponse,
  TStatementResponse
} from './type';

export function* getBetsWatcher(action: ReturnType<typeof fetchMyBets>) {
  try {
    let response: TMyBetsResponse = yield call(api.myBets.getBets, action.payload);

    if (action.payload.page && action.payload.onChangePage && action.payload.page + 1 > response.totalPages) {
      response = yield call(api.myBets.getBets, { ...action.payload, page: response.totalPages - 1 });
      action.payload.onChangePage(response.totalPages - 1);
    }

    yield put(successFetchMyBets(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getStatementWatcher(action: ReturnType<typeof fetchStatement>) {
  try {
    let response: TStatementResponse = yield call(api.myBets.getAccountStatement, action.payload);

    if (action.payload.page && action.payload.onChangePage && action.payload.page + 1 > response.totalPages) {
      const newPage = response.totalPages > 0 ? response.totalPages - 1 : 0;
      response = yield call(api.myBets.getAccountStatement, { ...action.payload, page: newPage });
      action.payload.onChangePage(newPage);
    }

    yield put(successStatement(response));
  } catch (error) {
    yield put(failureFetchStatement(error));
  }
}

export function* getPLWatcher(action: ReturnType<typeof fetchPL>) {
  try {
    const response: TPLResponse = yield call(api.myBets.getPL, action.payload);
    yield put(successPL(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getPLTotalWatcher(action: ReturnType<typeof fetchPLTotal>) {
  try {
    const response: TPLTotalResponse = yield call(api.myBets.getPLTotal, action.payload);
    yield put(successPLTotal(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getPLTotalEventWatcher(action: ReturnType<typeof fetchPLTotalForEvent>) {
  try {
    const response: TPLTotalResponse = yield call(api.myBets.getPLTotal, action.payload);
    yield put(successPLTotalForEvent(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getSportsListWatcher(action: ReturnType<typeof fetchProfitSports>) {
  try {
    const response: Sport[] = yield call(api.myBets.getSportsList, action.payload);
    yield put(successProfitSports(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getGamesListWatcher(action: ReturnType<typeof fetchProfitGames>) {
  try {
    const response: Game[] = yield call<typeof api.myBets.getGamesList>(api.myBets.getGamesList, action.payload);
    yield put(successProfitGames(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* cancelUnmatchedBet(action: ReturnType<typeof cancelBet>) {
  try {
    const response: TCancelBetResponse = yield call(api.myBets.cancelBet, action.payload);
    yield put(successCancelBet(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getChargesWatcher() {
  try {
    const response: string[] = yield call(api.myBets.getCharges);
    yield put(successCharges(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getAccountProductsWatcher(action: ReturnType<typeof fetchAccountProducts>) {
  try {
    const response: TAccountProduct = yield call(api.myBets.accountProducts, action.payload);
    const betsType = action.payload.betsType as TBetsType;
    yield put(successAccountProducts({ [betsType]: response } as SuccessAccountProduct));
  } catch (error) {
    yield put(failureAccountProducts(error));
  } finally {
    if (action.payload.onFinishCallback) {
      action.payload.onFinishCallback();
    }
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchMyBets.type, getBetsWatcher),
    takeLatest(fetchStatement.type, getStatementWatcher),
    takeLatest(fetchPL.type, getPLWatcher),
    takeLatest(fetchPLTotal.type, getPLTotalWatcher),
    takeLatest(fetchPLTotalForEvent.type, getPLTotalEventWatcher),
    takeLatest(fetchProfitSports.type, getSportsListWatcher),
    takeLatest(fetchProfitGames.type, getGamesListWatcher),
    takeLatest(fetchCharges.type, getChargesWatcher),
    takeLatest(cancelBet.type, cancelUnmatchedBet),
    takeLatest(fetchAccountProducts.type, getAccountProductsWatcher)
  ]);
}
