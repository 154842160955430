import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import EventMarket from 'components/EventMarket';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getIsHideMarketDepth } from 'redux/modules/appConfigs/selectors';
import { IEventViewSettings } from 'redux/modules/appConfigs/type';
import { fetchTop5ViewMarketRules } from 'redux/modules/top5View';
import { CookieNames, PageBlocks, PlacementPage, SportId } from 'types';
import { BettingType, IMarket, IMarketRules } from 'types/markets';
import { getCollapsedCookie } from 'utils/cookie';
import { serializeCollapsedCookies } from 'utils/market';

import styles from '../styles.module.scss';

type Top5ViewMarketProps = {
  topMarket: IMarket;
  eventSettings: IEventViewSettings;
  isDesktop: boolean;
  sportId: string;
  rulesLoading: boolean;
  index: number;
  rules: Record<string, IMarketRules> | null;
  isLandscape: boolean;
  isDepthEnabled: boolean;
};

const Top5ViewMarket = ({
  topMarket,
  eventSettings,
  isDesktop,
  sportId,
  rulesLoading,
  index,
  rules,
  isLandscape,
  isDepthEnabled
}: Top5ViewMarketProps) => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.TOP_MARKETS_COLLAPSED]);

  const hideMarketDepth = useSelector(getIsHideMarketDepth);

  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  let showDeepPrices: boolean;
  const isCricket = sportId === SportId.CRICKET;
  const collapsedMarkets = cookies.BIAB_TOP_MARKETS_COLLAPSED
    ? serializeCollapsedCookies(cookies.BIAB_TOP_MARKETS_COLLAPSED.split(','))
    : {};

  if (isDesktop) {
    showDeepPrices = !hideMarketDepth && !eventSettings.twoColumnView;
  } else {
    if (isLandscape) {
      showDeepPrices = !hideMarketDepth;
    } else {
      showDeepPrices = false;
    }
  }

  const handleFetchOtherMarketRules = (marketId: string) => {
    dispatch(fetchTop5ViewMarketRules(marketId));
  };

  const setMarketCollapse = (marketId: string, value: boolean) => {
    setCookie(
      CookieNames.TOP_MARKETS_COLLAPSED,
      getCollapsedCookie(marketId, value, cookies.BIAB_TOP_MARKETS_COLLAPSED)
    );
  };

  return (
    <div
      className={classNames({
        [styles.top5View__otherMarkets__sections__item__2columns]: eventSettings.twoColumnView && isDesktop,
        'biab_fancy-swap':
          topMarket.description.bettingType === BettingType.LINE && isCricket && swapColorsFancyMarketsOnCricket
      })}
    >
      <EventMarket
        market={topMarket}
        rulesLoading={rulesLoading}
        onFetchMarketRules={handleFetchOtherMarketRules}
        marketIndex={index}
        marketsRules={rules}
        pageBlock={PageBlocks.TOP_5_VIEW}
        collapsedMarkets={collapsedMarkets}
        setMarketCollapse={setMarketCollapse}
        collapseTitleClassName="biab_multi-market-title"
        isRightArrow
        showDeepPrices={showDeepPrices}
        collapseInPlayClassName="biab_multi-market-inplay"
        noCollapsedByDefault
        redirectToSingleMarketPage
        collapseInPlayStatusFirst
        showMarketTableTooltip={!isDesktop}
        doubleCellsWidth={eventSettings.twoColumnView || !isDepthEnabled}
        isOtherSection
        classes={{
          market: classNames(styles.top5View__otherMarkets__market, {
            [styles.top5View__otherMarkets__market__mobile]: !isDesktop
          }),
          viewMarket: styles.top5View__otherMarkets__viewMarket,
          collapseHeader: classNames(styles.top5View__otherMarkets__collapseHeader, {
            [styles.top5View__otherMarkets__collapseHeader__mobile]: !isDesktop
          }),
          runnersContainer: styles.top5View__paddingRight,
          marketTableHeader: styles.top5View__otherMarkets__marketTableHeader
        }}
        page={PlacementPage.TOP_5_VIEW}
      />
    </div>
  );
};

export default Top5ViewMarket;
