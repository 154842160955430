import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import { getDesktopSettingsDepositBtn, getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { getAppSettings } from 'redux/modules/appSettings/selectors';
import { getDefaultLinkTarget } from 'utils/navigation';

import styles from './styles.module.scss';

const DepositBtn = () => {
  const { t } = useTranslation();

  const settings = useSelector(getAppSettings);
  const depositBtn = useSelector(getDesktopSettingsDepositBtn);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const depositLink = settings?.[0]?.url;

  if (!depositBtn || !depositLink) {
    return null;
  }

  return (
    <a
      className={classNames(styles.btn, componentsBranding.SECONDARY_BTN)}
      href={depositLink}
      target={getDefaultLinkTarget({
        url: depositLink,
        absolutePath: true,
        isIframeEnabled,
        currentTarget: '_blank'
      })}
    >
      {t('accountNavigation.deposit')}
    </a>
  );
};

export default DepositBtn;
