import { TFailureActionPayload } from 'types';
import { ECancelAllBtnState } from 'types/betslip';

export type TCancelBetsNotification = { type: 'error' | 'success' | 'progress'; text: string };

export type TCancelActionData = {
  accountId: string;
  actualNumberCancelled: number;
  actualSizeCancelled: number;
  createdByOperator: boolean;
  creationDate: number;
  expectedNumberToCancel: number;
  expectedSizeCancelled: number;
  id: number;
  lastModifiedDate: number;
  status: ECancelActionStatuses;
  offerIds: string[];
};

export type TCancelAction = {
  [cancelActionId: number]: TCancelActionData;
};

export type TCancelActionsState = {
  actions: TCancelAction;
  loading: boolean;
  inProgress: boolean;
  error: TFailureActionPayload;
  cancelActionStatus: TCancelActionData | null;
  cancelAllBtnState: ECancelAllBtnState;
  cancelBetsNotification: TCancelBetsNotification | null;
};

export type TCancelActionStatusPayload = {
  cancelActionId: number;
  onSuccess?: (response: TCancelActionData) => void;
};

export enum ECancelActionStatuses {
  SUCCESSFUL = 'SUCCESSFUL',
  SUCCESSFUL_WITH_LESS_CANCELLED = 'SUCCESSFUL_WITH_LESS_CANCELLED',
  FAILED = 'FAILED',
  CREATED = 'CREATED'
}
