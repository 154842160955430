import { createSelector } from '@reduxjs/toolkit';

import { AsianViewMarketLink } from 'constants/asianView';
import { AppState } from 'redux/reducers';

export const getNavigationSports = ({ asianSportsNavigation }: AppState) => asianSportsNavigation.sports;

export const getSportBySportId = (sportId: string) =>
  createSelector(getNavigationSports, sports => sports?.find(({ id }) => id === sportId));

export const getNavigationSportsLoading = ({ asianSportsNavigation }: AppState) => asianSportsNavigation.sportsLoading;

export const getNavigationCountersLoadingBySportId =
  (sportId: string) =>
  ({ asianSportsNavigation }: AppState) =>
    asianSportsNavigation.countersLoading[sportId];

export const getNavigationCountersBySportId =
  (sportId: string) =>
  ({ asianSportsNavigation }: AppState) =>
    asianSportsNavigation.countersBySportId?.[sportId];

export const getNavigationEventsCountersBySportId =
  (sportId: string) =>
  ({ asianSportsNavigation }: AppState) =>
    asianSportsNavigation.eventsCountersBySportId?.[sportId];

export const getFirstNavigationMarketLinkBySportId = (sportId: string) =>
  createSelector(getNavigationSports, sports => {
    const sport = sports.find(({ id }) => id === sportId);

    if (sport) {
      const marketLinksNames = Object.keys(sport.marketLinks);

      return marketLinksNames[0] ?? AsianViewMarketLink.HDP_AND_OU;
    }

    return AsianViewMarketLink.HDP_AND_OU;
  });

export const getNavigationInitialLoading = (state: AppState) => state.asianSportsNavigation.initialLoading;
