import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding, mobileComponents } from 'constants/branding';
import { BetsPeriods } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useDevice from 'hooks/useDevice';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';

import BetsPeriodTab from './components/BetsPeriodTab';

import styles from './styles.module.scss';

const BetsPeriodTabs = () => {
  const { isMobile } = useDevice();
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);
  const { isMyBetsPage } = useAccountUrlParams();
  const disabled = isMyBetsPage && isCancellingBets;

  return (
    <div
      className={classNames(styles.periodTabs, {
        [styles.periodTabs__mobile]: isMobile,
        [styles.periodTabs__disabled]: disabled,
        [componentsBranding.TAB_CONTAINER]: !isMobile,
        [mobileComponents.SWITCH_TABS]: isMobile
      })}
    >
      {BetsPeriods.map(tab => (
        <BetsPeriodTab key={`period_tab_${tab}`} tab={tab} disabled={disabled} />
      ))}
    </div>
  );
};

export default BetsPeriodTabs;
