import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import styles from './styles.module.scss';

interface AccountPagesSubHeaderNavigationItemProps {
  item: {
    link: string;
    title: string;
  };
}

const AccountPagesSubHeaderNavigationItem = ({ item }: AccountPagesSubHeaderNavigationItemProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isLoggedIn = useSelector(getLoggedInStatusState);

  const { link, title } = item;
  const isActive = location.pathname.includes(link) && isLoggedIn;

  return (
    <NavLink
      to={link}
      className={classNames(styles.link, 'biab_navigation-link', {
        biab_active: isActive
      })}
    >
      {t(title)}
    </NavLink>
  );
};

export default AccountPagesSubHeaderNavigationItem;
