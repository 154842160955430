import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useElementSize from 'hooks/useElementSize';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { convertTimezoneToDropdown } from 'utils';
import { applyTimezone, parseDateToTime } from 'utils/date';

import styles from './styles.module.scss';

const TOP_AND_BOTTOM_PADDINGS = 8;

const AsianTimeZone = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const [time, setTime] = useState('');

  const current = useMemo(() => {
    const convertedData = convertTimezoneToDropdown(t);

    return convertedData.find(item => item.value === timezone);
  }, [timezone]);

  const setDateTime = () => {
    let date = parseDateToTime(new Date());
    if (current?.value) {
      date = parseDateToTime(applyTimezone(new Date(), current?.value, timezoneCookieEnabled));
    }
    setTime(date);
  };

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.ASIAN_TIMEZONE, height: height + TOP_AND_BOTTOM_PADDINGS }));
    }
  });

  useEffect(() => {
    setDateTime();

    const interval = setInterval(() => {
      setDateTime();
    }, 60000);

    return () => {
      clearInterval(interval);
      dispatch(setElementHeight({ name: EElementNames.ASIAN_TIMEZONE, height: 0 }));
    };
  }, []);

  return (
    <div className={styles.main} ref={componentRef}>
      {time} {current?.label}
    </div>
  );
};

export default AsianTimeZone;
