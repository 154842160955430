import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, toNumber } from 'lodash';

import { numberOfButtons, tabs } from 'constants/quickBets';
import {
  getCurrency,
  getDefaultQuickStakes,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { setSettings } from 'redux/modules/user';
import {
  getAccountSettingsGamesStakes,
  getAccountSettingsStakes,
  getIsUserAccountSettings
} from 'redux/modules/user/selectors';
import { ActiveTab, EditMode, FormState } from 'types/quickBets';
import { validateDesktopSettingsStakeValue, validateStakeValue } from 'utils/validation';

type Params = {
  isDesktopSettings?: boolean;
};

export const useQuickBets = (params?: Params) => {
  const dispatch = useDispatch();

  const currency = useSelector(getCurrency);
  const defaultQuickStakes = useSelector(getDefaultQuickStakes);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const stakes = useSelector(getAccountSettingsStakes);
  const gameStakes = useSelector(getAccountSettingsGamesStakes);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const tabsList: { sports?: ActiveTab; games?: ActiveTab } = {
    ...(exchangeSportsEnabled ? { sports: tabs.sports } : {}),
    ...(exchangeGamesEnabled ? { games: tabs.games } : {})
  };

  const [editMode, setEditMode] = useState<EditMode>({ sports: false, games: false });
  const [formState, setFormState] = useState<FormState>({
    sports: { values: [], errors: [], valuesChanged: false },
    games: { values: [], errors: [], valuesChanged: false }
  });
  const [activeTab, setActiveTab] = useState<ActiveTab>(exchangeSportsEnabled ? tabs.sports : tabs.games);

  const defaultStakes = defaultQuickStakes?.stakes || [];
  const defaultGameStakes = defaultQuickStakes?.gameStakes || [];
  const stakesDependency = params?.isDesktopSettings ? null : stakes;
  const gameStakesDependency = params?.isDesktopSettings ? null : gameStakes;

  const removeNulls = (arr: (number | string)[]) => {
    return arr?.map(elem => (elem === null ? '' : elem));
  };

  const setDefaultQuickBetsValues = () => {
    const gameValues = removeNulls(gameStakes ?? defaultGameStakes);
    const gameErrors = gameValues?.map(value => validateQuickStakeValue(value.toString())) ?? [];
    const sportValues = removeNulls(stakes ?? defaultStakes);
    const sportErrors = sportValues?.map(value => validateQuickStakeValue(value.toString())) ?? [];

    setFormState({
      games: {
        values: gameValues,
        errors: gameErrors,
        valuesChanged: false
      },
      sports: {
        values: sportValues,
        errors: sportErrors,
        valuesChanged: false
      }
    });
  };

  useEffect(() => {
    setDefaultQuickBetsValues();
  }, [stakesDependency, gameStakesDependency, activeTab]);

  const validateQuickStakeValue = (value: string) => {
    if (value === '') {
      return { translationKey: '' };
    }

    if (params?.isDesktopSettings) {
      return validateDesktopSettingsStakeValue(value, currency, activeTab.value);
    }

    return validateStakeValue(value, currency, 'quickBets', activeTab.value);
  };

  const handleSetInputValue = (evt: ChangeEvent<HTMLInputElement>, onlyNumbers?: boolean) => {
    const { value, name } = evt.target;

    if (!onlyNumbers || /^\d+(\.\d*)?$/.test(value) || value === '') {
      const updatedValues = formState[activeTab.value].values.map((quickBet, index) =>
        index === toNumber(name) ? value : quickBet
      );
      const stakesArray = activeTab === tabs.sports ? stakes : gameStakes;

      setFormState({
        ...formState,
        [activeTab.value]: {
          ...formState[activeTab.value],
          values: updatedValues,
          errors: formState[activeTab.value].errors.map((error, index) =>
            index === toNumber(name) ? validateQuickStakeValue(value) : error
          ),
          valuesChanged: !isEqual(
            updatedValues.map(v => (v ? toNumber(v) : null)),
            stakesArray
          )
        }
      });
    }
  };

  const handleSaveQuickBets = (options?: { onFinish: () => void }) => {
    if (isAccountSettings) {
      const payload =
        activeTab === tabs.sports
          ? { stakes: formState.sports.values.filter(elem => elem) }
          : { gameStakes: formState.games.values.filter(elem => elem) };
      dispatch(
        setSettings({
          settings: payload,
          onSuccess: () => {
            setFormState(prevState => ({
              ...prevState,
              [activeTab.value]: {
                ...prevState[activeTab.value],
                valuesChanged: false
              }
            }));
          },
          onFinish: () => {
            options?.onFinish();
            setEditMode({ ...editMode, [activeTab.value.toLowerCase()]: false });
          }
        })
      );
      setFormState({
        ...formState,
        [activeTab.value]: {
          ...formState[activeTab.value],
          values: [
            ...formState[activeTab.value].values.filter(elem => elem).map(elem => toNumber(elem)),
            ...formState[activeTab.value].values.filter(elem => !elem)
          ]
        }
      });
    }
  };

  const handleResetQuickBets = () => {
    const defaultValues = activeTab === tabs.sports ? defaultStakes : defaultGameStakes;
    setFormState({
      ...formState,
      [activeTab.value]: {
        values: defaultValues.map(quickBet => quickBet ?? ''),
        errors: Array(numberOfButtons).fill(''),
        valuesChanged: true
      }
    });
  };

  const handleCancelQuickBets = () => {
    setEditMode({ ...editMode, [activeTab.value.toLowerCase()]: false });
    setDefaultQuickBetsValues();
  };

  return {
    tabs: tabsList,
    activeTab,
    setActiveTab,
    editMode,
    setEditMode,
    formState,
    handleSetInputValue,
    handleSaveQuickBets,
    handleResetQuickBets,
    handleCancelQuickBets
  };
};
