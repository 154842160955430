import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { mobileAccountPagesBranding } from 'constants/branding';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import {
  getAccountNavigationMobileLinks,
  getCurrentAccountNavigationButtonMobile,
  MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME
} from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { getIsResponsibleBettingMenuEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsMobileStatementItemDetails, isShowMobileBetDetails } from 'redux/modules/myBets/selectors';
import { TMobileAccountDropdownValue } from 'types';

import styles from './styles.module.scss';

const MobileAccountNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isResponsibleBettingMenuEnabled = useSelector(getIsResponsibleBettingMenuEnabled);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isMobileStatementItemDetails = useSelector(getIsMobileStatementItemDetails);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isGamePage = location.pathname.includes(GAMES_BASE_URL);

  const accountNavigationButtons: TMobileAccountDropdownValue[] = getAccountNavigationMobileLinks({
    isResponsibleBettingMenuEnabled,
    isMyBetsSettledTab: localStorage.getItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME) === 'true',
    isGamePage,
    isAsianViewPage
  });

  const navigationSubMenu = accountNavigationButtons.filter(({ isSubMenu }) => isSubMenu);

  const { selectedTab } = useAccountUrlParams();
  const currentTab = `${ACCOUNT_BASE_URL}/${selectedTab}`;

  const currentPageValue = getCurrentAccountNavigationButtonMobile(
    currentTab || location.pathname,
    accountNavigationButtons
  );

  const selectedLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (selectedLinkRef.current) {
      selectedLinkRef.current?.scrollIntoView({ inline: 'center', block: 'nearest' });
    }
  }, [currentPageValue.name]);

  if (!selectedTab) {
    return null;
  }

  return (
    <>
      {currentPageValue.isSubMenu && !isDetailsOpen && !isMobileStatementItemDetails && (
        <div className={classNames(styles.subMenu, mobileAccountPagesBranding.SUBHEADER)}>
          <div className={styles.subMenu__inner}>
            {navigationSubMenu.map(({ labelTranslationKey, url, name }) => (
              <Link
                to={url}
                key={url}
                className={classNames(styles.subMenu__item, mobileAccountPagesBranding.TAB, {
                  [styles.subMenu__item__default]: currentPageValue.name !== name,
                  [styles.subMenu__item__active]: currentPageValue.name === name,
                  [mobileAccountPagesBranding.SELECTED]: currentPageValue.name === name
                })}
                ref={currentPageValue.name === name ? selectedLinkRef : undefined}
              >
                {t(labelTranslationKey)}
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MobileAccountNavigation;
