import classNames from 'classnames';

import styles from './styles.module.scss';

type TMyBetsStandardCellProps = {
  value?: string | number | null | boolean;
  capitalise?: boolean;
  classname?: string;
  isWithTooltip?: boolean;
  tooltipValue?: string;
  entity?: string;
  coloredValue?: boolean;
  highlight?: boolean;
};

const MyBetsStandardCell = ({
  value,
  capitalise,
  classname,
  isWithTooltip,
  tooltipValue,
  entity,
  coloredValue,
  highlight
}: TMyBetsStandardCellProps) => {
  const isWon = coloredValue && value && value > 0;
  const isLost = coloredValue && value && value < 0;

  const valueFormatted = isWon ? `+${value}` : value;

  return (
    <div
      data-type={entity}
      className={classNames(classname, {
        [styles.capitalise]: capitalise,
        [styles.won]: isWon,
        [styles.lost]: isLost,
        [styles.highlight]: highlight,
        [styles.offerId]: entity === 'offerId'
      })}
    >
      {isWithTooltip ? (
        <span data-tooltip-id={'tooltip'} data-tooltip-html={tooltipValue}>
          {valueFormatted || '--'}
        </span>
      ) : (
        <>{valueFormatted || '--'}</>
      )}
    </div>
  );
};

export default MyBetsStandardCell;
