import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import { mobileComponents } from 'constants/branding';
import { MobilePlacementTypes } from 'constants/inlinePlacement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useIsMobilePlacementDisabled } from 'hooks/useMobilePlacement';
import { usePlacementData } from 'hooks/usePlacement';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import { getBetStatusErrorByOfferId, getPlacedBetStatusByOfferId } from 'redux/modules/betsStatuses/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { setCurrentBetAction } from 'redux/modules/currentBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import {
  removeProcessingMobileBet,
  setInlineSelectedBet,
  setMobilePlacementNotification,
  setProcessingMobileBet
} from 'redux/modules/inlinePlacement';
import { getIsMobileBetInProcessByOfferId } from 'redux/modules/inlinePlacement/selectors';
import { getMarketPricesById } from 'redux/modules/marketsPrices/selectors';
import { getMobileEditOfferId } from 'redux/modules/myBets/selectors';
import { getMarketPricesById as placementById } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { PageBlocks, PlacementPage } from 'types';
import { BetTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { getUniqSelectedBetKey } from 'utils/betslip';
import { calculateLiability } from 'utils/liability';
import { getPricesByMarketType } from 'utils/price';

import styles from './MobileUnmatchedBetActions.module.scss';

type MobileUnmatchedBetActionsProps = {
  bet: TCurrentBet;
  pageBlock: PageBlocks;
  currency: string;
  page?: PlacementPage;
};

const MobileUnmatchedBetActions = ({ bet, pageBlock, currency, page }: MobileUnmatchedBetActionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const marketPrices = useSelector(getMarketPricesById(bet.marketId));
  const gameMarketPrices = useSelector(placementById(PageBlocks.GAME, bet.marketId));
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const betStatusByOfferId = useSelector(getPlacedBetStatusByOfferId(bet.offerId));
  const betErrorByOfferId = useSelector(getBetStatusErrorByOfferId(bet.offerId));
  const isEditBetInProcess = useSelector(getIsMobileBetInProcessByOfferId(bet.offerId));
  const mobileEditOfferId = useSelector(getMobileEditOfferId);

  const [isCancelling, setIsCancelling] = useState(false);
  const [action, setAction] = useState<'edit' | 'cancel' | null>(null);

  const isLoading = action === 'cancel' ? isCancelling : isEditBetInProcess;
  const isLineMarket = bet.bettingType === BettingType.LINE;
  const isGameBetSlip = pageBlock === PageBlocks.GAME;

  const selectionPrices = isGameBetSlip
    ? gameMarketPrices?.rc?.find(rc => rc.id === bet.selectionId)
    : marketPrices?.rc?.find(rc =>
        isLineMarket
          ? rc.id === bet.selectionId
          : rc.id === bet.selectionId && ((rc.hc || 0) === +(bet.handicap || 0) || bet.handicap === 0)
      );

  const { isDisabled: areActionsDisabled } = useIsMobilePlacementDisabled({
    ...bet,
    pageBlock,
    type: bet.side === BetTypes.BACK ? BetTypes.BACK : BetTypes.LAY,
    sportId: String(bet.eventTypeId)
  });

  const takeOdds = getPricesByMarketType(
    selectionPrices?.[bet.side === BetTypes.BACK ? 'bdatb' : 'bdatl']?.[0]?.odds,
    bet.marketType,
    bet.bettingType
  );
  const isTakeDisabled = areActionsDisabled || !takeOdds || takeOdds === bet.price;
  const isCancelActionParam = searchParams.get('action') === 'cancel';
  const offerIdFromParams = searchParams.get('offerId');

  const takeProfit = calculateLiability(takeOdds, bet.sizeRemaining, {
    marketType: bet.marketType,
    bettingType: bet.bettingType,
    eachWayDivisor: bet.eachWayDivisor,
    betType: bet.side
  });
  const { noFormattedAmount: profit } = useFormatCurrency(takeProfit || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  const onErrorPlacement = (_: TPlacementError | string = '') => {
    setIsCancelling(false);
    dispatch(removeProcessingMobileBet(bet.offerId));
  };

  const { cancelBetsHandler, editBetsHandler } = usePlacementData({
    eachWayDivisor: bet.eachWayDivisor,
    numberOfWinners: bet.numberOfWinners,
    successPlacement: () => {
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    },
    errorPlacement: onErrorPlacement,
    isMobilePlacement: true
  });

  const onTakeBet = () => {
    if (!isTakeDisabled) {
      dispatch(setProcessingMobileBet({ offerIdOrBetUuid: bet.offerId, isLoading: true }));
      setAction('edit');
      const betUuid = getUniqSelectedBetKey({
        handicap: bet.handicap,
        selectionId: bet.selectionId ?? 0,
        marketId: bet.marketId
      });

      dispatch(
        setMobilePlacementNotification({
          pageBlock,
          data: {
            marketId: bet.marketId,
            offerId: bet.offerId,
            betUuid,
            priceToShow: takeOdds,
            sizeToShow: bet.sizeRemaining
          },
          placementType: MobilePlacementTypes.Edit
        })
      );

      editBetsHandler({
        marketId: bet.marketId,
        bets: [
          {
            price: takeOdds,
            size: bet.sizeRemaining,
            side: bet.side,
            selectionId: bet.selectionId,
            handicap: bet.handicap,
            offerId: bet.offerId,
            sizeRemaining: bet.sizeRemaining,
            betUuid,
            persistenceType: bet.persistenceType
          }
        ],
        options: {
          betType: bet.betType,
          pageBlock
        }
      });
    }
  };

  const onEditBet = () => {
    dispatch(
      setInlineSelectedBet({
        selectedBet: {
          marketId: bet.marketId,
          eventId: bet.eventId,
          sportId: bet.eventTypeId.toString(),
          selectionId: bet.selectionId || 0,
          handicap: isGameBetSlip ? 0 : +(bet.handicap || 0),
          marketType: bet.marketType,
          bettingType: bet.bettingType,
          numberOfWinners: bet.numberOfWinners || 0,
          eventName: bet.eventName,
          marketName: bet.marketName,
          selectionName: bet.selectionName,
          marketUnit: bet.marketUnit ?? '',
          lineSide: bet.lineSide ?? null,
          marketStartTime: bet.marketStartDate,
          eachWayDivisor: bet.eachWayDivisor,
          priceLadderDescription: bet.priceLadderDescription,
          fancyView: bet.fancyView,
          raceName: bet.raceName ?? '',
          type: bet.side,
          size: bet.sizeRemaining,
          price: bet.price,
          pageBlock,
          currentOfferId: bet.offerId,
          currency,
          offers: { [bet.offerId]: bet },
          page,
          action: Actions.EDIT,
          disabledLayOdds: bet.disabledLayOdds,
          notHighlightBetContentCell: true
        }
      })
    );
  };

  const onCancelBet = () => {
    if (!areActionsDisabled) {
      setIsCancelling(true);
      setAction('cancel');

      const betUuid = getUniqSelectedBetKey({
        handicap: bet.handicap,
        selectionId: bet.selectionId ?? 0,
        marketId: bet.marketId
      });

      dispatch(
        setMobilePlacementNotification({
          pageBlock,
          data: {
            betUuid,
            offerId: bet.offerId,
            marketId: bet.marketId
          },
          placementType: MobilePlacementTypes.Cancel
        })
      );

      cancelBetsHandler({
        marketId: bet.marketId,
        pageBlock,
        bets: [
          {
            ...bet,
            betUuid
          }
        ],
        onSuccess: () => {
          dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
        }
      });
    }
  };

  useEffect(() => {
    if (action === 'cancel' && (betStatusByOfferId === BetsStatusesTypes.CANCELLED || betErrorByOfferId)) {
      setIsCancelling(false);
    }
  }, [action, betStatusByOfferId, betErrorByOfferId]);

  useEffect(() => {
    if (isCancelActionParam && offerIdFromParams && offerIdFromParams === bet.offerId.toString()) {
      onCancelBet();
    }
  }, [isCancelActionParam, offerIdFromParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mobileEditOfferId && mobileEditOfferId === bet.offerId) {
      onEditBet();
    }
  }, [mobileEditOfferId]);

  return (
    <>
      <div className={styles.actions}>
        <button
          type="button"
          onClick={onCancelBet}
          className={classNames(styles.actions__cancel, mobileComponents.BUTTON, mobileComponents.TERTIARY, {
            [mobileComponents.DISABLED]: areActionsDisabled
          })}
          disabled={areActionsDisabled}
        >
          {t('market.mobileOpenBetsLabels.cancelBet')}
        </button>
        <button
          type="button"
          onClick={onTakeBet}
          className={classNames(styles.actions__take, mobileComponents.BUTTON, mobileComponents.PRIMARY, {
            biab_disabled: !isTakeDisabled,
            [mobileComponents.DISABLED]: isTakeDisabled
          })}
          disabled={isTakeDisabled}
        >
          <span className={styles.actions__take__label}>
            {t('market.mobileOpenBetsLabels.takeBtn.take')} @{takeOdds}
          </span>
          <span>
            {t(
              'market.mobileOpenBetsLabels.takeBtn.' +
                (bet.side && bet.side.toUpperCase() == 'BACK' ? 'profit' : 'liability')
            )}{' '}
            {profit}
          </span>
        </button>
        <button
          onClick={onEditBet}
          type="button"
          className={classNames(styles.actions__edit, mobileComponents.BUTTON, mobileComponents.PRIMARY, {
            [mobileComponents.DISABLED]: areActionsDisabled
          })}
          disabled={areActionsDisabled}
        >
          {t('market.mobileOpenBetsLabels.editBet')}
        </button>
      </div>
      {isLoading && (
        <div className={styles.overlay}>
          <Loader circleColor={CircleColors.BLUE_2} hideBiabClassName className={styles.loader} />
        </div>
      )}
    </>
  );
};

export default MobileUnmatchedBetActions;
