import { AppState } from 'redux/reducers';

import { EElementNames } from './type';

export const getAppSettings = ({ appSettings }: AppState) => appSettings.settings;
export const getParentDomain = ({ appSettings }: AppState) => appSettings.parentDomain;
export const getAccountPageReferer = ({ appSettings }: AppState) => appSettings.accountPageReferer;
export const getOddsType = ({ appSettings }: AppState) => appSettings.oddsType;
export const getElementHeightByName =
  (elementName: EElementNames) =>
  ({ appSettings }: AppState) =>
    appSettings.elementsHeights[elementName];
export const isShowSearch = ({ appSettings }: AppState) => appSettings.isShowingSearch;
export const getIsAsianViewMenuOpen = ({ appSettings }: AppState) => appSettings.isAsianViewMenuOpen;
export const getScrollContentHeight = ({ appSettings }: AppState) => appSettings.scrollContentHeight;
export const getIsUserScrolling = ({ appSettings }: AppState) => appSettings.isUserScrolling;
export const getScrollUpdate = ({ appSettings }: AppState) => appSettings.scrollUpdate;
export const getMobileHeaderHeight = ({ appSettings }: AppState) =>
  appSettings.elementsHeights[EElementNames.MOBILE_HEADER_HEIGHT];
export const getElementHeight =
  (element: EElementNames) =>
  ({ appSettings }: AppState) =>
    appSettings.elementsHeights[element];
