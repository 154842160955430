import { ReactNode } from 'react';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

type TSortingFieldProps = {
  sortBy: string;
  children?: ReactNode;
  sortingValue: 'asc' | 'desc' | null;
  isDisabled?: boolean;
  isInactive?: boolean;
  onSorting: (params: { sortBy: string; value: 'asc' | 'desc' }) => void;
  align?: 'center' | 'left';
  className?: string;
};

const SortingField = ({
  sortBy,
  children,
  sortingValue,
  isDisabled,
  isInactive,
  onSorting,
  align = 'center',
  className = ''
}: TSortingFieldProps) => {
  const { isMobile } = useDevice();

  const handleSort = () => {
    if (!isDisabled) {
      onSorting({ sortBy, value: sortingValue === 'asc' || sortingValue === null ? 'desc' : 'asc' });
    }
  };

  return (
    <span
      className={classNames(
        styles.sort,
        {
          [styles.sort__disabled]: isDisabled,
          [styles.sort__left]: align === 'left'
        },
        className
      )}
      onClick={handleSort}
    >
      {children}
      <span
        className={classNames(styles.iconWrap, {
          [styles.iconWrap__desktop]: !isMobile,
          [styles.iconWrap__mobile]: isMobile,
          [styles.iconWrap__disabled]: isDisabled
        })}
      >
        <i
          className={classNames(styles.icon, {
            [styles.icon__mobile]: isMobile,
            [styles.icon__disabled]: isDisabled,
            [styles.icon__inactive]: isInactive,
            ['biab_custom-icon-sort-up']: !isDisabled && sortingValue === 'asc',
            ['biab_custom-icon-sort-down']: !isDisabled && sortingValue === 'desc',
            ['biab_custom-icon-sort']: !sortingValue || isDisabled || isInactive
          })}
        >
          <span className="path1" />
          <span className="path2" />
        </i>
      </span>
    </span>
  );
};

export default SortingField;
