import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import CashOutButton from 'components/CashOutButton';
import EventHeaderHandler from 'components/EventHeaderHandler';
import FavoriteStar from 'components/FavoriteStar';
import MarketInfoRow from 'components/MarketInfoRow';
import MobileAllMarketsLink from 'components/MobileAllMarketsLink';
import PLTable from 'components/PLTable';
import { ALL_MARKETS_TAB_NAME, FAVORITES_TYPES, tooltipStatus } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { PLTableType } from 'constants/marketsTable';
import { inningsMarket } from 'constants/marketTypes';
import { MULTI_MARKET_EXTRA_TAB_ID } from 'constants/multiMarket';
import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import useLayColumn from 'hooks/useLayColumn';
import useMarketDepth from 'hooks/useMarketDepth';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import DesktopOtherMarketsTitle from 'pages/Top5View/components/DesktopOtherMarketsTitle';
import DesktopTopMarket from 'pages/Top5View/components/DesktopTopMarket';
import MobileRulesView from 'pages/Top5View/components/MobileRulesView';
import { getAppDevice, getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchGetEvents } from 'redux/modules/competitions';
import { FancyMarketTypes } from 'redux/modules/fancyMarket/type';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { removeMarketPricesSocketParamsByMarketIds } from 'redux/modules/marketsPrices';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesCurrency,
  getMarketPricesId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import {
  fetchMultiMarketDetails,
  fetchMultiMarketEventRules,
  fetchMultiMarketKeyMarketDetails,
  fetchMultiMarketKeyMarketFancyDetails,
  fetchMultiMarketRules,
  removeExtraMultiMarketTab,
  resetMultiMarketState,
  resetMultiMarketTopDetails,
  setExtraMultiMarketTab
} from 'redux/modules/multiMarket';
import {
  getDesktopTabsMarkets,
  getIsExtraTab,
  getMultiMarketKeyMarketDetails,
  getMultiMarketKeyMarketFancyDetails,
  getMultiMarketLines,
  getMultiMarketLinesText,
  getMultiMarketReset,
  getMultiMarketRulesLoading,
  getMultiMarketShowAll,
  getMultiMarketsRules,
  getMultiMarketTabs,
  getMultiMarketTop
} from 'redux/modules/multiMarket/selectors';
import { TMultiMarketTabDetails } from 'redux/modules/multiMarket/type';
import { CookieNames, LiveWidgetHeaderType, MarketStatus, PageBlocks, PlacementPage } from 'types';
import { BettingType, DesktopTopMarketViewType, EMarketDepthView, IMarket, TFancyMultiMarketData } from 'types/markets';
import { getBooleanValue } from 'utils';
import {
  filterFancyMarkets,
  getDesktopMarketTitle,
  getFancyMarketsData,
  getIsFancyMarket,
  getIsNoFancyMarket,
  getMobileFancyNavLinks
} from 'utils/fancyMultiMarket';
import { getMarketTypes } from 'utils/market';

import FancyMultiMarket from './components/FancyMultiMarket';
import FancyMultiMarketHeader from './components/FancyMultiMarketHeader';
import MarketsTabs from './components/MarketsTabs';
import MobileExtraTabMultiMarket from './components/MobileExtraTabMultiMarket';
import MultiMarketSimpleMarkets from './components/MultiMarketSimpleMarkets';
import MultiMarketViewIntervalRequestInjection from './components/MultiMarketViewIntervalRequestInjection';

import styles from './styles.module.scss';

type MultiMarketViewProps = {
  isFancyView: boolean;
};

const MultiMarketView = ({ isFancyView }: MultiMarketViewProps) => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    CookieNames.MULTI_MARKETS_COLLAPSED,
    CookieNames.MULTI_MARKETS_OTHER_IS_OPENED,
    CookieNames.PL_TABLE_VISIBLE
  ]);
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const topMultiMarket = useSelector(getMultiMarketTop);
  const keyMarket = useSelector(getMultiMarketKeyMarketDetails);
  const { allMarketsTab, allTabs } = useSelector(getMultiMarketTabs);
  const desktopTabsMarkets = useSelector(getDesktopTabsMarkets);
  const marketInfo = useSelector(getSingleMarketInfo);
  const isExtraTab = useSelector(getIsExtraTab);
  const rulesLoading = useSelector(getMultiMarketRulesLoading);
  const lines = useSelector(getMultiMarketLines);
  const reset = useSelector(getMultiMarketReset);
  const showAll = useSelector(getMultiMarketShowAll);
  const linesText = useSelector(getMultiMarketLinesText);
  const marketPricesId = useSelector(getMarketPricesId(marketInfo?.marketId ?? ''));
  const marketStatus = useSelector(getStatusByMarketPricesId(marketInfo?.marketId ?? ''));
  const marketPricesCurrency = useSelector(getMarketPricesCurrency(marketInfo?.marketId ?? ''));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketInfo?.marketId ?? ''));
  const rules = useSelector(getMultiMarketsRules);
  const device = useSelector(getAppDevice);
  const keyMarketFancyDetails = useSelector(getMultiMarketKeyMarketFancyDetails);
  const isFavorite = useSelector(getFavoriteById(params.marketId ?? ''));
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const [isRulesView, setIsRulesView] = useState(false);
  const [currentDisplayedMarkets, setCurrentDisplayedMarkets] = useState<IMarket[]>([]);
  const [activeTab, setActiveTab] = useState<TMultiMarketTabDetails>();

  const { target: marketWrapperRef, addedHeight, targetHeight } = useAddHeightForFooter();
  const { isDepthEnabled, isLandscape } = useMarketDepth(EMarketDepthView.MULTI_MARKET);
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: marketInfo?.marketId,
    eventId: marketInfo?.event?.id,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });
  const { isLayColumnEnabled } = useLayColumn({
    sportId: marketInfo?.eventType.id,
    bettingType: marketInfo?.description?.bettingType
  });

  const isCloseMarket = marketPricesId && marketInfo ? marketStatus === MarketStatus.CLOSED : false;

  const { isHandicap } =
    (marketInfo && getMarketTypes(marketInfo.description.marketType, marketInfo.description.bettingType)) || {};

  const prevTopMultiMarketId = useRef<undefined | string>(undefined);
  const marketsToRemove = useRef<IMarket[]>([]);

  prevTopMultiMarketId.current = topMultiMarket?.topMarketId;
  const isShowPlTable = !!marketInfo && PLTableType.includes(marketInfo.description.bettingType) && !isCloseMarket;
  const hasMinHeightStyle = !(device === Devices.MOBILE && isIframeEnabled);
  const isMobile = device === Devices.MOBILE;
  const searchParamTab = searchParams.get('tab');
  const viewMarketSearchParam = searchParams.get('viewMarket');
  const isDesktop = device === Devices.DESKTOP;
  const noDesktopMarkets =
    !desktopTabsMarkets.length ||
    (desktopTabsMarkets.length === 1 && desktopTabsMarkets[0].marketId === params.marketId);
  const isOpenedMultiMarketsOther = cookies.BIAB_MULTI_MARKETS_OTHER_IS_OPENED
    ? getBooleanValue(cookies.BIAB_MULTI_MARKETS_OTHER_IS_OPENED ?? '')
    : true;
  const displayedTabs = useMemo(() => {
    if (isDesktop) {
      return allTabs.filter(({ marketCatalogues }) => {
        return !(marketCatalogues.length === 1) || marketCatalogues[0].marketId !== params.marketId;
      });
    }

    return allTabs;
  }, [allTabs, isDesktop, params.marketId]);

  const isHasDesktopMarkets = noDesktopMarkets && isOpenedMultiMarketsOther && isDesktop;

  const isShowFavorite = !(
    inningsMarket(marketInfo!.description.marketType) &&
    isFancyView &&
    Object.keys(FancyMarketTypes).includes(marketInfo!.description.marketType)
  );

  const favoriteData = {
    entryId: params.marketId ?? '',
    entryName: marketInfo ? getDesktopMarketTitle(marketInfo?.marketName, marketInfo?.description, isFancyView, t) : '',
    entryType: FAVORITES_TYPES.market,
    starred: !isFavorite,
    sportId: params.sportId ?? ''
  };

  const multiMarketName = marketInfo
    ? getDesktopMarketTitle(marketInfo.marketName, marketInfo.description, isFancyView, t)
    : '';

  useEffect(() => {
    if (!getBooleanValue(String(viewMarketSearchParam)) && isExtraTab) {
      dispatch(removeExtraMultiMarketTab());
    }

    return () => {
      dispatch(resetMultiMarketState());
    };
  }, [params.marketId]);

  useEffect(() => {
    if (marketInfo?.event.id) {
      dispatch(fetchMultiMarketDetails(marketInfo.event.id));
    }
  }, [marketInfo?.event.id]);

  useEffect(() => {
    if (
      keyMarket?.marketId &&
      marketInfo?.marketId &&
      keyMarket.marketId !== marketInfo?.marketId &&
      getIsFancyMarket(keyMarket.description) &&
      !keyMarketFancyDetails
    ) {
      let type: FancyMarketTypes;

      if (keyMarket.marketName.startsWith('1st')) {
        type = FancyMarketTypes.FIRST_INNINGS_FANCY;
      } else {
        type = FancyMarketTypes.SECOND_INNINGS_FANCY;
      }

      dispatch(fetchMultiMarketKeyMarketFancyDetails({ eventId: keyMarket.event.id, fancyMarketTypes: type }));
    }
  }, [keyMarket?.marketId, marketInfo?.marketId]);

  useEffect(() => {
    if (params.marketId && topMultiMarket?.topMarketId && topMultiMarket.topMarketId !== params.marketId) {
      dispatch(fetchMultiMarketKeyMarketDetails(topMultiMarket.topMarketId));
    }
  }, [marketInfo?.marketId, topMultiMarket?.topMarketId]);

  useEffect(() => {
    if (displayedTabs.length) {
      if (isDesktop) {
        let newMarkets = displayedTabs[0].marketCatalogues;

        if (isFancyView) {
          newMarkets = filterFancyMarkets(newMarkets);
        }

        setCurrentDisplayedMarkets(newMarkets);
        setActiveTab(displayedTabs[0]);

        if (searchParamTab) {
          navigate(`${SPORT_BASE_URL}/${params.sportId}/market/${params.marketId}`, { replace: true });
        }
      } else {
        const extraTab = displayedTabs.find(({ tabId }) => tabId === MULTI_MARKET_EXTRA_TAB_ID);

        if (extraTab) {
          let newMarkets = extraTab.marketCatalogues;

          if (isFancyView) {
            newMarkets = filterFancyMarkets(newMarkets);
          }

          setCurrentDisplayedMarkets(newMarkets);
          setActiveTab(extraTab);
        } else if (searchParamTab) {
          const currentTab = displayedTabs.find(({ tabId }) => String(tabId) === searchParamTab);

          if (currentTab) {
            let newMarkets = currentTab.marketCatalogues;

            if (isFancyView) {
              newMarkets = filterFancyMarkets(newMarkets);
            }

            setCurrentDisplayedMarkets(newMarkets);
            setActiveTab(currentTab);
          }
        } else if (!searchParamTab && activeTab?.tabName !== ALL_MARKETS_TAB_NAME) {
          let newMarkets = displayedTabs[0].marketCatalogues;

          if (isFancyView) {
            newMarkets = filterFancyMarkets(newMarkets);
          }

          setCurrentDisplayedMarkets(newMarkets);
          setActiveTab(displayedTabs[0]);
        }
      }
    }
  }, [displayedTabs, isDesktop, isFancyView]);

  useEffect(() => {
    // TODO if remove from here -> should be added in MobileExtraTabMultiMarket (if move to separate component for top market, because most likely it shouldn't be here)
    ref(marketWrapperRef.current);

    return () => {
      dispatch(resetMultiMarketTopDetails());
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
      dispatch(
        removeMarketPricesSocketParamsByMarketIds({
          section: MarketsPricesSocketParamsSections.SportsMiddleSection,
          marketIds: marketsToRemove.current.map(({ marketId }) => marketId)
        })
      );
    };
  }, []);

  useEffect(() => {
    if (isDesktop && (viewMarketSearchParam || searchParamTab)) {
      setSearchParams();
    }
  }, [isDesktop]);

  useEffect(() => {
    if (isMobile && marketInfo?.event.id) {
      dispatch(fetchGetEvents({ eventId: marketInfo.event.id }));
    }
  }, [isMobile, marketInfo?.event.id]);

  useEffect(() => {
    dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight }));
  }, [targetHeight]);

  const handleFetchTopMultiMarketRules = () => {
    if (isDesktop) {
      dispatch(fetchMultiMarketRules(marketInfo?.marketId ?? ''));
    } else {
      dispatch(fetchMultiMarketEventRules(marketInfo?.event.id ?? ''));
      setIsRulesView(true);
    }
  };

  const handleFetchMarketRules = useCallback(
    (marketId: string) => {
      dispatch(fetchMultiMarketRules(marketId));
    },
    [dispatch]
  );

  const handleChangeTab = useCallback(
    (tab: TMultiMarketTabDetails) => {
      if (activeTab?.tabId === MULTI_MARKET_EXTRA_TAB_ID) {
        dispatch(removeExtraMultiMarketTab());
      }

      let newMarkets = tab.marketCatalogues;

      if (isFancyView) {
        newMarkets = filterFancyMarkets(newMarkets);
      }

      setCurrentDisplayedMarkets(newMarkets);
      setActiveTab(tab);

      if (!isDesktop) {
        if (tab.tabId === displayedTabs[0].tabId) {
          navigate(
            {
              pathname: `${SPORT_BASE_URL}/${params.sportId}/market/${topMultiMarket?.topMarketId}`,
              search: `tab=${tab.tabId}`
            },
            { replace: true }
          );
        } else if (tab.tabName === ALL_MARKETS_TAB_NAME) {
          setSearchParams({}, { replace: true });
        } else if (tab.tabId !== MULTI_MARKET_EXTRA_TAB_ID && tab.tabName !== ALL_MARKETS_TAB_NAME) {
          setSearchParams({ tab: String(tab.tabId) }, { preventScrollReset: true, replace: true });
        }
      }
    },
    [
      activeTab?.tabId,
      displayedTabs,
      isDesktop,
      isFancyView,
      params?.sportId,
      setSearchParams,
      topMultiMarket?.topMarketId
    ]
  );

  const handleToggleOtherMarkets = () => {
    setCookie(CookieNames.MULTI_MARKETS_OTHER_IS_OPENED, !isOpenedMultiMarketsOther, { path: '/' });
  };

  const handlerPLTable = () => {
    setCookie(CookieNames.PL_TABLE_VISIBLE, cookies.PL_TABLE_VISIBLE ? '' : true);
  };

  const platformDisplayedMarkets = useMemo(() => {
    if (isDesktop) {
      let markets = currentDisplayedMarkets.filter(market => market.marketId !== params.marketId);

      if (
        params.marketId &&
        topMultiMarket?.topMarketId &&
        topMultiMarket.topMarketId !== params.marketId &&
        keyMarket &&
        !!displayedTabs.length &&
        activeTab?.tabId === displayedTabs[0].tabId
      ) {
        markets = currentDisplayedMarkets.filter(market => market.marketId !== keyMarket.marketId);

        if (isFancyView) {
          if (getIsNoFancyMarket(keyMarket.description)) {
            markets = [keyMarket, ...markets];
          }
        } else {
          markets = [keyMarket, ...markets];
        }
      }

      return markets;
    }
    let result = currentDisplayedMarkets;

    if (activeTab?.tabName === displayedTabs[0]?.tabName && marketInfo) {
      const isTopMarketInTab = currentDisplayedMarkets.some(({ marketId }) => marketId === marketInfo?.marketId);

      if (!isTopMarketInTab && (!isFancyView || !getIsFancyMarket(marketInfo.description))) {
        result = [marketInfo, ...currentDisplayedMarkets];
      }
    }

    return result.slice().sort(a => (a.marketId === params.marketId ? -1 : 1));
  }, [
    currentDisplayedMarkets,
    params.marketId,
    isDesktop,
    marketInfo,
    displayedTabs,
    activeTab,
    topMultiMarket?.topMarketId,
    keyMarket,
    isFancyView
  ]);

  const fancyMarketsData = useMemo(() => {
    if (isFancyView && activeTab?.tabId && marketInfo) {
      if (activeTab.tabId === MULTI_MARKET_EXTRA_TAB_ID && !isDesktop) {
        return {};
      }

      const data = getFancyMarketsData(marketInfo, activeTab);

      if (
        isDesktop &&
        keyMarketFancyDetails &&
        keyMarket &&
        marketInfo.marketId &&
        keyMarket.marketId !== marketInfo.marketId
      ) {
        const type = keyMarket.marketName.startsWith('1st')
          ? FancyMarketTypes.FIRST_INNINGS_FANCY
          : FancyMarketTypes.SECOND_INNINGS_FANCY;

        const isKeyMarketFancyInList = data.markets?.find(fancyData => fancyData.type === type);

        if (data.markets?.length) {
          if (!isKeyMarketFancyInList) {
            const fancyKeyMarketData: TFancyMultiMarketData = {
              inningsFancy: keyMarketFancyDetails.inningsFancy,
              inningsFours: keyMarketFancyDetails.inningsFours,
              inningsSixes: keyMarketFancyDetails.inningsSixes,
              fallOfWicket: keyMarketFancyDetails.fallOfWicket,
              totalInningsRuns: keyMarketFancyDetails.totalInningsRuns,
              otherLineMarkets: keyMarketFancyDetails.otherLineMarkets,
              batsmen: keyMarketFancyDetails.batsmen,
              eventInfo: keyMarketFancyDetails.eventInfo,
              id: 0,
              type
            };

            return {
              marketsParams: [
                {
                  marketId: keyMarket.marketId,
                  eventId: keyMarket.event.id
                },
                ...data.marketsParams
              ],
              markets: [fancyKeyMarketData, ...data.markets]
            };
          }

          const resultMarkets =
            type === FancyMarketTypes.SECOND_INNINGS_FANCY && data.markets?.length === 2
              ? data.markets.slice().reverse()
              : data.markets;

          return { ...data, markets: resultMarkets };
        }

        return data;
      }

      return data;
    }

    return {};
  }, [isFancyView, activeTab, marketInfo, isDesktop, keyMarketFancyDetails, keyMarket]);

  const mobileMarketsLinks = useMemo(() => {
    if (allMarketsTab?.marketCatalogues) {
      if (isFancyView && !isDesktop) {
        return allMarketsTab.marketCatalogues.filter(({ description }) => description.bettingType !== BettingType.LINE);
      }

      return allMarketsTab.marketCatalogues;
    }

    return [];
  }, [allMarketsTab?.marketCatalogues, isFancyView, isDesktop]);

  const mobileFancyNavLinks = useMemo(() => {
    if (!isDesktop && isFancyView && mobileMarketsLinks.length) {
      return getMobileFancyNavLinks(mobileMarketsLinks);
    }

    return [];
  }, [isDesktop, isFancyView, mobileMarketsLinks]);

  const handleClickLink = (market: IMarket) => {
    if (market.marketId === topMultiMarket?.topMarketId) {
      let newMarkets = displayedTabs[0].marketCatalogues;

      if (isFancyView) {
        newMarkets = filterFancyMarkets(newMarkets);
      }

      setCurrentDisplayedMarkets(newMarkets);
      setActiveTab(displayedTabs[0]);
    } else {
      dispatch(setExtraMultiMarketTab(market));
    }
  };

  const displaySections =
    isOpenedMultiMarketsOther &&
    !!marketPricesId &&
    !!platformDisplayedMarkets.length &&
    activeTab?.tabId !== MULTI_MARKET_EXTRA_TAB_ID;

  const displayFancyMultiMarkets =
    isFancyView &&
    activeTab?.tabName !== ALL_MARKETS_TAB_NAME &&
    !viewMarketSearchParam &&
    !!activeTab &&
    isOpenedMultiMarketsOther &&
    !!marketPricesId &&
    !!fancyMarketsData.markets?.length;

  const keyMarketIsNoFancy = !keyMarket || getIsNoFancyMarket(keyMarket.description);
  const isKeyMarketIdNotEqualCurrentDisplayedTopMarket =
    !!keyMarket?.marketId && !!marketInfo?.marketId && keyMarket.marketId !== marketInfo.marketId;
  const isShowCashOutBtn =
    marketInfo && marketPricesId
      ? marketInfo.cashOutEnabled && (!isFancyView || isDesktop) && !tooltipStatus[marketStatus || '']
      : false;

  const desktopSimpleKeyMarketBeforeFancyMarkets: IMarket | false =
    displayFancyMultiMarkets &&
    isKeyMarketIdNotEqualCurrentDisplayedTopMarket &&
    !!platformDisplayedMarkets.length &&
    !!displayedTabs.length &&
    activeTab?.tabId === displayedTabs[0].tabId &&
    keyMarketIsNoFancy &&
    platformDisplayedMarkets[0];

  const mobileSimpleMarket = isKeyMarketIdNotEqualCurrentDisplayedTopMarket ? keyMarket : platformDisplayedMarkets[0];

  const mobileSimpleKeyMarketBeforeFancyMarkets: IMarket | false =
    displayFancyMultiMarkets &&
    !!displayedTabs.length &&
    activeTab?.tabId === displayedTabs[0].tabId &&
    keyMarketIsNoFancy &&
    !!platformDisplayedMarkets.length &&
    mobileSimpleMarket;

  const simpleKeyMarketBeforeFancyMarkets = isDesktop
    ? desktopSimpleKeyMarketBeforeFancyMarkets
    : mobileSimpleKeyMarketBeforeFancyMarkets;
  marketsToRemove.current = [
    ...platformDisplayedMarkets,
    ...(mobileSimpleKeyMarketBeforeFancyMarkets ? [mobileSimpleKeyMarketBeforeFancyMarkets] : [])
  ];

  return (
    <>
      <div
        data-market-id={marketInfo?.marketId}
        data-event-id={marketInfo?.event.id}
        data-market-prices={true}
        ref={marketWrapperRef}
        className={classNames('biab_market', { ['biab_handicap-market']: isHandicap })}
      >
        {marketInfo?.marketId && (
          <MultiMarketViewIntervalRequestInjection
            marketId={marketInfo.marketId}
            cashOutEnabled={marketInfo.cashOutEnabled}
          />
        )}
        <div id="multiMarketContainer">
          {marketInfo?.event?.id && marketPricesId && (
            <>
              <EventHeaderHandler
                eventId={marketInfo.event.id}
                sportId={marketInfo.eventType.id}
                startDate={marketInfo.marketStartTime}
                inPlay={isInPlay}
                title={marketInfo.event.name}
                status={marketStatus}
                type={LiveWidgetHeaderType.MARKET}
                videoStreamingEnabled={marketInfo.event.videoStreamingEnabled}
                matchStatEnabled={marketInfo.event.matchStatEnabled}
              />
              {isDesktop && (
                <div className={classNames('biab_market-name', styles.multiMarket__marketName)}>
                  {multiMarketName && !isCloseMarket && (
                    <div className={styles.multiMarket__favouriteIcon}>
                      {isShowFavorite && <FavoriteStar entryData={favoriteData} />}
                    </div>
                  )}
                  {getDesktopMarketTitle(marketInfo.marketName, marketInfo.description, isFancyView, t)}
                </div>
              )}
              {!isRulesView && isDesktop && !isCloseMarket && (
                <MarketInfoRow
                  marketType={marketInfo.description.marketType}
                  marketName={marketInfo.marketName}
                  sportId={marketInfo.eventType.id}
                  isCashOutEnabled={marketInfo.cashOutEnabled}
                  marketStartTime={marketInfo.marketStartTime}
                  marketId={marketInfo.marketId}
                  marketsRules={rules}
                  rulesLoading={rulesLoading}
                  onFetchMarketRules={handleFetchTopMultiMarketRules}
                  isShowPlTable={isShowPlTable}
                  isOpenPLTable={cookies.PL_TABLE_VISIBLE}
                  handlerPLTable={handlerPLTable}
                  mobileViewOptions={{
                    noFavourite: true,
                    refreshAfterRules: true,
                    noBottom: true,
                    noCashOut: true,
                    noRulesModal: !isDesktop,
                    noCancelAllBetsBtn: true
                  }}
                />
              )}
              {cookies.PL_TABLE_VISIBLE && isShowPlTable && marketPricesCurrency && !isMobile && (
                <PLTable
                  marketId={marketInfo.marketId}
                  units={marketInfo?.description?.lineRangeInfo?.marketUnit || DEFAULT_MARKET_UNITS}
                  bettingType={marketInfo.description.bettingType}
                  marketType={marketInfo.description.marketType}
                  currencyCode={marketPricesCurrency}
                />
              )}
              {isDesktop && isShowCashOutBtn && (
                <CashOutButton
                  marketId={marketInfo.marketId}
                  partialCashOutEnabled={marketInfo.partialCashOutEnabled}
                />
              )}
              {isDesktop && (
                <>
                  {isFancyView && getIsFancyMarket(marketInfo.description) ? (
                    <FancyMultiMarketHeader isLayColumnDisabled={!isLayColumnEnabled} isDepthEnabled={isDepthEnabled} />
                  ) : (
                    <DesktopTopMarket
                      topMarket={marketInfo}
                      lines={lines}
                      linesText={linesText}
                      viewType={DesktopTopMarketViewType.MULTI_MARKET}
                      showAll={showAll}
                      reset={reset}
                      pageBlock={PageBlocks.MULTI_MARKET_VIEW_TOP_MARKET}
                      isDepthEnabled={isDepthEnabled}
                      isLandscape={isLandscape}
                      page={PlacementPage.MULTI_MARKET}
                    />
                  )}
                </>
              )}
            </>
          )}
          {!isRulesView && activeTab?.tabName !== ALL_MARKETS_TAB_NAME && (
            <div
              className={classNames(styles.multiMarket__otherMarkets, {
                [styles.multiMarket__otherMarkets__closed]: !isOpenedMultiMarketsOther && isDesktop
              })}
            >
              {isDesktop && (
                <DesktopOtherMarketsTitle
                  onToggleOtherMarkets={handleToggleOtherMarkets}
                  isOpenedTopMarkets={isOpenedMultiMarketsOther}
                  titleKey="market.otherMarketsSection"
                />
              )}
              {isOpenedMultiMarketsOther && (!noDesktopMarkets || !isDesktop) && activeTab?.tabId && (
                <MarketsTabs
                  onChangeTab={handleChangeTab}
                  activeTabId={activeTab.tabId}
                  activeTabName={activeTab.tabName}
                />
              )}
              {!isDesktop && marketPricesId && marketInfo && (
                <MarketInfoRow
                  marketType={marketInfo.description.marketType}
                  marketName={marketInfo.marketName}
                  sportId={marketInfo.eventType.id}
                  isCashOutEnabled={marketInfo.cashOutEnabled}
                  marketStartTime={marketInfo.marketStartTime}
                  marketId={marketInfo.marketId}
                  marketsRules={rules}
                  rulesLoading={rulesLoading}
                  onFetchMarketRules={handleFetchTopMultiMarketRules}
                  mobileViewOptions={{
                    noFavourite: true,
                    refreshAfterRules: true,
                    noBottom: true,
                    noCashOut: true,
                    noRulesModal: !isDesktop,
                    noCancelAllBetsBtn: true
                  }}
                />
              )}
              {isCloseMarket ||
                (isHasDesktopMarkets && (
                  <p className={styles.multiMarket__noMarkets}>{t('market.labels.noMarkets')}</p>
                ))}
              {activeTab?.tabId === MULTI_MARKET_EXTRA_TAB_ID && !isDesktop && (
                <MobileExtraTabMultiMarket isDepthEnabled={isDepthEnabled} isLandscape={isLandscape} />
              )}
              {isCloseMarket ? (
                <p className={styles.multiMarket__noMarkets}>{t('market.labels.noMarkets')}</p>
              ) : (
                <div
                  className={classNames(styles.multiMarket__fancyMultiMarketsAndOtherMarketSectionsContainer, {
                    [styles.multiMarket__fancyMultiMarketsAndOtherMarketSectionsContainer__display]:
                      displayFancyMultiMarkets && displaySections && topMultiMarket?.twoColumnView && isDesktop
                  })}
                >
                  {!isHasDesktopMarkets && (
                    <>
                      {simpleKeyMarketBeforeFancyMarkets && (
                        <MultiMarketSimpleMarkets
                          markets={[simpleKeyMarketBeforeFancyMarkets]}
                          onFetchMarketRules={handleFetchMarketRules}
                          displayFancyMultiMarkets={displayFancyMultiMarkets}
                          isDepthEnabled={isDepthEnabled}
                          isLandscape={isLandscape}
                        />
                      )}
                      {!noDesktopMarkets && displayFancyMultiMarkets && (
                        <div
                          className={classNames(styles.multiMarket__otherMarkets__sections, {
                            [styles.multiMarket__otherMarkets__sections__2columns]:
                              topMultiMarket?.twoColumnView && isDesktop,
                            [styles.multiMarket__otherMarkets__sections__contents]:
                              displaySections && topMultiMarket?.twoColumnView && isDesktop,
                            [styles.multiMarket__otherMarkets__sections__fancy]: displaySections
                          })}
                        >
                          {fancyMarketsData.markets?.map((data, index, arr) => (
                            <FancyMultiMarket
                              key={data.id}
                              data={data}
                              onFetchMarketRules={handleFetchMarketRules}
                              isLayColumnDisabled={!isLayColumnEnabled}
                              isDepthEnabled={isDepthEnabled}
                              isLastItem={index === arr.length - 1}
                            />
                          ))}
                        </div>
                      )}
                      {displaySections && (
                        <MultiMarketSimpleMarkets
                          markets={
                            simpleKeyMarketBeforeFancyMarkets
                              ? platformDisplayedMarkets.slice(1)
                              : platformDisplayedMarkets
                          }
                          onFetchMarketRules={handleFetchMarketRules}
                          displayFancyMultiMarkets={displayFancyMultiMarkets}
                          isDepthEnabled={isDepthEnabled}
                          isLandscape={isLandscape}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          {activeTab?.tabName === ALL_MARKETS_TAB_NAME && !isDesktop && (
            <>
              {isFancyView &&
                mobileFancyNavLinks.map(({ to, id, titleKey }) => (
                  <Link to={to} key={id} className={styles.multiMarket__allMarketsTab__link}>
                    <span>{t(titleKey)}</span>
                    <i
                      className={classNames(
                        'biab_arrow-icon fa2 fa2-arrow-right',
                        styles.multiMarket__allMarketsTab__icon
                      )}
                    />
                  </Link>
                ))}
              {mobileMarketsLinks.map(market => (
                <Link
                  to={{
                    pathname: `${SPORT_BASE_URL}/${params.sportId}/market/${market.marketId}`,
                    search: market.marketId === topMultiMarket?.topMarketId ? '' : 'viewMarket=true'
                  }}
                  replace
                  key={market.marketId}
                  className={styles.multiMarket__allMarketsTab__link}
                  onClick={() => handleClickLink(market)}
                >
                  <span>{market.marketName}</span>
                  <i
                    className={classNames(
                      'biab_arrow-icon fa2 fa2-arrow-right',
                      styles.multiMarket__allMarketsTab__icon
                    )}
                  />
                </Link>
              ))}
            </>
          )}
          {!isDesktop && !isRulesView && marketInfo?.event && activeTab?.tabName !== ALL_MARKETS_TAB_NAME && (
            <MobileAllMarketsLink
              to={{
                pathname: `${SPORT_BASE_URL}/${params.sportId}/event/${marketInfo.event.id}`,
                search: 'showEvent=true'
              }}
            />
          )}
          {isRulesView && <MobileRulesView setIsRulesView={setIsRulesView} markets={platformDisplayedMarkets} />}
        </div>
      </div>
      {hasMinHeightStyle && addedHeight > 0 && <div style={{ height: addedHeight }} />}
    </>
  );
};

export default MultiMarketView;
