import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { accountPagesBranding, componentsBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { TOfferState } from 'types/myBets';
import { getStatusTranslationKey } from 'utils/myBetsValues';

import styles from './styles.module.scss';

type TMyBetsStatusCellProps = {
  offerState: string;
  isRowHovered?: boolean;
  onCancelBet?: () => void;
  isAsian?: boolean;
  isPNC?: boolean;
};

const MyBetsStatusCell = ({ offerState, isRowHovered, isAsian, isPNC, onCancelBet }: TMyBetsStatusCellProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const state = offerState.toLowerCase();

  const isPositive = ['matched', 'won', ...(isPNC ? ['placed'] : [])].includes(state);
  const isNegative = ['unmatched', 'lost', ...(!isPNC ? ['placed'] : [])].includes(state);
  const isNeutral = ['cancelled', 'lapsed', 'voided'].includes(state);
  const isCancelBetAvailable = state === 'placed' && !!isRowHovered && !!onCancelBet;

  const statusValue = t(getStatusTranslationKey(offerState as TOfferState, isPNC, isAsian));

  if (isCancelBetAvailable) {
    return (
      <button className={classNames(styles.cancelBet, componentsBranding.SECONDARY_BTN)} onClick={onCancelBet}>
        {t('account.mybets.btns.cancelBet')}
      </button>
    );
  }

  return (
    <div
      className={classNames(styles.status, accountPagesBranding.BET_STATUS, {
        [styles.status__positive]: isPositive,
        [styles.status__negative]: isNegative,
        [styles.status__neutral]: isNeutral,
        [styles.status__mobile]: isMobile,
        [accountPagesBranding.POSITIVE]: isPositive,
        [accountPagesBranding.NEGATIVE]: isNegative,
        [accountPagesBranding.NEUTRAL]: isNeutral
      })}
    >
      <i
        className={classNames('biab_custom-icon-dot', accountPagesBranding.BET_STATUS_DOT, {
          [styles.status__dot__mobile]: isMobile
        })}
      />
      {statusValue}
    </div>
  );
};

export default MyBetsStatusCell;
