import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { unescape } from 'lodash';

import Image from 'components/Image/Image';
import { STARTING_TODAY } from 'constants/date';
import { SPORT_BASE_URL } from 'constants/locations';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { getMarketPricesCurrency, getMatchedAmountByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { TNextRace } from 'redux/modules/races/type';
import { SportId } from 'types';
import { parseMillisecondsToTime } from 'utils/date';

import styles from './NextRace.module.scss';

type NextRaceProps = {
  /**
   * NextRace which should be rendered
   */
  race: TNextRace;
  /**
   * Available next races count
   */
  racesCount: number;
};

const NextRace = ({ race, racesCount }: NextRaceProps) => {
  const { marketId, title, country, eventId } = race;
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const currency = useSelector(getMarketPricesCurrency(marketId));
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(marketId));

  const raceTime = useMemo(() => {
    if (timezone) {
      return parseMillisecondsToTime(race.time, timezone, timezoneCookieEnabled);
    }

    return '';
  }, [timezone, race.time, timezoneCookieEnabled]);

  const { showHoursAndMinutes, hoursAndMinutes, key, showMonthDay, monthDay, time } = useConvertTimeToString({
    startDate: race.time
  });
  const { formattedAmount: matchedAmount } = useFormatCurrency(totalMatched ?? '0', currency, {
    isCheckIndian: true
  });
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId,
    eventId,
    observerOptions: { rootMargin: '100px' },
    section: MarketsPricesSocketParamsSections.SportsNextRaces
  });

  const raceStartTime = () => {
    if (showHoursAndMinutes) {
      if (key === STARTING_TODAY) {
        return hoursAndMinutes;
      } else if (showMonthDay) {
        return `${unescape(t(`${key}`))} ${monthDay} ${hoursAndMinutes}`;
      } else {
        return `${unescape(t(`${key}`))} ${hoursAndMinutes}`;
      }
    } else {
      return unescape(
        t(`${key}`, '', {
          min: time
        })
      );
    }
  };

  return (
    <div
      ref={ref}
      data-market-id={marketId}
      data-event-id={eventId}
      data-market-prices={true}
      className={styles.nextRacesList__item}
      style={{ flexBasis: `${100 / racesCount}%` }}
    >
      <NavLink to={`${SPORT_BASE_URL}/${SportId.HORSE_RACING}/market/${marketId}`} className={styles.nextRace}>
        <div className={styles.nextRace__icon}>
          <Image
            className="dropdown-placeholder__label biab_next-race-img"
            path={`horse-racing/${country}.svg`}
            width={21}
            height={14}
            name={country}
          />
        </div>
        <div className={styles.nextRace__info}>
          <span className={styles.nextRace__title}>
            {raceTime} {title}
          </span>
          <span className={styles.nextRace__totalMatch}>{matchedAmount}</span>
        </div>
        <div className={styles.nextRace__date}>
          <span>{raceStartTime()}</span>
        </div>
        <div className={styles.nextRace__nav}>
          <i className="fa2 fa2-arrow-right" />
        </div>
      </NavLink>
    </div>
  );
};

export default NextRace;
