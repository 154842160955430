import { AppState } from 'redux/reducers';

import { ECancelActionStatuses } from './type';

export const getIsCancelActionsInProgress = ({ cancelActions }: AppState) =>
  cancelActions.cancelBetsNotification?.type === 'progress';

export const getCancelBetsNotification = ({ cancelActions }: AppState) => cancelActions.cancelBetsNotification;
export const getHasCancelBetsNotification = ({ cancelActions }: AppState) =>
  cancelActions.cancelBetsNotification !== null || cancelActions.inProgress;

export const getLoading = ({ cancelActions }: AppState) => cancelActions.loading;

export const getCancelActionStatus = ({ cancelActions }: AppState) => cancelActions.cancelActionStatus;

export const getCancelledBetsActualNumber = ({ cancelActions }: AppState) =>
  cancelActions.cancelActionStatus?.actualNumberCancelled ?? 0;
export const getCancelledBetsExpectedNumber = ({ cancelActions }: AppState) =>
  cancelActions.cancelActionStatus?.expectedNumberToCancel ?? 0;

export const getIsCancelActionMessage = ({ cancelActions }: AppState) =>
  cancelActions.cancelActionStatus?.status === ECancelActionStatuses.SUCCESSFUL ||
  cancelActions.cancelActionStatus?.status === ECancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED;

export const getCancelAllBtnState = ({ cancelActions }: AppState) => cancelActions.cancelAllBtnState;
