import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { PLBetType, PLExchangeTypes } from 'constants/myBets';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getProductMobileFilter } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';

import useActivePLProduct from './useActivePLProduct';

const usePLNetOfCommission = () => {
  const activePLTab = useActivePLProduct();
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const productMobileFilter = useSelector(getProductMobileFilter);
  const [cookies, setCookie] = useCookies([CookieNames.PL_NET_COMMISSION]);
  const isProfitNetOfCommissionChecked = getBooleanValue(cookies.PL_NET_COMMISSION);
  const currentProduct = !isUndefined(productMobileFilter) ? productMobileFilter : activePLTab;

  const isAsianView = currentProduct === PLExchangeTypes[PLBetType.AsianView];
  const isGamesView = currentProduct === PLExchangeTypes[PLBetType.Games];
  const hasNetCommission = (!isPNCEnabled && !isAsianView) || isGamesView;

  const onChange = () => {
    setCookie(CookieNames.PL_NET_COMMISSION, !isProfitNetOfCommissionChecked, { path: '/' });
  };

  return { hasNetCommission, value: isProfitNetOfCommissionChecked, onChange };
};

export default usePLNetOfCommission;
