import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { BETTING_TYPES } from 'constants/app';
import { MARKET_TYPES } from 'constants/marketTypes';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getDisplayCustomerCommissionRange, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import {
  getMarketPricesCommission,
  getMarketPricesCurrency,
  getMarketPricesNumberOfWinners
} from 'redux/modules/marketsPrices/selectors';
import { setSettings } from 'redux/modules/user';
import { getAccountSettings, getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { getCurrentWhatIfById, getNewWhatIfById } from 'redux/modules/whatIf/selectors';
import { CookieNames } from 'types';
import { TBetslipMarket } from 'types/betslip';
import { getBooleanValue } from 'utils';
import { getAHDoubleLineNetProfitTable } from 'utils/AHLiability';

import AHNetPLValueRows from './components/AHNetPLValueRows';
import NetPLValue from './components/NetPLValue';

import styles from './styles.module.scss';

const NetProfitLoss = ({ market, showHeader = false }: { market: TBetslipMarket; showHeader?: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.BIAB_BETSLIP_NET_PROFIT_LOSS]);

  const displayCustomerCommissionRange = useSelector(getDisplayCustomerCommissionRange);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const currency = useSelector(getMarketPricesCurrency(market.marketId));
  const marketCommission = useSelector(getMarketPricesCommission(market.marketId));
  const numberOfWinners = useSelector(getMarketPricesNumberOfWinners(market.marketId));
  const currentBets = useSelector(getCurrentWhatIfById(market.marketId));
  const newBets = useSelector(getNewWhatIfById(market.marketId));
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const accountSettings = useSelector(getAccountSettings);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const { netOfCommissionBetslip, netOfCommissionBetslipDefaultState } = useDeviceSettings();

  const eventName = market?.event?.name;
  const marketName = market.marketName;
  const checkboxLabel = t('betslip.netOfCommission.checkbox');
  const hasCommission = displayCustomerCommissionRange && !isPNCEnabled;
  const commission = marketCommission || market?.commission || 0;
  const isGoalLine = market.description.marketType === MARKET_TYPES.altTotalGoals;
  const isAHDoubleLine = market.description.bettingType === BETTING_TYPES.asianHandicapDoubleLine;
  const isMatchOdds = market.description.marketType === MARKET_TYPES.matchOdds;
  const isOverUnder = market.description.marketType.indexOf('OVER_UNDER_') !== -1;
  const cookieValue = cookies[CookieNames.BIAB_BETSLIP_NET_PROFIT_LOSS];
  const isEnabled = isLoggedIn && netOfCommissionBetslip;
  let isProfitNetOfCommissionBetSlipChecked: boolean;

  if (isLoggedIn) {
    isProfitNetOfCommissionBetSlipChecked = accountSettings?.netOfCommissionBetslipEnabledState || false;
  } else if (cookieValue !== undefined) {
    isProfitNetOfCommissionBetSlipChecked = getBooleanValue(cookieValue);
  } else if (!isEnabled) {
    isProfitNetOfCommissionBetSlipChecked = false;
  } else {
    isProfitNetOfCommissionBetSlipChecked = netOfCommissionBetslipDefaultState;
  }

  const onClick = () => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            netOfCommissionBetslipEnabledState: !isProfitNetOfCommissionBetSlipChecked
          }
        })
      );
    }

    setCookie(CookieNames.BIAB_BETSLIP_NET_PROFIT_LOSS, !isProfitNetOfCommissionBetSlipChecked, { path: '/' });
  };

  const AHDoubleLineNetProfitTable = getAHDoubleLineNetProfitTable(market, newBets, currentBets);

  const marketCurrency = newBets[0]?.currency;
  const isPL = currentBets.length !== 0;
  const isWhatIf = newBets.length !== 0;
  const hasCheckbox = !(!isGameBetslip && isPNCEnabled);

  const getAHTitle = () => {
    const runners = /(.*)\s(?:vs|v|@)\s(.*)/g.exec(market?.event?.name || market?.eventType?.name || '');
    let runnerHome, runnerAway;

    if (runners && runners.length === 3) {
      runnerHome = runners[1];
      runnerAway = runners[2];
    }

    return (
      <tr className={classNames(styles.headerRow, 'biab_net-profit-header')}>
        {isGoalLine ? (
          <>
            <th colSpan={2}>{t('betslip.netOfCommission.numberOfGoals')}</th>
            <th colSpan={2}>{t('betslip.netOfCommission.PL')}</th>
          </>
        ) : (
          <>
            <th />
            <th>
              <b>{runnerHome}</b> {t('betslip.netOfCommission.AHDoubleLine.winBy')}
            </th>
            <th>
              <b>{runnerAway}</b> {t('betslip.netOfCommission.AHDoubleLine.winBy')}
            </th>
          </>
        )}
      </tr>
    );
  };

  return isLoggedIn && (isMatchOdds || isOverUnder || isAHDoubleLine) ? (
    <div className={classNames(styles.tableWrapper, { biab_active: isProfitNetOfCommissionBetSlipChecked })}>
      <table
        className={classNames('biab_net-profit-table', styles.table, {
          [styles.activeTable]: isProfitNetOfCommissionBetSlipChecked
        })}
      >
        {!(isMatchOdds || isOverUnder) && (
          <colgroup>
            <col width="50px" />
            <col />
            <col />
          </colgroup>
        )}
        {showHeader && (
          <thead>
            <tr className={classNames('biab_net-profit-title', styles.title)}>
              <th colSpan={4}>
                {hasCheckbox && (
                  <button
                    onClick={onClick}
                    className={classNames('biab_checkbox-icon', styles.checkbox, {
                      [styles.activeCheckbox]: isProfitNetOfCommissionBetSlipChecked,
                      biab_active: isProfitNetOfCommissionBetSlipChecked
                    })}
                  >
                    {!isProfitNetOfCommissionBetSlipChecked && (
                      <i className={classNames('fa2 biab_checkbox-icon', 'fa2-confirmed-no', styles.noCheckIcon)}>
                        <span className="path1" />
                        <span className={classNames('path2', styles.noCheckIconBorder)} />
                      </i>
                    )}
                    {isProfitNetOfCommissionBetSlipChecked && <i className="fa fa-square biab_square" />}
                    {isProfitNetOfCommissionBetSlipChecked && (
                      <i className={classNames('fa fa-check biab_check', styles.checkIcon)} />
                    )}
                  </button>
                )}
                <span>
                  {checkboxLabel}{' '}
                  {isProfitNetOfCommissionBetSlipChecked && (
                    <span className={classNames('biab_net-profit-event', styles.eventName)}>
                      {eventName + ' - ' + marketName}
                    </span>
                  )}
                </span>
              </th>
            </tr>
          </thead>
        )}
        {isProfitNetOfCommissionBetSlipChecked && (
          <tbody>
            {isAHDoubleLine && getAHTitle()}
            {isAHDoubleLine && (
              <AHNetPLValueRows
                isGoalLine={isGoalLine}
                isPL={isPL}
                isWhatIf={isWhatIf}
                currency={marketCurrency}
                AHDoubleLineNetProfitTable={AHDoubleLineNetProfitTable}
              />
            )}
            {(isMatchOdds || isOverUnder) &&
              market?.runners?.map((runner, index) => (
                <tr key={`${index}_${runner.selectionId}`}>
                  <td
                    className={classNames(styles.textLeft, styles.noRightBorder)}
                    colSpan={2}
                    dangerouslySetInnerHTML={{
                      __html:
                        index == 2
                          ? t('betslip.labels.ifDraw')
                          : t('betslip.labels.teamWin', {
                              team: `<b>${runner.runnerName}</b>`,
                              interpolation: { escapeValue: false }
                            })
                    }}
                  />
                  <td className={styles.textRight} colSpan={1}>
                    <NetPLValue
                      marketId={market.marketId}
                      commission={market.commission || 0}
                      eachWayDivisor={market.description?.eachWayDivisor}
                      currency={currency}
                      numberOfWinners={numberOfWinners}
                      selectionId={runner.selectionId}
                      handicap={runner.handicap || 0}
                    />
                  </td>
                </tr>
              ))}
            {hasCommission && (
              <tr className={classNames(styles.commission, 'biab_net-profit-commission')}>
                <td className={styles.textLeft} colSpan={2}>
                  {t('betslip.netOfCommission.commission')}
                </td>
                <td className={styles.textRight} colSpan={isGoalLine ? 2 : 1}>
                  {+commission}%
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
    </div>
  ) : null;
};

export default NetProfitLoss;
