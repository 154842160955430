import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { mobileComponents } from 'constants/branding';
import SearchBar from 'pages/MyBetsPages/components/SearchBar/SearchBar';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { TGetBetAndPageDataParams } from 'types/myBets';

import styles from './styles.module.scss';

interface AccountSearchProps {
  isCurrentPeriod: boolean;
  isSearchBarOpen: boolean;
  setIsSearchBarOpen: (value: boolean) => void;
  getBetData: (params: TGetBetAndPageDataParams) => void;
}

const AccountSearch = ({ isCurrentPeriod, isSearchBarOpen, setIsSearchBarOpen, getBetData }: AccountSearchProps) => {
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  if (isSearchBarOpen) {
    return (
      <SearchBar
        isCurrentPeriod={isCurrentPeriod}
        isOpen={isSearchBarOpen}
        setIsOpen={setIsSearchBarOpen}
        getBetData={getBetData}
      />
    );
  }

  return (
    <button
      type="button"
      onClick={() => setIsSearchBarOpen(true)}
      className={classNames(styles.btn, mobileComponents.BUTTON, mobileComponents.TERTIARY, {
        [mobileComponents.DISABLED]: isCancellingBets
      })}
      disabled={isCancellingBets}
    >
      <i className="biab_custom-icon-search-24" />
    </button>
  );
};

export default AccountSearch;
