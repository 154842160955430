import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountSettingsQuickBets from 'components/AccountSettingsQuickBets/AccountSettingsQuickBets';
import { setSettings } from 'redux/modules/user';
import { getIsQuickStakesEnabled, getIsUserAccountSettings } from 'redux/modules/user/selectors';

import DesktopSettingsToggleableHeader from './DesktopSettingsToggleableHeader/DesktopSettingsToggleableHeader';

const DesktopSettingsQuickBets = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isQuickBetsEnabled = useSelector(getIsQuickStakesEnabled);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [isQuickBetsEnabledState, setIsQuickBetsEnabledState] = useState(isQuickBetsEnabled);

  const handleSwitchQuickBets = (value: boolean) => {
    setIsQuickBetsEnabledState(value);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            quickStakesEnabled: value
          }
        })
      );
    }
  };

  return (
    <>
      <DesktopSettingsToggleableHeader
        isChecked={isQuickBetsEnabledState}
        onToggle={handleSwitchQuickBets}
        title={t('account.settings.quickBets.newTitle')}
      />
      <AccountSettingsQuickBets />
    </>
  );
};

export default DesktopSettingsQuickBets;
