import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { asianViewMiddleSection, asianViewPopUps } from 'constants/branding';
import { setIsAsianCouponSettingsOpen } from 'redux/modules/asianView';
import { getIsAsianCouponSettingsOpen, getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';

import CouponSettingsContent from '../CouponSettingsContent';

import styles from './styles.module.scss';

const CouponSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isOpened = useSelector(getIsAsianCouponSettingsOpen);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);

  const [isLayBettingRulesModalOpen, setIsLayBettingRulesModalOpen] = useState(false);

  return (
    <>
      <button
        className={classNames(styles.settings__wrapper, asianViewMiddleSection.MOBILE_SUB_HEADER_BTN)}
        onClick={() => dispatch(setIsAsianCouponSettingsOpen(true))}
      >
        <i className={classNames('av-icon av-icon-settings', styles.settings__icon)} />
        {isLandscapeAsianView && <span className={styles.settings__label}>{t('asianView.labels.couponSettings')}</span>}
      </button>
      <Modal
        open={isOpened}
        clickableBackground
        noCloseIcon
        hasTitle={false}
        customClassNames={{
          body: classNames(styles.settings__modalBody, asianViewPopUps.POP_UP_BODY, asianViewPopUps.POP_UP_BORDER),
          content: styles.settings__modalContent,
          dialog: classNames(styles.settings__modalDialog, {
            [styles.settings__modalDialog__hidden]: isLayBettingRulesModalOpen
          })
        }}
        onClose={() => dispatch(setIsAsianCouponSettingsOpen(!isOpened))}
      >
        <CouponSettingsContent
          isLayBettingRulesModalOpen={isLayBettingRulesModalOpen}
          setIsLayBettingRulesModalOpen={setIsLayBettingRulesModalOpen}
        />
      </Modal>
    </>
  );
};

export default CouponSettings;
