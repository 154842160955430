import { createSelector } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';

import { AppState } from 'redux/reducers';

export const getAsianViewCashOutMarketsTotalElements = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.marketsData.totalElements;
export const getAsianViewCashOutMarketsTotalPages = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.marketsData.totalPages;
export const getAsianViewCashOutMarketsContent = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.marketsData.content;
export const getIsAsianViewCashOutMarketsContent = ({ asianViewCashOut }: AppState) =>
  !!asianViewCashOut.marketsData.content.length;
export const getAsianViewCashOutMarketsContentMarketIds = createSelector(
  getAsianViewCashOutMarketsContent,
  markets => markets?.map(({ marketId }) => marketId) ?? []
);
export const getAsianViewCashOutPaginationLoading = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.paginationLoading;
export const getAsianViewCashOutIsFirstMarketsLoaded = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.isFirstMarketsLoaded;
export const getAsianViewCashOutMarketsLoading = ({ asianViewCashOut }: AppState) => asianViewCashOut.marketsLoading;
export const getAsianViewCashOutQuoteByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.quotes[marketId];
export const getAsianViewCashOutQuoteCurrencyByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.quotes[marketId]?.currency;
export const getAsianViewQuoteLiabilityByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.quotes[marketId]?.currentLiability;
export const getAsianViewPendingCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.pendingCashOuts[marketId];
export const getAsianViewCashOutStatusByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.cashOutStatuses[marketId];
export const getAsianViewAutoCashOutStatusByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.autoCashOutMarkets[marketId]?.status;
export const getAsianViewCashOutSettingsTabByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.settingTabsStates[marketId];
export const getAsianViewAutoCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.autoCashOutMarkets[marketId];
export const getAsianViewStringifiedAutoCashOutMarkets = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.stringifiedAutoCashOutMarkets;
export const getAsianViewCashOutPlaceIdByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.placedIds[marketId];
export const getAsianViewCashOutPossibleProfitByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.possibleProfits[marketId];
export const getAsianViewCashOutSuccessFullCreatedAutoCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.successFullCreatedAutoCashOuts[marketId];
export const getAsianViewCashOutSuccessFullDeletedAutoCashOutByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.successFullDeletedAutoCashOuts[marketId];
export const getAsianViewCashOutCashedValueByMarketId =
  (marketId: string) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.cashedValues[marketId];
export const getAsianViewPendingCashOuts = ({ asianViewCashOut }: AppState) => asianViewCashOut.pendingCashOuts;
export const getIsAsianViewCashOutMarketsLastPage = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.marketsData.last;
export const getAsianViewCashOutMarketsByCompetitions = createSelector(getAsianViewCashOutMarketsContent, content => {
  return groupBy(content, ({ outrightMarket, event }) => (outrightMarket ? 'outrights' : `${event.id} `));
});

export const getAsianViewCashOutWhatIfBySelectionId =
  (marketId: string, selectionId: number) =>
  ({ asianViewCashOut }: AppState) =>
    asianViewCashOut.whatIfMarketId === marketId ? asianViewCashOut.whatIf[selectionId] : null;

export const getIsAsianViewCashOutFirstQuotesLoaded = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.isFirstQuotesLoaded;
export const getAsianViewCashOutQuotesLength = ({ asianViewCashOut }: AppState) =>
  Object.keys(asianViewCashOut.quotes).length;
const getAsianViewCashOutQuotes = ({ asianViewCashOut }: AppState) => asianViewCashOut.quotes;
export const getAsianViewCashOutQuotesMarketsIds = createSelector(getAsianViewCashOutQuotes, quotes =>
  Object.keys(quotes)
);
export const getIsAsianViewCashOutMarkets = ({ asianViewCashOut }: AppState) =>
  !!asianViewCashOut.marketsData.content.length;

export const getASianViewCashOutStringifiedQuotes = ({ asianViewCashOut }: AppState) =>
  asianViewCashOut.stringifiedQuotes;
export const getASianViewCashOutQuotesLoading = ({ asianViewCashOut }: AppState) => asianViewCashOut.quotesLoading;
