import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { getFeaturedSports, getHomePageType } from 'redux/modules/appConfigs/selectors';
import { getFeaturedMarkets } from 'redux/modules/featuredMarkets/selectors';
import { AppState } from 'redux/reducers';
import { HomePageType } from 'types';

export const getPopularMarkets = ({ popularMarkets }: AppState) => popularMarkets.markets;
export const getLoadingPopularMarkets = ({ popularMarkets }: AppState) => popularMarkets.loading;

export const getPopularMarketsAndFeatureMarketsSport = createSelector(
  getFeaturedMarkets,
  getHomePageType,
  getFeaturedSports,
  (featuredMarkets, homePageType, featuredSports) => {
    const sportsIds = featuredMarkets.map(({ eventType }) => eventType.id);

    if (homePageType === HomePageType.FEATURED_SPORTS) {
      featuredSports.forEach(sportId => {
        sportsIds.push(sportId);
      });
    }

    return uniq(sportsIds);
  }
);
