import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ONE_MINUTE_IN_MS } from 'constants/date';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setShowReminder } from 'redux/modules/responsibleBetting';
import {
  getIsSelfExclusionEnabled,
  getIsTimeOutEnabled,
  getResponsibleBettingSettings,
  getShowReminder
} from 'redux/modules/responsibleBetting/selectors';
import { getUserSessionInfo } from 'redux/modules/user/selectors';

const useReminder = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const sessionInfo = useSelector(getUserSessionInfo);
  const showReminder = useSelector(getShowReminder);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);

  const rbSettings = useSelector(getResponsibleBettingSettings);
  const { reminderEnabled = false, reminderPeriod = null } = rbSettings?.rgTools || {};

  const startSessionDate = sessionInfo?.startSessionDate;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const triggerReminder = () => {
    if (!showReminder) {
      dispatch(setShowReminder(true));
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (reminderPeriod) {
      timeoutRef.current = setTimeout(triggerReminder, reminderPeriod * ONE_MINUTE_IN_MS);
    }
  };

  useEffect(() => {
    if (
      !isLoggedIn ||
      !reminderEnabled ||
      !startSessionDate ||
      !reminderPeriod ||
      isSelfExclusionEnabled ||
      isTimeOutEnabled
    ) {
      return;
    }
    timeoutRef.current = setTimeout(triggerReminder, reminderPeriod * ONE_MINUTE_IN_MS);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isLoggedIn, startSessionDate, reminderPeriod, reminderEnabled, dispatch]);
};

export default useReminder;
