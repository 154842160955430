import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ResponsibleBettingReminderView from 'components/ResponsibleBetting/components/ResponsibleBettingReminderView';
import { ResponsibleBettingSection } from 'components/ResponsibleBetting/components/ResponsibleBettingSection';
import { REMINDER_MAX_PERIOD, REMINDER_MIN_PERIOD } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import { updateResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { ResponsibleBettingTab, UpdatedResponsibleBettingDisplayedContent } from 'types/responsibleBetting';

interface ResponsibleBettingReminderProps {
  tab: ResponsibleBettingTab;
}

const ResponsibleBettingReminder = ({ tab }: ResponsibleBettingReminderProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const [isUpdatingMode, setIsUpdatingMode] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const rbSettings = useSelector(getResponsibleBettingSettings);

  const { reminderEnabled = false, reminderPeriod = null } = rbSettings?.rgTools || {};

  const [selectedLimit, setSelectedLimit] = useState<number | null>(reminderPeriod);

  const isVisibleForm = isOpened || (reminderEnabled && ((!isUpdatingMode && !!selectedLimit) || isUpdatingMode));

  const validateLimitInput = (limit: number) => {
    setError(null);
    if (limit !== undefined && limit < REMINDER_MIN_PERIOD) {
      setError(
        t('responsibleBetting.validation.minNumber', {
          N: `<strong>${REMINDER_MIN_PERIOD}</strong>`,
          interpolation: { escapeValue: false }
        })
      );
      return;
    } else if (limit && limit > REMINDER_MAX_PERIOD) {
      setError(
        t('responsibleBetting.validation.maxNumber', {
          N: `<strong>${REMINDER_MAX_PERIOD}</strong>`,
          interpolation: { escapeValue: false }
        })
      );
      return;
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const limit = event.target.value.trim().replace(/[^0-9.]/g, '');
    if (limit) {
      validateLimitInput(+limit);
    }
    setSelectedLimit(limit ? +limit : null);
  };

  const handleOnApply = () => {
    if (isUpdatingMode) {
      setIsUpdatingMode(false);
    }

    if (rbSettings?.rgTools) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            reminderEnabled: true,
            reminderPeriod: selectedLimit,
            type: UpdatedResponsibleBettingDisplayedContent.Reminder
          },
          type: UpdatedResponsibleBettingDisplayedContent.Reminder
        })
      );
    }
  };

  const handleOnCancel = () => {
    setSelectedLimit(reminderPeriod);
    setIsOpened(false);

    if (isUpdatingMode) {
      setIsUpdatingMode(false);
    }

    if (reminderPeriod) {
      validateLimitInput(reminderPeriod);
    }
  };

  const handleOpen = () => {
    setError(null);
    setSelectedLimit(reminderPeriod);
    setIsOpened(true);
  };

  const handleOnRemove = () => {
    if (rbSettings?.rgTools) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            reminderEnabled: false,
            type: UpdatedResponsibleBettingDisplayedContent.Reminder
          },
          type: UpdatedResponsibleBettingDisplayedContent.Reminder
        })
      );
    }

    setIsOpened(false);
    setSelectedLimit(null);
  };

  return (
    <ResponsibleBettingSection tab={tab} onClick={handleOpen} isOpened={isVisibleForm}>
      {isVisibleForm && (
        <ResponsibleBettingReminderView
          reminderEnabled={reminderEnabled}
          reminderPeriod={reminderPeriod}
          selectedLimit={selectedLimit}
          error={error}
          onApply={handleOnApply}
          onCancel={handleOnCancel}
          onChange={handleOnChange}
          onUpdate={() => setIsUpdatingMode(true)}
          onRemove={handleOnRemove}
          isMobile={isMobile}
          isUpdatingMode={isUpdatingMode}
          inputLabel={t('responsibleBetting.labels.remindMeEvery')}
          inputPlaceholder={t('responsibleBetting.placeholders.enterAmount')}
        />
      )}
    </ResponsibleBettingSection>
  );
};

export default ResponsibleBettingReminder;
