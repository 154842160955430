import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ASIAN_TABS } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewTimeFilter,
  AsianViewTimeFrame,
  FETCH_EVENTS_COUNTERS_BY_TIME_FRAME_INTERVAL
} from 'constants/asianView';
import useLeaguesParam from 'hooks/useLeaguesParam';
import { getIsPropertiesLoaded } from 'redux/modules/appConfigs/selectors';
import { fetchAsianCounter } from 'redux/modules/asianSportsNavigation';
import { getLeaguesCurParams, getLeaguesIsLoaded } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsValidMarketLink, getIsValidTimeFilter } from 'utils/asianView';

import List from './components/List';
import Tab from './components/Tab';

import styles from '../../styles.module.scss';

type ContentProps = {
  sportId: string;
  isCashOutPage?: boolean;
};

const Content = ({ sportId, isCashOutPage = false }: ContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { timeFilter, marketLink } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const areLeaguesLoaded = useSelector(getLeaguesIsLoaded);
  const curLeaguesParam = useSelector(getLeaguesCurParams);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const leaguesParam = useLeaguesParam();

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && !!singleViewSportId;
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const outrightCompetitionId = searchParams.get(ASIAN_OUTRIGHT_COMPETITION_ID);
  const isSingleOutrightMarketView = !!outrightCompetitionId;

  useEffect(() => {
    const isNotValidTimeFilter = !timeFilter || !getIsValidTimeFilter(timeFilter);
    const isNotValidMarketLink = !marketLink || !getIsValidMarketLink(marketLink);

    if (
      arePropertiesLoaded &&
      (isCashOutPage ||
        (areLeaguesLoaded && curLeaguesParam === `${sportId}-${marketLink}-${timeFilter}`) ||
        isSingleOutrightMarketView ||
        isNotValidTimeFilter ||
        isNotValidMarketLink)
    ) {
      const fetchCounters = (withLoader = false) => {
        dispatch(
          fetchAsianCounter({
            sportId,
            requestPayload: {
              marketLink:
                isCashOutPage || isNotValidMarketLink || isSingleView || isFavouriteView || isPopularLinkView
                  ? AsianViewMarketLink.HDP_AND_OU
                  : (marketLink as AsianViewMarketLink),
              timeFilter: isNotValidTimeFilter
                ? AsianViewTimeFilter.Today
                : (timeFilter as AsianViewTimeFilter) || AsianViewTimeFilter.Today,
              timeFrame: AsianViewTimeFrame.ALL,
              competitions: isCashOutPage ? [] : leaguesParam,
              withLoader
            },
            navigate:
              isSingleView || isFavouriteView || isPopularLinkView || isCashOutPage || isSingleOutrightMarketView
                ? undefined
                : navigate
          })
        );
      };

      fetchCounters(true);

      intervalRef.current = setInterval(fetchCounters, FETCH_EVENTS_COUNTERS_BY_TIME_FRAME_INTERVAL);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [
    timeFilter,
    marketLink,
    sportId,
    leaguesParam,
    areLeaguesLoaded,
    arePropertiesLoaded,
    isLoggedIn,
    isSingleOutrightMarketView
  ]);

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        {ASIAN_TABS.map(tab => (
          <Tab
            sportId={sportId}
            key={tab.key}
            identifier={tab.key}
            selected={tab.key === (timeFilter ?? AsianViewTimeFilter.Today)}
            title={t(`asianView.labels.timeFilter.${tab.key}`)}
            isCashOutPage={isCashOutPage}
          />
        ))}
      </div>
      <List sportId={sportId} />
    </div>
  );
};

export default Content;
