import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAppConfigs, setTimezone } from 'redux/modules/appConfigs';
import { getLanguage, getSupportedLocales } from 'redux/modules/appConfigs/selectors';
import { MobilePreferencesOption } from 'types/mobile';
import { convertTimezoneToDropdown } from 'utils';
import { getLanguageCookieName } from 'utils/cookie';
import { getDefaultTimezoneOffset, getTimezoneCookieName } from 'utils/date';
import { parseDomainFromUrl } from 'utils/url';

const useMobilePreferences = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timezoneCookieName = getTimezoneCookieName();
  const languageCookieName = getLanguageCookieName();
  const [cookies, setCookie] = useCookies([timezoneCookieName, languageCookieName]);

  const language = useSelector(getLanguage);
  const supportedLocales = useSelector(getSupportedLocales);

  const [showLocaleView, setShowLocaleView] = useState(false);
  const [showTimezoneView, setShowTimezoneView] = useState(false);

  const locales = supportedLocales.map((item, index) => ({
    id: index,
    value: item.code,
    label: t(`locale.${item.code}`),
    image: `locales/${item.code}.svg`
  }));
  const selectedLocale = locales.find(locale => locale.value === language);
  const languageCookie = cookies[languageCookieName];
  const timezoneCookie = cookies[timezoneCookieName] || getDefaultTimezoneOffset();
  const timezoneOffsets = convertTimezoneToDropdown(t);
  const selectedOffset = timezoneOffsets.find(item => item.value === timezoneCookie);

  const handleSelectTimezone = (timezone: MobilePreferencesOption) => {
    setCookie(timezoneCookieName, timezone.value, { path: '/' });
    dispatch(setTimezone(timezone.value));
    window.location.reload();
  };

  const setLanguageCookie = (value: string) => {
    setCookie(languageCookieName, value, {
      path: '/',
      domain: parseDomainFromUrl(
        window.location.hostname,
        window.environmentConfig?.langCookieDomainPattern ||
          (process.env.REACT_APP_LANG_COOKIE_DOMAIN_PATTERN as string)
      )
    });
  };

  const handleSelectLocal = (local: MobilePreferencesOption) => {
    setLanguageCookie(local.value);
    dispatch(fetchAppConfigs({ changeLoadingState: false }));
    window.location.reload();
  };

  useEffect(() => {
    if (language && !languageCookie) {
      setLanguageCookie(language);
    }
  }, [language, languageCookie]);

  return {
    showLocaleView,
    setShowLocaleView,
    showTimezoneView,
    setShowTimezoneView,
    onSelectTimezone: handleSelectTimezone,
    onSelectLocal: handleSelectLocal,
    selectedLocale,
    selectedOffset,
    locales,
    timezoneOffsets
  };
};

export default useMobilePreferences;
