import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reverse, sortBy } from 'lodash';

import { getDesktopSettingsFillOrKill } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { closeAllCurrentBetsCanBeRemoved } from 'redux/modules/currentBets';
import {
  getCurrentBetsByType,
  getCurrentBetsLengthByType,
  getUpdatedOffers
} from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';

import CancelAllUnmatched from '../CancelAllUnmatched/CancelAllUnmatched';
import OpenedBet from '../OpenedBet';
import OpenedBetsHeader from '../OpenedBetsHeader';
import OpenedBetsTabEmpty from '../OpenedBetsTabEmpty/OpenedBetsTabEmpty';
import OpenedBetsUnmatchedHeader from '../OpenedBetsUnmatchedHeader/OpenedBetsUnmatchedHeader';

const OpenedBetsUnmatched = () => {
  const dispatch = useDispatch();
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const fillOrKillEnabled = useSelector(getDesktopSettingsFillOrKill);

  useEffect(() => {
    if (fillOrKillEnabled) {
      dispatch(closeAllCurrentBetsCanBeRemoved());
    }
  }, [dispatch, fillOrKillEnabled]);

  const unmatchedBets = useSelector(
    getCurrentBetsByType({
      type: MatchTypes.UNMATCHED,
      isGameType: isGameBetslip,
      anyCancelled: true,
      showLapsed: true
    })
  );

  const unmatchedBetsWithoutCancelled = useSelector(
    getCurrentBetsLengthByType({
      type: MatchTypes.UNMATCHED,
      isGameType: isGameBetslip,
      ignoreCancelled: true,
      ignoreFullyMatchedAction: true
    })
  );

  const updatedOffers = useSelector(getUpdatedOffers);

  const sortedCurrentBets = useMemo(() => {
    return reverse(sortBy(unmatchedBets, 'placedDate'));
  }, [unmatchedBets]);

  if (!unmatchedBets.length) {
    return <OpenedBetsTabEmpty type="unmatched" />;
  }

  return (
    <>
      {unmatchedBetsWithoutCancelled > 0 && <CancelAllUnmatched />}
      {sortedCurrentBets.map((bet, index) => {
        return (
          <Fragment key={`${bet.offerId}_${bet.matchType ?? ''}`}>
            <OpenedBetsUnmatchedHeader bet={bet} />
            <OpenedBetsHeader
              bet={bet}
              prevBet={sortedCurrentBets[index - 1] ?? null}
              side={bet.side}
              matchType={MatchTypes.UNMATCHED}
            />
            <OpenedBet
              bet={bet}
              matchType={MatchTypes.UNMATCHED}
              betIndex={index}
              isFirst={index === 0}
              isLast={index === sortedCurrentBets.length - 1}
              side={bet.side}
              updatedBet={updatedOffers[bet.offerId]}
            />
          </Fragment>
        );
      })}
    </>
  );
};

export default OpenedBetsUnmatched;
