import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import SortingField from 'components/BetsTable/components/SortingField';
import { MIN_ITEMS_PAGINATION, PLBetType, SEARCH_CURRENCY_KEY } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useActivePLProduct from 'hooks/useActivePLProduct';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import { useSetHighlightedRow } from 'hooks/useSetHighlightedRow';
import FiltersButton from 'pages/MyBetsPages/components/FiltersButton';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import { getCurrency, getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { setMobileSorting, setMultiCurrencyValue } from 'redux/modules/myBets';
import { bettingPLLoading, getMobileSorting, getPLData } from 'redux/modules/myBets/selectors';
import { SortByFields, TSortByType } from 'redux/modules/myBets/type';
import { addDays, getDaysDifference, getPeriod, getTodayDate, getTodayEndDate } from 'utils/date';

import PLTable from './components/PLTable';
import TotalPL from './components/TotalPL/TotalPL';

import styles from './styles.module.scss';

interface BettingProfitAndLossProps {
  activePLTab: PLBetType;
}

const BettingProfitAndLoss = ({ activePLTab }: BettingProfitAndLossProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const betType = useActivePLProduct();
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const isLoading = useSelector(bettingPLLoading);
  const plData = useSelector(getPLData);
  const currency = useSelector(getCurrency);
  const mobileSorting = useSelector(getMobileSorting);

  const { isProductDropdownEnabled } = useAccountProducts();
  const sortingOptions = [SortByFields.SETTLED_DATE, SortByFields.START_DATE] as TSortByType[];
  const currentSorting =
    mobileSorting && sortingOptions.includes(mobileSorting.type) ? mobileSorting.type : SortByFields.SETTLED_DATE;

  const {
    startDate,
    eventType,
    pageSize,
    refreshData,
    bettingDay,
    endDate,
    timezoneOffset,
    handleSetPageSize,
    handleSetPageSizeAndNumber,
    handleSetPageNumber,
    pageNumber
  } = useMyBetsPagesNavigation();

  const todayDate = getTodayEndDate(timezoneOffset || timezone, timezoneCookieEnabled || bettingDay);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);

  const currentPeriod =
    startDate && endDate
      ? getPeriod(
          addDays(startDate, -1 * dayDifference),
          addDays(endDate, -1 * dayDifference),
          undefined,
          dayDifference
        )
      : '';

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const { rowHighlighted, setRowHighLighted } = useSetHighlightedRow(pageNumber, pageSize, {
    startDate,
    eventType,
    activePLTab
  });

  const dateParams = { hideHoursMinutes: true, divider: ',' };
  const { date: startDateFormatted } = useParseMyBetsDates({ dateToParse: startDate.getTime(), ...dateParams });
  const { date: endDateFormatted } = useParseMyBetsDates({ dateToParse: endDate.getTime(), ...dateParams });

  const periodLabel =
    currentPeriod === 'custom'
      ? `${startDateFormatted} - ${endDateFormatted}`
      : t(`account.labels.period.${currentPeriod}`);

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const { content, totalElements } = plData;
  const { isMobile } = useDevice();

  const handleSortMobile = ({ sortBy, value }: { sortBy: string; value: 'asc' | 'desc' }) => {
    dispatch(setMobileSorting({ type: sortBy as TSortByType, order: value }));
    refreshData({
      sortBy: {
        [sortBy]: value
      }
    });
  };

  useEffect(() => {
    if (isMultiCurrencySupported && currency.currencyCode) {
      dispatch(setMultiCurrencyValue(searchCurrency || currency.currencyCode));
    }
  }, [currency.currencyCode, isMultiCurrencySupported]);

  return (
    <div>
      {isMobile && (
        <>
          <div className={styles.mobileFilters}>
            <TotalPL />
            <FiltersButton />
          </div>
          <div className={styles.mobileTableHeader}>
            <div className={styles.mobileTableHeader__left}>
              <p className={styles.mobileTableHeader__dateRange}>{periodLabel}</p>
              {isProductDropdownEnabled && (
                <p className={styles.mobileTableHeader__product}>
                  {t(`account.profitandloss.labels.${betType.toLowerCase()}`)}
                </p>
              )}
            </div>
            <SortingField
              sortBy={currentSorting}
              sortingValue={mobileSorting?.order ?? 'desc'}
              onSorting={handleSortMobile}
            />
          </div>
        </>
      )}
      <div
        className={classNames(styles.skeletonContainer, {
          [styles.skeletonContainer__active]: !isMobile && isLoading
        })}
      >
        <PLTable content={content} rowHighlighted={rowHighlighted} setRowHighLighted={setRowHighLighted} />
      </div>

      {!isLoading && totalElements > MIN_ITEMS_PAGINATION && (
        <Pagination
          source={plData}
          pageSize={pageSize}
          setPageSize={handleSetPageSize}
          pageNumber={pageNumber}
          setPageNumber={handleSetPageNumber}
          setPageSizeAndNumber={handleSetPageSizeAndNumber}
        />
      )}
    </div>
  );
};

export default BettingProfitAndLoss;
