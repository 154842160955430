import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { MY_BETS_CONTENT_SM_WIDTH_DESKTOP } from 'constants/myBets';
import { getCancelledBetsActualNumber, getIsCancelActionMessage } from 'redux/modules/cancelActions/selectors';
import { showMobileBetDetails } from 'redux/modules/myBets';
import { getMyBets } from 'redux/modules/myBets/selectors';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';

import MobileContentItem from './components/MobileContentItem';
import TableRow from './components/TableRow';

import styles from './styles.module.scss';

type BetsTableProps = {
  isMobile?: boolean;
  windowWidth: number;
  tableWidth?: number;
  descriptionWidth?: number;
};

const BetsTable = ({ isMobile = false, windowWidth, tableWidth, descriptionWidth }: BetsTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { content } = useSelector(getMyBets);
  const cancelledBetsActualNumber = useSelector(getCancelledBetsActualNumber);
  const isCancelActionMessage = useSelector(getIsCancelActionMessage);

  const openMobileBetDetails = (bet: TMyBetsContentItem) => {
    dispatch(showMobileBetDetails(bet));
  };

  return (
    <>
      {content?.length ? (
        content.map(el => {
          if (isMobile) {
            return <MobileContentItem openMobileBetDetails={openMobileBetDetails} bet={el} key={el.offerId} />;
          }

          return (
            <TableRow
              item={el}
              key={el.offerId}
              windowWidth={windowWidth}
              descriptionWidth={
                descriptionWidth && tableWidth && windowWidth > MY_BETS_CONTENT_SM_WIDTH_DESKTOP
                  ? descriptionWidth
                  : undefined
              }
            />
          );
        })
      ) : (
        <>
          {(isMobile || !isCancelActionMessage) && (
            <div className={classNames(styles.noData, { [styles.noData__mobile]: isMobile })}>
              {t('account.mybets.messages.noData')}
            </div>
          )}
        </>
      )}
      {!isMobile && isCancelActionMessage && (
        <div className={styles.noData}>
          {t('account.mybets.labels.cancelledAllMessage', { number: cancelledBetsActualNumber })}
        </div>
      )}
    </>
  );
};

export default BetsTable;
