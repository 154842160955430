import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';
import { PageBlocks, PlacementPage } from 'types';
import { BetTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';

import { MobilePlacementNotificationsMap } from './type';

const getInlineSelected = (state: AppState) => state.inlinePlacement;

export const getInlineSelectedBetByMarket = (pageBlock: PageBlocks | null = null, marketId: string) =>
  createSelector(getInlineSelected, inlinePlacement => {
    if (pageBlock && marketId) {
      const bets = Object.values(inlinePlacement.bets[pageBlock]?.[marketId]?.bets ?? {});

      return bets.length ? bets[0] : null;
    }

    return null;
  });

export const getIsInlineSelectedBetByMarket = (pageBlock: PageBlocks | null = null, marketId: string) =>
  createSelector(getInlineSelectedBetByMarket(pageBlock, marketId), selectedBet => !!selectedBet);

export const getInlineSelectedBetBySelection =
  (pageBlock: PageBlocks | null = null, marketId: string, selectionId: number, handicap: string | number | null) =>
  (state: AppState) => {
    const selectedBet = getInlineSelectedBetByMarket(pageBlock, marketId)(state);
    return selectedBet &&
      selectedBet.selectionId === selectionId &&
      (+(selectedBet.handicap || 0) === +(handicap || 0) || selectedBet.bettingType === BettingType.LINE)
      ? selectedBet
      : null;
  };

export const getInlineSelectedBetsByMarket = (pageBlock: PageBlocks | null = null, marketId: string) =>
  createSelector(getInlineSelected, inlinePlacement => {
    return pageBlock && marketId
      ? Object.values(inlinePlacement.bets[pageBlock]?.[marketId]?.bets ?? {}).reverse()
      : [];
  });

export const getInlineSelectedBetsBySelection = (
  pageBlock: PageBlocks | null = null,
  marketId: string,
  id: number,
  hc: string | number | null
) =>
  createSelector(getInlineSelectedBetsByMarket(pageBlock, marketId), selectedBets => {
    return selectedBets
      .filter(({ selectionId, handicap, bettingType }) => {
        return selectionId === id && (+(handicap || 0) === +(hc || 0) || bettingType === BettingType.LINE);
      })
      .reverse();
  });

export const getIsInlineSelectedBetsBySelection = (
  pageBlock: PageBlocks | null = null,
  marketId: string,
  id: number,
  hc: string | number | null
) =>
  createSelector(getInlineSelectedBetsBySelection(pageBlock, marketId, id, hc), selectedBets => !!selectedBets.length);

export const getInlineSelectedBetBeforePlacement = (
  pageBlock: PageBlocks | null = null,
  marketId: string,
  id: number,
  hc: string | number | null,
  betType: BetTypes
) =>
  createSelector(getInlineSelectedBetsBySelection(pageBlock, marketId, id, hc), selectedBets =>
    selectedBets.find(
      ({ action, type, placedBet }) =>
        (action === Actions.SELECT || action === Actions.CONFIRM) && type === betType && !placedBet
    )
  );

export const getInlineSelectedBet =
  (
    pageBlock: PageBlocks | null = null,
    marketId: string,
    selectionId: number,
    handicap: string | number | null,
    type: BetTypes
  ) =>
  (state: AppState) => {
    const selectedBet = getInlineSelectedBetBySelection(pageBlock, marketId, selectionId, handicap)(state);
    return selectedBet && selectedBet.type === type ? selectedBet : null;
  };

export const getInlineSelectedBets = ({ inlinePlacement }: AppState) => inlinePlacement.bets;

export const getMobilePlacementNotifications = (isGamePage: boolean) =>
  createSelector(getInlineSelected, ({ mobilePlacementNotifications }) => {
    if (isGamePage) {
      return Object.entries(mobilePlacementNotifications).reduce<MobilePlacementNotificationsMap>(
        (acc, [betUuid, betInfo]) => {
          if (betInfo.pageBlock === PageBlocks.GAME || betInfo.bet?.page === PlacementPage.GAME) {
            return {
              ...acc,
              [betUuid]: betInfo
            };
          }

          return acc;
        },
        {}
      );
    }

    return Object.entries(mobilePlacementNotifications).reduce<MobilePlacementNotificationsMap>(
      (acc, [betUuid, betInfo]) => {
        if (betInfo.pageBlock === PageBlocks.GAME || betInfo.bet?.page === PlacementPage.GAME) {
          return acc;
        }

        return {
          ...acc,
          [betUuid]: betInfo
        };
      },
      {}
    );
  });

export const getAreMobilePlacementNotifications = (isGamePage: boolean) =>
  createSelector(
    getMobilePlacementNotifications(isGamePage),
    notificationsMap => !!Object.keys(notificationsMap).length
  );

export const getIsMobileBetInProcessByOfferId =
  (offerId: number) =>
  ({ inlinePlacement }: AppState) =>
    inlinePlacement.processingMobileBets[offerId];

const getProcessingMobileBets = ({ inlinePlacement }: AppState) => inlinePlacement.processingMobileBets;

export const getIsAtLeastOneMobileBetInProcess = createSelector(
  getProcessingMobileBets,
  bets => !!Object.values(bets).filter(Boolean).length
);

export const getIsMobilePlacementConfirmationStep = ({ inlinePlacement }: AppState) =>
  inlinePlacement.isMobilePlacementConfirmationStep;
