export const HOME_BASE_URL = '/';
export const MOBILE_LOGIN_BASE_URL = '/login';
export const ACCOUNT_RESPONSIBLE_BETTING_URL = '/account/responsible-betting';
export const SPORT_BASE_URL = '/sport';
export const EVENT_BASE_URL = '/event';
export const MARKET_BASE_URL = '/market';
export const MOBILE_FAVOURITES_BASE_URL = '/favourites';
export const COMPETITION_BASE_URL = '/competition';
export const GROUP_BASE_URL = '/group';
export const COUNTRY_BASE_URL = '/country';
export const GAMES_BASE_URL = '/games';
export const IN_PLAY_BASE_URL = '/inplay';
export const SEARCH_BASE_URL = '/search';
export const MY_BETS_BASE_URL = '/mybets';
export const BETTING_PROFIT_LOSS_BASE_URL = '/profitandloss';
export const ACCOUNT_STATEMENT_BASE_URL = '/statement';
export const CASH_OUT_BASE_URL = '/cashout';
export const ACCOUNT_BASE_URL = '/account';
export const MATCH_STATISTIC_SOON = '/match-statistic-soon';
export const ROOT_URL = '/customer';
export const ASIAN_BASE_URL = '/asian-view';
export const ASIAN_MOBILE_FAVOURITES_URL = `${ASIAN_BASE_URL}/favourites`;
export const ASIAN_VIEW_CASH_OUT_URL = `${ASIAN_BASE_URL}/cash-out`;
export const ASIAN_VIEW_MORE_URL = `${ASIAN_BASE_URL}/more`;
export const CUSTOM_PAGES_BASE_URL = '/pages';
export const ASIAN_MOBILE_BET_LIST_URL = `${ASIAN_BASE_URL}/bet-list`;
export const RESPONSIBLE_BETTING_BASE_URL = '/responsible-betting';
