import { createSelector } from '@reduxjs/toolkit';
import { values } from 'lodash';

import { AppState } from 'redux/reducers';

export const getLeaguesLoading = ({ asianViewLeaguesFilter }: AppState) => asianViewLeaguesFilter.loading;

export const getLeaguesCurParams = ({ asianViewLeaguesFilter }: AppState) => asianViewLeaguesFilter.currentParams;

export const getAsianViewLeaguesFilter = ({ asianViewLeaguesFilter }: AppState) => asianViewLeaguesFilter.leagues;

export const getIsAllLeaguesSelected = createSelector(getAsianViewLeaguesFilter, leagues =>
  values(leagues).every(({ isSelected }) => isSelected)
);

export const getIsLeaguesChanged = createSelector(getAsianViewLeaguesFilter, leagues =>
  values(leagues).some(({ isSelected, isSelectedInit }) => isSelected !== isSelectedInit)
);

export const getLeaguesAmounts = createSelector(getAsianViewLeaguesFilter, leagues => {
  const leaguesList = values(leagues);
  const selectedList = [];
  const selectedInitList = [];
  const changedList = [];

  leaguesList.forEach(item => {
    if (item.isSelected) {
      selectedList.push(item);
    }

    if (item.isSelectedInit) {
      selectedInitList.push(item);
    }

    if (item.isSelected !== item.isSelectedInit) {
      changedList.push(item);
    }
  });

  return {
    total: leaguesList.length,
    selected: selectedList.length,
    selectedInit: selectedInitList.length,
    changed: changedList.length
  };
});

export const getLeaguesIsLoaded = ({ asianViewLeaguesFilter }: AppState) => asianViewLeaguesFilter.isLeaguesLoaded;

export const getSavedSelectedLeagues = createSelector(getAsianViewLeaguesFilter, leagues =>
  values(leagues).map(({ isSelectedInit }) => isSelectedInit)
);

export const getLeaguesOpened = ({ asianViewLeaguesFilter }: AppState) => asianViewLeaguesFilter.isOpened;
