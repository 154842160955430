import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { BetsTypesByPeriod, Period, TExchangeTypes, TPLExchangeTypes } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useActivePLProduct from 'hooks/useActivePLProduct';
import { setMobileFilters } from 'redux/modules/myBets';
import { CookieNames } from 'types';
import { TMobileSelectOption } from 'types/myBets';

import MobileTabs from '../MobileFiltersTabs';

import styles from './styles.module.scss';

const MobileFiltersProducts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isProfitLossPage, isMyBetsPage } = useAccountUrlParams();

  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW, CookieNames.PROFITANDLOSS_BETTYPE]);
  const { availableProducts } = useAccountProducts();
  const plProduct = useActivePLProduct();

  const defaultProduct =
    (isProfitLossPage ? plProduct : (cookies.EXCHANGE_TYPE_NEW as TExchangeTypes)) || availableProducts[0];

  const translationKey = isProfitLossPage ? 'account.profitandloss.labels.' : 'asianView.labels.account.exchangeType.';
  const products: TMobileSelectOption[] = availableProducts.map(tab => {
    return {
      id: tab,
      label: t(`${translationKey}${isProfitLossPage ? tab.toLowerCase() : tab}`),
      isSelected: tab === defaultProduct,
      defaultIsSelected: tab === defaultProduct
    };
  });

  const changeProductsHandler = (productsChanged: TMobileSelectOption[], isProductChanged: boolean) => {
    dispatch(
      setMobileFilters({
        ...(isMyBetsPage ? { betTypes: BetsTypesByPeriod[Period.Open] } : {}),
        product: (productsChanged.find(({ isSelected }) => isSelected)?.id || defaultProduct) as
          | TExchangeTypes
          | TPLExchangeTypes,
        isProductChanged
      })
    );
  };

  return (
    <div>
      <p className={styles.product}>{t('account.labels.product')}</p>
      <MobileTabs defaultOptions={products} onChangeCallback={changeProductsHandler} />
    </div>
  );
};

export default MobileFiltersProducts;
