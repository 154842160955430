import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { failureGetData } from 'redux/modules/appConfigs';
import { fetchTooltips, successFetchTooltips } from 'redux/modules/tooltip';

function* getTooltipsWorker(action: ReturnType<typeof fetchTooltips>) {
  try {
    let asianViewTooltip: Record<string, boolean> = {};
    const response: Record<string, boolean> = yield call(api.app.getTooltips, {
      locale: action.payload.locale,
      platform: action.payload.platform
    });

    if (action.payload.asianViewEnabled) {
      asianViewTooltip = yield call(api.app.getTooltips, action.payload);
    }

    yield put(successFetchTooltips({ ...response, ...asianViewTooltip }));
  } catch (error) {
    yield put(failureGetData(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchTooltips, getTooltipsWorker)]);
}
