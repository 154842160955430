import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'components/Dropdown';
import { componentsBranding } from 'constants/branding';
import { PLBetType, plDefaultSportTypeValues, PLExchangeTypes } from 'constants/myBets';
import useActivePLProduct from 'hooks/useActivePLProduct';
import CurrenciesDropdown from 'pages/MyBetsPages/components/CurrenciesDropdown';
import { fetchProfitGames, fetchProfitSports } from 'redux/modules/myBets';
import { getBetsTypeList, getEventTypeFilter } from 'redux/modules/myBets/selectors';
import { Game, Sport } from 'redux/modules/myBets/type';
import { CookieNames, DropdownItem } from 'types';

import FilterNetCommission from '../FilterNetCommission/FilterNetCommission';

import styles from './FiltersContainer.module.scss';

interface FiltersContainerProps {
  setEventType: (item: DropdownItem) => void;
  currencyCode: string;
}

const FiltersContainer = ({ setEventType, currencyCode }: FiltersContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activePLTab = useActivePLProduct();
  const betsTypeList = useSelector(getBetsTypeList(activePLTab));
  const eventType = useSelector(getEventTypeFilter);
  const [, setCookie] = useCookies([CookieNames.PROFITANDLOSS_SPORTID]);

  const handleChangeSportValue = (item: DropdownItem) => {
    setEventType(item);
  };

  const isAsianView = activePLTab === PLExchangeTypes[PLBetType.AsianView];
  const isSports = activePLTab === PLExchangeTypes[PLBetType.Sports];
  const allItem = isSports ? plDefaultSportTypeValues.sports : plDefaultSportTypeValues.games;

  useEffect(() => {
    setCookie(CookieNames.PROFITANDLOSS_SPORTID, '', {
      path: '/'
    });
    if (!isAsianView) {
      if (isSports) {
        dispatch(fetchProfitSports(currencyCode));
      } else if (activePLTab === PLExchangeTypes[PLBetType.Games]) {
        dispatch(fetchProfitGames(currencyCode));
      }
    }
  }, [activePLTab]);

  const data = (betsTypeList.map(betType => {
    return {
      label: betType.name,
      id: isSports ? (betType as Sport).id : (betType as Game).channelId,
      value: isSports ? (betType as Sport).id : (betType as Game).channelId
    };
  }) || []) as DropdownItem[];

  const allOption = {
    label: t(allItem.translationKey),
    id: '',
    value: '',
    hasDivider: data.length > 0
  };

  const dropdownBranding = {
    dropdownItems: componentsBranding.DROPDOWN_LIST,
    dropdownItem: componentsBranding.DROPDOWN_ITEM,
    selected: componentsBranding.ACTIVE
  };

  return (
    <div className={styles.filters}>
      {!isAsianView && activePLTab && (
        <Dropdown
          data={[allOption, ...data]}
          defaultValue={eventType?.id ? eventType : allOption}
          onSelectOption={handleChangeSportValue}
          classes={{
            placeholder: styles.placeholder,
            dropdown: styles.dropdown,
            selectedOption: styles.selectedOption,
            activePlaceholder: styles.placeholder__active
          }}
          brandingClasses={dropdownBranding}
        />
      )}
      {!isAsianView && <CurrenciesDropdown />}
      <FilterNetCommission />
    </div>
  );
};

export default FiltersContainer;
