import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import useDevice from 'hooks/useDevice';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import usePLNetOfCommission from 'hooks/usePLNetOfCommission';
import { setMobileFilters } from 'redux/modules/myBets';

import styles from './FilterNetCommission.module.scss';

interface FilterNetCommissionProps {}

const FilterNetCommission = ({}: FilterNetCommissionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    hasNetCommission,
    value: isProfitNetOfCommissionChecked,
    onChange: onChangeNetCommission
  } = usePLNetOfCommission();
  const { refreshData } = useMyBetsPagesNavigation();
  const { isMobile } = useDevice();
  const [mobileValue, setMobileValue] = useState<boolean>(isProfitNetOfCommissionChecked);

  const onChangePLNetCommission = () => {
    if (isMobile) {
      const isNetCommissionChanged = isProfitNetOfCommissionChecked === mobileValue;
      dispatch(
        setMobileFilters({
          netCommission: !mobileValue,
          isNetCommissionChanged
        })
      );
      setMobileValue(!mobileValue);
    } else {
      onChangeNetCommission();
    }
    refreshData({ netCommission: !isProfitNetOfCommissionChecked });
  };

  return (
    <>
      {hasNetCommission && (
        <>
          <div
            className={classNames(styles.netCommission__container, {
              [styles.netCommission__container__mobile]: isMobile
            })}
          >
            {!isMobile && (
              <Checkbox
                checked={isProfitNetOfCommissionChecked}
                className={styles.netCommission}
                labelClassName={styles.netCommission__label}
                onChange={onChangePLNetCommission}
              >
                {t('market.profitNetOfCommission')}
              </Checkbox>
            )}
            {isMobile && (
              <>
                <div className={styles.title}>{t('betslip.netOfCommission.commission')}</div>
                <label className={styles.settings__item}>
                  <p className={styles.settings__item__label}>{t('market.profitNetOfCommission')}</p>
                  <ToggleSwitch
                    checked={mobileValue}
                    onChange={onChangePLNetCommission}
                    containerClassName={styles.settings__item__toggle}
                  />
                </label>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FilterNetCommission;
