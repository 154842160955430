import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { componentsBranding } from 'constants/branding';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import { MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME } from 'constants/myBets';
import { getAccountSubHeaderNavigationItems } from 'constants/navigation';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled,
  getIsResponsibleBettingMenuEnabled
} from 'redux/modules/appConfigs/selectors';
import { getAccountPageReferer } from 'redux/modules/appSettings/selectors';
import { logoutUser } from 'redux/modules/auth';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { SportId } from 'types';

import AccountPagesSubHeaderNavigationItem from './components/AccountPagesSubHeaderNavigationItem';

import styles from './styles.module.scss';

const AccountPagesSubheader = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const accountPageReferer = useSelector(getAccountPageReferer);

  const isAsianViewPage =
    accountPageReferer.includes(ASIAN_BASE_URL) || (!isExchangeSportsEnabled && !isExchangeGamesEnabled);
  const isGamesPage = accountPageReferer.includes(GAMES_BASE_URL) || (!isExchangeSportsEnabled && !isAsianViewEnabled);
  const homeUrl = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || HOME_BASE_URL;

  const isAsianPage = pathname.includes(ASIAN_BASE_URL);
  const isGamePage = pathname.includes(GAMES_BASE_URL);
  const isResponsibleBetting = useSelector(getIsResponsibleBettingMenuEnabled);

  const accountSubHeaderItems = getAccountSubHeaderNavigationItems({
    isResponsibleBetting,
    isMyBetsSettledTab: localStorage.getItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME) === 'true',
    isGamePage,
    isAsianViewPage: isAsianPage
  });

  const { loginHeaderButtons } = useDeviceSettings();

  const isShowLogout = isLoggedIn && loginHeaderButtons;

  const [isOpen, setIsOpen] = useState(false);

  const productsDropdownRef = useRef<HTMLAnchorElement>(null);

  useOnClickOutside(productsDropdownRef, () => setIsOpen(false));

  const handleLogout = () => {
    let redirectUrl =
      isAsianPage && asianViewEnabled
        ? `${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`
        : HOME_BASE_URL;

    if (!asianViewEnabled && !isExchangeSportsEnabled) {
      redirectUrl = GAMES_BASE_URL;
    }

    dispatch(logoutUser(redirectUrl));
  };

  const handleOnHome = () => {
    navigate(homeUrl, { replace: true });
  };

  const displayBackToIcon =
    [isAsianViewEnabled, isExchangeSportsEnabled, isExchangeGamesEnabled].filter(Boolean).length > 1;

  return (
    <div className={classNames(styles.container, 'biab_header-submenu')}>
      <div className={classNames(styles.navigationContainer, 'biab_navigation-links')}>
        <div className={styles.iconsContainer}>
          {!isTimeOutEnabled && (
            <a
              className={classNames(styles.iconContainer, styles.iconLink, 'biab_navigation-link')}
              onClick={handleOnHome}
            >
              <i className={classNames(styles.icon, 'fa2 fa2-home-icon biab_fav-manage-icon')} />
            </a>
          )}
          {!isTimeOutEnabled && displayBackToIcon ? (
            <>
              <i
                className={classNames(
                  styles.icon,
                  styles.icon__divider,
                  'fa2 fa2-header-divider-icon biab_fav-manage-icon'
                )}
              />
              <a
                ref={productsDropdownRef}
                className={classNames(styles.iconContainer, styles.dropdownIcon, 'biab_navigation-link')}
                onClick={() => setIsOpen(!isOpen)}
              >
                <i
                  className={classNames(styles.icon, 'fa2', 'fa2-chevron-down-icon', 'biab_fav-manage-icon', {
                    [styles.rotatedIcon]: isOpen
                  })}
                />
                {isOpen && (
                  <div className={styles.dropdownContainer}>
                    {isAsianViewEnabled && (
                      <NavLink
                        to={`${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`}
                        className={classNames(styles.navLink, componentsBranding.DROPDOWN_ITEM)}
                      >
                        {t('header.navigation.asianView')}
                      </NavLink>
                    )}
                    {isExchangeSportsEnabled && (
                      <NavLink
                        to={HOME_BASE_URL}
                        className={classNames(styles.navLink, componentsBranding.DROPDOWN_ITEM)}
                      >
                        {t('header.navigation.sports')}
                      </NavLink>
                    )}
                    {isExchangeGamesEnabled && (
                      <NavLink
                        to={GAMES_BASE_URL}
                        className={classNames(styles.navLink, componentsBranding.DROPDOWN_ITEM)}
                      >
                        {t('header.navigation.games')}
                      </NavLink>
                    )}
                  </div>
                )}
              </a>
            </>
          ) : null}
        </div>
        {accountSubHeaderItems.map(item => (
          <AccountPagesSubHeaderNavigationItem item={item} key={item.title} />
        ))}
      </div>
      {isShowLogout && !isIframeEnabled && (
        <div className="biab_navigation-links">
          <a onClick={handleLogout} className={classNames(styles.logoutBtn, 'biab_navigation-link')}>
            <span className={classNames(styles.logoutBtnText)}>{t('header.logout')}</span>
            <i className={classNames(styles.icon, styles.logoutIcon, 'fa2 fa2-logout-icon biab_fav-manage-icon')} />
          </a>
        </div>
      )}
    </div>
  );
};

export default AccountPagesSubheader;
