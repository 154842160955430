import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EXCHANGE } from 'constants/app';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import { getCurrentBetOfferStateByOfferId } from 'redux/modules/currentBets/selectors';
import { setMyBetsCancelBetStateValue } from 'redux/modules/myBets';
import { getMyBetsCancelBetStateValue, myBetsLoading } from 'redux/modules/myBets/selectors';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { cancelBets } from 'redux/modules/placement';
import { fetchBalance } from 'redux/modules/user';
import { OfferStates } from 'types/myBets';

import { useMyBetsPagesNavigation } from './useMyBetsPagesNavigation';

const useAccountMyBetsCancelAction = ({
  bet,
  isMobile,
  isBetDetail
}: {
  bet?: TMyBetsContentItem;
  isMobile?: boolean;
  isBetDetail?: boolean;
}) => {
  const dispatch = useDispatch();

  const isUnmatched = bet?.offerState === OfferStates.PLACED;
  const cancelBetOfferId = isUnmatched ? String(bet?.offerId) : undefined;

  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const { isSuccess, isLoading, isError } = useSelector(getMyBetsCancelBetStateValue(cancelBetOfferId));
  const betOfferState = useSelector(getCurrentBetOfferStateByOfferId(isMobile ? bet?.offerId : undefined));
  const fetchMyBetsLoading = useSelector(myBetsLoading);

  const { getBetData: fetchMyBetsData } = useMyBetsPagesNavigation();

  const isCancelled = betOfferState === OfferStates.CANCELLED;

  useEffect(() => {
    if (isCancelled && isMobile && isBetDetail) {
      fetchMyBetsData({ noResetMyBetsCancelState: true });
    }
  }, [isCancelled]);

  const isCancelBetLoading = isMobile
    ? isLoading || (isSuccess ? !isCancelled && !!betOfferState : false) || fetchMyBetsLoading
    : isLoading;

  const onCancelBet = () => {
    if (bet && isUnmatched && cancelBetOfferId) {
      dispatch(setMyBetsCancelBetStateValue({ id: cancelBetOfferId, field: 'isLoading', value: true }));

      dispatch(
        cancelBets({
          data: {
            [bet.marketId]: [
              {
                betType: bet.betType || EXCHANGE,
                handicap: bet.handicap,
                offerId: bet.offerId,
                price: bet.price,
                selectionId: bet.selectionId,
                size: bet.size
              }
            ]
          },
          successCallback: () => {
            dispatch(setMyBetsCancelBetStateValue({ id: cancelBetOfferId, field: 'isSuccess', value: true }));

            if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
              dispatch(fetchBalance());
            }
          },
          errorCallback: () => {
            dispatch(setMyBetsCancelBetStateValue({ id: cancelBetOfferId, field: 'isError', value: true }));
          },
          finallyCallback: () => {
            dispatch(setMyBetsCancelBetStateValue({ id: cancelBetOfferId, field: 'isLoading', value: false }));
          }
        })
      );
    }
  };

  return {
    isCancelBetLoading,
    isCancelBetSuccess: isSuccess && !isCancelBetLoading,
    isCancelBetError: isError,
    onCancelBet
  };
};

export default useAccountMyBetsCancelAction;
