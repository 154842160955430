import { TValidationMessage } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { OddsType, TFailureActionPayload, TSocketMarketParams } from 'types';
import { BetTypes, EPersistenceTypes, MatchTypes, TPrice, TProfit, TSize } from 'types/bets';
import { ELineSide, EPlacementType } from 'types/betslip';
import { TPriceLadderDescription } from 'types/markets';

export enum ECurrentBetActions {
  HIDDEN = 'HIDDEN',
  CANCELLING = 'CANCELLING',
  CANCELLING_ALL = 'CANCELLING_ALL',
  EDITING = 'EDITING',
  FULLY_MATCHED = 'FULLY_MATCHED',
  PARTIALLY_MATCHED = 'PARTIALLY_MATCHED',
  FULLY_MATCHED_AFTER_EDITING = 'FULLY_MATCHED_AFTER_EDITING',
  PLACED_PARTIALLY_MATCHED = 'PLACED_PARTIALLY_MATCHED'
}

export type TCurrentBet = {
  alternativeBackOdds?: number | string | null;
  alternativeBackOddsRounded?: number | string | null;
  averagePrice: number;
  averagePriceRounded: number;
  betType?: string;
  bettingType: string;
  cancelledByOperator?: boolean;
  cancelledDate?: number | null;
  commissionType?: string;
  competitionId?: number | string;
  competitionName?: string;
  currency?: string;
  currencyExRate?: number | string;
  currencyRate?: number | string;
  disabledLayOdds?: boolean;
  eachWayDivisor: number | null;
  inPlay?: boolean;
  isEachWay: boolean;
  eventId: string;
  eventName?: string;
  eventTypeId: number;
  mainEventName?: string;
  mainEventId?: string;
  fancyView?: boolean;
  groupName?: string;
  handicap?: number | string;
  interval?: number;
  liability?: number | string | null;
  lineSide?: ELineSide | null;
  marketId: string;
  marketName?: string;
  marketNameWithParents?: string;
  marketStartDate?: number | null;
  marketType: string;
  marketUnit: string | null;
  masterAccountId?: number;
  masterCurrency?: string | null;
  masterProfit?: number | null;
  matchedDate?: number | null;
  maxUnitValue?: number;
  minUnitValue?: number;
  numberOfWinners?: number | null;
  offerId: number;
  offerState: BetsStatusesTypes;
  oldOfferId: number;
  pastTotalLiability?: number | string | null;
  persistenceEnabled?: boolean;
  persistenceType: EPersistenceTypes;
  placedDate?: number;
  potentialProfit?: number | string;
  price: number;
  profit?: number | string;
  profitNet?: number | string;
  raceName?: string | null;
  resettled?: boolean;
  selectionId?: number;
  selectionName?: string;
  settledDate?: number | null;
  side: BetTypes;
  size: number | string;
  sizeCancelled: number | string;
  sizeLapsed: number | string;
  sizeMatched: number | string;
  sizePlaced: number | string;
  sizeRemaining: number | string;
  sizeVoided: number | string;
  sportName: string;
  totalWinnings?: number | string;
  triggeredByCashOut?: boolean;
  type?: string;
  action?: ECurrentBetActions | null;
  isCombined?: boolean;
  combinedAmount?: number;
  matchType?: MatchTypes;
  priceLadderDescription: TPriceLadderDescription;
  betIndex?: number;
  changedPrice?: TPrice;
  changedSize?: TSize;
  changedProfit?: TProfit;
  oddsType?: OddsType;
  score?: string;
  isAsian?: boolean;
  isOutright?: boolean;
  eventInPlay: boolean;
  marketProfit: number;
  marketLiability: number;
  partialCashOutEnabled: boolean;
  cashOutEnabled: boolean;
  autoCashOutEnabled: boolean;
  isPNLAvailable: boolean;
  isValid?: boolean;
  isPriceValid?: boolean;
  isSizeValid?: boolean;
  /**
   * This property used only for unmatched bet notifications to show black header and close icon to remove bet from
   * current bet list
   */
  canBeRemoved?: boolean;
  validationMessage?: TValidationMessage | null;
  hidePartiallyMatchedNotification?: boolean;
  fillOrKill?: boolean;
};

export type TCurrentBetsMap = {
  [offerId: number | string]: TCurrentBet;
};

export type TPlacedBets = {
  [offerId: number]: EPlacementType;
};

export type TCurrentBetsState = {
  offers: TCurrentBetsMap;
  updatedOffers: TCurrentBetsMap;
  placedBets: TPlacedBets;
  marketsToSubscribe: TSocketMarketParams[];
  placementMessage: string;
  loading: boolean;
  error: TFailureActionPayload;
  isFirstLoad: boolean;
  currentBetsString: string;
  currentBetsList: TCurrentBet[];
  areCurrentBetsLoaded: boolean;
  // Lapsed state can be displayed for these unmatched offers ids
  unmatchedOffersIdsToShowLapsed: Record<string, boolean>;
  closedUnmatchedOfferIds: Record<string, boolean>;
};

export type TCurrentBetsPayload = {
  successCallback?: (currentBets: any) => void;
  errorCallback?: (error: any) => void;
};

export type TCurrentBetActionPayload = {
  offerId: number;
  action: ECurrentBetActions | null;
};

export type TCalculatedConsolidatedBetFields = keyof Pick<
  TCurrentBet,
  | 'liability'
  | 'profit'
  | 'profitNet'
  | 'potentialProfit'
  | 'size'
  | 'sizeMatched'
  | 'sizePlaced'
  | 'totalWinnings'
  | 'averagePriceRounded'
>;

export interface TGroupedEvent {
  eventId: string;
  mainEventId: string;
  isPNLAvailable: boolean;
  betsByType: Record<BetTypes, TCurrentBet[]>;
  marketStartDate?: number | null;
  competitionId?: number | string;
}
