import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import BetsIndicatorSearchPage from 'components/BetsIndicatorSearchPage';
import MarketRunner from 'components/MarketRunner';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import RacingGameTitle from 'components/RacingGameTitle';
import SearchTotalBets from 'components/SearchTotalBets';
import WhatIf from 'components/WhatIf';
import { RACING_SPORT } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { MAX_MARKETS_COUNT_ON_SEARCH } from 'constants/marketsTable';
import useCellConfigs from 'hooks/useCellConfigs';
import useLayColumn from 'hooks/useLayColumn';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getMarketPricesCurrency,
  getMarketPricesId,
  getMarketPricesIsBettingEnabled,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { TContent } from 'redux/modules/search/type';
import { PageBlocks } from 'types';
import { IMarket } from 'types/markets';
import { getBetGroups } from 'utils/market';

import SearchMarketHeader from '../SearchMarketHeader';

import styles from './styles.module.scss';

interface ContentProps {
  content: TContent;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Whether the last element in the current list, used by infinite scroll observer
   * @param node
   */
  lastItemRef?: (node: HTMLDivElement) => void;
  isInPlay: boolean;
}

function Content({
  content: {
    topMarkets,
    eventTypeId,
    sportName,
    seeAllMarkets,
    id,
    countryCode,
    name,
    openDate,
    parents,
    videoStreamingEnabled,
    matchStatEnabled
  },
  isDepthEnabled,
  lastItemRef,
  isInPlay
}: ContentProps) {
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const marketPricesId = useSelector(getMarketPricesId(topMarkets[0]?.marketId ?? ''));
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(topMarkets[0]?.marketId ?? ''));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(topMarkets[0]?.marketId ?? ''));
  const marketPricesCurrency = useSelector(getMarketPricesCurrency(topMarkets[0]?.marketId ?? ''));

  const { isLayColumnEnabled, isLineMarket } = useLayColumn({
    sportId: topMarkets[0]?.eventType?.id || eventTypeId,
    bettingType: topMarkets[0]?.description?.bettingType
  });

  const [isShowAll, setIsShowAll] = useState(false);
  const isMobile = device === Devices.MOBILE;
  const showSeeAllMarkets = seeAllMarkets || isMobile || topMarkets.length > 3;
  const hasDeepPrices = isMobile && isDepthEnabled;
  const isRacing = RACING_SPORT[eventTypeId];

  const market: IMarket = {
    ...topMarkets[0],
    eventType: {
      id: eventTypeId,
      name: sportName
    },
    event: { id, name, openDate, countryCode, videoStreamingEnabled, matchStatEnabled }
  };
  const betGroups = getBetGroups(hasDeepPrices, isLayColumnEnabled, isLineMarket);
  const { desktopCellsContainerWidth, mobileCellsContainerWidth } = useCellConfigs({
    cellGroupsCount: hasDeepPrices ? 3 : 1,
    doubleWidth: true,
    showDepth: false,
    addMobileExtraSpace: true
  });
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.marketId,
    eventId: id,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.SportsMiddleSection
  });

  let runners = market.runners;
  const seeAllMarketsEventLinkId = id.includes('.') && parents?.length ? parents[0].id : id;

  const { showStatus, status, showLockIcon, displayStatus } = useMarketStatusAndLockIcon(
    bettingEnabled,
    marketPricesStatus
  );

  if (runners.length > MAX_MARKETS_COUNT_ON_SEARCH && (!isRacing || !isShowAll)) {
    runners = market.runners.slice(0, MAX_MARKETS_COUNT_ON_SEARCH + 1);
  }

  const whatIfClasses = useMemo(() => ({ container: styles.searchContent__whatIf }), []);

  return (
    <div
      ref={ref}
      className={classNames(styles.searchContent, 'searchContent')}
      data-market-id={market.marketId}
      data-event-id={id}
      data-market-prices={true}
    >
      <div className={styles.searchContent__markets}>
        {isLoggedIn && !isMobile && <SearchTotalBets eventId={id.split('.')[0]} />}
        {topMarkets.slice(1, !isMobile ? 4 : 3).map(({ marketId, marketName }, index) => (
          <Link
            to={`${SPORT_BASE_URL}/${eventTypeId}/market/${marketId}`}
            className={classNames('biab_search-market-link', styles.searchContent__market)}
            key={`${index}-${marketId}`}
          >
            {marketName} <i className="fa2 fa2-arrow-right" />
          </Link>
        ))}
        {showSeeAllMarkets && (
          <Link
            to={`${SPORT_BASE_URL}/${eventTypeId}/event/${seeAllMarketsEventLinkId}`}
            className={classNames('biab_see-all-markets', styles.searchContent__moreMarkets)}
          >
            <>
              {t('search.seeAllMarkets')} <i className="fa2 fa2-arrow-right" />
            </>
          </Link>
        )}
      </div>
      <div className={styles.searchContent__tableContainer}>
        <div className={styles.searchContent__tableWrapper}>
          <SearchMarketHeader
            eventTypeId={eventTypeId}
            inPlay={isInPlay}
            videoStreamingEnabled={videoStreamingEnabled}
            marketId={topMarkets[0].marketId}
            marketStartTime={openDate}
            marketName={topMarkets[0].marketName}
            cashOutEnabled={topMarkets[0].cashOutEnabled}
            isDepthEnabled={isDepthEnabled}
            eventId={id}
            matchStatEnabled={matchStatEnabled}
            sportId={eventTypeId}
          />
          {runners.map((runner, marketIndex) => {
            return (
              <div
                key={`${runner.selectionId}-${marketIndex}`}
                ref={lastItemRef && marketIndex === runners.length - 1 ? lastItemRef : undefined}
                data-last={!!(lastItemRef && marketIndex === runners.length - 1)}
              >
                <MarketRunner
                  marketStatusSettings={{ showStatus, status, showLockIcon, displayStatus }}
                  marketPricesCurrency={marketPricesCurrency}
                  betGroups={betGroups}
                  desktopCellsContainerWidth={desktopCellsContainerWidth}
                  mobileCellsContainerWidth={mobileCellsContainerWidth}
                  isLineMarket={isLineMarket}
                  isLayColumnEnabled={isLayColumnEnabled}
                  market={market}
                  runner={runner}
                  pageBlock={PageBlocks.SEARCH}
                  redirectToSingleMarketPage
                >
                  <div className={styles.searchContent__selectionNameWrapper}>
                    <div className={styles.betsIndicatorWrapper}>
                      {!isMobile && (
                        <BetsIndicatorSearchPage
                          eventTypeId={eventTypeId}
                          marketId={topMarkets[0].marketId}
                          selectionId={runner.selectionId}
                          handicap={runner.handicap}
                        />
                      )}
                    </div>
                    <div>
                      <RacingGameTitle runner={runner} isRacing={isRacing} countryCode={countryCode} />
                      {marketPricesId && (
                        <WhatIf
                          marketId={market.marketId}
                          commission={market.commission}
                          eachWayDivisor={market.description?.eachWayDivisor}
                          marketType={market.description?.marketType}
                          selectionId={runner.selectionId}
                          handicap={runner.handicap}
                          classes={whatIfClasses}
                        />
                      )}
                    </div>
                  </div>
                </MarketRunner>
              </div>
            );
          })}
          {showStatus && <StatusOverlay status={status} inMiddle />}
        </div>

        {runners.length > MAX_MARKETS_COUNT_ON_SEARCH && (
          <div className={styles.searchContent__marketWrap}>
            {!isShowAll && <div className={styles.searchContent__blurLastMarket} />}
            {isRacing && (
              <div
                onClick={() => setIsShowAll(!isShowAll)}
                className={classNames(styles.searchContent__showMoreMarket, {
                  [styles.searchContent__showMoreMarket_active]: isShowAll
                })}
              >
                {t(isShowAll ? 'market.showLess' : 'market.showMore')}
                <i className={classNames('fa2 biab_expand', `${isShowAll ? 'fa2-arrow-up' : 'fa2-arrow-down'}`)} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Content;
