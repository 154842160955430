import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { SEARCH_CURRENCY_KEY } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import MobileSelect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileSelect';
import { getCurrenciesDropdownItems } from 'redux/modules/appConfigs/selectors';
import { setMobileFilters } from 'redux/modules/myBets';
import { DropdownItem } from 'types';
import { TMobileSelectOption } from 'types/myBets';

const MobileFiltersCurrencies = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { isProfitLossPage } = useAccountUrlParams();

  const currenciesDropdownItems = useSelector(getCurrenciesDropdownItems);
  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY) || currenciesDropdownItems[0].id;

  const currenciesOptions: TMobileSelectOption[] = (
    isProfitLossPage
      ? []
      : [{ id: 'all', value: 'all', label: t('account.currencies.all'), isLabelTranslatable: true } as DropdownItem]
  )
    .concat(currenciesDropdownItems)
    .map(option => {
      const { id, label } = option;
      return {
        id: id.toString(),
        label: label ?? '',
        isSelected: option.value === searchCurrency,
        defaultIsSelected: option.value === searchCurrency
      };
    });

  const changeCurrenciesHandler = (currenciesChanged: TMobileSelectOption[], isCurrencyChanged: boolean) => {
    dispatch(
      setMobileFilters({
        currency: currenciesChanged.find(({ isSelected }) => isSelected)?.id ?? '',
        isCurrencyChanged
      })
    );
  };

  return (
    <MobileSelect
      title={t('account.labels.currencies')}
      defaultOptions={currenciesOptions}
      onChangeCallback={changeCurrenciesHandler}
    />
  );
};

export default MobileFiltersCurrencies;
