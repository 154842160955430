import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { mobileIcons } from 'constants/branding';
import { AVG_PRICE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface MobileDetailsFieldProps {
  value?: string | number | null | boolean;
  name?: string;
  children?: ReactNode;
  capitalise?: boolean;
  classname?: string;
  isWithTooltip?: boolean;
  tooltipValue?: number | string | null;
  tooltipHTMLValue?: string;
  isSingleRow?: boolean;
  borderTop?: boolean;
  copyToClipboard?: boolean;
  valueClassName?: string;
}

const MobileDetailsField = ({
  name,
  value,
  isWithTooltip,
  tooltipValue,
  tooltipHTMLValue,
  capitalise,
  borderTop,
  copyToClipboard,
  valueClassName
}: MobileDetailsFieldProps) => {
  const { t } = useTranslation();
  const { translationKey } = useTooltip(AVG_PRICE);

  return (
    <div className={classNames(styles.field, { [styles.field__borderTop]: borderTop })}>
      <span className={styles.fieldName}>{name}</span>
      <span className={classNames(styles.fieldValue, valueClassName, { [styles.capitalise]: capitalise })}>
        {value || '--'}
        {isWithTooltip && tooltipValue && tooltipValue !== '--' && (
          <i
            onClick={e => e.preventDefault()}
            data-tooltip-id={'tooltip'}
            data-tooltip-html={tooltipHTMLValue || unescape(t(translationKey, { N: tooltipValue }))}
            className={classNames('biab_custom-icon-info-24', styles.icon, mobileIcons.TOOLTIP)}
          >
            <span className={classNames('path1', styles.iconBg, mobileIcons.TOOLTIP_BG)} />
            <span className={classNames('path2', mobileIcons.TOOLTIP_INNER)} />
            <span className={classNames('path3', mobileIcons.TOOLTIP_INNER)} />
          </i>
        )}
        {copyToClipboard && value && (
          <i
            className={classNames('biab_custom-icon-copy-24', styles.icon)}
            onClick={() => navigator.clipboard.writeText(value?.toString())}
          />
        )}
      </span>
    </div>
  );
};

export default MobileDetailsField;
