import { useEffect } from 'react';

type TKeyHandler = (event: KeyboardEvent) => void;
type TKeyName = 'Escape' | 'Enter';

const useKeyDown = (key: TKeyName, handler: TKeyHandler) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === key) {
        handler(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [key, handler]);
};

export default useKeyDown;
