import { ChangeEvent } from 'react';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';
import { componentsBranding } from 'constants/branding';
import { TMultiselectDropdownItem } from 'types';

type TMultiSelectDropdownItemProps = {
  option: TMultiselectDropdownItem;
  handleSelectOption: (option: TMultiselectDropdownItem) => void;
  customClass?: string;
};

const MultiselectDropdownItem = ({ option, handleSelectOption, customClass = '' }: TMultiSelectDropdownItemProps) => {
  const { label, isSelected } = option;

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    handleSelectOption({ ...option, isSelected: ev.target.checked });
  };

  return (
    <li className={classNames(customClass, componentsBranding.DROPDOWN_ITEM)}>
      <Checkbox checked={!!isSelected} onChange={handleChange}>
        {label}
      </Checkbox>
    </li>
  );
};

export default MultiselectDropdownItem;
