import { PageBlocks, PlacementPage, TFailureActionPayload } from 'types';
import { TPrice, TSize } from 'types/bets';

export type TPlacementBet = {
  betUuid?: string;
  marketId: string;
  price?: number | string | undefined;
  size?: number | string;
  handicap?: number | string | null;
  selectionId: number | undefined;
  side?: string;
  page?: PlacementPage;
  persistenceType?: string;
  eachWayDivisor?: number | null;
  numberOfWinners?: number;
};

export type TPlaceBet = {
  applicationType?: string;
  confirmBetsEnabled?: boolean;
  eachWayData?: object;
  handicap?: number | string | null;
  isEachWay?: boolean;
  netPLBetslipEnabled?: boolean;
  netPLMarketPageEnabled?: boolean;
  page?: PlacementPage;
  persistenceType?: string;
  placedUsingEnterKey?: boolean;
  price?: number | string | undefined;
  selectionId: number | undefined;
  side?: string;
  size?: number | string;
  quickStakesEnabled?: boolean;
  pageBlock?: PageBlocks;
  betUuid: string;
};

export type TCancelBet = {
  betType?: string;
  handicap?: number | string;
  offerId: number;
  price?: TPrice;
  selectionId?: number;
  size?: TSize;
  betUuid?: string;
};

export type TEditBet = {
  applicationType?: string;
  betType?: string;
  confirmBetsEnabled?: boolean;
  handicap?: number | string;
  mobile?: boolean;
  netPLBetslipEnabled?: boolean;
  netPLMarketPageEnabled?: boolean;
  offerId: number;
  page?: PlacementPage;
  persistenceType?: string;
  placedUsingEnterKey?: boolean;
  price?: number | string;
  quickStakesEnabled?: boolean;
  selectionId?: number;
  side?: string;
  size?: TSize;
  sizeRemaining?: TSize;
  betUuid?: string;
};

export type TPlaceBetsDataPayload = {
  [marketId: string]: TPlaceBet[];
};

export type TPlaceBetsPayload = {
  data: TPlaceBetsDataPayload;
  successCallback?: (data: { response: TPlacedBetsByMarket; offerIds: number[] }) => void;
  errorCallback?: (error: string) => void;
  isMobilePlacement?: boolean;
  isInlinePlacement?: boolean;
  pageBlock?: PageBlocks;
};

export type TCancelBetsPayload = {
  data: {
    [marketId: string]: TCancelBet[];
  };
  successCallback?: () => void;
  errorCallback?: (error: string) => void;
  isMobilePlacement?: boolean;
  pageBlock?: PageBlocks;
  finallyCallback?: () => void;
};

export type TCancelAllBetsPayload = {
  data: TCancelAllBetsDataPayload;
  successCallback?: (actionId: number) => void;
  errorCallback?: (error: string) => void;
};

export type TCancelAllBetsDataPayload = {
  betTypes: string[];
};

export type TEditBetsPayload = {
  data: {
    [marketId: string]: TEditBet[];
  };
  offersIds?: number[];
  successCallback?: (data: TPlacedBetsByMarket) => void;
  errorCallback?: (error: string) => void;
  isMobilePlacement?: boolean;
  pageBlock?: PageBlocks;
};

export type TPlacedBetException = {
  id: string;
  code: string;
  title?: null | string;
  message: string;
};

export type TPlacedBetInfo = {
  offerIds?: Record<string, number>;
  offerIdsUuids?: Record<string, number>;
  status: TPlacementStatus;
  error: string | null;
  exception?: TPlacedBetException;
  betDelay: number | null;
  betUuids?: string[];
};

export type TPlacedBetsByMarket = {
  [marketId: string]: TPlacedBetInfo;
};

export type EditCancelBetResponse = {
  betDelay: null | number;
  error: string | null;
  status: TPlacementStatus;
};

export type EditCancelBetResponseByMarket = {
  [marketId: string]: EditCancelBetResponse;
};

export type TPlacedMarkets = Record<
  string,
  {
    status?: string;
    exception?: TPlacedBetException;
    offerIdsUuids?: Record<string, number>;
    isInlinePlacement?: boolean;
  }
>;

export type TPlacedBets = Record<
  string,
  {
    offerId?: number;
    status?: string;
    exception?: TPlacedBetException;
    isInlinePlacement?: boolean;
  }
>;

export type TPlacedBetsState = {
  placedSelectedMarkets: TPlacedMarkets;
  placedSelectedBets: TPlacedBets;
  cancelledBetsStatusId: number | null;
  loading: boolean;
  error: TFailureActionPayload;
  rgErrorMessage: TPlacementError | null | string;
};

export type TPlacementErrorData = {
  id: string;
  code: string;
  message: string;
  status?: string;
};

export type TPlacementError = {
  data?: TPlacementErrorData;
  response?: {
    data?: TPlacementErrorData;
  };
};

export const PlacementStatus = {
  OK: 'OK',
  FAIL: 'FAIL'
} as const;

export type TPlacementStatus = typeof PlacementStatus[keyof typeof PlacementStatus];

export const BetslipType = {
  INLINE: 'INLINE',
  REGULAR: 'REGULAR'
} as const;

export type TBetslipType = typeof BetslipType[keyof typeof BetslipType];
