import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Footer from 'components/Footer';
import MobileAccountNavigation from 'components/MobileAccountNavigation';
import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import { ASIAN_BASE_URL } from 'constants/locations';
import { RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import ResponsibleBettingLossLimit from 'pages/ResponsibleBetting/components/ResponsibleBettingLossLimit';
import ResponsibleBettingReminder from 'pages/ResponsibleBetting/components/ResponsibleBettingReminder';
import ResponsibleBettingSelfExclusion from 'pages/ResponsibleBetting/components/ResponsibleBettingSelfExclusion';
import ResponsibleBettingTimeLimit from 'pages/ResponsibleBetting/components/ResponsibleBettingTimeLimit';
import {
  getBettingDisplaySettings,
  getIsAsianViewEnabled,
  getMultiCurrencySupported
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeight } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { fetchResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { SportId } from 'types';
import {
  ResponsibleBettingDisplayedContent,
  ResponsibleBettingTab,
  UpdatedResponsibleBettingDisplayedContent
} from 'types/responsibleBetting';
import { getUpdatedResponsibleBettingTabs } from 'utils/responsibleBetting';

import styles from './styles.module.scss';

const ResponsibleBetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const multiCurrencySupported = useSelector(getMultiCurrencySupported);
  const bettingDisplaySettings = useSelector(getBettingDisplaySettings);
  const headerHeight = useSelector(getElementHeight(EElementNames.HEADER_HEIGHT));

  const responsibleBettingTabs: ResponsibleBettingTab[] = getUpdatedResponsibleBettingTabs(
    bettingDisplaySettings,
    multiCurrencySupported
  );

  const isSomeVisible = responsibleBettingTabs.some(({ isVisible }) => isVisible);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  const RESPONSIBLE_BETTING_COMPONENTS = {
    [UpdatedResponsibleBettingDisplayedContent.LossLimit]: (tab: ResponsibleBettingTab) => (
      <ResponsibleBettingLossLimit tab={tab} />
    ),
    [UpdatedResponsibleBettingDisplayedContent.SelfExclusion]: (tab: ResponsibleBettingTab) => (
      <ResponsibleBettingSelfExclusion tab={tab} />
    ),
    [UpdatedResponsibleBettingDisplayedContent.TimeLimit]: (tab: ResponsibleBettingTab) => (
      <ResponsibleBettingTimeLimit tab={tab} />
    ),
    [UpdatedResponsibleBettingDisplayedContent.Reminder]: (tab: ResponsibleBettingTab) => (
      <ResponsibleBettingReminder tab={tab} />
    )
  };

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (!isSomeVisible) {
      if (isAsianViewEnabled) {
        navigate(
          `${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`,
          { replace: true }
        );
      } else {
        navigate(`${PagesFromBackend.PAGE_NOT_FOUND}`, { replace: true });
      }
    }
  }, [isSomeVisible]);

  const responsibleBettingSettingsInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    dispatch(fetchResponsibleBettingSettings());
    responsibleBettingSettingsInterval.current = setInterval(() => {
      dispatch(fetchResponsibleBettingSettings());
    }, RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL);

    return () => {
      if (responsibleBettingSettingsInterval.current) {
        clearInterval(responsibleBettingSettingsInterval.current);
      }
    };
  }, []);

  return (
    <div ref={componentRef} className={styles.container} style={{ height: `calc(100vh - ${headerHeight}px` }}>
      {isMobile && <MobileAccountNavigation />}
      <div
        className={classNames(styles.responsibleBetting, {
          [styles.responsibleBetting__mobile]: isMobile
        })}
      >
        {responsibleBettingTabs.map((tab: ResponsibleBettingTab) => {
          const { name, isVisible } = tab;
          return isVisible ? (
            <div key={`rb_${name}`}>
              {RESPONSIBLE_BETTING_COMPONENTS[
                name as Exclude<ResponsibleBettingDisplayedContent, ResponsibleBettingDisplayedContent.ExposureLimit>
              ](tab)}
            </div>
          ) : null;
        })}
      </div>
      <Footer />
    </div>
  );
};

export default ResponsibleBetting;
