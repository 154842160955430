import classNames from 'classnames';

import { BannerImage } from 'redux/modules/banners/type';

import styles from '../../styles.module.scss';

type HeaderLogoProps = {
  logo: BannerImage;
  isMobile: boolean;
};

const HeaderLogo = ({ logo, isMobile }: HeaderLogoProps) => {
  return (
    <img
      src={`${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer${logo?.imageUrl}`}
      alt="logo"
      className={classNames({ [styles.header__logoImage]: isMobile })}
      srcSet={`${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer${
        logo?.retinaImageUrl || logo?.imageUrl
      } 2x`}
    />
  );
};

export default HeaderLogo;
