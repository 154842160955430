import { OddsType } from 'types';

export type TAppSettingsResponse = {
  tag: string;
  type: string;
  url: string;
};

export enum EElementNames {
  HEADER_HEIGHT = 'HEADER_HEIGHT',
  FOOTER_HEIGHT = 'FOOTER_HEIGHT',
  COLLAPSE_HEIGHT = 'COLLAPSE_HEIGHT',
  HOW_TO_MENU_TITLE_HEIGHT = 'HOW_TO_MENU_TITLE_HEIGHT',
  HOW_TO_SECTION_HEIGHT = 'HOW_TO_SECTION_HEIGHT',
  ACTIVE_MENU_TITLE_HEIGHT = 'ACTIVE_MENU_TITLE_HEIGHT',
  ACTIVE_MENU_HEIGHT = 'ACTIVE_MENU_HEIGHT',
  FAVORITES_MENU_TITLE_HEIGHT = 'FAVORITES_MENU_TITLE_HEIGHT',
  FAVORITES_MENU_HEIGHT = 'FAVORITES_MENU_HEIGHT',
  NAVIGATION_PARENT_SECTION = 'NAVIGATION_PARENT_SECTION',
  NAVIGATION_PARENT_SPORT_NAME = 'NAVIGATION_PARENT_SPORT_NAME',
  NAVIGATION_ALL_SPORT_ITEM = 'NAVIGATION_ALL_SPORT_ITEM',
  NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT = 'NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT',
  NAVIGATION_HIDE_NAVBAR_BUTTON = 'NAVIGATION_HIDE_NAVBAR_BUTTON',
  CONTEXTUAL_HELP = 'CONTEXTUAL_HELP',
  BANNER = 'BANNER',
  FAVORITES_CONTENT_HEIGHT = 'FAVORITES_CONTENT_HEIGHT',
  ACTIVE_MARKETS_CONTENT_HEIGHT = 'ACTIVE_MARKETS_CONTENT_HEIGHT',
  WEBTOURS_IMAGE = 'WEBTOURS_IMAGE',
  SIDEBAR_HEIGHT = 'SIDEBAR_HEIGHT',
  CONTENT_HEIGHT = 'CONTENT_HEIGHT',
  NAVIGATION_HEIGHT = 'NAVIGATION_HEIGHT',
  SEARCH_INPUT_HEIGHT = 'SEARCH_INPUT_HEIGHT',
  CASHOUT_HEADER_HEIGHT = 'CASHOUT_HEADER_HEIGHT',
  MIN_NAV_HEIGHT = 'MIN_NAV_HEIGHT',
  MOBILE_FOOTER_NAV_HEIGHT = 'MOBILE_FOOTER_NAV_HEIGHT',
  MOBILE_PAGE_HEIGHT = 'MOBILE_PAGE_HEIGHT',
  MOBILE_CONTENT_HEIGHT = 'MOBILE_CONTENT_HEIGHT',
  MOBILE_HEADER_HEIGHT = 'MOBILE_HEADER_HEIGHT',
  MOBILE_SWIPE_MENU_HEIGHT = 'MOBILE_SWIPE_MENU_HEIGHT',
  MOBILE_ASIAN_SUB_HEADER = 'MOBILE_ASIAN_SUB_HEADER',
  POPULAR_MARKETS_HEIGHT = 'POPULAR_MARKETS_HEIGHT',
  ASIAN_NAVIGATION_SPORTS_HEIGHT = 'ASIAN_NAVIGATION_SPORTS_HEIGHT',
  ASIAN_FAVOURITES_NAVIGATION = 'ASIAN_FAVOURITES_NAVIGATION',
  ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY = 'ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY',
  ASIAN_BET_SLIP_TABS = 'ASIAN_BET_SLIP_TABS',
  ASIAN_TIMEZONE = 'ASIAN_TIMEZONE',
  ASIAN_BALANCE = 'ASIAN_BALANCE',
  ASIAN_BET_SLIP_COLLAPSE = 'ASIAN_BET_SLIP_COLLAPSE',
  ASIAN_MIDDLE_SECTION = 'ASIAN_MIDDLE_SECTION'
}

export type AppSettings = {
  settings: TAppSettingsResponse[];
  loading: boolean;
  parentDomain: string;
  accountPageReferer: string;
  oddsType: OddsType;
  isShowingSearch: boolean;
  isAsianViewMenuOpen: boolean;
  scrollContentHeight: number;
  elementsHeights: {
    [EElementNames.HEADER_HEIGHT]: number;
    [EElementNames.FOOTER_HEIGHT]: number;
    [EElementNames.COLLAPSE_HEIGHT]: number;
    [EElementNames.HOW_TO_MENU_TITLE_HEIGHT]: number;
    [EElementNames.HOW_TO_SECTION_HEIGHT]: number;
    [EElementNames.ACTIVE_MENU_TITLE_HEIGHT]: number;
    [EElementNames.ACTIVE_MENU_HEIGHT]: number;
    [EElementNames.FAVORITES_MENU_TITLE_HEIGHT]: number;
    [EElementNames.FAVORITES_MENU_HEIGHT]: number;
    [EElementNames.NAVIGATION_PARENT_SECTION]: number;
    [EElementNames.NAVIGATION_PARENT_SPORT_NAME]: number;
    [EElementNames.NAVIGATION_ALL_SPORT_ITEM]: number;
    [EElementNames.NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT]: number;
    [EElementNames.NAVIGATION_HIDE_NAVBAR_BUTTON]: number;
    [EElementNames.CONTEXTUAL_HELP]: number;
    [EElementNames.BANNER]: number;
    [EElementNames.WEBTOURS_IMAGE]: number;
    [EElementNames.FAVORITES_CONTENT_HEIGHT]: number;
    [EElementNames.ACTIVE_MARKETS_CONTENT_HEIGHT]: number;
    [EElementNames.SIDEBAR_HEIGHT]: number;
    [EElementNames.CONTENT_HEIGHT]: number;
    [EElementNames.NAVIGATION_HEIGHT]: number;
    [EElementNames.SEARCH_INPUT_HEIGHT]: number;
    [EElementNames.CASHOUT_HEADER_HEIGHT]: number;
    [EElementNames.MIN_NAV_HEIGHT]: number;
    [EElementNames.MOBILE_FOOTER_NAV_HEIGHT]: number;
    [EElementNames.MOBILE_PAGE_HEIGHT]: number;
    [EElementNames.MOBILE_CONTENT_HEIGHT]: number;
    [EElementNames.MOBILE_HEADER_HEIGHT]: number;
    [EElementNames.MOBILE_SWIPE_MENU_HEIGHT]: number;
    [EElementNames.MOBILE_ASIAN_SUB_HEADER]: number;
    [EElementNames.POPULAR_MARKETS_HEIGHT]: number;
    [EElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT]: number;
    [EElementNames.ASIAN_FAVOURITES_NAVIGATION]: number;
    [EElementNames.ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY]: number;
    [EElementNames.ASIAN_BET_SLIP_TABS]: number;
    [EElementNames.ASIAN_TIMEZONE]: number;
    [EElementNames.ASIAN_BALANCE]: number;
    [EElementNames.ASIAN_BET_SLIP_COLLAPSE]: number;
    [EElementNames.ASIAN_MIDDLE_SECTION]: number;
  };
  isUserScrolling: boolean;
  scrollUpdate: TScrollUpdate;
};

export type TScrollUpdate = { top: number; offset: number; trigger: boolean };

export type TSetElementHeightPayload = { name: EElementNames; height: number };
