import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver, useUnmount, useWindowSize } from 'usehooks-ts';

import BetsTable from 'components/BetsTable';
import MobileBetDetail from 'components/BetsTable/components/MobileBetDetail';
import TableRow from 'components/BetsTable/components/TableRow';
import MobileMyBetsSkeleton from 'components/Skeleton/MobileMyBetsSkeleton';
import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import { MY_BETS_CONTENT_SM_WIDTH_DESKTOP, SEARCH_CURRENCY_KEY } from 'constants/myBets';
import useAccountPages from 'hooks/useAccountPages';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import {
  getDesktopSettingsAlternativeLayOdds,
  getDesktopSettingsTotalWinnings
} from 'redux/modules/appConfigs/selectors';
import { removeCancelActionStatus, setCancelAllBtnState } from 'redux/modules/cancelActions';
import { getCancelAllBtnState, getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { hideMobileBetDetails, setCancelAllUnmatchedBetsActionId, setMultiCurrencyValue } from 'redux/modules/myBets';
import { getMyBets, isShowMobileBetDetails, myBetsLoading } from 'redux/modules/myBets/selectors';
import { successCancelAllBets } from 'redux/modules/placement';
import { ECancelAllBtnState } from 'types/betslip';
import { getDescriptionColumnWidth } from 'utils/accountPages';

import styles from './MyExchangeBetsPage.module.scss';

const MyExchangeBetsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const isCancellingBets = useSelector(getIsCancelActionsInProgress);
  const myBetsData = useSelector(getMyBets);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isLoading = useSelector(myBetsLoading);
  const cancelAllBtnState = useSelector(getCancelAllBtnState);
  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getDesktopSettingsTotalWinnings);

  const tableRef = useRef(null);

  const { width: tableWidth } = useResizeObserver({ ref: tableRef, box: 'border-box' });
  const { isMobile } = useDevice();
  const { isAsianView } = useAccountPages();
  const { width } = useWindowSize();
  const { product, periodType, isCurrentPeriod } = useMyBetsFilters();

  const {
    startDate,
    endDate,
    pageSize,
    pageNumber,
    handleSetPageNumber,
    handleSetPageSize,
    handleSetPageSizeAndNumber
  } = useMyBetsPagesNavigation();
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const { totalElements } = myBetsData;
  const showTableBlock = !isDetailsOpen || !isMobile;
  const showBetDetails = isDetailsOpen && isMobile;

  const isWideScreenView = width > MY_BETS_CONTENT_SM_WIDTH_DESKTOP;
  const descriptionWidth = getDescriptionColumnWidth(
    tableWidth,
    isWideScreenView,
    isCurrentPeriod,
    isAsianView,
    alternativeLayOdds,
    totalWinnings
  );

  useEffect(() => {
    if (isMultiCurrencySupported) {
      dispatch(setMultiCurrencyValue(searchCurrency || ''));
    }
  }, [isMultiCurrencySupported]);

  useEffect(() => {
    if (cancelAllBtnState === ECancelAllBtnState.CONFIRM) {
      dispatch(setCancelAllBtnState(ECancelAllBtnState.ACTIVE));
    }

    dispatch(removeCancelActionStatus());

    return () => {
      dispatch(removeCancelActionStatus());
      dispatch(successCancelAllBets(null));
      dispatch(setCancelAllUnmatchedBetsActionId(null));
    };
  }, [startDate, endDate, periodType, product]);

  useUnmount(() => {
    if (showBetDetails) {
      dispatch(hideMobileBetDetails());
    }
  });

  return (
    <>
      {showBetDetails && <MobileBetDetail />}
      {showTableBlock && (
        <>
          {isMobile ? (
            <>
              {isLoading || isCancellingBets ? (
                <MobileMyBetsSkeleton isShowTable />
              ) : (
                <BetsTable isMobile windowWidth={width} />
              )}
            </>
          ) : (
            <div
              ref={tableRef}
              className={classNames(styles.skeletonContainer, {
                [styles.skeletonContainer__active]: isLoading
              })}
            >
              {!isAsianView && alternativeLayOdds && (
                <div className={styles.infoMsg}>{t('account.mybets.messages.alternativeLayOdds')}</div>
              )}
              <TableRow isHeader isDisabled={!totalElements} windowWidth={width} />
              {(isLoading || isCancellingBets) && <MyBetsSkeleton isShowTable isAddColumn={!isCurrentPeriod} />}
              {!isLoading && !isCancellingBets && (
                <BetsTable windowWidth={width} tableWidth={tableWidth} descriptionWidth={descriptionWidth} />
              )}
            </div>
          )}
          {!isLoading && !!totalElements && !isCancellingBets && (
            <Pagination
              source={myBetsData}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageSize={handleSetPageSize}
              setPageNumber={handleSetPageNumber}
              setPageSizeAndNumber={handleSetPageSizeAndNumber}
            />
          )}
        </>
      )}
    </>
  );
};

export default MyExchangeBetsPage;
