import { BetsType, TBetsType } from 'redux/modules/myBets/type';
import { TMobileAccountDropdownValue } from 'types';

import {
  ACCOUNT_BASE_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  ASIAN_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  GAMES_BASE_URL,
  MY_BETS_BASE_URL
} from './locations';

export enum PLBetType {
  AsianView = 'AsianView',
  Games = 'Games',
  Sports = 'Sports'
}
export enum BetTypeValue {
  Games = 'GAME',
  Sports = 'EXCHANGE'
}

export const PLExchangeTypes = {
  [PLBetType.AsianView]: 'asianView',
  [PLBetType.Sports]: 'sports',
  [PLBetType.Games]: 'games'
};

export const PLTabButtons = [
  PLExchangeTypes[PLBetType.AsianView],
  PLExchangeTypes[PLBetType.Sports],
  PLExchangeTypes[PLBetType.Games]
];

export type TPLExchangeTypes = typeof PLExchangeTypes[keyof typeof PLExchangeTypes];

export const ExchangeTypes = {
  AsianView: 'asianView',
  SportsGames: 'sportsGames'
} as const;

export type TExchangeTypes = typeof ExchangeTypes[keyof typeof ExchangeTypes];

export const ExchangeTypeTabButtons = [ExchangeTypes.AsianView, ExchangeTypes.SportsGames];

export const plDefaultSportTypeValues = {
  sports: {
    translationKey: 'account.profitandloss.labels.allSports',
    name: 'allSports',
    id: '',
    value: '',
    hasDivider: true
  },
  games: {
    translationKey: 'account.profitandloss.labels.allGames',
    name: 'allGames',
    id: '',
    value: '',
    hasDivider: true
  }
};

export const defaultTimeRange = {
  translationKey: 'account.profitandloss.ranges.lastDay',
  name: 'lastDay'
};

export const SEPARATE_WALLET = 'SEPARATE_WALLET';
export const SINGLE_WALLET = 'SINGLE_WALLET';

export enum NavigationButtons {
  Back = 'back',
  MyBets = 'mybets',
  ProfitAndLoss = 'profitandloss',
  Statement = 'statement',
  Settings = 'settings',
  ResponsibleBetting = 'responsibleBetting'
}

export const mapAccountPageToBetsType: Record<string, TBetsType> = {
  mybets: BetsType.All_MY_BETS,
  profitandloss: BetsType.ALL_PROFIT_AND_LOSS,
  statement: BetsType.ALL_ACCOUNT_STATEMENT
};

export enum Period {
  Open = 'Open',
  Settled = 'Settled'
}

export const BetsPeriods = [Period.Open, Period.Settled];

export enum BetStatuses {
  Settled = 'Settled',
  Cancelled = 'Cancelled',
  Voided = 'Voided',
  Lapsed = 'Lapsed',
  Matched = 'Matched',
  Unmatched = 'Unmatched',
  Removed = 'REMOVED',
  Pending = 'Pending'
}

export enum BetTypes {
  Settled = 'settled',
  Cancelled = 'cancelled',
  Voided = 'voided',
  Lapsed = 'lapsed',
  Won = 'won',
  Lost = 'lost',
  Matched = 'matched',
  Unmatched = 'unmatched'
}

export const BetsTypesByPeriod = {
  [Period.Open]: [BetTypes.Unmatched, BetTypes.Matched],
  [Period.Settled]: [BetTypes.Won, BetTypes.Lost, BetTypes.Cancelled, BetTypes.Lapsed, BetTypes.Voided]
};

export enum MyBetsLocations {
  MyBets = '/account/mybets',
  Statement = '/account/statement',
  ProfitAndLoss = '/account/profitandloss',
  Settings = '/account/settings',
  ResponsibleBetting = '/account/responsible-betting',
  Open = '/account/mybets/open',
  Settled = '/account/mybets/settled'
}

export const BetPeriodsMap: Record<string, Period> = {
  [MyBetsLocations.Open]: Period.Open,
  [MyBetsLocations.Settled]: Period.Settled
};

export const myBetsDateFormat = 'dd/MM/yyyy';

export const myBetsDateFormatUS = 'MM/dd/yyyy';

export const statementFilterValues: { [key: string]: { value: string; translationKey: string } } = {
  all: { translationKey: 'account.statement.labels.all', value: 'ALL' },
  withdrawal: { translationKey: 'account.statement.labels.depositAndWithdrawal', value: 'DEPOSIT_WITHDRAWAL' }
};

export enum PLTimeRanges {
  LastDay = 'lastDay',
  Yesterday = 'yesterday',
  SevenDays = 'sevenDays',
  Month = 'month',
  ThreeMonths = 'threeMonths'
}

export const lineBettingType = 'LINE';

export const lineAvgPriceFlatValue = '2.00';

export const pageSizes = [20, 50, 100];

export const firstPageIndex = '1';

export const getAccountNavigationMobileLinks = ({
  isResponsibleBettingMenuEnabled,
  isMyBetsSettledTab,
  isGamePage,
  isAsianViewPage
}: {
  isResponsibleBettingMenuEnabled: boolean;
  isMyBetsSettledTab: boolean;
  isAsianViewPage: boolean;
  isGamePage: boolean;
}) => {
  let baseUrl = ACCOUNT_BASE_URL;

  if (isAsianViewPage) {
    baseUrl = ASIAN_BASE_URL + ACCOUNT_BASE_URL;
  } else if (isGamePage) {
    baseUrl = GAMES_BASE_URL + ACCOUNT_BASE_URL;
  }

  return [
    {
      name: NavigationButtons.MyBets,
      url: `${baseUrl}${MY_BETS_BASE_URL}/${(isMyBetsSettledTab
        ? Period.Settled
        : Period.Open
      ).toLowerCase()}/${firstPageIndex}`,
      labelTranslationKey: 'accountNavigation.mybets',
      isSubMenu: true
    },
    {
      name: NavigationButtons.ProfitAndLoss,
      url: `${baseUrl}${BETTING_PROFIT_LOSS_BASE_URL}/${firstPageIndex}`,
      labelTranslationKey: 'accountNavigation.profitandloss',
      isSubMenu: true
    },
    {
      name: NavigationButtons.Statement,
      url: `${baseUrl}${ACCOUNT_STATEMENT_BASE_URL}/${firstPageIndex}`,
      labelTranslationKey: 'accountNavigation.statement',
      isSubMenu: true
    },
    {
      name: NavigationButtons.ResponsibleBetting,
      url: `${baseUrl}/responsible-betting`,
      labelTranslationKey: 'responsibleBetting.labels.responsibleBetting',
      isVisible: isResponsibleBettingMenuEnabled
    }
  ];
};

export enum MyBetsPage {
  ExchangeBets = 'exchange-bets',
  ProfitLoss = 'profit-loss',
  AccountStatement = 'account-statement'
}

export const mapMyBetsPageToBetsType: Record<MyBetsPage, TBetsType> = {
  [MyBetsPage.ExchangeBets]: BetsType.All_MY_BETS,
  [MyBetsPage.ProfitLoss]: BetsType.ALL_PROFIT_AND_LOSS,
  [MyBetsPage.AccountStatement]: BetsType.ALL_ACCOUNT_STATEMENT
};

export const getCurrentAccountNavigationButtonMobile = (
  pathname: string,
  navigationButtons: TMobileAccountDropdownValue[]
) => {
  const currentMyBetsPage = pathname.split('/', 3).join('/');

  switch (currentMyBetsPage) {
    case MyBetsLocations.MyBets:
      return navigationButtons[0];
    case MyBetsLocations.ProfitAndLoss:
      return navigationButtons[1];
    case MyBetsLocations.Statement:
      return navigationButtons[2];
    case MyBetsLocations.ResponsibleBetting:
      return navigationButtons[3];
    case MyBetsLocations.Settings:
      return navigationButtons[4];
  }

  return navigationButtons[0];
};

export const SEARCH_CURRENCY_KEY = 'currency';
export const MY_BETS_CONTENT_SM_WIDTH_DESKTOP = 1200;
export const MY_BETS_PAGINATION_LANDSCAPE_WIDTH = 768;
export const EXPORT_DELAY = 5000;

export const EXPORT_FILENAME = {
  ExchangeBets: 'My_Bets',
  ProfitLoss: 'Betting_Profit_and_Loss',
  AccountStatement: 'Account_Statement'
};

export const MIN_ITEMS_PAGINATION = pageSizes[0] || 20;
export const MIN_BACK_TO_TOP_ITEMS = 10;

export const myBetsTableHeader = {
  REF_ID: 'account.mybets.labels.refID',
  PLACED: 'account.mybets.labels.placed',
  SETTLED: 'account.mybets.labels.settled',
  DESCRIPTION: 'account.mybets.labels.description',
  TYPE: 'account.mybets.labels.type',
  ODDS_TYPE: 'asianView.labels.account.oddsType',
  BACK_ODDS: 'account.mybets.labels.backOdds',
  LAY_ODDS: 'account.mybets.labels.layOdds',
  ODDS: 'account.mybets.labels.odds',
  STAKE: 'account.mybets.labels.stake',
  RISK: 'account.mybets.labels.risk',
  LIABILITY: `account.mybets.labels.liability`,
  TOTAL_WINNINGS: 'account.mybets.labels.totalWinnings',
  POTENTIAL_PROFIT: 'account.mybets.labels.potentialProfit',
  PROFIT_LOSS: 'account.mybets.labels.profitLoss',
  STATUS: 'account.mybets.labels.status'
};

export const plTableHeader = {
  EVENT: 'account.profitandloss.labels.event',
  MARKET: 'account.profitandloss.labels.market',
  START: 'account.profitandloss.labels.start',
  SETTLED: 'account.profitandloss.labels.settled',
  PL: 'account.profitandloss.labels.profitandloss'
};

export const MY_BETS_PAGES: Record<string, MyBetsPage> = {
  mybets: MyBetsPage.ExchangeBets,
  profitandloss: MyBetsPage.ProfitLoss,
  statement: MyBetsPage.AccountStatement
};

export const StatementDataStatuses = {
  WON: 'WON',
  LOST: 'LOST',
  HALF_WON: 'HALF_WON',
  HALF_LOST: 'HALF_LOST',
  TRANSACTION: 'TRANSACTION'
} as const;

export const StatementOriginTypes = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  SETTLEMENT: 'SETTLEMENT',
  CORRECTION: 'CORRECTION',
  RESETTLEMENT: 'RESETTLEMENT',
  CHARGE_COMMISSION: 'CHARGE_COMMISSION',
  CHARGE_TRANSACTION: 'CHARGE_TRANSACTION'
} as const;

export const StatementOriginNoteTypes = {
  HALF_LOSS: 'HALF_LOSS',
  HALF_WIN: 'HALF_WIN',
  SETTLEMENT: 'SETTLEMENT',
  CORRECTION: 'CORRECTION',
  RESETTLEMENT: 'RESETTLEMENT',
  RESERVATION: 'RESERVATION',
  RESERVATION_IN_PLAY: 'RESERVATION_IN_PLAY',
  REFUND: 'REFUND',
  STAKE_REFUND: 'STAKE_REFUND'
} as const;

export const StatementTransactionTypes = {
  ALL: 'ALL',
  BETTING: 'BETTING',
  COMMISSION: 'COMMISSION',
  RESETTLEMENT_CORRECTION: 'RESETTLEMENT_CORRECTION',
  DEPOSIT_WITHDRAWAL: 'DEPOSIT_WITHDRAWAL'
} as const;

export const AccountStatementSortByFields = {
  SETTLED_DATE: 'sort_settled_date',
  PLACED_DATE: 'sort_placed_date'
} as const;

export const COL_XS = 40;
export const COL_SM = 60;
export const COL_MD = 80;
export const COL_LG = 110;
export const COL_GAP = 20;
export const TABLE_PADDING = 20;

export const DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME =
  'DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES';

export const MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME = 'MY_BETS_IS_SETTLED_PERIOD_TYPE';
