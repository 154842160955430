import { useEffect, useState } from 'react';

import { DropdownItem } from 'types';

export const useSetHighlightedRow = (
  pageNumber: number,
  pageSize: number,
  optional: {
    plStartDate?: Date;
    startDate?: Date;
    endDate?: Date;
    activePLTab?: string;
    eventType?: DropdownItem;
  }
) => {
  const [rowHighlighted, setRowHighLighted] = useState<number | undefined>();
  const { plStartDate, startDate, endDate, eventType, activePLTab } = optional;

  useEffect(() => {
    setRowHighLighted(undefined);
  }, [pageNumber, pageSize, plStartDate, startDate, endDate, eventType, activePLTab]);

  return { rowHighlighted, setRowHighLighted };
};
