import { SyntheticEvent, useEffect, useState } from 'react';
import classNames from 'classnames';

import { mobileIcons } from 'constants/branding';
import { TMobileSelectOption } from 'types/myBets';

import styles from './styles.module.scss';

type TMobileSelectProps = {
  title: string;
  defaultOptions: TMobileSelectOption[];
  onChangeCallback: (options: TMobileSelectOption[], isChanged: boolean) => void;
};

const MobileSelect = ({ title, defaultOptions, onChangeCallback }: TMobileSelectProps) => {
  const [options, setOptions] = useState<TMobileSelectOption[]>(defaultOptions);

  const isChanged = options.some(({ isSelected, defaultIsSelected }) => isSelected !== defaultIsSelected);

  const toggleOptionHandler = (ev: SyntheticEvent<HTMLLIElement>, id: string) => {
    setOptions(options.map(option => ({ ...option, isSelected: option.id === id })));
  };

  useEffect(() => {
    onChangeCallback(options, isChanged);
  }, [options, isChanged]);

  return (
    <section className={styles.wrap}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
      </div>
      <ul className={styles.list}>
        {options.map(({ id, isSelected, label, tooltip }) => (
          <li
            className={classNames(styles.item, { [styles.item__selected]: isSelected })}
            key={id}
            onClick={ev => toggleOptionHandler(ev, id)}
          >
            <span className={styles.label}>
              {label}
              {tooltip && (
                <i
                  data-tooltip-id="tooltip"
                  data-tooltip-html={tooltip}
                  className={classNames('biab_custom-icon-info-24', styles.icon, mobileIcons.TOOLTIP)}
                >
                  <span className={classNames('path1', styles.iconBg, mobileIcons.TOOLTIP_BG)} />
                  <span className={classNames('path2', mobileIcons.TOOLTIP_INNER)} />
                  <span className={classNames('path3', mobileIcons.TOOLTIP_INNER)} />
                </i>
              )}
            </span>
            {isSelected && <i className={classNames('biab_custom-icon-check-24', styles.icon)} />}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default MobileSelect;
