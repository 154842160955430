import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SEPARATE_WALLET, StatementTransactionTypes } from 'constants/myBets';
import MobileMultiselect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileMultiselect';
import {
  getIsExchangeGamesEnabled,
  getPNCEnabledSetting,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { setMobileFilters } from 'redux/modules/myBets';
import { getAccountStatementTransactionsTypes } from 'redux/modules/myBets/selectors';
import { StatementTransactionType } from 'redux/modules/myBets/type';
import { TMobileSelectOption } from 'types/myBets';

type MobileTransactionsTypesProps = {
  isAsianViewTab: boolean;
};

const MobileTransactionsTypes = ({ isAsianViewTab }: MobileTransactionsTypesProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accountStatementTransactions = useSelector(getAccountStatementTransactionsTypes);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const walletIntegrationType = useSelector(getWalletIntegrationType);

  const transactionsOptions = [
    {
      id: StatementTransactionTypes.BETTING,
      label: t('account.statement.labels.sportsBetting'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.BETTING),
      defaultIsSelected: accountStatementTransactions.includes(StatementTransactionTypes.BETTING),
      isVisible: true
    },
    {
      id: StatementTransactionTypes.COMMISSION,
      label: t('accountStatement.commission'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.COMMISSION),
      defaultIsSelected: accountStatementTransactions.includes(StatementTransactionTypes.COMMISSION),
      isVisible: isAsianViewTab ? false : !isPNCEnabled || exchangeGamesEnabled
    },
    {
      id: StatementTransactionTypes.RESETTLEMENT_CORRECTION,
      label: t('account.statement.labels.resettlementsCorrections'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.RESETTLEMENT_CORRECTION),
      defaultIsSelected: accountStatementTransactions.includes(StatementTransactionTypes.RESETTLEMENT_CORRECTION),
      isVisible: true
    },
    {
      id: StatementTransactionTypes.DEPOSIT_WITHDRAWAL,
      label: t('account.statement.labels.depositAndWithdrawal'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.DEPOSIT_WITHDRAWAL),
      defaultIsSelected: accountStatementTransactions.includes(StatementTransactionTypes.DEPOSIT_WITHDRAWAL),
      isVisible: walletIntegrationType === SEPARATE_WALLET
    }
  ].filter(({ isVisible }) => isVisible);

  const handleChangeTransactionsTypes = (
    transactionsTypesChanged: TMobileSelectOption[],
    isTransactionsTypesChanged: boolean
  ) => {
    dispatch(
      setMobileFilters({
        transactionsTypes: transactionsTypesChanged
          .filter(({ isSelected }) => isSelected)
          .map(({ id }) => id as StatementTransactionType),
        isTransactionsTypesChanged
      })
    );
  };

  return (
    <MobileMultiselect
      title={t('account.statement.labels.transactionTypes')}
      defaultOptions={transactionsOptions}
      onChangeCallback={handleChangeTransactionsTypes}
    />
  );
};

export default MobileTransactionsTypes;
