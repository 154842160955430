import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { setSettings } from 'redux/modules/user';
import { getIsQuickStakesEnabled, getIsUserAccountSettings } from 'redux/modules/user/selectors';

import styles from './QuickBetsSection.module.scss';

type QuickBetsSectionProps = {
  showQuickBetsView: () => void;
};

const QuickBetsSection = ({ showQuickBetsView }: QuickBetsSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isQuickBetsEnabled = useSelector(getIsQuickStakesEnabled);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [isQuickBetsEnabledState, setIsQuickBetsEnabledState] = useState(isQuickBetsEnabled);

  const handleSwitchQuickBets = (event: ChangeEvent<HTMLInputElement>) => {
    setIsQuickBetsEnabledState(event.target.checked);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            quickStakesEnabled: event.target.checked
          }
        })
      );
    }
  };

  return (
    <div className={styles.quickBets}>
      <label className={styles.quickBets__header}>
        <h4 className={styles.quickBets__header__title}>{t('account.settings.quickBets.newTitle')}</h4>
        <ToggleSwitch
          checked={isQuickBetsEnabledState}
          onChange={handleSwitchQuickBets}
          containerClassName={styles.quickBets__header__toggle}
        />
      </label>
      <button onClick={showQuickBetsView} className={styles.quickBets__setUp}>
        {t('account.settings.setUpQuickBets')}
        <i className={classNames('biab_custom-icon-chevron-right-thin', styles.quickBets__setUp__icon)} />
      </button>
    </div>
  );
};

export default QuickBetsSection;
