import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding } from 'constants/branding';
import { EXPORT_DELAY } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { fetchRequest } from 'redux/api/request';
import { setExportNotification } from 'redux/modules/myBets';
import { getExportNotification } from 'redux/modules/myBets/selectors';
import { Method } from 'types';

import styles from './styles.module.scss';

interface ExportButtonProps {
  data: any;
  isDisabled: boolean;
}

const ExportButton = ({ data, isDisabled }: ExportButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, isDisabled: disabled, isDownloading: downloading } = useSelector(getExportNotification);

  const { selectedTab } = useAccountUrlParams();

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const isBtnDisabled = downloading || disabled || isDisabled;
  const isError = error !== '';

  useEffect(() => {
    dispatch(setExportNotification({ error: '', isDownloading: false, isDisabled: false }));
  }, [selectedTab]);

  const handleDownload = async () => {
    dispatch(setExportNotification({ error: '', isDownloading: true, isDisabled: true }));
    timeout.current = setTimeout(() => {
      timeout.current = null;
      dispatch(setExportNotification({ isDisabled: false }));
    }, EXPORT_DELAY);

    try {
      let urlParams = '';

      if (data?.params?.betsType) {
        urlParams = data?.params?.betsType.map((type: string) => `betsType=${type}`).join('&');

        delete data?.params?.betsType;
      }

      if (data?.params?.accountStatementOrigin) {
        urlParams = data?.params?.accountStatementOrigin
          .map((type: string) => `accountStatementOrigin=${type}`)
          .join('&');

        delete data?.params?.accountStatementOrigin;
      }

      const file = await fetchRequest(
        Method.GET,
        `${data?.url}${urlParams ? `?${urlParams}` : ''}`,
        data?.params,
        {},
        true,
        'blob' as ResponseType
      ).then(res => res);

      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement('a');
      const time = new Date().getTime();
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${data.filename}_${time}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();

      document.body.removeChild(fileLink);
      URL.revokeObjectURL(fileURL);
    } catch (e) {
      dispatch(setExportNotification({ error: t('account.labels.exportError') }));
    } finally {
      dispatch(setExportNotification({ isDownloading: false }));
    }
  };

  useEffect(() => {
    dispatch(setExportNotification({ isDisabled: false }));
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <div className={styles.exportButton__container}>
      {isError && (
        <div className={classNames(styles.error, componentsBranding.NOTIFICATION, componentsBranding.ERROR)}>
          <i className={classNames(styles.error__icon, 'biab_custom-icon-warning-circle')} />
          {error}
          <button className={styles.error__closeBtn} onClick={() => dispatch(setExportNotification({ error: '' }))}>
            <i className={classNames('biab_custom-icon-close', styles.error__closeIcon)} />
          </button>
        </div>
      )}
      {!isError && (
        <button
          className={classNames(styles.exportButton, {
            [styles.exportButton__disabled]: isBtnDisabled
          })}
          onClick={handleDownload}
          disabled={isBtnDisabled}
          data-tooltip-id="tooltip"
          data-tooltip-html={t('account.labels.export')}
        >
          {downloading ? (
            <i className={classNames('fa fa-spinner fa-pulse fa-2x fa-fw', styles.icon)} />
          ) : (
            <i className={classNames('biab_custom-icon-export', styles.icon__export)} />
          )}
        </button>
      )}
    </div>
  );
};

export default ExportButton;
