import { NavigateFunction } from 'react-router-dom';
import { CookieSetOptions } from 'universal-cookie';

import { CookieNames, EventViewType, FailureActionPayload, HomePageType, ViewBy } from 'types';
import { MarketOddsBannerLocationType } from 'types/markets';
import { ResponsibleBettingReminderTimeUnit } from 'types/responsibleBetting';

import { Pages } from '../pages/types';
import { ImageFileType } from '../tours/type';

export enum Devices {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP'
}

export enum Platform {
  WEB = 'WEB'
}

export enum CommissionTypes {
  WINNINGS = 'WINNINGS',
  PRICES = 'PRICES'
}

type SupportedLocales = {
  code: string;
  image: string;
};

export interface TPageServiceOptions {
  desktopFooterHidden: boolean;
  desktopHeaderHidden: boolean;
  iframeEnabled: boolean;
  mobileAccountButtonEnabled: boolean;
  mobileTimezoneDropdownEnabled: boolean;
  defaultSorting: ViewBy;
}

export interface TCurrencySteps {
  min: number;
  max: number;
  step: number;
}

export interface IEventViewSettings {
  eventTypeId: string;
  fixPosition: boolean;
  topMarketId: string | null;
  twoColumnView: boolean;
  viewType: EventViewType;
}

export type FooterImage = {
  link: string;
  retinaImageUrl: string;
  imageUrl: string;
  id: number;
  absolutePathEnabled: boolean;
  openInNewTab: boolean;
};

export type NavigationType = {
  absolutePath: boolean;
  authUserVisible: boolean;
  description: null | string;
  highlighted: boolean;
  iconName: string;
  label: string;
  modifiable: boolean;
  noneAuthUserVisible: boolean;
  openInNewTab: boolean;
  orderNumber: number;
  page: string;
  pageBlockId: number;
  placeOnTheRight: boolean;
  rearrangeable: boolean;
  sportId: number | string;
  url: string;
  isMore?: boolean;
};

export type Settings = {
  activeMarkets: boolean;
  alternativeLayOdds: boolean;
  anySportWithLayEnabled: boolean;
  asianViewCashOutPaginationButtons: boolean;
  backButtonMobile: boolean;
  collapseSportsBar: boolean;
  competitionViewSports: string[];
  confirmBetsBeforePlace: boolean;
  connectionChecks: boolean;
  consolidateBets: boolean;
  contextualHelp: boolean;
  defStake: boolean;
  depositBtn: boolean;
  displayLocksForRestrictedMarkets: boolean;
  fancyMarketsOnCricket: boolean;
  fillOrKill: boolean;
  footerContent: string;
  footerImages: FooterImage[] | null;
  footerLinks: NavigationType[];
  headerNavigation: NavigationType[];
  howTo: boolean;
  howToMobile: boolean;
  infiniteScrollEnabled: boolean;
  inlineBetting: boolean;
  langDropdownAuth: boolean;
  langDropdownNoAuth: boolean;
  lineMarketsSwitchBackLayOnCricket: boolean;
  listViewSports: string[];
  loginHeaderButtons: boolean;
  myBetsLinks: NavigationType[];
  netOfCommission: boolean;
  netOfCommissionBetslip: boolean;
  netOfCommissionBetslipDefaultState: boolean;
  netOfCommissionDefaultState: boolean;
  pageNotFoundBackGroundId: number;
  pageNotFoundLinks: NavigationType[];
  placeBetWithEnterKey: boolean;
  pwdChangeBtn: boolean;
  quickstakeBetslip: boolean;
  replaceBackLayWithUnderOver: boolean;
  showLabelsAboveInputFields: boolean;
  showLayOddsGames: boolean;
  showLayOddsSports: boolean;
  sportsWithoutLayColumn: string[];
  swapColorsFancyMarketsOnCricket: boolean;
  totalWinnings: boolean;
  viewLiquidity: boolean;
  virtualKeyboardBetslip: boolean;
  webtours: boolean;
};

export type ResponsibleBettingDisplaySettings = {
  id: number | null;
  domainId: number | null;
  walletGroupId: number | null;
  lossLimitCooldownDays?: number;
  groupsToCopy: null;
  exposureLimitEnabled: boolean;
  lossLimitEnabled: boolean;
  timeLimitEnabled: boolean;
  selfExclusionEnabled: boolean;
  reminderEnabled: boolean;
  reminderInterval: number | null;
  reminderUnit: null | ResponsibleBettingReminderTimeUnit;
  selfExclusionPeriods?: string[];
  dayLimitEnabled: boolean;
  passiveSessionEnabled: boolean;
  passiveSessionPositionHeader: boolean;
  headsUpOverHeader: boolean;
  lastUpdated: number | null;
  timeLimitEndNotificationEnabled: boolean;
};

type DesktopSettings = {
  anyGameEnabled: boolean;
  asianViewBalanceToggle: boolean;
  asianViewMiddleSectionPageSize: number;
};

type MobileSettings = {
  asianViewMobileBetListPagination: boolean;
  asianViewMobileNavigationMenu: boolean;
  classicNavigation: boolean;
  classicNavigationLinks: NavigationType[];
  positionOfAsianViewMobileNavigationMenu: boolean;
  swipeMenu: boolean;
  swipeMenuLinks: NavigationType[];
};

export type TProperties = {
  minNavSportIds: string[];
  accountId: string;
  language: string;
  version: string;
  brandingCssVersion: string;
  connectionFilePath: string;
  connectionFileSize: number;
  connectionInterval: number;
  connectionMaxSpeed: number;
  connectionMinSpeed: number;
  defaultQuickStakes: { stakes: number[]; gameStakes: number[] };
  displayCustomerCommissionRange: boolean;
  competitionViewWithFiltersSupportedSportIds: string[];
  competitionViewSupportedSportIds: string[];
  isCompetitionViewEnabled: boolean;
  supportedLocales: SupportedLocales[];
  reconnectAttempts?: number;
  reconnectTimeout?: number;
  rtoMultiplier?: number;
  marketPricesWSEnabled: boolean;
  operatorBalanceEnabled: boolean;
  mobileSettings: Settings & MobileSettings;
  desktopSettings: Settings & DesktopSettings;
  domain: string;
  displayIconsInSubHeader: boolean;
  operator: string;
  newCustomerCookieName: string;
  fallbackLang: string;
  displayCurrencySymbol: boolean;
  currencySymbolAfterAmount: boolean;
  featuredSports: string[];
  desktopExtendedMarketCellWidth: number;
  desktopCellWidth: number;
  desktopExtendedCellWidth: number | string;
  desktopMarketCellWidth: number | string;
  hideToursFadePeriodMs: number;
  mobileMarketCellWidth: number;
  mobileCellWidth: number;
  runningBallInterval: number;
  marketSuspendedTimeToHideSeconds: number;
  americanDateFormatEnabled: boolean;
  pwdLink: NavigationType;
  timezoneCookieEnabled: boolean;
  sportEnabled: boolean;
  horseRacingSportPageInterval: number;
  cashOutEnabled: boolean;
  cashOutGetMarketsInterval: number;
  cashOutGetStatusInterval: number;
  marketOddsBackOnly: boolean;
  marketOdds: boolean;
  marketOddsBannerLocation: MarketOddsBannerLocationType | null;
  masterAccountCommissionType: string;
  multiCurrencySupported: boolean;
  replaceCurrencySymbolForMatchedAndTooltips: boolean;
  homePageColumnsNumber: number;
  hideMarketDepth: boolean;
  minSearchQueryLength: number;
  pageServingOptions: TPageServiceOptions;
  currencySteps: TCurrencySteps[];
  inPlayDefTabHighlights: boolean;
  countriesWithCustomClothIcon: string;
  betfairCdnImageFeedPath: string;
  isSportIconsEnabled: boolean;
  inPlayPaginationSize: number;
  walletIntegrationType: string;
  homePageType: HomePageType | null;
  currentBetsRequestInterval: string;
  betsStatusesRequestInterval: string;
  betslipSpinnerTime: string;
  nextHorseEnabled: boolean;
  desktopCompetitionPageSize: number;
  numberOfMarketSelectionsScroll: number;
  isMarketSelectionsScroll: boolean;
  eventViewSettings: IEventViewSettings[];
  minAmountToShowOdds: number;
  indianNumberSystemEnabled: boolean;
  customPopUpCloseButtonEnabled: boolean;
  customPopUpEnabled: boolean;
  tours: Tour[];
  timeformStatisticsEnabled: boolean;
  bettingDisplaySettings: ResponsibleBettingDisplaySettings;
  headerWarnMessage: string;
  nonAuthLoginNotificationEnabled: boolean;
  postLoginNotificationEnabled: boolean;
  tokenTypeCookieName: string;
  hideLiveScoreInHeader: boolean;
  matchStatUrl: null | string;
  nonAuthMatchStatisticsEnabled: boolean;
  isMobileMenuButtonTextEnabled: boolean;
  asianViewEnabled: boolean;
  exchangeGamesEnabled: boolean;
  exchangeSportsEnabled: boolean;
  currentBetsWsEnabled: boolean;
  autoCashOutEnabled: boolean;
  asianViewLayBettingEnabled?: boolean;
  exchangeGamesInterval: number;
  desktopCashoutPageSize: number;
  asianViewCashOutSportsEnabled: string[];
  autoCashOutWsEnabled: boolean;
  cashOutQuoteWsEnabled: boolean;
  cashOutGetQuotesInterval: number;
  asianViewMobileBetListPaginationSize: number;
  operatorBettingLimitsEnabled: boolean;
  bettingLoginModalShown: boolean;
  balanceWsEnabled: boolean;
  eventUpdatesWsEnabled: boolean;
  generalWsEnabled: boolean;
  // Properties below are received from general websocket PROPERTIES
  disabledLayOddsSportIds?: string[];
};

export type TFetchPropertiesResponse = TProperties & {
  competitionViewSupportedSportIds: string;
  competitionViewWithFiltersSupportedSportIds: string;
};

export type AppConfigs = {
  properties: TProperties;
  splashScreenRefreshTimeout: null | number;
  splashScreenContent: string;
  splashScreenEnabled: boolean;
  timezone: string;
  device: Devices;
  isLandscape: boolean;
  pages: Pages;
  loading: boolean;
  error: FailureActionPayload;
  currency: TSuccessFetchCurrency;
  currencies: TSuccessFetchCurrency[];
  propertiesLoading: boolean;
  cashOutPopUp: CashOutPopUp;
  cashOutPopUpLoading: boolean;
  isPropertiesLoaded: boolean;
  backendContentPage: string;
  brandingCSSLoaded: boolean;
  currencyLoading: boolean;
  currenciesLoading: boolean;
  stringifiedCurrency: string;
  isCollapsibleRightSide: boolean;
  arePropertiesFromWebSocketLoaded: boolean;
};

export type TSuccessFetchCurrency = {
  currencyCode: string;
  defaultDisplayFormat: boolean;
  description: string;
  displayFormat: number | null;
  gamesMaxBetSize: number;
  gamesMinBetSize: number;
  gamesStep: number;
  maxBetSize: number;
  minBetSize: number;
  step: number;
  symbol: string;
};

export type Tour = {
  enabled: boolean;
  displayOrder: number;
  id: number;
  steps: TourStep[];
  tourHeader: string;
  tourName: string;
};

export type TourStep = {
  content: string | null;
  displayOrder: number;
  enabled: boolean;
  id: number;
  imageFile: ImageFileType | null;
  maximumHeight: number;
  maximumWidth: number;
  stepName: string;
  tourId: number;
};

export interface TTranslation {
  [key: string]: string;
}

export type TFetchConfigsPayload =
  | {
      changeLoadingState?: boolean;
      disabledProductError?: boolean;
      navigate?: NavigateFunction;
    }
  | undefined;

export type CashOutPopUp = {
  content: string;
  name: string;
};

export type TCSRFCookieType = {
  cookies: {
    [CookieNames.CSRF]?: any;
    [CookieNames.TOKEN]?: string;
    [CookieNames.LANGUAGE]?: string;
    [CookieNames.TIMEZONE]?: string;
  };
  setCookie: (name: CookieNames.CSRF | CookieNames.TOKEN, value: any, options?: CookieSetOptions | undefined) => void;
};
