import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import dropdownStyles from 'pages/MyBetsPages/dropdown.module.scss';

import MultiCurrencyDropdown from '../MultiCurrencyDropdown';

const CurrenciesDropdown = () => {
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  if (!isMultiCurrencySupported) {
    return null;
  }

  return <MultiCurrencyDropdown customClasses={dropdownStyles} />;
};

export default CurrenciesDropdown;
