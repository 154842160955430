import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Image from 'components/Image/Image';
import { OPERATOR } from 'constants/app';
import { asianViewIcons, iconsBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getIsAsianViewEnabled, getOperator } from 'redux/modules/appConfigs/selectors';
import { clearQuickBets } from 'redux/modules/asianViewQuickBetting';
import { BrandingDropdownClasses, DropdownClasses, DropdownItem } from 'types';

import styles from './styles.module.scss';

interface DropdownProps {
  /**
   * Data that should be rendered into dropdown
   */
  data: Array<DropdownItem>;

  /**
   * Data that should be rendered into dropdown
   */
  defaultValue?: DropdownItem;

  /**
   * Will title be appeared into placeholder?
   */
  title?: boolean;

  /**
   * Label will appear with some type of dropdowns: f.e. - localization
   */
  label?: boolean;

  /**
   * Callback called when user select any option from dropdown
   */
  onSelectOption: (item: DropdownItem) => void;

  /**
   * Customize dropdown with classes
   */
  classes?: DropdownClasses;

  /**
   * Customize dropdown with branding
   * */
  brandingClasses?: BrandingDropdownClasses;

  /**
   * Callback that returned icon node
   */
  icon?: (isOpen: boolean) => ReactNode;

  /**
   * Should selected item be displayed in the dropdown list if opened
   */
  dropdownListIncludesSelected?: boolean;

  /**
   * Add background overlay for dropdown
   **/
  withBackgroundOverlay?: boolean;

  /*** to get is dropdown in header or not (for branding) ***/
  isInHeader?: boolean;
  disabled?: boolean;
  tooltipsEnabled?: boolean;
  tooltipElement?: 'icon' | 'item';
  tooltipPosition?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';
  iconPosition?: 'left' | 'right';
}

const Dropdown = ({
  data,
  defaultValue = data[0],
  label,
  title = true,
  onSelectOption,
  classes,
  brandingClasses,
  icon = () => null,
  dropdownListIncludesSelected = true,
  withBackgroundOverlay = false,
  isInHeader = false,
  disabled,
  tooltipsEnabled = true,
  tooltipElement = 'icon',
  tooltipPosition = 'top',
  iconPosition = 'right',
  ...props
}: DropdownProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const operator = useSelector(getOperator);
  const isAsianView = useSelector(getIsAsianViewEnabled);

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useDevice();

  const handleClickOption = (selectedOption: DropdownItem) => {
    setIsOpen(false);
    onSelectOption(selectedOption);
  };

  useOnClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });

  const dropdownList = !dropdownListIncludesSelected ? data.filter(({ id }) => id !== defaultValue.id) : data;

  useEffect(() => {
    if (isAsianView && isOpen) {
      dispatch(clearQuickBets());
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={classNames(`js-select`, styles.dropdownContainer, {
          [classes?.container ?? '']: classes?.container,
          [styles.opened]: isOpen && withBackgroundOverlay,
          'js-is-open': isOpen && isInHeader
        })}
        ref={dropdownRef}
        {...props}
      >
        <span
          className={classNames(
            'js-placeholder biab_placeholder biab_dropbtn',
            styles.dropdownPlaceholder,
            {
              [styles.dropdownPlaceholder_active]: isOpen,
              [styles.dropdownPlaceholder__mobile]: isMobile,
              [classes?.activePlaceholder ?? '']: classes?.activePlaceholder && isOpen,
              [styles.dropdownPlaceholder__ps]: operator === OPERATOR.POKER_STAR,
              [brandingClasses?.active ?? '']: brandingClasses?.active && isOpen,
              [classes?.disabledDropdown ?? '']: disabled
            },
            classes?.placeholder ?? '',
            brandingClasses?.dropdownBtn ?? ''
          )}
          onClick={disabled ? undefined : () => setIsOpen(!isOpen)}
        >
          {defaultValue?.image && label && (
            <Image
              className={styles.dropdownPlaceholder__label}
              name={defaultValue.value}
              width={21}
              height={14}
              path={defaultValue?.image}
            />
          )}
          {iconPosition === 'left' && icon(isOpen)}
          {defaultValue?.icon && <i className={classNames(classes?.icon, defaultValue?.icon)} />}
          {title && (defaultValue?.isLabelTranslatable ? t(defaultValue.label ?? '') : defaultValue?.label)}
          {iconPosition === 'right' && icon(isOpen)}
          {tooltipsEnabled && defaultValue?.tooltip && defaultValue?.tooltip !== '' && (
            <i
              data-tooltip-id="tooltip"
              data-tooltip-html={defaultValue?.tooltip}
              className={classNames(
                'fa2 fa2-info-circle tooltip-icon',
                styles.tooltipIcon,
                styles.dropdownPlaceholder__tooltip,
                classes?.tooltipIcon,
                iconsBranding.TOOLTIP
              )}
            >
              <span className={classNames('path1', iconsBranding.TOOLTIP_BG)} />
              <span className={classNames('path2', iconsBranding.TOOLTIP_INNER)} />
            </i>
          )}
        </span>

        {isOpen && (
          <ul
            className={classNames(
              styles.dropdown,
              {
                [styles.dropdown_mobile]: isMobile
              },
              classes?.dropdown ?? '',
              brandingClasses?.dropdownItems ?? ''
            )}
          >
            {dropdownList.map(item => (
              <Fragment key={item.id}>
                <li
                  {...(tooltipElement === 'item' && {
                    'data-tooltip-id': 'tooltip',
                    'data-tooltip-html': item?.tooltip,
                    'data-tooltip-place': tooltipPosition
                  })}
                  className={classNames(
                    styles.dropdown__option,
                    classes?.option ?? '',
                    brandingClasses?.dropdownItem ?? '',
                    {
                      [classes?.selectedOption ?? '']: classes?.selectedOption && defaultValue?.value === item.value,
                      [brandingClasses?.selected ?? '']: brandingClasses?.selected && defaultValue?.value === item.value
                    }
                  )}
                  id={item.value}
                  onClick={() => handleClickOption(item)}
                >
                  {item?.image && label && <Image name={item.value} width={21} height={15} path={item?.image} />}
                  {item?.icon && (
                    <i
                      className={classNames(classes?.icon, item.icon, asianViewIcons.DROPDOWN_ICON, {
                        [classes?.selectedIcon ?? '']: classes?.selectedIcon && defaultValue?.value === item.value
                      })}
                    />
                  )}
                  <span className={styles.dropdown__option__label}>
                    {item.isLabelTranslatable ? t(item.label ?? '') : item.label}
                  </span>
                  {tooltipsEnabled && item?.tooltip && item?.tooltip !== '' && (
                    <i
                      {...(tooltipElement === 'icon' && {
                        'data-tooltip-id': 'tooltip',
                        'data-tooltip-html': item?.tooltip
                      })}
                      className={classNames(
                        'fa2 fa2-info-circle tooltip-icon',
                        styles.tooltipIcon,
                        classes?.tooltipIcon,
                        iconsBranding.TOOLTIP
                      )}
                    >
                      <span className={classNames('path1', iconsBranding.TOOLTIP_BG)} />
                      <span className={classNames('path2', iconsBranding.TOOLTIP_INNER)} />
                    </i>
                  )}
                </li>
                {item.hasDivider && (
                  <li className={styles.divider}>
                    <hr />
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        )}
      </div>
      <BodyClassName className={classNames({ disableScroll: isOpen && withBackgroundOverlay })} />

      {withBackgroundOverlay && isOpen && <div className={styles.darkBackground} />}
    </>
  );
};

export default Dropdown;
