import { AccountStatementSortByFields, BetTypes, BetTypeValue } from 'constants/myBets';
import { StatementTransactionType, TStatementContentItem } from 'redux/modules/myBets/type';

import { TimeFormats } from './index';

export type TGetBetAndPageDataParams = {
  refreshedStartDate?: Date;
  refreshedEndDate?: Date;
  size?: number;
  number?: number;
  currency?: string;
  noFetchPLData?: boolean;
  eventTypeId?: string;
  betType?: BetTypeValue;
  betsType?: BetTypes;
  betsTypes?: BetTypes[];
  sortBy?: Record<string, 'asc' | 'desc' | null>;
  statementTransactions?: StatementTransactionType[];
  netCommission?: boolean;
  asianView?: boolean;
  search?: string | null;
  noResetMyBetsCancelState?: boolean;
  timeFormat?: TimeFormat;
};

export type SelectDropdownValue = {
  translationKey: string;
  id?: string;
  name: string;
  translations?: Record<string, string>;
  value?: string;
  channelId?: string;
  gameId?: string;
};

export type TGetPLData = {
  refreshedStartDate?: Date;
  refreshedEndDate?: Date;
  size?: number;
  number?: number;
  betType?: BetTypeValue;
  eventTypeId?: string;
  currency?: string;
  netCommission?: boolean;
  asianView?: boolean;
  sortBy?: Record<string, 'asc' | 'desc' | null>;
  timeFormat?: TimeFormat;
};

export type TGetStatementData = {
  refreshedStartDate?: Date;
  refreshedEndDate?: Date;
  size?: number;
  number?: number;
  transactionTypes?: StatementTransactionType[];
  currency?: string;
  asianView?: boolean;
  sortBy?: Record<string, 'asc' | 'desc' | null>;
  timeFormat?: TimeFormat;
};

export type TProfitLossTimeRangeChangeParams = {
  value: SelectDropdownValue;
  currency?: string;
  eventTypeId?: string;
};

export type TOnFetchDataProfitLossTimeRangeChangeParams = {
  startDatePl: Date;
  currency?: string;
  eventTypeId?: string;
};

export const OfferStates = {
  WON: 'WON',
  LOST: 'LOST',
  CANCELLED: 'CANCELLED',
  LAPSED: 'LAPSED',
  VOIDED: 'VOIDED',
  PLACED: 'PLACED',
  MATCHED: 'MATCHED'
} as const;

export type TOfferState = typeof OfferStates[keyof typeof OfferStates];

export type TMobileSelectOption = {
  id: string;
  label: string;
  isSelected: boolean;
  defaultIsSelected: boolean;
  tooltip?: string;
};

export type AccountStatementTableColumn = {
  id: keyof TStatementContentItem;
  labelKey: string;
  isVisible: boolean;
  className: string;
  right?: boolean;
  sortName?: AccountStatementSortByField;
  type?: 'date' | 'avgBackOdds' | 'avgLayOdds' | 'oddsTooltip';
  sortingValue?: 'asc' | 'desc' | null;
};

export type AccountStatementSortByField =
  typeof AccountStatementSortByFields[keyof typeof AccountStatementSortByFields];

export type TPageSizeOption = {
  id: number;
  value: string;
  label: string;
};

export type TimeFormat = typeof TimeFormats[keyof typeof TimeFormats];
