import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountSettingsDefaultStake from 'components/AccountSettingsDefaultStake/AccountSettingsDefaultStake';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings, getUserAccountSettingsDefaultStake } from 'redux/modules/user/selectors';

import styles from './DefaultStakeSection.module.scss';

const DefaultStakeSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isDefaultStakeEnabled = useSelector(getUserAccountSettingsDefaultStake);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [isDefaultStakeEnabledState, setIsDefaultStakeEnabledState] = useState(isDefaultStakeEnabled);

  const handleSwitchDefaultStake = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDefaultStakeEnabledState(event.target.checked);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            defaultStake: event.target.checked
          }
        })
      );
    }
  };

  return (
    <div className={styles.defaultStake}>
      <label className={styles.defaultStake__header}>
        <h4 className={styles.defaultStake__header__title}>{t('account.settings.defaultStake.title')}</h4>
        <ToggleSwitch
          checked={isDefaultStakeEnabledState}
          onChange={handleSwitchDefaultStake}
          containerClassName={styles.defaultStake__header__toggle}
        />
      </label>
      <AccountSettingsDefaultStake isMobile />
    </div>
  );
};

export default DefaultStakeSection;
