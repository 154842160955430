import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRefProps } from 'react-tooltip';
import { unescape, uniqueId } from 'lodash';

import useTooltip from 'hooks/useTooltip';

interface TooltipOptions {
  translationOptions?: Record<string, string>;
}

const useImperativeTooltip = (tooltipKey: string, options: TooltipOptions = {}) => {
  const tooltipRef = useRef<TooltipRefProps>(null);
  const { translationKey, isEnabled, isShowIcon } = useTooltip(tooltipKey);
  const { t } = useTranslation();

  const { translationOptions } = options;

  const openTooltip = () => {
    tooltipRef.current?.open();
  };

  const closeTooltip = () => {
    tooltipRef.current?.close();
  };

  const mouseEvents = {
    onMouseEnter: openTooltip,
    onMouseLeave: closeTooltip
  };

  return {
    translationKey,
    isShowIcon,
    isEnabled,
    tooltipRef,
    tooltipHtml: unescape(t(translationKey, translationOptions)),
    tooltipAnchorId: `tooltip-anchor-${uniqueId()}`,
    tooltipMouseEvents: isEnabled ? mouseEvents : {}
  };
};

export default useImperativeTooltip;
