import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { loadPlacedMobileBetsFromStorage } from 'redux/modules/asianViewMobileBetslip';
import { getAsianMobilePlacedBetsAmount } from 'redux/modules/asianViewMobileBetslip/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import AsianViewMobileBetsPlacedMessages from './components/AsianViewMobileBetsPlacedMessages';

const AsianViewMobileBetsPlaced = () => {
  const dispatch = useDispatch();
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const placedBetsAmount = useSelector(getAsianMobilePlacedBetsAmount);
  const isPlacedBets = isLoggedIn && isMobileAsianView && !!placedBetsAmount;

  useEffect(() => {
    if (isLoggedIn && isMobileAsianView && !placedBetsAmount) {
      dispatch(loadPlacedMobileBetsFromStorage());
    }
  }, [isLoggedIn, isMobileAsianView, placedBetsAmount]);

  if (isPlacedBets) {
    return <AsianViewMobileBetsPlacedMessages />;
  }

  return null;
};

export default AsianViewMobileBetsPlaced;
