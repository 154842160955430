import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { mobileComponents } from 'constants/branding';
import { TMobileSelectOption } from 'types/myBets';

import styles from './styles.module.scss';

type TMobileTabsProps = {
  defaultOptions: TMobileSelectOption[];
  onChangeCallback: (options: TMobileSelectOption[], isChanged: boolean) => void;
};

const MobileTabs = ({ defaultOptions, onChangeCallback }: TMobileTabsProps) => {
  const [options, setOptions] = useState<TMobileSelectOption[]>(defaultOptions);

  const isChanged = options.some(({ isSelected, defaultIsSelected }) => isSelected !== defaultIsSelected);

  const toggleOptionHandler = (id: string) => {
    setOptions(options.map(option => ({ ...option, isSelected: option.id === id })));
  };

  useEffect(() => {
    onChangeCallback(options, isChanged);
  }, [options, isChanged]);

  return (
    <div className={styles.wrap}>
      <div className={classNames(styles.tabs, mobileComponents.SWITCH_TABS)}>
        {options.map(({ id, label, isSelected }) => (
          <button
            type="button"
            onClick={() => toggleOptionHandler(id)}
            className={classNames(styles.tab, {
              [styles.tab__selected]: isSelected,
              [mobileComponents.SELECTED]: isSelected
            })}
            key={`filters_${id}`}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MobileTabs;
