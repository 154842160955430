import { HTMLAttributes, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PlacesType } from 'react-tooltip';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { QUICK_BETS_HOVER_MESSAGE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import {
  getDesktopSettingsQuickstakeBetslip,
  getIsMobile,
  getMobileSettingsQuickstakeBetslip
} from 'redux/modules/appConfigs/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { isGamePage } from 'utils';

import QuickBet from './components/QuickBet';

import styles from './styles.module.scss';

interface QuickBetsProps {
  handler: (value: string | number) => void;
  onFocusChange?: (value: boolean) => void;
  byCenter?: boolean;
  tooltipPosition?: PlacesType;
  isShort?: boolean;
  isDisabled?: boolean;
  hasTooltip?: boolean;
  customClassName?: HTMLAttributes<HTMLDivElement>['className'];
  quickBetClassname?: HTMLAttributes<HTMLButtonElement>['className'];
  emptyContainer?: JSX.Element;
  shouldIgnoreBlurEvent?: boolean;
}

const QuickBets = ({
  handler,
  onFocusChange,
  byCenter,
  tooltipPosition = 'bottom',
  isShort = false,
  isDisabled = false,
  hasTooltip = true,
  customClassName = '',
  quickBetClassname,
  emptyContainer,
  shouldIgnoreBlurEvent
}: QuickBetsProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { translationKey: translationKeyBets, isEnabled } = useTooltip(QUICK_BETS_HOVER_MESSAGE);
  const accountSettings = useSelector(getAccountSettings);
  const isMobile = useSelector(getIsMobile);
  const quickStakeBetslipMobile = useSelector(getMobileSettingsQuickstakeBetslip);
  const quickStakeBetslipDesktop = useSelector(getDesktopSettingsQuickstakeBetslip);

  const isTurnOnQuickstakeBetslip = isMobile ? quickStakeBetslipMobile : quickStakeBetslipDesktop;
  const stakes = isGamePage(location.pathname) ? accountSettings?.gameStakes : accountSettings?.stakes;

  if (
    !isTurnOnQuickstakeBetslip ||
    !accountSettings?.quickStakesEnabled ||
    !stakes?.length ||
    stakes.every(stake => !stake)
  ) {
    return emptyContainer || null;
  }

  return (
    <div
      id="quickBets"
      data-tooltip-id="tooltip"
      data-tooltip-place={tooltipPosition}
      data-tooltip-html={isEnabled && hasTooltip ? unescape(t(translationKeyBets)) : ''}
      className={classNames(styles.container, customClassName, {
        [styles.container__byCenter]: byCenter
      })}
    >
      {stakes
        .filter(stake => stake)
        .slice(0, isShort ? 4 : undefined)
        .map((stake, index) => (
          <QuickBet
            key={`${index}-${stake}`}
            value={stake}
            byCenter={byCenter}
            handler={handler}
            isDisabled={isDisabled}
            onFocusChange={onFocusChange}
            className={quickBetClassname}
            shouldIgnoreBlurEvent={shouldIgnoreBlurEvent}
          />
        ))}
    </div>
  );
};

export default memo(QuickBets);
