import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useUnmount } from 'usehooks-ts';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { componentsBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import useKeyDown from 'hooks/useKeyDown';
import useOnMountEffect from 'hooks/useOnMountEffect';
import { setPageNumber, setSearchValue } from 'redux/modules/myBets';
import { getPageNumber, myBetsLoading } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { TGetBetAndPageDataParams } from 'types/myBets';

import styles from './SearchBar.module.scss';

const MIN_SEARCH_CHARACTERS = 1;
const MAX_SEARCH_CHARACTERS = 125;
const OPEN_BETS_LABEL = 'Search is performed across all open bets';
const SETTLED_BETS_LABEL = 'Search is performed across settled bets within the last 3 months';

interface SearchBarProps {
  isCurrentPeriod: boolean;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  getBetData: (params: TGetBetAndPageDataParams) => void;
}

const SearchBar = ({ isCurrentPeriod, setIsOpen, getBetData }: SearchBarProps) => {
  const dispatch = useDispatch();
  const isDataLoading = useSelector(myBetsLoading);
  const { isMobile, isDesktop } = useDevice();
  const [cookies, setCookie] = useCookies([CookieNames.MY_BETS_SEARCH]);
  const pageNumber = useSelector(getPageNumber);

  const [isTouched, setIsTouched] = useState<boolean>(!!cookies.BIAB_MY_BETS_SEARCH);
  const [value, setValue] = useState<string>(cookies.BIAB_MY_BETS_SEARCH || '');

  const inputRef = useRef<HTMLInputElement>(null);
  const prevPageNumber = useRef<number>(pageNumber);

  const isSearchValueValid = value.length >= MIN_SEARCH_CHARACTERS && value.length <= MAX_SEARCH_CHARACTERS;

  useOnMountEffect(() => {
    inputRef.current?.focus();

    if (cookies.BIAB_MY_BETS_SEARCH) {
      dispatch(setSearchValue(cookies.BIAB_MY_BETS_SEARCH));
    }
  });

  useUnmount(() => {
    dispatch(setSearchValue(null));
  });

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const inputValue = ev.target.value;

    if (inputValue === ' ' && value === '') return;

    setValue(inputValue);
  };

  const handleSearchClick = () => {
    const formattedValue = value.trimStart();

    setIsTouched(true);
    setCookie(CookieNames.MY_BETS_SEARCH, formattedValue, { path: '/' });

    dispatch(setPageNumber(0));
    dispatch(setSearchValue(formattedValue));

    getBetData({ search: formattedValue, number: 0 });
  };

  const resetInput = useCallback(() => {
    if (isTouched) {
      dispatch(setPageNumber(prevPageNumber.current));
      dispatch(setSearchValue(null));
      setCookie(CookieNames.MY_BETS_SEARCH, '', { path: '/' });
      getBetData({ number: prevPageNumber.current, search: null });
    }

    setIsOpen(false);
  }, [isTouched]); // eslint-disable-line react-hooks/exhaustive-deps

  useKeyDown('Escape', resetInput);

  return (
    <div className={styles.search__row}>
      <div className={styles.search__searchBarRow}>
        <div className={styles.search__inputWrapper}>
          {isDesktop && (
            <Icon
              iconClass="fa2-search"
              fontFamily="fa2"
              className={classNames(styles.search__inputIcon, styles.search__inputIcon__search)}
            />
          )}
          <input
            ref={inputRef}
            className={classNames(styles.input, {
              [componentsBranding.FORM_INPUT]: isDesktop,
              [mobileComponents.INPUT]: isMobile
            })}
            value={value}
            onChange={handleInputChange}
            type="text"
            name="search"
            id="search"
            placeholder="Search by Ref ID or Selection Name"
          />
          <button className={styles.search__closeBtn} onClick={resetInput}>
            <Icon
              iconClass="biab_custom-icon-close"
              className={classNames(styles.search__inputIcon, styles.search__inputIcon__close)}
            />
          </button>
        </div>
        <Button
          className={classNames(styles.search__searchBtn, {
            [`${mobileComponents.BUTTON} ${mobileComponents.PRIMARY}`]: isMobile
          })}
          disabled={!isSearchValueValid || isDataLoading}
          onClick={handleSearchClick}
        >
          {isMobile ? <Icon iconClass="biab_custom-icon-search-24" /> : 'Search'}
        </Button>
      </div>
      <p className={styles.search__label}>{isCurrentPeriod ? OPEN_BETS_LABEL : SETTLED_BETS_LABEL}</p>
    </div>
  );
};

export default SearchBar;
