import classNames from 'classnames';

import Skeleton from '../index';

import styles from './styles.module.scss';

interface MyBetsSkeletonProps {
  isShowHeader?: boolean;
  isShowTable?: boolean;
  isUsePadding?: boolean;
  isAddColumn?: boolean;
}

const MyBetsSkeleton = ({
  isShowHeader = false,
  isShowTable = false,
  isUsePadding = false,
  isAddColumn = false
}: MyBetsSkeletonProps) => {
  return (
    <div
      data-skeleton={'myBetsSkeleton'}
      className={classNames(styles.container, {
        [styles.paddingWrapper]: isUsePadding
      })}
    >
      {isShowHeader && (
        <div className={styles.topHeader}>
          <div className={classNames(styles.row, styles.row__first)}>
            <div className={styles.row__left}>
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
            </div>
            <div className={styles.row__right}>
              <Skeleton className={styles.item} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__left}>
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
              <Skeleton className={styles.item} />
            </div>
            <div className={styles.row__right}>
              <Skeleton className={styles.item} />
            </div>
          </div>
          <div className={styles.subHeaderTitle}>
            <Skeleton className={styles.itemWidth} />
          </div>
        </div>
      )}

      {isShowTable && (
        <div className={styles.table}>
          {new Array(4).fill(0).map((_, index) => {
            return (
              <div key={index} className={styles.table__row}>
                <Skeleton className={styles.table__col_1} />
                <Skeleton className={styles.table__col_2} />
                {isAddColumn && <Skeleton className={styles.table__col_2} />}
                <Skeleton className={styles.table__col_3} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MyBetsSkeleton;
