import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MultiselectDropdown from 'components/MultiselectDropdown';
import { BetsTypesByPeriod, BetTypes } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { CookieNames } from 'types';

const BetTypesDropdown = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.MY_BETS_TYPES]);

  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  const { isMyBetsPage } = useAccountUrlParams();
  const { periodType, betsTypes, setBetsTypes } = useMyBetsFilters();
  const { isAsianViewSelected } = useAccountProducts();
  const { refreshData } = useMyBetsPagesNavigation();

  const disabled = isMyBetsPage && isCancellingBets;
  const betTypesOptions = (BetsTypesByPeriod[periodType] ?? [])
    .map(option => {
      return {
        id: option,
        label: t(`account.mybets.btns.${option.toLowerCase()}`),
        isSelected: betsTypes?.includes(option)
      };
    })
    .filter(({ id }) => !isAsianViewSelected || id !== BetTypes.Lapsed);

  const handleOnSelect = (selected: string[]) => {
    if (
      !cookies.BIAB_MY_BETS_TYPES?.length ||
      cookies.BIAB_MY_BETS_TYPES?.length !== selected.length ||
      selected.some(type => !cookies.BIAB_MY_BETS_TYPES.includes(type))
    ) {
      const newBetTypes = selected.length ? (selected as BetTypes[]) : BetsTypesByPeriod[periodType];

      setBetsTypes(newBetTypes);

      refreshData({
        betsTypes: newBetTypes
      });
    }
  };

  const activeBetTypesNumber = betsTypes?.length;
  const totalBetsTypesNumber = betTypesOptions.length;

  const placeholder =
    activeBetTypesNumber === 1
      ? t(`account.mybets.btns.${betsTypes[0]}`)
      : activeBetTypesNumber < totalBetsTypesNumber
      ? `${activeBetTypesNumber} ${t('account.myBets.labels.betTypes')}`
      : t('account.myBets.labels.allBetTypes');

  return (
    <MultiselectDropdown disabled={disabled} options={betTypesOptions} onSelect={handleOnSelect}>
      {placeholder}
    </MultiselectDropdown>
  );
};

export default BetTypesDropdown;
