import { AppState } from 'redux/reducers';

export const getIsUserInfo = ({ user }: AppState) => !!user.info;
export const getBalance = ({ user }: AppState) => user.operatorBalance || user.balance;
export const getAVBalance = ({ user }: AppState) => user.operatorBalance || user.avBalance;
export const getUserLoading = ({ user }: AppState) => user.loading;
export const getUserBalanceLoading = ({ user }: AppState) => user.balanceLoading;
export const getOperatorCurrency = ({ user }: AppState) => user.operatorCurrency;
export const getPrecisionType = ({ user }: AppState) => user.info?.precisionType;
export const getAccountSettings = ({ user }: AppState) => user.info?.accountSettings ?? null;

export const getUserCurrency = ({ user }: AppState) => user.info?.currency;

export const getUserSessionInfo = ({ user }: AppState) => user.info?.sessionInfo;
export const getUserSessionInfoStartSessionDate = ({ user }: AppState) => user.info?.sessionInfo?.startSessionDate;

export const getIsUserInfoLoaded = ({ user }: AppState) => user.isUserInfoLoaded;

export const getUserAsianViewAutoRefresh = ({ user }: AppState) => !!user.info?.accountSettings?.asianViewAutoRefresh;

export const getUserAsianViewQuickBetting = ({ user }: AppState) => !!user.info?.accountSettings?.asianViewQuickBetting;

export const getAccountSettingsConfirmBetsBeforePlacement = ({ user }: AppState) =>
  !!user.info?.accountSettings?.confirmBetsBeforePlacement;

export const getUserDefaultStakeValue = ({ user }: AppState) =>
  user.info?.accountSettings?.defaultStake &&
  user.info?.accountSettings?.defaultStakes.find(item => item?.defaultValue)?.value;

export const getUserAsianViewLayBetting = ({ user }: AppState) => !!user.info?.accountSettings?.asianViewLayBetting;
export const getUserAsianViewViewType = ({ user }: AppState) => user.info?.accountSettings?.asianViewViewType;
export const getUserAsianViewOddsType = ({ user }: AppState) => user.info?.accountSettings?.asianViewOddsType;
export const getIsUserAccountSettings = ({ user }: AppState) => !!user.info?.accountSettings;
export const getAccountSettingsStakes = ({ user }: AppState) => user.info?.accountSettings?.stakes;
export const getAccountSettingsGamesStakes = ({ user }: AppState) => user.info?.accountSettings?.gameStakes;
export const getIsAccountSettingsContextualHelpEnabled = ({ user }: AppState) =>
  user.info?.accountSettings?.contextualHelpEnabled;
export const getIsAccountSettingsContextualHelpEnabledMobile = ({ user }: AppState) =>
  !!user.info?.accountSettings?.contextualHelpEnabledMobile;
export const getUserAccountSettingsBetSlipPlacement = ({ user }: AppState) =>
  user.info?.accountSettings?.betslipPlacement;
export const getUserAccountSettingsConsolidateBets = ({ user }: AppState) =>
  !!user.info?.accountSettings?.consolidateBets;
export const getUserAccountSettingsDefaultStakes = ({ user }: AppState) =>
  user.info?.accountSettings?.defaultStakes || [];
export const getUserAccountSettingsDefaultStake = ({ user }: AppState) => !!user.info?.accountSettings?.defaultStake;
export const getUserAccountSettingsAsianViewAutoRefresh = ({ user }: AppState) =>
  !!user.info?.accountSettings?.asianViewAutoRefresh;
export const getUserAccountSettingsPlaceBetWithEnterKey = ({ user }: AppState) =>
  !!user.info?.accountSettings?.placeBetWithEnterKey;
export const getUserAccountSettingsFillOrKillEnabled = ({ user }: AppState) =>
  !!user.info?.accountSettings?.fillOrKillEnabled;
export const getUserAccountSettingsAsianViewShowPLTableSwipeMessage = ({ user }: AppState) =>
  !!user.info?.accountSettings?.asianViewShowPLTableSwipeMessage;
export const getDefaultLandingPage = ({ user }: AppState) => user.info?.defaultLandingPage;
export const getIsQuickStakesEnabled = ({ user }: AppState) => !!user.info?.accountSettings?.quickStakesEnabled;
export const getIsUpdateSettingsError = ({ user }: AppState) => !!user.updateSettingsError;
export const getUsername = ({ user }: AppState) => user.info?.username;
export const getUserInfoSymbol = ({ user }: AppState) => user.info?.symbol;
