import { useSelector } from 'react-redux';

import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import { getMarketPricesIsBettingEnabled, getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';

interface MarketStatusProps {
  /**
   * Market ID
   */
  marketId: string;
}

const MarketStatus = ({ marketId }: MarketStatusProps) => {
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(marketId));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(marketId));

  const { showStatus, status } = useMarketStatusAndLockIcon(bettingEnabled, marketPricesStatus);

  if (showStatus) {
    return <StatusOverlay status={status} inMiddle />;
  }

  return null;
};

export default MarketStatus;
