import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getIsAmericanDateFormatEnabled } from 'redux/modules/appConfigs/selectors';

import useConvertTimeToString from './useConvertTimeToString';

export const useParseMyBetsDates = ({
  dateToParse,
  yearHyphen,
  hideHoursMinutes,
  isBettingDay,
  divider = ''
}: {
  dateToParse: number;
  yearHyphen?: boolean;
  hideHoursMinutes?: boolean;
  isBettingDay?: boolean;
  divider?: string;
}) => {
  const { t } = useTranslation();
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const { hoursAndMinutes, monthDay, year, month } = useConvertTimeToString({
    startDate: dateToParse,
    isBettingDay
  });

  const date = americanDateFormatEnabled
    ? `${t(`dates.shortMonth.${month}`)} ${monthDay.toString().padStart(2, '0')}${
        yearHyphen ? '-' : `${divider} `
      }${year} ${hideHoursMinutes ? '' : hoursAndMinutes}`
    : `${monthDay.toString().padStart(2, '0')} ${t(`dates.shortMonth.${month}`)}${yearHyphen ? '-' : ' '}${year} ${
        hideHoursMinutes ? '' : hoursAndMinutes
      }`;

  return { date };
};
