import { useSelector } from 'react-redux';

import { ExchangeTypes, SEPARATE_WALLET, StatementTransactionTypes } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import {
  getIsExchangeGamesEnabled,
  getPNCEnabledSetting,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { getAccountStatementTransactionsTypes, getProductMobileFilter } from 'redux/modules/myBets/selectors';

const useAccountTransactionTypes = () => {
  const { selectedProduct } = useAccountProducts();
  const productMobileFilter = useSelector(getProductMobileFilter);
  const accountStatementTransactions = useSelector(getAccountStatementTransactionsTypes);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const walletIntegrationType = useSelector(getWalletIntegrationType);

  const isAsianViewTab = (productMobileFilter || selectedProduct) === ExchangeTypes.AsianView;

  const transactionsOptions = [
    {
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.BETTING),
      isVisible: true
    },
    {
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.COMMISSION),
      isVisible: isAsianViewTab ? false : !isPNCEnabled || exchangeGamesEnabled
    },
    {
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.RESETTLEMENT_CORRECTION),
      isVisible: true
    },
    {
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.DEPOSIT_WITHDRAWAL),
      isVisible: walletIntegrationType === SEPARATE_WALLET
    }
  ].filter(({ isVisible }) => isVisible);

  return {
    isAllTransactionsSelected: transactionsOptions.every(({ isSelected }) => isSelected)
  };
};

export default useAccountTransactionTypes;
