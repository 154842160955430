import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalize } from 'lodash';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { componentsBranding } from 'constants/branding';
import useOptimisticState from 'hooks/useOptimisticState';
import { getConsolidateBets } from 'redux/modules/appConfigs/selectors';
import { setConsolidateBets } from 'redux/modules/betslip';
import { setSettings } from 'redux/modules/user';
import { getIsUpdateSettingsError, getUserAccountSettingsConsolidateBets } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const ConsolidateBetsButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isUpdateSettingsError = useSelector(getIsUpdateSettingsError);
  const isConsolidateBetsEnabled = useSelector(getConsolidateBets);
  const consolidateBets = useSelector(getUserAccountSettingsConsolidateBets);

  useEffect(() => {
    dispatch(setConsolidateBets(consolidateBets && isConsolidateBetsEnabled));
  }, [consolidateBets, isConsolidateBetsEnabled, dispatch]);

  const [isSelected, setIsSelected] = useOptimisticState({
    initialState: consolidateBets,
    onSetState: value => dispatch(setSettings({ settings: { consolidateBets: value } })),
    externalError: isUpdateSettingsError
  });

  const tooltipLabel = capitalize(
    isSelected ? t('betslip.tooltip.openBets.consolidateBetsActive') : t('betslip.tooltip.openBets.consolidateBets')
  );

  const handleChange = () => {
    setIsSelected(!isSelected);
  };

  return (
    <Button
      className={classNames(styles.consolidateBets, {
        [componentsBranding.ACTIVE]: isSelected
      })}
      onClick={handleChange}
      variant="tertiary"
      isSelected={isSelected}
      data-tooltip-id="tooltip"
      data-tooltip-html={tooltipLabel}
    >
      <Icon iconClass="fa2-vector" fontFamily="fa2" />
    </Button>
  );
};

export default ConsolidateBetsButton;
