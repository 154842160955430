import { all, call, put, takeEvery } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureFetchBanner, fetchBanner, successFetchBanner } from './index';
import { BannerData } from './type';

export function* fetchBannerWorker(action: ReturnType<typeof fetchBanner>) {
  try {
    const response: BannerData = yield call(
      api.app.banners,
      action.payload.platform,
      action.payload.section,
      action.payload.locale
    );

    yield put(
      successFetchBanner({
        name: action.payload.name,
        data: response
      })
    );
  } catch (error: any) {
    // This is necessary because the top margin for Pagination won't be calculated correctly (see components/Pagination/index.tsx)
    yield put(failureFetchBanner(error));
  }
}

export default function* saga() {
  yield all([takeEvery(fetchBanner.type, fetchBannerWorker)]);
}
