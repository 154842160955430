import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface RefreshButtonProps {
  onRefresh: () => void;
  isDisabled: boolean;
}

const RefreshButton = ({ onRefresh, isDisabled }: RefreshButtonProps) => {
  const { t } = useTranslation();

  return (
    <div data-tooltip-id="tooltip" data-tooltip-html={t('account.labels.refresh')}>
      <button
        className={classNames(styles.refreshButton, {
          [styles.refreshButton__disabled]: isDisabled
        })}
        onClick={onRefresh}
        disabled={isDisabled}
      >
        <i className={classNames('biab_custom-icon-refresh', styles.icon)} />
      </button>
    </div>
  );
};

export default RefreshButton;
