import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountSettingsDefaultStake from 'components/AccountSettingsDefaultStake/AccountSettingsDefaultStake';
import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings, getUserAccountSettingsDefaultStake } from 'redux/modules/user/selectors';

import DesktopSettingsToggleableHeader from './DesktopSettingsToggleableHeader/DesktopSettingsToggleableHeader';

const DesktopSettingsDefaultStake = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isDefaultStakeEnabled = useSelector(getUserAccountSettingsDefaultStake);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [isDefaultStakeEnabledState, setIsDefaultStakeEnabledState] = useState(isDefaultStakeEnabled);

  const handleSwitchDefaultStake = (value: boolean) => {
    setIsDefaultStakeEnabledState(value);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            defaultStake: value
          }
        })
      );
    }
  };

  return (
    <>
      <DesktopSettingsToggleableHeader
        isChecked={isDefaultStakeEnabledState}
        onToggle={handleSwitchDefaultStake}
        title={t('account.settings.defaultStake.title')}
      />
      <AccountSettingsDefaultStake />
    </>
  );
};

export default DesktopSettingsDefaultStake;
