import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { toNumber } from 'lodash';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getMyBetsMultiCurrencyValue } from 'redux/modules/myBets/selectors';

import styles from './styles.module.scss';

interface MobileProfitProps {
  profit: string;
}

const MobileProfit = ({ profit }: MobileProfitProps) => {
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const { noFormattedAmount: plProfit } = useFormatCurrency(
    profit,
    isMultiCurrencySupported && multiCurrencyValue ? multiCurrencyValue : '',
    {
      noRounding: true,
      isCheckIndian: true,
      hideCurrencySymbol: !isMultiCurrencySupported,
      isPositiveSign: true,
      noZeroSign: true
    }
  );

  return (
    <div
      className={classNames(styles.plProfit, {
        [styles.betWon]: toNumber(profit) > 0,
        [styles.betLost]: toNumber(profit) < 0
      })}
    >
      {plProfit}
    </div>
  );
};

export default MobileProfit;
