import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AccountStatementSortByFields } from 'constants/myBets';
import { setMobileFilters } from 'redux/modules/myBets';
import { getMobileStatementSorting } from 'redux/modules/myBets/selectors';
import { AccountStatementSortByField, TMobileSelectOption } from 'types/myBets';

import MobileSelect from './MobileSelect';

const MobileFiltersAccountStatementSorting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobileStatementSorting = useSelector(getMobileStatementSorting);

  const sortingOptions: TMobileSelectOption[] = [
    {
      id: AccountStatementSortByFields.SETTLED_DATE,
      label: t('account.statement.labels.settledTime'),
      isSelected: !mobileStatementSorting || mobileStatementSorting?.name === AccountStatementSortByFields.SETTLED_DATE,
      defaultIsSelected:
        !mobileStatementSorting || mobileStatementSorting?.name === AccountStatementSortByFields.SETTLED_DATE
    },
    {
      id: AccountStatementSortByFields.PLACED_DATE,
      label: t('account.statement.labels.placedTime'),
      isSelected: mobileStatementSorting?.name === AccountStatementSortByFields.PLACED_DATE,
      defaultIsSelected: mobileStatementSorting?.name === AccountStatementSortByFields.PLACED_DATE
    }
  ];

  const handleChangeSorting = (optionsChanged: TMobileSelectOption[], isStatementSortingChanged: boolean) => {
    dispatch(
      setMobileFilters({
        statementSorting:
          (optionsChanged.find(({ isSelected }) => isSelected)?.id as AccountStatementSortByField) ??
          AccountStatementSortByFields.SETTLED_DATE,
        isStatementSortingChanged
      })
    );
  };

  return (
    <MobileSelect
      title={t('account.statement.labels.sortBy')}
      defaultOptions={sortingOptions}
      onChangeCallback={handleChangeSorting}
    />
  );
};

export default MobileFiltersAccountStatementSorting;
