import { times } from 'lodash';

import Skeleton from '../index';

import styles from './styles.module.scss';

type TMobileMyBetsSkeletonProps = {
  isShowHeader?: boolean;
  isShowTable?: boolean;
};

const MobileMyBetsSkeleton = ({ isShowHeader = false, isShowTable = false }: TMobileMyBetsSkeletonProps) => {
  return (
    <div data-skeleton={'myBetsSkeleton'} className={styles.container}>
      {isShowHeader && (
        <div className={styles.header}>
          <Skeleton className={styles.item__lg} />
          <div className={styles.header__row}>
            <Skeleton className={styles.item__xxs} />
            <Skeleton className={styles.item__xxs} />
          </div>
        </div>
      )}

      {isShowTable && (
        <div className={styles.table}>
          {times(4, index => {
            return (
              <div className={styles.table__row} key={`my_bets_row_${index}`}>
                <Skeleton className={styles.item__indicator} />
                <div className={styles.table__row__left}>
                  <Skeleton className={styles.item__md} />
                  <Skeleton className={styles.item__sm} />
                  <Skeleton className={styles.item__sm} />
                </div>
                <div className={styles.table__row__right}>
                  <Skeleton className={styles.item__xs} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MobileMyBetsSkeleton;
