import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import { SPORT_BASE_URL } from 'constants/locations';
import {
  BetsTypesByPeriod,
  BetTypes,
  BetTypeValue,
  ExchangeTypes,
  EXPORT_FILENAME,
  mapMyBetsPageToBetsType,
  MIN_ITEMS_PAGINATION,
  MY_BETS_PAGES,
  MyBetsLocations,
  MyBetsPage,
  Period,
  PLBetType,
  plDefaultSportTypeValues,
  PLExchangeTypes,
  PLTimeRanges,
  StatementTransactionTypes
} from 'constants/myBets';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { urls } from 'redux/api/urls';
import {
  getCurrency,
  getDisplayCustomerCommissionRange,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getPNCEnabledSetting,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import {
  fetchCharges,
  fetchMyBets,
  fetchPL,
  fetchPLTotal,
  fetchPLTotalForEvent,
  fetchStatement,
  resetPLTotalForEvent,
  setEndDate,
  setEventTypeFilter,
  setIsCustomPeriod,
  setLoading,
  setPageNumber,
  setPageSize,
  setStartDate,
  setTimeRange
} from 'redux/modules/myBets';
import {
  cancelledBet,
  getAccountProductsByBetsType,
  getAccountStatementSort,
  getAccountStatementTransactionsTypes,
  getBettingPLSort,
  getEventTypeFilter,
  getMobileSorting,
  getMobileStatementSorting,
  getMyBetsMultiCurrencyValue,
  getPageNumber,
  getPageSize,
  getSearchValue,
  getSortValueByType,
  getTimeRange,
  isBetUpdated
} from 'redux/modules/myBets/selectors';
import { SortByFields, TGetBetPayload, TSortByType } from 'redux/modules/myBets/type';
import { CookieNames, DropdownItem, TimeFormats, TPeriods } from 'types';
import {
  TGetBetAndPageDataParams,
  TGetPLData,
  TGetStatementData,
  TOnFetchDataProfitLossTimeRangeChangeParams,
  TProfitLossTimeRangeChangeParams
} from 'types/myBets';
import {
  addDays,
  applyTimezone,
  getBettingDayTimezone,
  getDaysDifference,
  getEndDateByPeriod,
  getSevenDaysAgo,
  getStartDateByPeriod,
  getThirtyDaysAgo,
  getThreeMonthsAgo,
  getTodayDate,
  getTodayEndDate,
  getTodayStartDate,
  getYesterdayDate,
  setHoursMinutesToZero,
  subtractSelectedTimezoneToDate
} from 'utils/date';
import { getMultiCurrencyCodeFromArg } from 'utils/myBetsValues';

import useAccountPageDates from './useAccountPageDates';
import useAccountProducts from './useAccountProducts';
import useAccountUrlParams from './useAccountUrlParams';
import useActivePLProduct from './useActivePLProduct';
import useDevice from './useDevice';
import useMultiCurrencySupporting from './useMultiCurrencySupporting';
import usePLNetOfCommission from './usePLNetOfCommission';

export const useMyBetsPagesNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useDevice();
  const { exchangePageType, selectedTab } = useAccountUrlParams();

  const pageType = MY_BETS_PAGES[selectedTab];

  const [cookies, setCookie] = useCookies([
    CookieNames.MY_BETS_TYPES,
    CookieNames.TIME_FORMAT,
    CookieNames.EXCHANGE_TYPE_NEW,
    CookieNames.ACCOUNT_DATETO,
    CookieNames.ACCOUNT_DATEFROM,
    CookieNames.PROFITANDLOSS_BETTYPE,
    CookieNames.PROFITANDLOSS_TIMERANGE,
    CookieNames.PROFITANDLOSS_SPORTID
  ]);

  const pageNumber = useSelector(getPageNumber);
  const pageSize = useSelector(getPageSize);
  const searchValue = useSelector(getSearchValue);
  const timeRange = useSelector(getTimeRange);
  const betUpdate = useSelector(isBetUpdated);
  const cancelledBetData = useSelector(cancelledBet);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const displayCustomerCommissionRange = useSelector(getDisplayCustomerCommissionRange);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const currentCurrency = useSelector(getCurrency);
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const accountProducts = useSelector(getAccountProductsByBetsType(mapMyBetsPageToBetsType[pageType]));
  const eventType = useSelector(getEventTypeFilter);
  const accountStatementTransactions = useSelector(getAccountStatementTransactionsTypes);
  const accountStatementSort = useSelector(getAccountStatementSort);
  const accountBettingPLSort = useSelector(getBettingPLSort);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const mobileStatementSorting = useSelector(getMobileStatementSorting);
  const sortByPlacedDate = useSelector(getSortValueByType(SortByFields.PLACED_DATE));
  const sortBySettledDate = useSelector(getSortValueByType(SortByFields.DATE));
  const mobileSorting = useSelector(getMobileSorting);

  const { startDate, endDate } = useAccountPageDates();
  const { defaultProduct } = useAccountProducts();
  const activePLTab = useActivePLProduct();
  const { hasNetCommission, value: isProfitNetOfCommissionChecked } = usePLNetOfCommission();
  const { multiCurrencySupported, isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { periodType, betsTypes: activeBetTypes } = useMyBetsFilters();

  const isAsianViewEnabled = asianViewEnabled || !!accountProducts?.asianView;
  const currentBetType = activePLTab === PLExchangeTypes[PLBetType.Games] ? BetTypeValue.Games : BetTypeValue.Sports;

  const [, setBetTypeValue] = useState<BetTypeValue>(currentBetType);

  const isActiveAsianView =
    pageType === MyBetsPage.ProfitLoss
      ? activePLTab === PLExchangeTypes[PLBetType.AsianView]
      : cookies.EXCHANGE_TYPE_NEW
      ? cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView
      : defaultProduct === ExchangeTypes.AsianView;

  const asianView = isAsianViewEnabled && isActiveAsianView;
  const bettingDay = isActiveAsianView && cookies.BIAB_TIME_FORMAT === TimeFormats.BETTING_DAY;
  const additionalParams = asianView
    ? {
        asianView: isActiveAsianView,
        bettingDay
      }
    : {};

  const timezoneOffset = bettingDay ? getBettingDayTimezone() : timezone;
  const todayDate = getTodayEndDate(timezoneOffset, bettingDay || timezoneCookieEnabled);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);

  const getStatementData = ({
    refreshedStartDate,
    refreshedEndDate,
    size,
    number,
    transactionTypes,
    currency,
    asianView: isAsianView,
    sortBy,
    timeFormat
  }: TGetStatementData) => {
    if (displayCustomerCommissionRange && !isPNCEnabled) {
      dispatch(fetchCharges());
    }

    const isAsianViewValue = typeof isAsianView === 'boolean' ? isAsianView : isActiveAsianView;

    const isBettingDay = timeFormat ? isAsianViewValue && timeFormat === TimeFormats.BETTING_DAY : bettingDay;
    const tzOffset = isBettingDay ? getBettingDayTimezone() : timezone;

    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(refreshedStartDate || startDate, dayDifference),
      tzOffset || timezone,
      timezoneCookieEnabled || isBettingDay
    );

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(refreshedEndDate || endDate, dayDifference),
      tzOffset || timezone,
      timezoneCookieEnabled || isBettingDay
    );

    let sorting: string[] | undefined;

    if (sortBy) {
      sorting = sortBy ? Object.entries(sortBy).map(([key, value]) => `${key},${value}`) : undefined;
    } else {
      if (isMobile) {
        sorting = mobileStatementSorting
          ? [`${mobileStatementSorting.name},${mobileStatementSorting.order}`]
          : undefined;
      } else {
        sorting = accountStatementSort
          ? Object.entries(accountStatementSort).map(([key, value]) => `${key},${value}`)
          : undefined;
      }
    }

    const accountStatementOrigin = (transactionTypes || accountStatementTransactions).filter(origin => {
      if (origin === StatementTransactionTypes.COMMISSION) {
        return isAsianViewValue ? false : !isPNCEnabled || exchangeGamesEnabled;
      }

      return true;
    });

    dispatch(
      fetchStatement({
        startDate: startDateAdjusted.getTime(),
        endDate: endDateAdjusted.getTime(),
        page: number ?? pageNumber,
        size: size || pageSize,
        currency: currencyCode,
        accountStatementOrigin,
        sortBy: sorting,
        ...(isAsianViewValue ? { asianView: isAsianViewValue, bettingDay: isBettingDay } : {}),
        onChangePage: (page: number) => {
          dispatch(setPageNumber(page));
          navigate(`${location.pathname.replaceAll(/[0-9]/g, '')}/${page + 1}`);
        }
      })
    );
  };

  const getPLData = ({
    refreshedStartDate,
    refreshedEndDate,
    size,
    number,
    betType,
    eventTypeId,
    currency,
    netCommission,
    asianView: isAsianView,
    sortBy,
    timeFormat
  }: TGetPLData) => {
    const isAsianViewValue = typeof isAsianView === 'boolean' ? isAsianView : isActiveAsianView;

    const isBettingDay = timeFormat ? isAsianViewValue && timeFormat === TimeFormats.BETTING_DAY : bettingDay;
    const tzOffset = isBettingDay ? getBettingDayTimezone() : timezone;
    const endDatePL = refreshedEndDate || endDate;
    const startDatePL = refreshedStartDate || startDate;
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(startDatePL, dayDifference),
      tzOffset || timezone,
      timezoneCookieEnabled || isBettingDay
    ).getTime();

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(endDatePL, dayDifference),
      tzOffset || timezone,
      timezoneCookieEnabled || isBettingDay
    ).getTime();

    const bettingData = {
      ...(isAsianViewValue ? { asianView: isAsianViewValue, bettingDay: isBettingDay } : {})
    };

    let sorting: string[] | undefined;
    const sortingOptions = [SortByFields.SETTLED_DATE, SortByFields.START_DATE] as TSortByType[];

    const sortByOptions = sortBy
      ? sortBy
      : isMobile
      ? {
          ...(mobileSorting
            ? {
                [sortingOptions.includes(mobileSorting.type) ? mobileSorting.type : SortByFields.SETTLED_DATE]:
                  mobileSorting.order
              }
            : {})
        }
      : null;

    if (sortByOptions) {
      sorting = sortByOptions ? Object.entries(sortByOptions).map(([key, value]) => `${key},${value}`) : undefined;
    } else {
      sorting = accountBettingPLSort
        ? Object.entries(accountBettingPLSort).map(([key, value]) => `${key},${value}`)
        : undefined;
    }

    const eventTypeIdValue = eventTypeId !== 'all' ? eventTypeId ?? eventType?.id.toString() : '';

    const profitsPayload = {
      startDate: startDateAdjusted,
      endDate: endDateAdjusted,
      page: number ?? pageNumber,
      size: size || pageSize,
      eventTypeId: eventTypeIdValue,
      betType: betType || currentBetType,
      currency: currencyCode,
      sortBy: sorting
    };
    const profitTotalPayload = {
      startDate: startDateAdjusted,
      endDate: endDateAdjusted,
      betType: betType || currentBetType,
      currency: currencyCode
    };
    const sportTotalPayload = {
      startDate: startDateAdjusted,
      endDate: endDateAdjusted,
      eventTypeId: eventTypeIdValue,
      betType: betType || currentBetType,
      currency: currencyCode
    };

    const isNetCommissionToggle = (!isPNCEnabled && !isAsianViewValue) || betType === BetTypeValue.Games;
    const netCommissionParam = isNetCommissionToggle
      ? { netOfCommission: netCommission ?? isProfitNetOfCommissionChecked }
      : {};

    dispatch(fetchPL({ ...profitsPayload, ...bettingData, ...netCommissionParam }));

    if (eventTypeIdValue) {
      dispatch(fetchPLTotalForEvent({ ...sportTotalPayload, ...bettingData, ...netCommissionParam }));
    } else {
      dispatch(fetchPLTotal({ ...profitTotalPayload, ...bettingData, ...netCommissionParam }));
    }
  };

  const fetchBetDataDebounced = debounce(
    ({
      refreshedStartDate,
      refreshedEndDate,
      number,
      size,
      currency,
      betsTypes,
      sortBy,
      search,
      noResetMyBetsCancelState,
      timeFormat,
      asianView: isAsianView
    }: TGetBetAndPageDataParams) => {
      const isAsianViewValue = typeof isAsianView === 'boolean' ? isAsianView : isActiveAsianView;

      const isBettingDay = timeFormat ? isAsianViewValue && timeFormat === TimeFormats.BETTING_DAY : bettingDay;
      const tzOffset = isBettingDay ? getBettingDayTimezone() : timezone;
      const currencyCode = getMultiCurrencyCodeFromArg(
        multiCurrencySupported,
        isMultiCurrencySupported,
        currentCurrency.currencyCode,
        multiCurrencyValue,
        currency
      );

      const startDateAdjusted = subtractSelectedTimezoneToDate(
        addDays(refreshedStartDate || startDate, dayDifference),
        tzOffset || timezone,
        timezoneCookieEnabled || isBettingDay
      );

      const endDateAdjusted = subtractSelectedTimezoneToDate(
        addDays(refreshedEndDate || endDate, dayDifference),
        tzOffset || timezone,
        timezoneCookieEnabled || isBettingDay
      );

      const payload: TGetBetPayload = {
        startDate: startDateAdjusted.getTime(),
        endDate: endDateAdjusted.getTime(),
        page: number ?? pageNumber,
        size: size || pageSize,
        currency: currencyCode
      };

      const sortByOptions = sortBy
        ? sortBy
        : isMobile
        ? {
            ...(mobileSorting ? { [mobileSorting.type]: mobileSorting.order } : {})
          }
        : {
            ...(sortByPlacedDate ? { [SortByFields.PLACED_DATE]: sortByPlacedDate } : {}),
            ...(sortBySettledDate ? { [SortByFields.DATE]: sortBySettledDate } : {})
          };

      const sortByList: string[] = [];

      Object.entries(sortByOptions ?? {}).forEach(([sortByKey, sortByValue]) =>
        sortByList.push(`${sortByKey},${sortByValue}`)
      );

      let typesList;

      if (betsTypes || activeBetTypes) {
        typesList = betsTypes || activeBetTypes;
      } else {
        if (periodType === Period.Open) {
          typesList = BetsTypesByPeriod[Period.Open];
        } else {
          typesList = BetsTypesByPeriod[Period.Settled];
        }
      }

      const betsTypesList = (typesList === 'undefined' ? [] : typesList).filter(
        (type: BetTypes) => !isAsianViewValue || (type !== BetTypes.Lapsed && type !== BetTypes.Matched)
      );

      const searchData: Partial<TGetBetPayload> = {};

      if ((search || searchValue) && search !== null) {
        searchData.search = search || searchValue || undefined;
        searchData.betsTypes = BetsTypesByPeriod[periodType];

        if (periodType === Period.Settled && isAsianViewValue) {
          searchData.betsTypes = searchData.betsTypes.filter(type => type !== BetTypes.Lapsed);
        }
      }

      dispatch(
        fetchMyBets({
          ...payload,
          betsTypes: betsTypesList,
          ...(sortByList.length ? { sortBy: sortByList } : {}),
          ...(isAsianViewValue ? { asianView: isAsianViewValue, bettingDay: isBettingDay } : {}),
          ...searchData,
          noResetMyBetsCancelState,
          onChangePage: (page: number) => dispatch(setPageNumber(page))
        })
      );
    },
    200
  );

  const getBetData = (params: TGetBetAndPageDataParams) => {
    dispatch(setLoading(true));
    fetchBetDataDebounced(params);
  };

  const getExportParams = (currency?: string) => {
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(startDate, dayDifference),
      timezoneOffset || timezone,
      timezoneCookieEnabled || bettingDay
    );

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(endDate, dayDifference),
      timezoneOffset || timezone,
      timezoneCookieEnabled || bettingDay
    );

    const baseParams = {
      startDate: startDateAdjusted.getTime(),
      endDate: endDateAdjusted.getTime(),
      currency: currencyCode,
      ...additionalParams
    };

    const betsTypesList = (activeBetTypes === 'undefined' ? [] : activeBetTypes).filter(
      (type: BetTypes) => !isActiveAsianView || type !== BetTypes.Lapsed
    );

    const netCommissionParam = hasNetCommission ? { netOfCommission: isProfitNetOfCommissionChecked } : {};

    let accountStatementOriginParam = accountStatementTransactions.filter(origin => {
      if (origin === StatementTransactionTypes.COMMISSION) {
        return isActiveAsianView ? false : !isPNCEnabled || exchangeGamesEnabled;
      }

      return true;
    });

    if (asianView) {
      if (accountStatementOriginParam.length === 3) {
        accountStatementOriginParam = [StatementTransactionTypes.ALL];
      }
    } else {
      if (accountStatementOriginParam.length === 4) {
        accountStatementOriginParam = [StatementTransactionTypes.ALL];
      }
    }

    switch (pageType) {
      case MyBetsPage.ExchangeBets:
        return {
          filename: EXPORT_FILENAME.ExchangeBets,
          url: urls.myBets.getMyBetsDownload(),
          params: {
            betsType: betsTypesList ?? [],
            ...(searchValue ? { query: searchValue } : {}),
            ...baseParams
          }
        };
      case MyBetsPage.AccountStatement:
        return {
          filename: EXPORT_FILENAME.AccountStatement,
          url: urls.myBets.getAccountStatementDownload(),
          params: {
            accountStatementOrigin: accountStatementOriginParam,
            ...baseParams
          }
        };
      case MyBetsPage.ProfitLoss:
        return {
          filename: EXPORT_FILENAME.ProfitLoss,
          url: urls.myBets.getProfitsDownload(),
          params: {
            eventTypeId: eventType?.id,
            betType: currentBetType,
            ...baseParams,
            ...netCommissionParam
          }
        };
    }
  };

  const getMyBetsPagesBaseUrl = () => {
    switch (pageType) {
      case MyBetsPage.ExchangeBets:
        return `${exchangePageType}${MyBetsLocations.MyBets}/${(periodType ?? Period.Open).toLowerCase()}`;
      case MyBetsPage.AccountStatement:
        return `${exchangePageType}${MyBetsLocations.Statement}`;
      case MyBetsPage.ProfitLoss:
        return `${exchangePageType}${MyBetsLocations.ProfitAndLoss}`;
    }
  };

  const getPageData = ({ refreshedStartDate, refreshedEndDate, size, number, currency }: TGetBetAndPageDataParams) => {
    switch (pageType) {
      case MyBetsPage.ExchangeBets:
        return getBetData({ refreshedStartDate, refreshedEndDate, size, number, currency });
      case MyBetsPage.AccountStatement:
        return getStatementData({ refreshedStartDate, refreshedEndDate, size, number, currency });
      case MyBetsPage.ProfitLoss:
        return getPLData({
          refreshedStartDate,
          refreshedEndDate,
          size,
          number,
          eventTypeId: eventType?.id.toString(),
          currency
        });
    }
  };

  const refreshData = (params: TGetBetAndPageDataParams = {}) => {
    const url = `${getMyBetsPagesBaseUrl()}/1`;
    dispatch(setPageNumber(0));
    if (pageType === MyBetsPage.ExchangeBets) {
      getBetData({ number: 0, ...params });
    }
    if (pageType === MyBetsPage.ProfitLoss) {
      getPLData({ number: 0, ...params });
    }
    if (pageType === MyBetsPage.AccountStatement) {
      getStatementData({
        number: 0,
        transactionTypes: params.statementTransactions,
        asianView: params.asianView,
        sortBy: params.sortBy,
        currency: params.currency,
        timeFormat: params.timeFormat,
        refreshedStartDate: params.refreshedStartDate,
        refreshedEndDate: params.refreshedEndDate,
        size: params?.size
      });
    }

    if (url !== location.pathname) {
      navigate(url, { replace: true });
    }
  };

  const handleSetDateRange = (fromDate: Date, toDate: Date) => {
    let startDay = addDays(fromDate, -1 * dayDifference);
    let endDay = addDays(toDate, -1 * dayDifference);
    startDay.setHours(0);
    startDay.setMinutes(0);
    startDay.setSeconds(0, 0);

    endDay.setHours(23);
    endDay.setMinutes(59);
    endDay.setSeconds(59, 999);

    startDay = applyTimezone(startDay, timezone, false);
    endDay = applyTimezone(endDay, timezone, false);

    setCookie(CookieNames.ACCOUNT_DATEFROM, new Date(startDay), {
      path: '/'
    });
    setCookie(CookieNames.ACCOUNT_DATETO, new Date(endDay), {
      path: '/'
    });

    dispatch(setPageNumber(0));
    dispatch(setPageSize(MIN_ITEMS_PAGINATION));
    dispatch(setStartDate(startDay));
    dispatch(setEndDate(endDay));

    getPageData({
      refreshedStartDate: startDay,
      refreshedEndDate: endDay,
      number: 0,
      size: MIN_ITEMS_PAGINATION
    });
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleSetStartDate = (date: Date) => {
    const newStartDate = new Date(date);
    newStartDate.setHours(0);
    const startOfTheDay = applyTimezone(newStartDate, timezone, false);
    const todayWithTimezone = applyTimezone(getTodayDate(), timezone, false);
    const startDateExtendsEndDate = startOfTheDay.getTime() > endDate.getTime();

    setCookie(CookieNames.ACCOUNT_DATEFROM, new Date(startOfTheDay), {
      path: '/'
    });
    if (startDateExtendsEndDate) {
      setCookie(CookieNames.ACCOUNT_DATETO, new Date(todayWithTimezone), {
        path: '/'
      });
    }

    dispatch(setPageNumber(0));
    dispatch(setStartDate(startOfTheDay));
    dispatch(setIsCustomPeriod(false));

    if (startDateExtendsEndDate) {
      dispatch(setEndDate(todayWithTimezone));
    }

    getPageData({
      refreshedStartDate: startOfTheDay,
      refreshedEndDate: startDateExtendsEndDate ? todayWithTimezone : undefined,
      number: 0
    });

    if (pageNumber !== 0) {
      navigate(`${getMyBetsPagesBaseUrl()}/1`);
    }
  };

  const handleSetEndDate = (date: Date) => {
    const endDateWithTimezone = applyTimezone(date, timezone, false);

    setCookie(CookieNames.ACCOUNT_DATETO, new Date(endDateWithTimezone), {
      path: '/'
    });

    dispatch(setPageNumber(0));
    dispatch(setEndDate(endDateWithTimezone));
    dispatch(setIsCustomPeriod(false));

    getPageData({ refreshedStartDate: startDate, refreshedEndDate: endDateWithTimezone, number: 0 });

    if (pageNumber !== 0) {
      navigate(`${getMyBetsPagesBaseUrl()}/1`);
    }
  };

  const handlePeriodDropdown = (period: TPeriods) => {
    const startOfTheDay = new Date(getStartDateByPeriod(period, startDate, dayDifference));
    const endWithTimezone = getEndDateByPeriod(period, endDate, dayDifference);

    setCookie(CookieNames.ACCOUNT_DATEFROM, new Date(startOfTheDay), {
      path: '/'
    });
    setCookie(CookieNames.ACCOUNT_DATETO, new Date(endWithTimezone), {
      path: '/'
    });

    dispatch(setPageNumber(0));
    dispatch(setPageSize(MIN_ITEMS_PAGINATION));
    dispatch(setStartDate(startOfTheDay));
    dispatch(setEndDate(endWithTimezone));

    getPageData({
      refreshedStartDate: startOfTheDay,
      refreshedEndDate: endWithTimezone,
      number: 0,
      size: MIN_ITEMS_PAGINATION
    });
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleResetDates = () => {
    const startOfTheDay = applyTimezone(new Date(getTodayStartDate()), timezone, false);
    const todayWithTimezone = applyTimezone(getTodayDate(), timezone, false);

    setCookie(CookieNames.ACCOUNT_DATEFROM, new Date(startOfTheDay), {
      path: '/'
    });
    setCookie(CookieNames.ACCOUNT_DATETO, new Date(todayWithTimezone), {
      path: '/'
    });

    dispatch(setPageNumber(0));
    dispatch(setPageSize(MIN_ITEMS_PAGINATION));
    dispatch(setStartDate(startOfTheDay));
    dispatch(setEndDate(todayWithTimezone));

    getPageData({
      refreshedStartDate: startOfTheDay,
      refreshedEndDate: todayWithTimezone,
      number: 0,
      size: MIN_ITEMS_PAGINATION
    });
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleSetPageNumber = (number: number) => {
    dispatch(setPageNumber(number));
    getPageData({ number });
    navigate(`${getMyBetsPagesBaseUrl()}/${number + 1}`);
  };

  const handleSetPageSize = (size: number) => {
    dispatch(setPageSize(size));
    getPageData({ size });
  };

  const handleSetPageSizeAndNumber = (number: number, size: number) => {
    dispatch(setPageNumber(number));
    dispatch(setPageSize(size));
    getPageData({ number, size });
    navigate(`${getMyBetsPagesBaseUrl()}/${number + 1}`);
  };

  const handleFetchDataOnTimeRangeChange = ({
    startDatePl,
    currency,
    eventTypeId
  }: TOnFetchDataProfitLossTimeRangeChangeParams) => {
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    dispatch(setPageNumber(0));
    const startOfTheDay = isMobile ? startDatePl : getTodayStartDate();

    getPLData({
      refreshedStartDate: applyTimezone(startOfTheDay, timezone, false),
      eventTypeId: eventTypeId || eventType?.id.toString(),
      currency: currencyCode,
      number: 0
    });
  };

  const handleSetTimeRange = ({ value, currency, eventTypeId }: TProfitLossTimeRangeChangeParams) => {
    if (value?.name) {
      setCookie(CookieNames.PROFITANDLOSS_TIMERANGE, value?.name, {
        path: '/'
      });
    }

    const id = eventTypeId;

    dispatch(setTimeRange(value));

    switch (value?.name) {
      case PLTimeRanges.LastDay:
        const startOfTheDay = setHoursMinutesToZero(new Date());
        handleFetchDataOnTimeRangeChange({ startDatePl: startOfTheDay, currency, eventTypeId: id });
        break;
      case PLTimeRanges.Yesterday: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getYesterdayDate(), currency, eventTypeId: id });
        break;
      }
      case PLTimeRanges.SevenDays: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getSevenDaysAgo(), currency, eventTypeId: id });
        break;
      }
      case PLTimeRanges.Month: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getThirtyDaysAgo(), currency, eventTypeId: id });
        break;
      }
      case PLTimeRanges.ThreeMonths: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getThreeMonthsAgo(), currency, eventTypeId: id });
        break;
      }
    }
  };

  const handleSetEventType = (item: DropdownItem) => {
    const eventTypeId = item?.id || '';
    dispatch(setEventTypeFilter(item));

    setCookie(CookieNames.PROFITANDLOSS_SPORTID, eventTypeId, {
      path: '/'
    });

    getPLData({
      eventTypeId: eventTypeId.toString(),
      number: 0
    });

    dispatch(setPageNumber(0));
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleChangeBetType = (betType: BetTypeValue) => {
    setBetTypeValue(betType);
    dispatch(resetPLTotalForEvent());
    dispatch(
      setEventTypeFilter(
        betType === BetTypeValue.Sports ? plDefaultSportTypeValues.sports : plDefaultSportTypeValues.games
      )
    );
  };

  useEffect(() => {
    if (betUpdate) {
      navigate(`${SPORT_BASE_URL}/${cancelledBetData?.eventTypeId}/market/${cancelledBetData?.marketId}/cancel`);
    }
  }, [betUpdate]);

  return {
    timezoneOffset,
    bettingDay,
    dayDifference,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    handleSetEndDate,
    handleSetStartDate,
    handleSetPageNumber,
    handleSetPageSize,
    handleSetPageSizeAndNumber,
    getBetData,
    handlePeriodDropdown,
    handleResetDates,
    handleSetDateRange,
    timeRange,
    handleSetTimeRange,
    eventType,
    handleSetEventType,
    handleChangeBetType,
    getStatementData,
    getPLData,
    refreshData,
    getExportParams,
    getPageData
  };
};
