import { createSelector } from '@reduxjs/toolkit';

import { ALL_MARKETS_TAB_NAME } from 'constants/app';
import { AppState } from 'redux/reducers';
import { IMarket } from 'types/markets';

import { TMultiMarketTabDetails } from './type';

export const getMultiMarketAllTabs = ({ multiMarket }: AppState) => multiMarket.tabs;
export const getMultiMarketsEventRules = ({ multiMarket }: AppState) => multiMarket.eventRules;
export const getMultiMarketsRules = ({ multiMarket }: AppState) => multiMarket.rules;
export const getMultiMarketTop = ({ multiMarket }: AppState) => multiMarket.topMultiMarket;
export const getMultiMarketRulesLoading = ({ multiMarket }: AppState) => multiMarket.rulesLoading;
export const getMultiMarketShowAll = ({ multiMarket }: AppState) => multiMarket.showAll;
export const getMultiMarketReset = ({ multiMarket }: AppState) => multiMarket.reset;
export const getMultiMarketLinesText = ({ multiMarket }: AppState) => multiMarket.linesText;
export const getMultiMarketLines = ({ multiMarket }: AppState) => multiMarket.lines;
export const getIsExtraTab = ({ multiMarket }: AppState) => multiMarket.isExtraTab;
export const getMultiMarketLoading = ({ multiMarket }: AppState) => multiMarket.loading;
export const getMultiMarketEventId = ({ multiMarket }: AppState) => multiMarket.eventId;

export const getFancyMultiMarketHeaderData = ({ multiMarket }: AppState) => multiMarket.fancyMultiMarketHeaderData;

export const getFancyMultiMarketHeaderLoading = ({ multiMarket }: AppState) =>
  multiMarket.fancyMultiMarketHeaderDetailsLoading;

export const getMultiMarketKeyMarketDetails = ({ multiMarket }: AppState) => multiMarket.keyMarketDetails;

export const getMultiMarketKeyMarketFancyDetails = ({ multiMarket }: AppState) => multiMarket.keyMarketFancyDetails;

export const getMultiMarketDirection = ({ multiMarket }: AppState) => multiMarket.isFromMultiMarketPage;

export const getAllMultiMarkets = createSelector(getMultiMarketAllTabs, tabs =>
  tabs.reduce<IMarket[]>((acc, { marketCatalogues }) => acc.concat(marketCatalogues), [])
);

export const getMultiMarketTabs = createSelector(getMultiMarketAllTabs, tabs => {
  let desktopDisplayedTabs: TMultiMarketTabDetails[] = [];
  let allMarketsTab: TMultiMarketTabDetails | undefined;

  tabs.forEach(tab => {
    if (tab.tabName === ALL_MARKETS_TAB_NAME) {
      allMarketsTab = tab;
    } else {
      desktopDisplayedTabs = desktopDisplayedTabs.concat(tab);
    }
  });

  return { desktopDisplayedTabs, allMarketsTab, allTabs: tabs };
});

export const getDesktopTabsMarkets = createSelector(getMultiMarketAllTabs, tabs =>
  tabs
    .filter(({ tabName }) => tabName !== ALL_MARKETS_TAB_NAME)
    .reduce<IMarket[]>((acc, { marketCatalogues }) => acc.concat(marketCatalogues), [])
);
