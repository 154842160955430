import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import Image from 'components/Image/Image';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { mobileAccountPagesBranding } from 'constants/branding';
import { ASIAN_BASE_URL, GAMES_BASE_URL, MOBILE_LOGIN_BASE_URL } from 'constants/locations';
import {
  getIsIframeEnabled,
  getIsMobileTimezoneDropdownEnabled,
  getMobileLangDropdownAuth,
  getMobileLangDropdownNoAuth,
  getMobileSettingsLoginHeaderButtons,
  getMobileSettingsPwdChangeBtn,
  getPwdLink
} from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsShowTooltipHelper } from 'redux/modules/tooltip/selectors';
import { setSettings } from 'redux/modules/user';
import {
  getIsAccountSettingsContextualHelpEnabledMobile,
  getIsUserAccountSettings
} from 'redux/modules/user/selectors';
import { MobilePreferencesOption } from 'types/mobile';
import { getDefaultLinkTarget } from 'utils/navigation';

import styles from './MobilePreferences.module.scss';

type MobilePreferencesProps = {
  selectedLocale?: MobilePreferencesOption;
  selectedOffset?: MobilePreferencesOption;
  setShowLocaleView: (value: boolean) => void;
  setShowTimezoneView: (value: boolean) => void;
  isAccountPage?: boolean;
  containerClassName?: string;
};

const MobilePreferences = ({
  selectedLocale,
  selectedOffset,
  setShowLocaleView,
  setShowTimezoneView,
  isAccountPage,
  containerClassName
}: MobilePreferencesProps) => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const showLangDropdownNoAuth = useSelector(getMobileLangDropdownNoAuth);
  const showLangDropdownAuth = useSelector(getMobileLangDropdownAuth);
  const isTimezoneDropdownEnabled = useSelector(getIsMobileTimezoneDropdownEnabled);
  const isShowTooltipHelper = useSelector(getIsShowTooltipHelper);
  const showChangePasswordLink = useSelector(getMobileSettingsPwdChangeBtn);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const loginHeaderButtons = useSelector(getMobileSettingsLoginHeaderButtons);

  const isLanguageDropdown = isLoggedIn ? showLangDropdownAuth : showLangDropdownNoAuth;
  const isAnyDropdown = isLanguageDropdown || isTimezoneDropdownEnabled;
  const showContent = isAccountPage
    ? isAnyDropdown || isShowTooltipHelper || showChangePasswordLink || (loginHeaderButtons && !isIframeEnabled)
    : isAnyDropdown;

  if (!showContent) {
    return null;
  }

  return (
    <div className={classNames(styles.preferences, containerClassName)}>
      {(!isAccountPage || isAnyDropdown || isShowTooltipHelper || showChangePasswordLink) && (
        <p className={styles.preferences__title}>{t('account.settings.preferences.title')}</p>
      )}
      <div className={styles.preferences__btnsContainer}>
        {isLanguageDropdown && (
          <button className={styles.preferences__btn} onClick={() => setShowLocaleView(true)}>
            <span className={styles.preferences__btn__left}>
              <i className={classNames('biab_custom-icon-globe', styles.preferences__btn__left__icon)} />
              {t('locale.label')}
            </span>
            <span className={styles.preferences__btn__right}>
              {selectedLocale?.image && (
                <Image
                  className={styles.flag}
                  name={selectedLocale.value}
                  width={31}
                  height={18}
                  path={selectedLocale.image}
                />
              )}
              {selectedLocale?.label?.toLowerCase()}
              <i className={classNames('biab_custom-icon-chevron-right-thin', styles.preferences__btn__right__icon)} />
            </span>
          </button>
        )}
        {isTimezoneDropdownEnabled && (
          <button className={styles.preferences__btn} onClick={() => setShowTimezoneView(true)}>
            <span className={styles.preferences__btn__left}>
              <i className={classNames('biab_custom-icon-clock', styles.preferences__btn__left__icon)} />
              {t('timezone.label')}
            </span>
            <span className={styles.preferences__btn__right}>
              {selectedOffset?.label}
              <i className={classNames('biab_custom-icon-chevron-right-thin', styles.preferences__btn__right__icon)} />
            </span>
          </button>
        )}
        {isAccountPage && <AccountPageContent />}
      </div>
    </div>
  );
};

export default MobilePreferences;

function AccountPageContent() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const isContextualHelpEnabled = useSelector(getIsAccountSettingsContextualHelpEnabledMobile);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const pwdUrl = useSelector(getPwdLink);
  const showChangePasswordLink = useSelector(getMobileSettingsPwdChangeBtn);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const loginHeaderButtons = useSelector(getMobileSettingsLoginHeaderButtons);
  const isShowTooltipHelper = useSelector(getIsShowTooltipHelper);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);
  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';

  const handleChangeContextualHelp = (e: ChangeEvent<HTMLInputElement>) => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            contextualHelpEnabledMobile: e.target.checked
          }
        })
      );
    }
  };

  const target = getDefaultLinkTarget({
    url: pwdUrl,
    absolutePath: true,
    isIframeEnabled,
    currentTarget: '_self',
    openInNewTab: false
  });

  return (
    <>
      {isShowTooltipHelper && (
        <label className={styles.account__contextualHelp}>
          <div className={styles.account__contextualHelp__left}>
            <i className={classNames('biab_custom-icon-question', styles.account__contextualHelp__icon)} />
            <p className={styles.account__contextualHelp__label}>{t('account.settings.contextualHelp.title')}</p>
          </div>
          <ToggleSwitch
            checked={isContextualHelpEnabled}
            onChange={handleChangeContextualHelp}
            containerClassName={styles.account__contextualHelp__toggle}
          />
        </label>
      )}
      {showChangePasswordLink && (
        <a href={pwdUrl} target={target} className={styles.account__changePassword}>
          {t('navigation.settings.label')}
          <i className={classNames('biab_custom-icon-chevron-right-thin', styles.account__changePassword__icon)} />
        </a>
      )}
      {loginHeaderButtons && !isIframeEnabled && (
        <button
          className={classNames(styles.account__logout, mobileAccountPagesBranding.LOGOUT_BUTTON)}
          onClick={() => dispatch(logoutUser(`${exchangePage}${MOBILE_LOGIN_BASE_URL}`))}
        >
          {t('header.logout')}
        </button>
      )}
    </>
  );
}
