import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import CashOut from 'components/CashOut';
import InPlayStatus from 'components/InPlayStatus';
import MatchStatistics from 'components/MatchStatistics';
import Rules from 'components/Rules';
import VideoStream from 'components/VideoStream';
import { BETTING_TYPES } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { BACK_COLUMN, MATCHED_TOTAL } from 'constants/tooltip';
import useCellConfigs from 'hooks/useCellConfigs';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLayColumn from 'hooks/useLayColumn';
import useTooltip from 'hooks/useTooltip';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { fetchHomeMarketRules } from 'redux/modules/home';
import {
  getMarketPricesBettingType,
  getMarketPricesCurrency,
  getMatchedAmountByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { CurrencySettingsPlacement, SportId } from 'types';

import RulesModal from '../RulesModal';

import styles from './styles.module.scss';

interface SearchMarketHeaderProps {
  /**
   * Is market in play or not
   */
  inPlay: boolean;
  /**
   * Event Type ID
   */
  eventTypeId: string;
  /**
   * Market ID
   */
  marketId: string;
  /**
   * Market start time
   */
  marketStartTime: number;
  /**
   * Market name
   */
  marketName: string;
  /**
   * Cashout enabled
   */
  cashOutEnabled: boolean;
  /**
   * Whether video streaming is enabled enabled
   */
  videoStreamingEnabled: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * ID of event
   */
  eventId: string;
  /**
   * Whether match statistics widget is enabled
   */
  matchStatEnabled: boolean;
  /**
   * ID of sport
   */
  sportId: string;
}

const SearchMarketHeader = ({
  inPlay,
  eventTypeId,
  marketId,
  marketName,
  marketStartTime,
  cashOutEnabled,
  videoStreamingEnabled,
  isDepthEnabled,
  eventId,
  matchStatEnabled,
  sportId
}: SearchMarketHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const device = useSelector(getAppDevice);
  const marketPricesCurrency = useSelector(getMarketPricesCurrency(marketId));
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(marketId));
  const bettingType = useSelector(getMarketPricesBettingType(marketId));

  const [openRulesModal, setOpenRulesModal] = useState<boolean>(false);

  const { isLayColumnEnabled } = useLayColumn({
    sportId,
    bettingType
  });
  const { lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver } = useDeviceSettings();
  const { translationKey: translationKeyMatchedTotal, isEnabled: isEnabledMatchedTotal } = useTooltip(MATCHED_TOTAL);
  const { translationKey: translationKeyBackColumn, isEnabled: isEnabledBackColumn } = useTooltip(BACK_COLUMN);

  const isDesktop = device === Devices.DESKTOP;

  const { desktopCellsContainerWidth, mobileCellsContainerWidth, cellWidthMobile } = useCellConfigs({
    cellGroupsCount: isDepthEnabled && !isDesktop ? 3 : 1,
    doubleWidth: true,
    addMobileExtraSpace: true
  });

  const { formattedAmount: matchedAmount, noFormattedAmount: tooltipMatchedAmount } = useFormatCurrency(
    totalMatched ?? 0,
    marketPricesCurrency,
    {
      isCheckIndian: true,
      placement: CurrencySettingsPlacement.MATCHED_AMOUNT
    }
  );

  const cellWidthStyle = isDesktop ? {} : { width: cellWidthMobile };

  const getCellHeaderTranslation = (label: string) => {
    const isCricket = eventTypeId === SportId.CRICKET;
    const isLineMarket = bettingType === BETTING_TYPES.line;
    const hasLineBackLayToNoYes = isCricket && lineMarketsSwitchBackLayOnCricket && isLineMarket;
    const replaceWithOverUnder = !isCricket && replaceBackLayWithUnderOver && isLineMarket;

    if (hasLineBackLayToNoYes) {
      return t(label === 'back' ? 'market.column.no' : 'market.column.yes');
    }
    if (replaceWithOverUnder) {
      return t(label === 'back' ? 'market.column.under' : 'market.column.over');
    }

    return t(label === 'back' ? 'market.back' : 'market.lay');
  };

  const handleOpenRulesModal = () => {
    dispatch(fetchHomeMarketRules({ sportId: eventTypeId, marketId }));
    setOpenRulesModal(true);
  };

  return (
    <>
      {isDesktop && (
        <>
          <div className={classNames('biab_search-market-info', styles.marketTable__headerInfo)}>
            {videoStreamingEnabled && <VideoStream visible={videoStreamingEnabled} eventId={eventId} isLiveLabel />}
            {matchStatEnabled && (
              <MatchStatistics
                isSoccer={sportId === SportId.SOCCER}
                visible={matchStatEnabled}
                isLabel
                noBrandingLabel
                eventId={eventId}
              />
            )}
            <InPlayStatus showLabel isInPlay={inPlay} labelClassName={styles.marketTable__headerInfo__inPlayIcon} />
            <div onClick={handleOpenRulesModal}>
              <Rules />
            </div>
            <RulesModal
              eventTypeId={eventTypeId}
              marketId={marketId}
              marketName={marketName}
              marketStartTime={marketStartTime}
              isOpen={openRulesModal}
              onClose={() => setOpenRulesModal(false)}
            />
            {cashOutEnabled && (
              <div className={styles.cashOutContainer}>
                <CashOut showLabel={isDesktop} showInfoIcon={false} />
              </div>
            )}
          </div>
          <div className={styles.marketTable__linkWrapper}>
            <NavLink
              to={`${SPORT_BASE_URL}/${eventTypeId}/market/${marketId}`}
              className={classNames('biab_market-link', styles.marketLink)}
              key={`content-${marketId}`}
            >
              {marketName} <i className="fa2 fa2-arrow-right" />
            </NavLink>
            <div className={styles.marketTable__totalMatched}>
              <span
                data-tooltip-id={'tooltip'}
                data-tooltip-html={
                  isEnabledMatchedTotal ? unescape(t(translationKeyMatchedTotal, { amount: tooltipMatchedAmount })) : ''
                }
                className={isEnabledMatchedTotal ? 'cursor-help' : ''}
              >
                {t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}: {matchedAmount}
              </span>
            </div>
          </div>
        </>
      )}
      <div
        className={classNames(styles.marketTable__content__header, {
          [styles.marketTable__content__header__withoutLay]: !isLayColumnEnabled
        })}
      >
        {isDesktop ? (
          <div className={styles.marketTable__totalMatched} />
        ) : (
          <div className={styles.marketTable__totalMatched}>
            <span
              data-tooltip-id={'tooltip'}
              data-tooltip-html={
                isEnabledMatchedTotal ? unescape(t(translationKeyMatchedTotal, { amount: tooltipMatchedAmount })) : ''
              }
              className={isEnabledMatchedTotal ? 'cursor-help' : ''}
            >
              {t('market.matched')}: {matchedAmount}
            </span>

            {videoStreamingEnabled && <VideoStream visible={videoStreamingEnabled} eventId={eventId} isLeftSpace />}
            {cashOutEnabled && (
              <div className={styles.cashOutContainer}>
                <CashOut showLabel={false} showInfoIcon={false} />
              </div>
            )}
            <div className={styles.inPlayIcon}>
              <InPlayStatus isInPlay={inPlay} />
            </div>
            <div onClick={handleOpenRulesModal}>
              <Rules isHideText isSearch />
            </div>
            <RulesModal
              eventTypeId={eventTypeId}
              marketId={marketId}
              marketName={marketName}
              marketStartTime={marketStartTime}
              isOpen={openRulesModal}
              onClose={() => setOpenRulesModal(false)}
            />
          </div>
        )}
        <div
          className={classNames(styles.marketTable__totalMatchedCell, {
            [styles.marketTable__totalMatchedCell__withoutLay]: !isLayColumnEnabled
          })}
          style={{ width: isDesktop ? desktopCellsContainerWidth : mobileCellsContainerWidth }}
        >
          <span
            data-tooltip-id={'tooltip'}
            data-tooltip-html={isEnabledBackColumn ? unescape(t(translationKeyBackColumn)) : ''}
            style={cellWidthStyle}
            className={isEnabledBackColumn ? 'cursor-help' : ''}
          >
            {getCellHeaderTranslation('back')}
          </span>

          {isLayColumnEnabled && (
            <span
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isEnabledBackColumn ? unescape(t(translationKeyBackColumn)) : ''}
              style={cellWidthStyle}
              className={isEnabledBackColumn ? 'cursor-help' : ''}
            >
              {getCellHeaderTranslation('lay')}
            </span>
          )}
        </div>
      </div>
      {!isDesktop && (
        <div>
          <NavLink
            to={`${SPORT_BASE_URL}/${eventTypeId}/market/${marketId}`}
            className={classNames('biab_market-link', styles.marketLink)}
            key={`content-${marketId}`}
          >
            {marketName} <i className="fa2 fa2-arrow-right" />
          </NavLink>
        </div>
      )}
    </>
  );
};

export default SearchMarketHeader;
