import classNames from 'classnames';

import { betslipBranding, mobileComponents } from 'constants/branding';

import styles from './styles.module.scss';

type TMobileNotificationProps = {
  text: string;
};

const MobileNotification = ({ text }: TMobileNotificationProps) => {
  return (
    <div className={classNames(styles.notification, mobileComponents.NOTIFICATION, mobileComponents.INFO)}>
      <i className={classNames(styles.icon, 'biab_custom-icon-info-circle', betslipBranding.INFO_ICON)} />
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default MobileNotification;
