import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { MULTI_MARKET_EXTRA_TAB_ID } from 'constants/multiMarket';
import { TFetchFancyEventDetailsPayload, TFetchFancyEventDetailsResponse } from 'redux/modules/fancyMarket/type';
import { TFailureActionPayload } from 'types';
import { IMarket, TFetchEventRulesResponse, TFetchMarketRulesResponse } from 'types/markets';

import {
  TMultiMarket,
  TMultiMarketTabDetails,
  TMultiMarketTopDetails,
  TSetMultiMarketLinesResetLinesTextPayload
} from './type';

const initialState: TMultiMarket = {
  loading: false,
  error: null,
  tabs: [],
  eventRulesLoading: false,
  eventRules: null,
  eventRulesError: null,
  rulesLoading: false,
  rules: null,
  rulesError: null,
  topMultiMarket: null,
  topMultiMarketLoading: false,
  lines: {
    from: 0,
    to: 0,
    currentLine: 1,
    defaultLine: 0,
    totalLines: 0
  },
  linesText: '',
  showAll: undefined,
  reset: false,
  isExtraTab: false,
  fancyMultiMarketHeaderData: null,
  fancyMultiMarketHeaderDetailsLoading: false,
  keyMarketDetails: null,
  keyMarketFancyDetails: null,
  isFromMultiMarketPage: false,
  eventId: null
};

const slice = createSlice({
  name: SLICES_NAMES.MULTI_MARKET,
  initialState,
  reducers: {
    fetchMultiMarketDetails: (state, __: PayloadAction<string>) => {
      state.loading = true;
    },
    successFetchMultiMarketDetails: (state, { payload }: PayloadAction<TMultiMarketTabDetails[]>) => {
      state.loading = false;
      state.tabs = payload;
    },
    failureFetchMultiMarketDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    fetchMultiMarketEventRules: (state, __: PayloadAction<string>) => {
      state.eventRulesLoading = true;
    },
    successFetchMultiMarketEventRules: (state, { payload }: PayloadAction<TFetchEventRulesResponse>) => {
      payload.rules.forEach(item => {
        if (state.eventRules === null) {
          state.eventRules = {};
        }

        if (typeof item.marketIds === 'string') {
          state.eventRules[item.marketIds] = item;
        }
      }, {});

      state.eventRulesLoading = false;
    },
    failureFetchMultiMarketEventRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.eventRulesLoading = false;
      state.eventRulesError = payload;
    },
    fetchMultiMarketRules: (state, __: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchMultiMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;

      if (state.rules === null) {
        state.rules = {};
      }

      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchMultiMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    fetchMultiMarketTopDetails: (state, { payload }: PayloadAction<string>) => {
      state.topMultiMarketLoading = true;
      state.eventId = payload;
    },
    successFetchMultiMarketTopDetails: (state, { payload }: PayloadAction<TMultiMarketTopDetails>) => {
      state.topMultiMarketLoading = true;
      state.topMultiMarket = payload;
    },
    failureFetchMultiMarketTopDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.topMultiMarketLoading = false;
      state.error = payload;
    },
    setMultiMarketReset: (state, { payload }: PayloadAction<boolean>) => {
      state.reset = payload;
    },
    setMultiMarketLinesResetLinesText: (
      state,
      { payload }: PayloadAction<TSetMultiMarketLinesResetLinesTextPayload>
    ) => {
      state.lines = payload.lines;
      state.reset = payload.reset;
      state.linesText = payload.linesText;
    },
    setMultiMarketShowAll: (state, { payload }: PayloadAction<boolean>) => {
      state.showAll = payload;
    },
    resetMultiMarketTopDetails: state => {
      state.topMultiMarket = null;
    },
    setMultiMarketRedirection: (state, { payload }: PayloadAction<boolean>) => {
      state.isFromMultiMarketPage = payload;
    },
    setExtraMultiMarketTab: (state, { payload }: PayloadAction<IMarket>) => {
      const newTabs: TMultiMarketTabDetails[] = current(state.tabs).slice();
      const emptyInningMarkets = {
        batsmenMarketCatalogues: [],
        fallOfWicketCatalogues: [],
        fancyMarketCatalogues: [],
        inningsFancyCatalogues: [],
        inningsFoursCatalogues: [],
        inningsSixesCatalogues: [],
        totalInningsRunsCatalogues: [],
        otherLineMarketsCatalogues: []
      };
      const extraMarketTab: TMultiMarketTabDetails = {
        marketCatalogues: [payload],
        tabName: payload.marketName,
        tabId: MULTI_MARKET_EXTRA_TAB_ID,
        firstInningsMarkets: emptyInningMarkets,
        secondInningsMarkets: emptyInningMarkets,
        fancyView: false,
        topMarketId: newTabs[0]?.topMarketId
      };

      newTabs.splice(newTabs.length - 1, 0, extraMarketTab);
      state.tabs = newTabs;
      state.isExtraTab = true;
    },
    removeExtraMultiMarketTab: state => {
      const newTabs: TMultiMarketTabDetails[] = current(state.tabs).slice();

      newTabs.splice(newTabs.length - 2, 1);

      state.tabs = newTabs;
      state.isExtraTab = false;
    },
    resetMultiMarketArrowScrollParams: state => {
      state.lines = initialState.lines;
      state.linesText = initialState.linesText;
      state.showAll = initialState.showAll;
      state.reset = initialState.reset;
    },
    fetchFancyMultiMarketHeaderDetails: (state, __: PayloadAction<TFetchFancyEventDetailsPayload>) => {
      state.fancyMultiMarketHeaderDetailsLoading = true;
    },
    successFetchFancyMultiMarketHeaderDetails: (state, { payload }: PayloadAction<TFetchFancyEventDetailsResponse>) => {
      state.fancyMultiMarketHeaderDetailsLoading = false;
      state.fancyMultiMarketHeaderData = payload;
    },
    failureFetchFancyMultiMarketHeaderDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.fancyMultiMarketHeaderDetailsLoading = false;
      state.error = payload;
    },
    fetchMultiMarketKeyMarketDetails: (_, __: PayloadAction<string>) => {},
    successFetchMultiMarketKeyMarketDetails: (state, { payload }: PayloadAction<IMarket>) => {
      state.keyMarketDetails = payload;
    },
    failureFetchMultiMarketKeyMarketDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
    },
    fetchMultiMarketKeyMarketFancyDetails: (_, __: PayloadAction<TFetchFancyEventDetailsPayload>) => {},
    successFetchMultiMarketKeyMarketFancyDetails: (
      state,
      { payload }: PayloadAction<TFetchFancyEventDetailsResponse>
    ) => {
      state.keyMarketFancyDetails = payload;
    },
    failureFetchMultiMarketKeyMarketFancyDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
    },
    resetMultiMarketState: () => initialState
  }
});

export const {
  failureFetchMultiMarketKeyMarketFancyDetails,
  failureFetchMultiMarketRules,
  failureFetchFancyMultiMarketHeaderDetails,
  failureFetchMultiMarketKeyMarketDetails,
  failureFetchMultiMarketTopDetails,
  fetchFancyMultiMarketHeaderDetails,
  successFetchMultiMarketKeyMarketDetails,
  successFetchMultiMarketKeyMarketFancyDetails,
  successFetchMultiMarketRules,
  successFetchMultiMarketTopDetails,
  resetMultiMarketState,
  resetMultiMarketTopDetails,
  setExtraMultiMarketTab,
  setMultiMarketLinesResetLinesText,
  setMultiMarketRedirection,
  setMultiMarketReset,
  setMultiMarketShowAll,
  successFetchFancyMultiMarketHeaderDetails,
  failureFetchMultiMarketEventRules,
  removeExtraMultiMarketTab,
  resetMultiMarketArrowScrollParams,
  fetchMultiMarketEventRules,
  fetchMultiMarketRules,
  successFetchMultiMarketEventRules,
  fetchMultiMarketTopDetails,
  successFetchMultiMarketDetails,
  failureFetchMultiMarketDetails,
  fetchMultiMarketDetails,
  fetchMultiMarketKeyMarketFancyDetails,
  fetchMultiMarketKeyMarketDetails
} = slice.actions;

export default slice.reducer;
