import { NEXT_RACE_COUNT } from 'constants/app';
import { TFetchResultHistoryPayload } from 'redux/modules/games/type';
import { ResponsibleBettingReportTypeKeys } from 'types/responsibleBetting';

export const urls = {
  app: {
    getPsFooterEndpoint: (licence: string, locale: string) =>
      `https://cmsstorage.rationalcdn.com/assets/bs/assets/export/betstars/${licence}/${locale}/footer-legacy.html`,
    configsEndpoint: (): string => 'page/properties',
    translationsEndpoint: (language: string): string => `translation/${language}`,
    contentPagesEndpoint: (): string => `content/pages`,
    pageContentEndpoint: (path: string): string => `content/pages/${path}`,
    customerImagesEndpoint: (id: number): string => `images/${id}`,
    banners: () => 'banners/slot',
    currencyEndpoint: () => 'currency',
    currencies: () => 'currencies',
    eventUpdates: () => 'event-updates',
    eventUpdatesById: (eventId: string) => `event-updates/${eventId}`,
    marketRules: (marketId: string) => `rules/market/${marketId}`,
    eventRules: (eventId: string) => `rules/sport/${eventId}`,
    tooltipsEndpoint: () => `translation/tooltips`,
    getCashOutPopUpEndpoint: (language: string) => `content/pages/${language}/cash_out_pop_up_link`
  },
  auth: {
    login: (): string => 'login',
    logout: (): string => 'logout',
    verify: (): string => 'code'
  },
  user: {
    info: (asianViewEnabled: boolean): string => `account/info${asianViewEnabled ? '?asianView=true' : ''}`,
    balance: (asianViewEnabled: boolean): string => `${asianViewEnabled ? 'asian-view/' : ''}account/balance`,
    settings: (): string => 'account/settings'
  },
  sports: {
    getSportsEndpoint: (): string => 'sports'
  },
  games: {
    getGamesEndpoint: (): string => 'games/',
    getCurrentGameData: (gameId: string): string => `games/${gameId}/current`,
    getGameRules: (gameId: string | number): string => `rules/game/${gameId}`,
    getResultsHistory: ({ channelId, page, gameId, betPlacedOnly }: TFetchResultHistoryPayload): string =>
      `games/history?channelId=${channelId}&page=${page}&sort=start_time,desc${
        betPlacedOnly ? '&betPlacedOnly=true' : ''
      }${gameId ? `&gameId=${gameId}` : ''}`
  },
  home: {
    marketsSection: (sportId: string): string => `home/${sportId}`
  },
  ws: {
    marketPrices: 'multiple-market-prices',
    general: 'general',
    asianGeneral: 'asian-view/general',
    cashOutCounter: 'cash-out-counter'
  },
  search: () => `event/search`,
  races: {
    getNextRacesEndpoint: (): string => `nextraces/${NEXT_RACE_COUNT}`
  },
  competitions: {
    getCountriesAndCompetitionsEndpoint: (sportId: string | number): string => `country-competition/sport/${sportId}`,
    getCompetitionDetails: (competitionId: string | number, showGroups: boolean | undefined): string =>
      `competition/${competitionId}${showGroups ? '?showGroups=true' : ''}`,
    getCountryCompetitionsBySportId: (countryId: string | number, sportId: string | number): string =>
      `country-competition/country/${countryId}?sportId=${sportId}`,
    getEvent: (eventId: string | number, showGroups: boolean | undefined): string =>
      `event/${eventId}${showGroups ? '?showGroups=true' : ''}`,
    getMarket: (marketId: string | number): string => `market/${marketId}`,
    getSportCompetition: (sportId: string | number, showGroups: boolean | undefined): string =>
      `competition/sport/${sportId}${showGroups ? '?showGroups=true' : ''}`,
    getGroup: (groupId: string | number, showGroups: boolean | undefined): string =>
      `group/${groupId}${showGroups ? '?showGroups=true' : ''}`,
    getSports: (sportId: string | number) => `sports/${sportId}`,
    getRace: (raceId: string | number): string => `race/${raceId}`,
    getTimeFilters: (): string => `time-filters`,
    fetchMultiMarket: (eventId: string | number): string => `multi-market/top/${eventId}`
  },
  marketOdds: {
    getMarketOddsEndpoint: (): string => 'market-odds'
  },
  featuredMarkets: {
    getFeaturedMarketsEndpoint: (): string => 'featured-markets'
  },
  racing: {
    horseRacing: (sportId: string): string => `horse-racing/${sportId}/all`
  },
  placement: {
    getCurrentBetsEndpoint: (): string => 'currentBets',
    getPlaceBetsEndpoint: (): string => 'placeBets',
    cancelBets: 'cancelBets',
    getEditBetsEndpoint: (): string => 'editBets',
    getBetsStatuses: (betIds: string): string => `betsStatuses?betIds=${betIds}`,
    getCancelAllBetsEndpoint: (): string => 'cancelBets/all',
    getCancelActionStatuses: (cancelActionId: number): string => `cancel-action/${cancelActionId}`,
    getAsianPlaceBetsEndpoint: (): string => 'asian-view/placeBets',
    getAsianViewCurrentBetsEndpoint: 'asian-view/currentBets'
  },
  myBets: {
    getMyBets: (urlParams = '') => `myBets${urlParams}`,
    getMyBetsSearch: (urlParams = '') => `myBets/search${urlParams}`,
    getMyBetsDownload: (urlParams = '') => `myBets/download${urlParams}`,
    getProfitsDownload: () => 'profits/download',
    getAccountStatementDownload: () => 'account/statement/download',
    getAccountStatement: (params = '') => `account/statement${params}`,
    getPL: (urlParams = '') => `profits${urlParams}`,
    getPLTotal: (urlParams = '') => `total/profit${urlParams}`,
    charge: (): string => 'account/charge',
    sportsList: (): string => 'profits/sports',
    gamesList: (): string => 'profits/games',
    accountProducts: (): string => 'account/products'
  },
  settings: {
    getSettings: (language: string): string => `settings/${language}`
  },
  inPlay: {
    inPlayMarkets: (id: string) => `inplay/${id}`,
    sports: () => 'inplay/sports'
  },
  event: {
    getEventDetailsEndpoint: (eventId: string): string => `event/details/${eventId}`,
    getEventGroupEndpoint: (eventId: string): string => `event/${eventId}?showGroups=true`,
    getFancyEventDetailsEndpoint: (eventId: string): string => `fancyEvent/details/${eventId}`,
    getTop5ViewDetailsEndpoint: (eventId: string): string => `top-five/${eventId}`,
    getMultiMarketTabsEndpoint: (eventId: string): string => `multi-market/tabs/${eventId}`,
    getMultiMarketTopEndpoint: (eventId: string): string => `multi-market/top/${eventId}`
  },
  market: {
    getMarketDetailsEndpoint: (marketId: string, showGroups: boolean | undefined): string =>
      `market/${marketId}${showGroups ? '?showGroups=true' : ''}`,
    getListViewMarkets: (sportId: string): string => `list-view/details/${sportId}`
  },
  sportPage: {
    getDetails: (): string => 'sport/details'
  },
  popular: {
    getPopularLinks: (): string => 'popular'
  },
  plTable: {
    getAsianPLTable: (id: string): string => `ah/profitAndLossTable/${id}`,
    getLinePLTable: (id: string): string => `line/profitAndLossTable/${id}`
  },
  favorites: {
    favoriteEndpoint: (): string => 'favourite/stars'
  },
  tours: {
    getStep: (id: number) => `tours/step/${id}`
  },
  responsibleBetting: {
    base: () => 'responsible-gaming',
    updateReminderLastDate: () => 'responsible-gaming/updateReminderLastDate',
    updateDayLimitReminder: () => 'responsible-gaming/updateDayLimitReminder',
    confirmLimit: () => 'responsible-gaming/confirmLimit',
    cancelTimeLimit: () => 'responsible-gaming/cancel',
    fetchReportsAvailability: () => 'responsible-gaming/reports',
    fetchReports: (type: ResponsibleBettingReportTypeKeys) => `responsible-gaming/reports/${type}/download`
  },
  cashOut: {
    quote: (isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}cashout/quote`,
    markets: (isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}cashout/market`,
    createAutoCashOut: (isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}auto-cashout`,
    deleteAutoCashOut: (id: number, isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}auto-cashout/${id}`,
    getAutoCashOutMarkets: (isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}auto-cashout/markets`,
    setCashOutMarkets: (isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}cashout`,
    status: (id: number, isAsianView?: boolean) => `${isAsianView ? 'asian-view/' : ''}cashout/status/${id}`
  },
  videoStream: (eventId: string) => `video-streaming/${eventId}`,
  meta: () => 'meta',
  asianNavigation: {
    getAsianSports: () => 'asian-view/navigation',
    getAsianSportCounter: (sportId: string) => `asian-view/${sportId}/count`,
    getAsianSportEventsCounter: (sportId: string) => `asian-view/${sportId}/count-events`
  },
  asianView: {
    getMarketByTab: (sportId: string | number, itemId: number | string) =>
      `asian-view/${sportId}/coupons/${itemId}/sections`,
    asianViewFavourites: () => `favourite/asianViewStars`,
    list: (id: number | string, size: number, page?: number, offset?: number) =>
      `asian-view/${id}?${typeof page === 'number' ? 'page' : 'offset'}=${page ?? offset}&size=${size}`,
    competitions: (id: string | number) => `asian-view/${id}/competitions`,
    getCountMarkets: (id: string | number) => `asian-view/${id}/count-markets`,
    popularLinks: () => 'asian-view/popularLinks',
    asianViewFavouritesEventsList: (size: number, page?: number, offset?: number) =>
      `favourite/list-favourites?${typeof page === 'number' ? 'page' : 'offset'}=${page ?? offset}&size=${size}`,
    marketRules: (marketId: string) => `asian-view/rules/market/${marketId}`
  },
  asianSearch: {
    getEvents: (query: string, size: number, page?: number, offset?: number) =>
      `asian-view/search/?query=${query}&${typeof page === 'number' ? 'page' : 'offset'}=${page ?? offset}&size=${size}`
  },
  asianPlacement: {
    placeBets: 'asian-view/placeBets',
    getBetsStatuses: 'asian-view/betsStatuses'
  },
  checkMarkets: () => 'asian-view/market-status',
  checkSportsMarkets: () => 'market-status',
  checkGamesMarkets: () => 'games/market-status',
  profitLossTablePopUp: {
    avProfitLossTableData: (eventId: string) => `asian-view/profitAndLossTable/${eventId}`,
    avProfitLossTableMarketNames: (eventId: string) => `asian-view/profitAndLossMarketNames/${eventId}`,
    profitLossTableMarketNames: (eventId: string) => `profitAndLossMarketNames/${eventId}`,
    plTablePopUpData: (eventId: string) => `eventMarketsProfitAndLossTable/${eventId}`
  }
};
