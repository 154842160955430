import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { BannerNames, ROUTER_BASE_URL } from 'constants/app';
import { accountPagesBranding } from 'constants/branding';
import { HOME_BASE_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getCurrency,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled
} from 'redux/modules/appConfigs/selectors';
import { getBannerByName } from 'redux/modules/banners/selectors';
import { getAVBalance, getBalance } from 'redux/modules/user/selectors';
import { getDefaultLinkTarget } from 'utils/navigation';

import HeaderLogo from '../HeaderLogo';

import commonStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

const MobileHeaderTop = () => {
  const { isMobile } = useDevice();

  const { t } = useTranslation();

  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const defaultCurrency = useSelector(getCurrency);
  const balance = useSelector(getBalance);
  const avBalance = useSelector(getAVBalance);

  const { noFormattedAmount: userBalance } = useFormatCurrency(balance, defaultCurrency.symbol, {
    noRounding: true,
    isCheckIndian: true
  });

  const { noFormattedAmount: userAVBalance } = useFormatCurrency(avBalance, defaultCurrency.symbol, {
    noRounding: true,
    isCheckIndian: true
  });

  const hasSportsBalance = isExchangeGamesEnabled || isExchangeSportsEnabled;
  const isSingleBalance = !(avBalance && isAsianViewEnabled && hasSportsBalance);
  const singleBalance = isSingleBalance ? (hasSportsBalance ? userBalance : avBalance) : '';
  const balanceLabel =
    avBalance && isAsianViewEnabled
      ? t('asianView.labels.account.exchangeType.sportsGames')
      : t('account.statement.labels.balance');
  const avBalanceLabel = !isSingleBalance
    ? t('asianView.labels.account.exchangeType.asianView')
    : t('account.statement.labels.balance');

  const iframeEnabled = useSelector(getIsIframeEnabled);
  const logoBannerObj = useSelector(getBannerByName(BannerNames.logo));

  const logo = logoBannerObj && logoBannerObj.bannerImages ? logoBannerObj?.bannerImages[0] : null;

  return (
    <div className={classNames(commonStyles.header__topWrap, styles.header)}>
      {logo?.imageUrl ? (
        <div
          className={classNames(commonStyles.header__logoLink__container, {
            [commonStyles.header__logoLink__container__mobile]: isMobile
          })}
        >
          {logo.link.startsWith('http') ? (
            <a
              href={logo.link}
              target={getDefaultLinkTarget({
                url: logo.link,
                absolutePath: logo.absolutePathEnabled,
                isIframeEnabled: iframeEnabled,
                currentTarget: logo.openInNewTab ? '_blank' : '',
                openInNewTab: logo.openInNewTab
              })}
              className={classNames({ [commonStyles.header__logoLink]: isMobile })}
            >
              <HeaderLogo logo={logo} isMobile={isMobile} />
            </a>
          ) : (
            <Link
              to={logo?.link?.replace(ROUTER_BASE_URL, '') || HOME_BASE_URL}
              target={getDefaultLinkTarget({
                url: logo.link,
                absolutePath: logo.absolutePathEnabled,
                isIframeEnabled: iframeEnabled,
                currentTarget: logo.openInNewTab ? '_blank' : '',
                openInNewTab: logo.openInNewTab
              })}
              className={classNames({ [commonStyles.header__logoLink]: isMobile })}
            >
              <HeaderLogo logo={logo} isMobile={isMobile} />
            </Link>
          )}
        </div>
      ) : (
        <div
          className={classNames(commonStyles.header__logoEmptySpace, {
            [commonStyles.header__logoEmptySpace__mobile]: isMobile
          })}
        />
      )}

      {isSingleBalance ? (
        <div className={classNames(styles.singleWalletWrapper, accountPagesBranding.ACCOUNT_BALANCE)}>
          <span className={styles.balance}>{singleBalance}</span>
          <div className={styles.iconWrapper}>
            <i className={classNames(styles.icon, 'fa2 fa2-balance-icon')} />
          </div>
        </div>
      ) : (
        <div className={styles.wallets}>
          {!!avBalance && isAsianViewEnabled && (
            <div
              className={classNames(
                styles.walletWrapper,
                styles.walletWrapper__with_border,
                accountPagesBranding.ACCOUNT_BALANCE
              )}
            >
              <span className={styles.label}>{avBalanceLabel}:</span>
              <span className={styles.balance}>{userAVBalance}</span>
            </div>
          )}
          {balance && hasSportsBalance && (
            <div className={classNames(styles.walletWrapper, accountPagesBranding.ACCOUNT_BALANCE)}>
              <span className={styles.label}>{balanceLabel}:</span>
              <span className={styles.balance}>{userBalance}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MobileHeaderTop;
