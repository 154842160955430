import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import LoginForm from 'components/forms/LoginForm';
import styles from 'components/Header/styles.module.scss';
import Localization from 'components/Localization/Localization';
import SessionTime from 'components/SessionTime';
import Timezone from 'components/Timezone/Timezone';
import { BannerNames, ROUTER_BASE_URL } from 'constants/app';
import { ACCOUNT_BASE_URL, HOME_BASE_URL, MOBILE_LOGIN_BASE_URL } from 'constants/locations';
import {
  getDesktopSettingsLoginHeaderButtons,
  getDesktopSettingsPwdChangeBtn,
  getDeviceLangDropdownAuth,
  getDeviceLangDropdownNoAuth,
  getIsIframeEnabled,
  getIsPassiveSessionEnabled,
  getMobileAccountButtonEnabled
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getBannerByName } from 'redux/modules/banners/selectors';
import { getIsSelfExclusionEnabled, getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { getUserSessionInfo } from 'redux/modules/user/selectors';
import { getDefaultLinkTarget } from 'utils/navigation';

import BackButton from '../BackButton';
import HeaderLogo from '../HeaderLogo';
import MobileHeaderTop from '../MobileHeaderTop';
import MobileTopMenu from '../MobileTopMenu';
import Setting from '../Setting';
import UserInfo from '../UserInfo';

interface HeaderTopProps {
  isMobile: boolean;
}

const HeaderTop = ({ isMobile }: HeaderTopProps) => {
  const sessionInfo = useSelector(getUserSessionInfo);

  const loginHeaderButtons = useSelector(getDesktopSettingsLoginHeaderButtons);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const passiveSessionEnabled = useSelector(getIsPassiveSessionEnabled);
  const pwdChangeBtn = useSelector(getDesktopSettingsPwdChangeBtn);
  const mobileAccountButtonEnabled = useSelector(getMobileAccountButtonEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const logoBannerObj = useSelector(getBannerByName(BannerNames.logo));
  const langDropdownAuth = useSelector(getDeviceLangDropdownAuth(isMobile));
  const langDropdownNoAuth = useSelector(getDeviceLangDropdownNoAuth(isMobile));
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);
  const isLoginPage = location.pathname.includes(MOBILE_LOGIN_BASE_URL);

  const showLangDropdown = isLoggedIn ? langDropdownAuth : langDropdownNoAuth;

  const logo = logoBannerObj && logoBannerObj.bannerImages ? logoBannerObj?.bannerImages[0] : null;
  const isSessionCounterEnabled = passiveSessionEnabled && sessionInfo?.startSessionDate;
  const isShowChangePasswordIcon = pwdChangeBtn && isLoggedIn;

  if ((isAccountPage || isSelfExclusionEnabled || isTimeOutEnabled) && isMobile && isLoggedIn) {
    return <MobileHeaderTop />;
  }

  return (
    <div
      className={classNames(styles.header__topWrap, {
        [styles.header__topWrap__mobile]: isMobile,
        [styles.header__topWrap__mobile__login]: isMobile && isLoginPage
      })}
    >
      {isMobile && !isLoginPage && <BackButton />}
      {logo?.imageUrl ? (
        <div
          className={classNames(styles.header__logoLink__container, {
            [styles.header__logoLink__container__mobile]: isMobile
          })}
        >
          {logo.link.startsWith('http') ? (
            <a
              href={logo.link}
              target={getDefaultLinkTarget({
                url: logo.link,
                absolutePath: logo.absolutePathEnabled,
                isIframeEnabled: iframeEnabled,
                currentTarget: logo.openInNewTab ? '_blank' : '',
                openInNewTab: logo.openInNewTab
              })}
              className={classNames({ [styles.header__logoLink]: isMobile })}
            >
              <HeaderLogo logo={logo} isMobile={isMobile} />
            </a>
          ) : (
            <Link
              to={logo?.link?.replace(ROUTER_BASE_URL, '') || HOME_BASE_URL}
              target={getDefaultLinkTarget({
                url: logo.link,
                absolutePath: logo.absolutePathEnabled,
                isIframeEnabled: iframeEnabled,
                currentTarget: logo.openInNewTab ? '_blank' : '',
                openInNewTab: logo.openInNewTab
              })}
              className={classNames({ [styles.header__logoLink]: isMobile })}
            >
              <HeaderLogo logo={logo} isMobile={isMobile} />
            </Link>
          )}
        </div>
      ) : (
        <div
          className={classNames(styles.header__logoEmptySpace, {
            [styles.header__logoEmptySpace__mobile]: isMobile
          })}
        />
      )}
      {!isMobile && isSessionCounterEnabled && <SessionTime isMobile={isMobile} />}
      <div
        className={classNames(styles.header__timezoneLocaleLogin, {
          [styles.header__timezoneLocaleLogin__mobile]: isMobile
        })}
      >
        {!isMobile && (
          <div className={styles.header__timezoneAndLocalization}>
            {isShowChangePasswordIcon && <Setting />}
            <div className="biab_timezone-container">
              <Timezone />
            </div>
            {showLangDropdown && (
              <div className={classNames(styles.header__localizationContainer, 'biab_locale-container')}>
                <Localization />
              </div>
            )}
          </div>
        )}
        <div id="biab_login-block" className="biab_login-block loginBlock">
          {!isMobile ? (
            <>{isLoggedIn ? <UserInfo /> : loginHeaderButtons ? <LoginForm /> : null}</>
          ) : mobileAccountButtonEnabled && !isLoginPage ? (
            <MobileTopMenu />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
