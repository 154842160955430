import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccountSettingsQuickBets from 'components/AccountSettingsQuickBets/AccountSettingsQuickBets';

import styles from './QuickBetsView.module.scss';

type QuickBetsViewProps = {
  onGoBack: () => void;
};

const QuickBetsView = ({ onGoBack }: QuickBetsViewProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <div className={styles.quickBets__header}>
        <button onClick={onGoBack} className={styles.quickBets__header__goBack}>
          <i className="biab_custom-icon-chevron-left-24" />
        </button>
        <h2 className={styles.quickBets__header__label}>{t('account.settings.quickBets.newTitle')}</h2>
        <div className={styles.quickBets__header__empty} />
      </div>
      <div className={styles.quickBets__content}>
        <AccountSettingsQuickBets
          isMobile
          onCancel={() => {
            onGoBack();
            window.scrollTo({ top: 0 });
          }}
        />
      </div>
    </>
  );
};

export default QuickBetsView;
