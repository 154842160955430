import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import {
  getMobileSettingsConfirmBetsBeforePlace,
  getMobileSettingsFillOrKill,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { setSettings } from 'redux/modules/user';
import {
  getAccountSettingsConfirmBetsBeforePlacement,
  getIsUserAccountSettings,
  getUserAccountSettingsAsianViewAutoRefresh,
  getUserAccountSettingsFillOrKillEnabled
} from 'redux/modules/user/selectors';
import { SettingsBettingCheckbox } from 'types/navigation';

import styles from './BetSlipSettingsSection.module.scss';

const BetSlipSettingsSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fillOrKill = useSelector(getMobileSettingsFillOrKill);
  const confirmBetsBeforePlace = useSelector(getMobileSettingsConfirmBetsBeforePlace);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const confirmBetsBeforePlacement = useSelector(getAccountSettingsConfirmBetsBeforePlacement);
  const fillOrKillEnabled = useSelector(getUserAccountSettingsFillOrKillEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isEnabledAutoRefresh = useSelector(getUserAccountSettingsAsianViewAutoRefresh);

  const [settingsValues, setSettingsValues] = useState({
    [SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT]: confirmBetsBeforePlacement,
    [SettingsBettingCheckbox.AUTO_REFRESH]: isEnabledAutoRefresh,
    [SettingsBettingCheckbox.FILL_OR_KILL]: fillOrKillEnabled
  });

  const displaySettings = confirmBetsBeforePlace || isPNCEnabled || fillOrKill;

  if (!displaySettings) {
    return null;
  }

  const settings = [
    {
      isVisible: confirmBetsBeforePlace,
      labelKey: 'account.settings.confirmBetsBeforePlacement',
      value: settingsValues[SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT],
      name: SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT
    },
    {
      isVisible: isPNCEnabled,
      labelKey: 'asianView.labels.betslip.autoRefresh',
      value: settingsValues[SettingsBettingCheckbox.AUTO_REFRESH],
      name: SettingsBettingCheckbox.AUTO_REFRESH
    },
    {
      isVisible: fillOrKill && !isPNCEnabled,
      labelKey: 'account.settings.fillOrKill.enable',
      value: settingsValues[SettingsBettingCheckbox.FILL_OR_KILL],
      name: SettingsBettingCheckbox.FILL_OR_KILL
    }
  ];

  const handleChangeSettingsValue = (name: SettingsBettingCheckbox) => (event: ChangeEvent<HTMLInputElement>) => {
    setSettingsValues(prevState => {
      return {
        ...prevState,
        [name]: event.target.checked
      };
    });

    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            [name]: event.target.checked
          }
        })
      );
    }
  };

  return (
    <div className={styles.settings}>
      <h4 className={styles.settings__title}>{t('account.settings.preferences.betslipSettings')}</h4>
      {settings.map(({ isVisible, labelKey, value, name }) => {
        if (isVisible) {
          return (
            <label key={name} className={styles.settings__item}>
              <p className={styles.settings__item__label}>{t(labelKey)}</p>
              <ToggleSwitch
                checked={value}
                onChange={handleChangeSettingsValue(name)}
                containerClassName={styles.settings__item__toggle}
              />
            </label>
          );
        }

        return null;
      })}
    </div>
  );
};

export default BetSlipSettingsSection;
