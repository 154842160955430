import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

const getCashOutCounter = ({ asianViewCashOutCounter }: AppState) => asianViewCashOutCounter;

export const getAsianViewCashOutsAmount = ({ asianViewCashOutCounter }: AppState) =>
  asianViewCashOutCounter.allCashOutsAmount;

export const getAsianViewCashOutCounterByEventId =
  (sportId: string, eventId: string) =>
  ({ asianViewCashOutCounter }: AppState) =>
    asianViewCashOutCounter.eventCashOutCountersEventId[sportId]?.[eventId] ?? 0;

export const getIsAVCashOutOutrightEnabled = (sportId: string, competitionId: string, marketId: string) =>
  createSelector(getCashOutCounter, ({ cashOuts }) => {
    const { outrightCashOuts } = cashOuts[sportId] ?? {};

    const outrightCashOutsByCompetitionId = outrightCashOuts?.[competitionId] ?? [];

    return outrightCashOutsByCompetitionId.includes(marketId);
  });

export const getAsianViewCashOutMarketIds =
  (sportId?: string, eventId?: string) =>
  ({ asianViewCashOutCounter }: AppState) => {
    return sportId && eventId
      ? asianViewCashOutCounter.eventMarketIds[sportId]?.[eventId] ?? []
      : asianViewCashOutCounter.marketIds;
  };
