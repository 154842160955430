import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useUnmount } from 'usehooks-ts';

import CashOutButton from 'components/CashOutButton';
import EventHeaderHandler from 'components/EventHeaderHandler';
import FavoriteStar from 'components/FavoriteStar';
import MarketInfoRow from 'components/MarketInfoRow';
import MobileAllMarketsLink from 'components/MobileAllMarketsLink';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { FAVORITES_TYPES } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import useMarketDepth from 'hooks/useMarketDepth';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import CashOutQuotesAndAutoCashOutsIntervalsInjection from 'injections/CashOutQuotesAndAutoCashOutsIntervalsInjection';
import { getAppDevice, getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { Devices, IEventViewSettings } from 'redux/modules/appConfigs/type';
import { resetCashOut } from 'redux/modules/cashOut';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { resetMarketsPricesByMarket } from 'redux/modules/marketsPrices';
import { getIsMarketInPlayByMarketPricesId, getMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { fetchTop5ViewDetails, fetchTop5ViewEventRules, fetchTop5ViewMarketRules } from 'redux/modules/top5View';
import {
  getAllTop5ViewMarkets,
  getTop5Markets,
  getTop5ViewLines,
  getTop5ViewLinesText,
  getTop5ViewLoader,
  getTop5ViewOtherTopMatchedMarkets,
  getTop5ViewReset,
  getTop5ViewRules,
  getTop5ViewRulesLoading,
  getTop5ViewShowAll,
  getTop5ViewTopMatchedMarket
} from 'redux/modules/top5View/selectors';
import { CookieNames, LiveWidgetHeaderType, PageBlocks, PlacementPage } from 'types';
import { DesktopTopMarketViewType, EMarketDepthView } from 'types/markets';
import { getBooleanValue } from 'utils';

import AllMarkets from './components/AllMarkets';
import DesktopOtherMarketsTitle from './components/DesktopOtherMarketsTitle';
import DesktopShowMore from './components/DesktopShowMore';
import DesktopTopMarket from './components/DesktopTopMarket';
import MobileMarketsTabs from './components/MobileMarketsTabs';
import MobileRulesView from './components/MobileRulesView';
import Top5ViewMarket from './components/Top5ViewMarket';

import styles from './styles.module.scss';

type Top5ViewProps = {
  /**
   * View setting for displayed event
   */
  eventSettings: IEventViewSettings;
};

export const ALL_MARKETS_TAB = 'allMarkets';

const Top5View = ({ eventSettings }: Top5ViewProps) => {
  const dispatch = useDispatch();
  const { eventId = '', sportId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.TOP_MARKETS_IS_OPENED]);

  const device = useSelector(getAppDevice);
  const loading = useSelector(getTop5ViewLoader);
  const topMatchedMarket = useSelector(getTop5ViewTopMatchedMarket);
  const rules = useSelector(getTop5ViewRules);
  const rulesLoading = useSelector(getTop5ViewRulesLoading);
  const otherTopMatchedMarkets = useSelector(getTop5ViewOtherTopMatchedMarkets);
  const lines = useSelector(getTop5ViewLines);
  const linesText = useSelector(getTop5ViewLinesText);
  const showAll = useSelector(getTop5ViewShowAll);
  const reset = useSelector(getTop5ViewReset);
  const marketPricesId = useSelector(getMarketPricesId(topMatchedMarket?.marketId ?? ''));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(topMatchedMarket?.marketId ?? ''));
  const allTopMarkets = useSelector(getAllTop5ViewMarkets);
  const top5Markets = useSelector(getTop5Markets);
  const isFavorite = useSelector(getFavoriteById(topMatchedMarket?.marketId ?? ''));
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const [showMore, setShowMore] = useState(false);
  const [isRulesView, setIsRulesView] = useState(false);

  const { target: marketWrapperRef, addedHeight } = useAddHeightForFooter();
  const { isDepthEnabled, isLandscape } = useMarketDepth(EMarketDepthView.TOP_5);

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: topMatchedMarket?.marketId,
    eventId,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  const isOpenedTopMarkets = cookies.BIAB_TOP_MARKETS_IS_OPENED
    ? getBooleanValue(cookies.BIAB_TOP_MARKETS_IS_OPENED ?? '')
    : true;
  const isDesktop = device === Devices.DESKTOP;
  const top5Tab = searchParams.get('tab');
  const isAllMarketsTab = top5Tab === ALL_MARKETS_TAB;
  const showAllMarketsContent = isAllMarketsTab && !isDesktop;

  const favoriteData = {
    entryId: topMatchedMarket?.marketId ?? '',
    entryName: topMatchedMarket?.marketName ?? '',
    entryType: FAVORITES_TYPES.market,
    starred: !isFavorite,
    sportId
  };

  useEffect(() => {
    dispatch(fetchTop5ViewDetails(eventId));
  }, [eventId]);

  useEffect(() => {
    if (isAllMarketsTab && isDesktop) {
      searchParams.delete('tab');
      setSearchParams(searchParams);
    }
  }, [isAllMarketsTab, isDesktop]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [top5Tab]);

  useEffect(() => {
    return () => {
      dispatch(resetCashOut());
    };
  }, [topMatchedMarket?.marketId]);

  useUnmount(() => {
    if (!isDesktop) {
      currentMarkets.map(topMarket => {
        dispatch(resetMarketsPricesByMarket(topMarket.marketId));
      });
    }
  });

  const displayedOtherMarkets = useMemo(
    () => (!showMore ? otherTopMatchedMarkets.slice(0, 4) : otherTopMatchedMarkets),
    [otherTopMatchedMarkets, showMore]
  );
  const currentMarkets = useMemo(
    () => (topMatchedMarket ? [topMatchedMarket, ...displayedOtherMarkets] : displayedOtherMarkets),
    [topMatchedMarket, displayedOtherMarkets]
  );
  const handleFetchTopMarketRules = () => {
    if (isDesktop) {
      dispatch(fetchTop5ViewMarketRules(topMatchedMarket?.marketId ?? ''));
    } else {
      dispatch(fetchTop5ViewEventRules(topMatchedMarket?.event.id ?? ''));
      setIsRulesView(true);
    }
  };

  const handleToggleShowMore = () => {
    setShowMore(prevState => !prevState);
  };

  const handleToggleOtherMarkets = () => {
    setCookie(CookieNames.TOP_MARKETS_IS_OPENED, !isOpenedTopMarkets, { path: '/' });
  };

  return (
    <div className="skeleton_page_wrapper">
      {topMatchedMarket?.marketId && (
        <CashOutQuotesAndAutoCashOutsIntervalsInjection
          marketId={topMatchedMarket.marketId}
          cashOutEnabled={topMatchedMarket.cashOutEnabled}
        />
      )}
      <div ref={ref} className={classNames('biab_market', styles.top5View__wrapper)}>
        <div
          id="multiMarketContainer"
          ref={marketWrapperRef}
          data-market-id={topMatchedMarket?.marketId}
          data-event-id={eventId}
          data-market-prices={true}
        >
          {loading ? (
            <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} />
          ) : (
            <>
              {topMatchedMarket && marketPricesId && (
                <>
                  {!isAllMarketsTab && (
                    <EventHeaderHandler
                      eventId={eventId}
                      sportId={sportId}
                      startDate={topMatchedMarket.marketStartTime}
                      inPlay={isInPlay}
                      title={topMatchedMarket.event.name}
                      type={LiveWidgetHeaderType.MARKET}
                      videoStreamingEnabled={topMatchedMarket.event.videoStreamingEnabled}
                      matchStatEnabled={topMatchedMarket.event.matchStatEnabled}
                    />
                  )}
                  {isDesktop && (
                    <div className={classNames('biab_market-name', styles.top5View__marketName)}>
                      <div className={styles.top5View__favouriteIcon}>{<FavoriteStar entryData={favoriteData} />}</div>
                      {topMatchedMarket.marketName}
                    </div>
                  )}
                  {!isDesktop && !isRulesView && !isAllMarketsTab && (
                    <MobileMarketsTabs isAllMarketsTab={isAllMarketsTab} />
                  )}
                  {!isRulesView && !showAllMarketsContent && (
                    <MarketInfoRow
                      marketName={topMatchedMarket.marketName}
                      sportId={sportId}
                      isCashOutEnabled={topMatchedMarket.cashOutEnabled}
                      marketStartTime={topMatchedMarket.marketStartTime}
                      marketId={topMatchedMarket.marketId}
                      marketsRules={rules}
                      rulesLoading={rulesLoading}
                      onFetchMarketRules={handleFetchTopMarketRules}
                      mobileViewOptions={{
                        noFavourite: true,
                        refreshAfterRules: true,
                        noBottom: true,
                        noCashOut: true,
                        noRulesModal: !isDesktop,
                        noCancelAllBetsBtn: true
                      }}
                    />
                  )}
                  {topMatchedMarket.cashOutEnabled && isDesktop && (
                    <CashOutButton
                      marketId={topMatchedMarket.marketId}
                      partialCashOutEnabled={topMatchedMarket.partialCashOutEnabled}
                    />
                  )}
                  {isDesktop && (
                    <DesktopTopMarket
                      topMarket={topMatchedMarket}
                      lines={lines}
                      linesText={linesText}
                      viewType={DesktopTopMarketViewType.TOP_5}
                      showAll={showAll}
                      reset={reset}
                      pageBlock={PageBlocks.TOP_5_VIEW_TOP_MARKET}
                      redirectToSingleMarketPage
                      isDepthEnabled={isDepthEnabled}
                      isLandscape={isLandscape}
                      page={PlacementPage.TOP_5_VIEW}
                    />
                  )}
                </>
              )}
              {!isRulesView && !showAllMarketsContent && (
                <div
                  className={classNames(styles.top5View__otherMarkets, {
                    [styles.top5View__otherMarkets__mobile]: !isDesktop
                  })}
                >
                  {isDesktop && (
                    <DesktopOtherMarketsTitle
                      onToggleOtherMarkets={handleToggleOtherMarkets}
                      isOpenedTopMarkets={isOpenedTopMarkets}
                      titleKey="market.topFive.otherMarketsSection"
                    />
                  )}
                  {(isDesktop ? !otherTopMatchedMarkets.length : !allTopMarkets.length) && isOpenedTopMarkets && (
                    <p className={styles.top5View__noMarkets}>{t('market.topFive.noMarkets')}</p>
                  )}
                  {isOpenedTopMarkets &&
                    marketPricesId &&
                    (isDesktop ? !!otherTopMatchedMarkets.length : !!currentMarkets.length) && (
                      <div
                        className={classNames({
                          [styles.top5View__otherMarkets__sections__2columns]: eventSettings.twoColumnView && isDesktop,
                          [styles.top5View__otherMarkets__sections__mobile]: !isDesktop
                        })}
                      >
                        {(isDesktop ? displayedOtherMarkets : currentMarkets).map((topMarket, index) => (
                          <Top5ViewMarket
                            key={topMarket.marketId}
                            topMarket={topMarket}
                            eventSettings={eventSettings}
                            isDesktop={isDesktop}
                            sportId={sportId}
                            rulesLoading={rulesLoading}
                            index={index}
                            rules={rules}
                            isLandscape={isLandscape}
                            isDepthEnabled={isDepthEnabled}
                          />
                        ))}
                      </div>
                    )}
                </div>
              )}
              {isRulesView && !showAllMarketsContent && (
                <MobileRulesView setIsRulesView={setIsRulesView} markets={top5Markets} />
              )}
              {showAllMarketsContent && <AllMarkets />}
              {isDesktop && allTopMarkets.length > 5 && isOpenedTopMarkets && (
                <DesktopShowMore showMore={showMore} onToggleShowMore={handleToggleShowMore} />
              )}
              {!isDesktop && !isRulesView && !showAllMarketsContent && (
                <MobileAllMarketsLink
                  to={{ pathname: `${SPORT_BASE_URL}/${sportId}/event/${eventId}`, search: 'tab=allMarkets' }}
                />
              )}
            </>
          )}
        </div>
        {!isIframeEnabled && addedHeight > 0 && <div style={!isIframeEnabled ? { height: addedHeight } : {}} />}
      </div>
    </div>
  );
};

export default Top5View;
