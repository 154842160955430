import classNames from 'classnames';

import { mobileComponents } from 'constants/branding';
import { MY_BETS_PAGINATION_LANDSCAPE_WIDTH } from 'constants/myBets';
import useWindowSize from 'hooks/useWindowSize';
import { TPageSizeOption } from 'types/myBets';

import styles from './styles.module.scss';

type TPageSizeOptionsProps = {
  options: TPageSizeOption[];
  selectedSize?: TPageSizeOption;
  onChange: (option: TPageSizeOption) => void;
};

const PageSizeOptions = ({ options, selectedSize, onChange }: TPageSizeOptionsProps) => {
  const { windowWidth } = useWindowSize();

  const isLandscape = windowWidth >= MY_BETS_PAGINATION_LANDSCAPE_WIDTH;

  return (
    <div className={classNames(styles.perPageOptions, mobileComponents.SWITCH_TABS)}>
      {options.map(option => (
        <button
          type="button"
          key={`perPageOption_${option.id}`}
          className={classNames(styles.perPageOptions__option, {
            [styles.perPageOptions__option__selected]: option.value === selectedSize?.value,
            [styles.perPageOptions__option__landscape]: isLandscape,
            [mobileComponents.SELECTED]: option.value === selectedSize?.value
          })}
          onClick={() => onChange(option)}
        >
          {option.value}
        </button>
      ))}
    </div>
  );
};

export default PageSizeOptions;
