import classNames from 'classnames';

import Skeleton from 'components/Skeleton';

import styles from './AccountStatementMobileItemSkeleton.module.scss';

const AccountStatementMobileItemSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <div className={styles.skeleton__left}>
        {new Array(3).fill(0).map((_, index) => {
          return (
            <Skeleton
              key={index}
              className={classNames(styles.skeleton__item, {
                [styles.skeleton__item__top]: index === 0
              })}
            />
          );
        })}
      </div>
      <Skeleton className={styles.skeleton__item__right} />
    </div>
  );
};
export default AccountStatementMobileItemSkeleton;
