import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { myBetsDateFormat, myBetsDateFormatUS, MyBetsPage, Period, SINGLE_WALLET } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useDevice from 'hooks/useDevice';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { TPeriods } from 'types';
import { addDays, getDaysDifference, getThreeMonthsAgo, getTodayDate, getTodayEndDate } from 'utils/date';

import DateRangePicker from './components/DateRangePicker';
import SelectStatementFilter from './components/SelectStatementFilter/SelectStatementFilter';

import styles from './MyBetsDatePickers.module.scss';

type TDatePickersProps = {
  myBetsPage: MyBetsPage;
  statementFilterValue?: string;
  setStatementFilterValue?: (value: string) => void;
  children?: ReactNode;
  customClass?: string;
  customStartDate?: Date;
  customEndDate?: Date;
  customHandlePeriodDropdown?: (period: TPeriods) => void;
  customHandleSetStartDate?: (date: Date) => void;
  customHandleSetEndDate?: (date: Date) => void;
};

const DatePickers = ({
  myBetsPage = MyBetsPage.ExchangeBets,
  statementFilterValue,
  setStatementFilterValue,
  children,
  customClass,
  customStartDate,
  customEndDate,
  customHandlePeriodDropdown,
  customHandleSetStartDate,
  customHandleSetEndDate
}: TDatePickersProps) => {
  const { t } = useTranslation();

  const { timezoneOffset, bettingDay, startDate, endDate, handlePeriodDropdown, handleSetDateRange } =
    useMyBetsPagesNavigation();

  const { periodType } = useMyBetsFilters();
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);
  const { isMyBetsPage } = useAccountUrlParams();
  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const { isMobile } = useDevice();

  const todayDate = getTodayEndDate(timezoneOffset || timezone, timezoneCookieEnabled || bettingDay);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);
  const startDateAdjusted = addDays(customStartDate ?? startDate, dayDifference);
  const endDateAdjusted = addDays(customEndDate ?? endDate, dayDifference);

  const minDate = isMyBetsPage && periodType === Period.Open ? undefined : getThreeMonthsAgo();
  const isSingleWallet = walletIntegrationType === SINGLE_WALLET;

  const isAccountStatement = myBetsPage === MyBetsPage.AccountStatement;

  const handleChangeStatementFilterValue = (value: string) => {
    if (setStatementFilterValue) {
      setStatementFilterValue(value);
    }
  };

  const DatePickerBlock = (
    <DateRangePicker
      calendarStartDay={americanDateFormatEnabled ? 0 : 1}
      dateFormat={americanDateFormatEnabled ? myBetsDateFormatUS : myBetsDateFormat}
      startDate={startDateAdjusted}
      endDate={endDateAdjusted}
      minDate={minDate ?? undefined}
      maxDate={todayDate}
      setDateRange={handleSetDateRange}
      setStartDate={customHandleSetStartDate}
      setEndDate={customHandleSetEndDate}
      setPeriod={customHandlePeriodDropdown ?? handlePeriodDropdown}
      dayShift={dayDifference}
      disabled={isMyBetsPage && isCancellingBets}
    />
  );

  return (
    <>
      {isAccountStatement && isMobile ? (
        <div className={styles.accountStmntMobHolderWrapper}>
          <div
            className={classNames(styles.mobileAccStatementDatepickerHolder, {
              [styles.mobileAccStatementDatepickerHolder__mobile]: isMobile
            })}
          >
            <div className={classNames('biab_datepicker-form', styles.datepickerForm)}>
              <div
                className={classNames(styles.datepickerItemHolder, {
                  [styles.datepickerItemHolder__mobile]: isMobile
                })}
              >
                <div className={styles.datepickerItemInner}>
                  <div>
                    <div className={styles.mobileStatementDatepickerBlock}>{DatePickerBlock}</div>
                    <div className={classNames('biab_datepicker-item', styles.resetDatepickerHolder)} />
                  </div>
                  {!isSingleWallet && (
                    <div
                      className={classNames(styles.statementFilter, {
                        [styles.statementFilter__mobile]: isMobile
                      })}
                    >
                      <div
                        className={classNames(styles.statementFilter__label, 'biab_deposit-filter-label', {
                          [styles.statementFilter__mobile__label]: isMobile
                        })}
                      >
                        {t('account.statement.labels.filterBy')}
                      </div>
                      <div
                        className={classNames(styles.statementFilter__selectHolder, 'biab_deposit-filter', {
                          [styles.statementFilter__mobile__selectHolder]: isMobile
                        })}
                      >
                        <div className={classNames('biab_account-page ui-selectmenu-menu', styles.selectWrapper)}>
                          <SelectStatementFilter
                            handleChangeStatementFilterValue={handleChangeStatementFilterValue}
                            statementFilterValue={statementFilterValue}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(styles.datepickersAndCancelUnmatched, customClass, {
            [styles.datepickersAndCancelUnmatched__accountStatement]: isAccountStatement,
            [styles.datepickersAndCancelUnmatched__mobile]: isMobile
          })}
        >
          {isMobile ? (
            <>
              {!isAccountStatement && (
                <div className={classNames('biab_datepicker-holder-page', styles.mobileDatepickersWrapper)}>
                  <div className={classNames('biab_datepicker-form', styles.datepickerForm)}>
                    <div className={styles.datepickerItemHolder}>
                      <div className={styles.datepickers}>{DatePickerBlock}</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className={classNames(styles.datepickers, 'biab_datepicker-form')}>{DatePickerBlock}</div>
          )}
          {children}
        </div>
      )}
    </>
  );
};

export default DatePickers;
