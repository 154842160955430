import { useSelector } from 'react-redux';

import { accountPagesBranding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import {
  getDesktopSettingsMyBetsLinks,
  getIsIframeEnabled,
  getMobileSettingsMyBetsLinks
} from 'redux/modules/appConfigs/selectors';
import { checkUrlLinkFor, getDefaultLinkTarget } from 'utils/navigation';

import styles from './styles.module.scss';

const MyBetsLinks = () => {
  const { isMobile } = useDevice();

  const myBetsLinks = useSelector(getDesktopSettingsMyBetsLinks);
  const myBetsLinksMobile = useSelector(getMobileSettingsMyBetsLinks);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const links = isMobile ? myBetsLinksMobile : myBetsLinks;

  return (
    <>
      {links?.map(link => (
        <div key={link.url} className={styles.customLinks}>
          <span>{link.description}</span>
          <a
            className={accountPagesBranding.CUSTOM_LINK}
            href={link.absolutePath ? link.url : checkUrlLinkFor(link.url)}
            target={getDefaultLinkTarget({
              ...link,
              isIframeEnabled,
              currentTarget: link.openInNewTab ? '_blank' : '_self'
            })}
          >
            {link.label}
          </a>
        </div>
      ))}
    </>
  );
};

export default MyBetsLinks;
