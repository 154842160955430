import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

import { getTranslation } from '../appConfigs/selectors';

export const getSingleMarketInfo = ({ market }: AppState) => market.market;
export const getMarketsDropdownList = ({ market }: AppState) => market.dropdownMarkets;
export const getMarketLines = ({ market }: AppState) => market.lines;
export const getMarketLinesText = ({ market }: AppState) => market.linesText;
export const getMarketShowAll = ({ market }: AppState) => market.showAll;
export const getMarketReset = ({ market }: AppState) => market.reset;
export const getMarketScrollWidth = ({ market }: AppState) => market.scrollWidth;
export const getRaceChildren = ({ market }: AppState) => market.raceOptions?.children;
export const getRaceTabs = createSelector(getRaceChildren, getTranslation, (children = [], translation) => {
  return children.map(({ id, translations }) => ({ id, name: translations[translation] }));
});
export const getSingleMarketRules = ({ market }: AppState) => market.rules;
export const getSingleMarketRulesLoading = ({ market }: AppState) => market.rulesLoading;
export const getSingleMarketLoading = ({ market }: AppState) => market.loading;
export const getSingleMarketEventId = ({ market }: AppState) => market.market?.event.id;
export const getSingleMarketMarketType = ({ market }: AppState) => market.market?.description.marketType;
