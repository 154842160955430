import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { PostMessageTypes } from 'constants/app';
import { mobileAccountPagesBranding } from 'constants/branding';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled
} from 'redux/modules/appConfigs/selectors';
import { hideMobileBetDetails, setMobileStatementItemDetails } from 'redux/modules/myBets';
import { getIsMobileStatementItemDetails, isShowMobileBetDetails } from 'redux/modules/myBets/selectors';
import { sendIframeMessages } from 'utils/iframe';

import styles from './styles.module.scss';

interface MobileAccountBackBtnProps {
  classes?: {
    container?: string;
    icon?: string;
  };
}

const MobileAccountBackBtn = ({ classes }: MobileAccountBackBtnProps) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isMobileStatementItemDetails = useSelector(getIsMobileStatementItemDetails);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL) || (!isSportsEnabled && !isGamesEnabled);
  const isGamesPage = pathname.includes(GAMES_BASE_URL) || (!isSportsEnabled && !isAsianViewEnabled);

  const url = `${(isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || ''}${ACCOUNT_BASE_URL}`;

  return (
    <>
      {isDetailsOpen || isMobileStatementItemDetails ? (
        <button
          className={classNames(styles.goBack__button, classes?.container ?? '', mobileAccountPagesBranding.BUTTON)}
          onClick={() => {
            if (isDetailsOpen) {
              dispatch(hideMobileBetDetails());
            } else if (isMobileStatementItemDetails) {
              dispatch(setMobileStatementItemDetails(null));
            }

            window.scrollTo({ top: 0 });

            if (isIframeEnabled) {
              sendIframeMessages({ type: PostMessageTypes.ACCOUNT_BETTING_PAGE_SCROLL_TO_TOP });
            }
          }}
        >
          <i className={classNames('biab_custom-icon-chevron-left-24', styles.goBack__icon, classes?.icon ?? '')} />
        </button>
      ) : (
        <Link
          className={classNames(styles.goBack__button, classes?.container ?? '', mobileAccountPagesBranding.BUTTON)}
          to={url}
        >
          <i className={classNames('biab_custom-icon-chevron-left-24', styles.goBack__icon, classes?.icon ?? '')} />
        </Link>
      )}
    </>
  );
};

export default MobileAccountBackBtn;
