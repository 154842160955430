import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MOBILE_MAX_DISPLAY_SIZE, SLICES_NAMES } from 'constants/app';
import {
  BetTypes,
  defaultTimeRange,
  DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME,
  MIN_ITEMS_PAGINATION,
  Period,
  StatementTransactionTypes
} from 'constants/myBets';
import { DropdownItem, FailureActionPayload } from 'types';
import { AccountStatementSortByField } from 'types/myBets';
import { getTodayDate, getTodayStartDate } from 'utils/date';

import {
  AccountStatementSorting,
  Game,
  ICancelBetPayload,
  IGetPLPayload,
  IGetPLTotalPayload,
  MobileStatementSorting,
  MyBets,
  SortByFields,
  Sport,
  StatementTransactionType,
  SuccessAccountProduct,
  TAccountProductsPayload,
  TCancelBetResponse,
  TExportNotification,
  TFetchStatementPayload,
  TGetBetPayload,
  TimeRange,
  TMobileFiltersPayload,
  TMobileSorting,
  TMyBetsContentItem,
  TMyBetsResponse,
  TPLResponse,
  TPLTotalResponse,
  TSortByType,
  TStatementContentItem,
  TStatementResponse
} from './type';

const transactionTypesFromLocalStorage = localStorage.getItem(
  DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME
);

const initialState: MyBets = {
  data: {
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    content: []
  },
  statementData: {
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    content: []
  },
  PLData: {
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 1,
    content: []
  },
  isProfitLossFirstLoaded: false,
  isMyBetsFirstLoaded: false,
  isStatementFirstLoaded: false,
  PLTotal: 0,
  loadingPLTotal: false,
  PLTotalEvent: 0,
  loadingPLTotalEvent: false,
  isOnMyBetsPage: false,
  loading: false,
  chargesLoading: false,
  plDataLoading: false,
  cancelAllUnmatchedBets: false,
  cancelAllUnmatchedBetsActionId: null,
  showBetDetails: false,
  bet: undefined,
  betUpdated: false,
  cancelledBet: undefined,
  startDate: undefined,
  endDate: undefined,
  timeRange: defaultTimeRange,
  pageSize: MIN_ITEMS_PAGINATION,
  pageNumber: 0,
  charges: [],
  sportsList: [],
  loadingSportsList: false,
  gamesList: [],
  loadingGamesList: false,
  multiCurrencyValue: null,
  loadingProducts: false,
  areProductsLoaded: false,
  accountProducts: {
    allMyBets: null,
    allProfitAndLoss: null,
    allAccountStatement: null
  },
  isCustomPeriod: false,
  error: null,
  eventTypeFilter: undefined,
  sortBy: {
    [SortByFields.DATE]: location.pathname.includes(Period.Settled.toLowerCase()) ? 'desc' : null,
    [SortByFields.PLACED_DATE]: location.pathname.includes(Period.Open.toLowerCase()) ? 'desc' : null,
    [SortByFields.START_DATE]: null,
    [SortByFields.SETTLED_DATE]: null
  },
  isMobileFiltersOpen: false,
  mobileFilters: {},
  mobileSorting: {
    type: location.pathname.includes(Period.Settled.toLowerCase()) ? SortByFields.DATE : SortByFields.PLACED_DATE,
    order: 'desc'
  },
  accountStatementFilters: {
    transactions:
      window.innerWidth > MOBILE_MAX_DISPLAY_SIZE && transactionTypesFromLocalStorage
        ? JSON.parse(transactionTypesFromLocalStorage)
        : [
            StatementTransactionTypes.BETTING,
            StatementTransactionTypes.DEPOSIT_WITHDRAWAL,
            StatementTransactionTypes.COMMISSION,
            StatementTransactionTypes.RESETTLEMENT_CORRECTION
          ]
  },
  statementDataLoading: false,
  mobileStatementItemDetails: null,
  mobileStatementSorting: null,
  myBetsCancelBetsState: {},
  search: null,
  exportNotification: { error: '', isDisabled: false, isDownloading: false }
};

const slice = createSlice({
  name: SLICES_NAMES.MY_BETS,
  initialState,
  reducers: {
    setStartDate: (state, { payload }: PayloadAction<Date>) => {
      state.startDate = payload;
      state.pageNumber = 0;
    },
    setTimeRange: (state, { payload }: PayloadAction<TimeRange>) => {
      state.timeRange = payload;
      state.pageNumber = 0;
    },
    setEndDate: (state, { payload }: PayloadAction<Date>) => {
      state.endDate = payload;
      state.pageNumber = 0;
    },
    setPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.pageNumber = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.pageSize = payload;
    },
    resetPageData: state => {
      state.pageSize = MIN_ITEMS_PAGINATION;
      state.pageNumber = 0;
      state.startDate = getTodayStartDate();
      state.endDate = getTodayDate();
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    fetchMyBets: (state, { payload }: PayloadAction<TGetBetPayload>) => {
      if (!payload.noResetMyBetsCancelState) {
        state.myBetsCancelBetsState = {};
      }
      state.loading = true;
      state.exportNotification = { error: '', isDisabled: false, isDownloading: false };

      if (payload.betsType === BetTypes.Unmatched) {
        state.betUpdated = false;
      } else {
        state.cancelAllUnmatchedBets = false;
        state.cancelledBet = undefined;
      }
    },
    successFetchMyBets: (state, { payload }: PayloadAction<TMyBetsResponse>) => {
      state.loading = false;
      state.data = payload;

      if (!state.isMyBetsFirstLoaded) {
        state.isMyBetsFirstLoaded = true;
      }
    },
    fetchStatement: (state, _: PayloadAction<TFetchStatementPayload>) => {
      state.statementDataLoading = true;
      state.exportNotification = { error: '', isDisabled: false, isDownloading: false };
    },
    successStatement: (state, { payload }: PayloadAction<TStatementResponse>) => {
      state.statementData = payload;
      state.statementDataLoading = false;

      if (!state.isStatementFirstLoaded) {
        state.isStatementFirstLoaded = true;
      }
    },
    failureFetchStatement: (state, { payload }: PayloadAction<FailureActionPayload>) => {
      state.statementDataLoading = false;
      state.error = payload;
    },
    fetchPL: (state, _: PayloadAction<IGetPLPayload>) => {
      state.plDataLoading = true;
      state.exportNotification = { error: '', isDisabled: false, isDownloading: false };
    },
    successPL: (state, { payload }: PayloadAction<TPLResponse>) => {
      state.plDataLoading = false;
      state.PLData = payload;

      if (!state.isProfitLossFirstLoaded) {
        state.isProfitLossFirstLoaded = true;
      }
    },
    fetchPLTotal: (state, _: PayloadAction<IGetPLTotalPayload>) => {
      state.loadingPLTotal = true;
    },
    successPLTotal: (state, { payload }: PayloadAction<TPLTotalResponse>) => {
      state.loadingPLTotal = false;
      state.PLTotal = payload.totalProfit;
    },
    fetchPLTotalForEvent: (state, _: PayloadAction<IGetPLTotalPayload>) => {
      state.loadingPLTotalEvent = true;
    },
    resetPLTotalForEvent: state => {
      state.PLTotalEvent = 0;
    },
    successPLTotalForEvent: (state, { payload }: PayloadAction<TPLTotalResponse>) => {
      state.loadingPLTotalEvent = false;
      state.PLTotalEvent = payload.totalProfit;
    },
    fetchProfitSports: (state, _: PayloadAction<string | undefined>) => {
      state.loadingSportsList = true;
    },
    successProfitSports: (state, { payload }: PayloadAction<Sport[]>) => {
      state.loadingSportsList = false;
      state.sportsList = payload;
    },
    fetchProfitGames: (state, _: PayloadAction<string | undefined>) => {
      state.loadingGamesList = true;
    },
    successProfitGames: (state, { payload }: PayloadAction<Game[]>) => {
      state.loadingSportsList = false;
      state.gamesList = payload;
    },
    showMobileBetDetails: (state, { payload }: PayloadAction<TMyBetsContentItem>) => {
      state.bet = payload;
      state.showBetDetails = true;
    },
    hideMobileBetDetails: state => {
      state.bet = undefined;
      state.showBetDetails = false;
    },
    setCancelAllUnmatchedBetsActionId: (state, { payload }: PayloadAction<number | null>) => {
      state.cancelAllUnmatchedBetsActionId = payload;
    },
    cancelBet: (state, { payload }: PayloadAction<ICancelBetPayload>) => {
      state.loading = true;
      state.cancelledBet = payload.bet;
    },
    successCancelBet: (state, _: PayloadAction<TCancelBetResponse>) => {
      state.betUpdated = true;
      state.loading = false;
    },
    fetchCharges: state => {
      state.chargesLoading = true;
    },
    successCharges: (state, { payload }: PayloadAction<string[]>) => {
      state.charges = payload;
      state.chargesLoading = false;
    },
    failureGetMyBetsData: (state, { payload }: PayloadAction<FailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    fetchAccountProducts: (state, _: PayloadAction<TAccountProductsPayload>) => {
      state.loadingProducts = true;
    },
    successAccountProducts: (state, { payload }: PayloadAction<SuccessAccountProduct>) => {
      Object.assign(state.accountProducts, payload);
      state.loadingProducts = false;
      state.areProductsLoaded = true;
    },
    failureAccountProducts: (state, _: PayloadAction<FailureActionPayload>) => {
      state.loadingProducts = false;
      state.areProductsLoaded = true;
    },
    resetAccountProducts: state => {
      state.accountProducts.allMyBets = null;
      state.accountProducts.allProfitAndLoss = null;
      state.accountProducts.allAccountStatement = null;
      state.loadingProducts = false;
      state.areProductsLoaded = false;
    },
    setMultiCurrencyValue: (state, { payload }: PayloadAction<string>) => {
      state.multiCurrencyValue = payload;
    },
    setIsCustomPeriod: (state, { payload }: PayloadAction<boolean>) => {
      state.isCustomPeriod = payload;
    },
    setEventTypeFilter: (state, { payload }: PayloadAction<DropdownItem>) => {
      state.eventTypeFilter = payload;
    },
    setSortBy: (state, { payload }: PayloadAction<{ type: TSortByType; value: 'asc' | 'desc' | null }>) => {
      Object.values(SortByFields).forEach(type => {
        state.sortBy[type] = null;
      });

      state.sortBy[payload.type] = payload.value;
    },
    setIsMobileFiltersOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileFiltersOpen = payload;
    },
    setMobileFilters: (state, { payload }: PayloadAction<TMobileFiltersPayload>) => {
      Object.assign(state.mobileFilters, payload);
    },
    resetMobileFilters: state => {
      state.mobileFilters = {};
    },
    setAccountStatementTransactionsTypes: (state, { payload }: PayloadAction<StatementTransactionType[]>) => {
      state.accountStatementFilters.transactions = payload;
    },
    setAccountStatementSort: (
      state,
      { payload }: PayloadAction<{ key: AccountStatementSortByField; value: 'asc' | 'desc' }>
    ) => {
      state.accountStatementFilters.sort = { [payload.key]: payload.value } as AccountStatementSorting;
    },
    resetAccountStatementTransactionTypes: state => {
      state.accountStatementFilters.transactions = initialState.accountStatementFilters.transactions;
    },
    setMobileStatementItemDetails: (state, { payload }: PayloadAction<null | TStatementContentItem>) => {
      state.mobileStatementItemDetails = payload;
    },
    setMobileStatementSorting: (state, { payload }: PayloadAction<MobileStatementSorting>) => {
      state.mobileStatementSorting = payload;
    },
    setMobileSorting: (state, { payload }: PayloadAction<TMobileSorting>) => {
      state.mobileSorting = payload;
    },
    setMyBetsCancelBetStateValue: (
      state,
      { payload }: PayloadAction<{ id: string; field: 'isLoading' | 'isSuccess' | 'isError'; value: boolean }>
    ) => {
      if (!state.myBetsCancelBetsState[payload.id]) {
        state.myBetsCancelBetsState[payload.id] = {
          isLoading: false,
          isSuccess: false,
          isError: false
        };
      }
      Object.assign(state.myBetsCancelBetsState[payload.id], { [payload.field]: payload.value });
    },
    setSearchValue: (state, { payload }: PayloadAction<string | null>) => {
      state.search = payload;
    },
    setExportNotification: (state, { payload }: PayloadAction<TExportNotification>) => {
      Object.assign(state.exportNotification, payload);
    },
    setMobileEditOfferId: (state, { payload }: PayloadAction<number | null>) => {
      state.mobileEditOfferId = payload;
    }
  }
});
export const {
  cancelBet,
  failureAccountProducts,
  failureGetMyBetsData,
  fetchAccountProducts,
  fetchCharges,
  fetchMyBets,
  fetchPL,
  fetchPLTotal,
  fetchPLTotalForEvent,
  fetchProfitGames,
  fetchProfitSports,
  fetchStatement,
  hideMobileBetDetails,
  resetAccountProducts,
  resetPageData,
  resetPLTotalForEvent,
  setCancelAllUnmatchedBetsActionId,
  setEndDate,
  setIsCustomPeriod,
  setMultiCurrencyValue,
  setPageNumber,
  setPageSize,
  setStartDate,
  setTimeRange,
  setLoading,
  showMobileBetDetails,
  successAccountProducts,
  successCancelBet,
  successCharges,
  successFetchMyBets,
  successPL,
  successPLTotal,
  successPLTotalForEvent,
  successProfitGames,
  successProfitSports,
  successStatement,
  setEventTypeFilter,
  setSortBy,
  setIsMobileFiltersOpen,
  setMobileFilters,
  resetMobileFilters,
  setAccountStatementTransactionsTypes,
  setAccountStatementSort,
  failureFetchStatement,
  setMobileStatementItemDetails,
  setMobileStatementSorting,
  setMobileSorting,
  setMyBetsCancelBetStateValue,
  setSearchValue,
  setExportNotification,
  setMobileEditOfferId,
  resetAccountStatementTransactionTypes
} = slice.actions;

export default slice.reducer;
