import { useTranslation } from 'react-i18next';

import { NAMES_BACCARAT_SHOE } from 'constants/games';
import { TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface CardCounterProps {
  gameData: TGameData;
  startDate?: number;
}

const CardCounter = ({ gameData }: CardCounterProps) => {
  const { t } = useTranslation();
  const { properties } = gameData;
  const cards = properties.slice(2);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.container}>
        <div className={styles.innerWrapper}>
          <div>
            <span className={styles.cardCounter}>{t('games.shoe.cardCounter')}</span>
            <span> : </span>
            <span>
              {properties[0]?.value} {t('games.shoe.cardsDealt')}, {properties[1]?.value}{' '}
              {t('games.shoe.cardsRemaining')}
            </span>
          </div>
          <div className={styles.tableContainer}>
            {cards.map(({ value, name }: { value: string; name: string }, index: number) => (
              <div key={name} className={styles.tableRow}>
                <div className={styles.tableCell}>{NAMES_BACCARAT_SHOE[index]}</div>
                <div className={styles.tableCell}>{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCounter;
