import { useDispatch, useSelector } from 'react-redux';

import { MyBetsPage } from 'constants/myBets';
import useAccountPageDates from 'hooks/useAccountPageDates';
import DatePickers from 'pages/MyBetsPages/components/DatePickers/MyBetsDatePickers';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { setMobileFilters } from 'redux/modules/myBets';
import { getMobileFiltersChanged } from 'redux/modules/myBets/selectors';
import { TimeFormats, TPeriods } from 'types';
import {
  getBettingDayTimezone,
  getDaysDifference,
  getEndDateByPeriod,
  getStartDateByPeriod,
  getTodayDate,
  getTodayEndDate
} from 'utils/date';

const MobileDatePickers = () => {
  const dispatch = useDispatch();

  const { startDate, endDate, timeDisplayFormat } = useSelector(getMobileFiltersChanged);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const { startDate: startDateSaved, endDate: endDateSaved } = useAccountPageDates();

  const isBettingDate = timeDisplayFormat === TimeFormats.BETTING_DAY;

  const handlePeriodDropdown = (period: TPeriods) => {
    const timezoneOffset = isBettingDate ? getBettingDayTimezone() : timezone;
    const todayDate = getTodayEndDate(timezoneOffset, isBettingDate || timezoneCookieEnabled);
    const dayDifference = getDaysDifference(getTodayDate(), todayDate);

    const startOfTheDay = new Date(getStartDateByPeriod(period, startDate ?? startDateSaved, dayDifference));
    const endWithTimezone = getEndDateByPeriod(period, endDate ?? endDateSaved, dayDifference);

    dispatch(
      setMobileFilters({ startDate: startOfTheDay, endDate: endWithTimezone, period, isDateRangeChanged: true })
    );
  };

  const handleSetStartDate = (date: Date) => dispatch(setMobileFilters({ startDate: date, isDateRangeChanged: true }));

  const handleSetEndDate = (date: Date) => dispatch(setMobileFilters({ endDate: date, isDateRangeChanged: true }));

  return (
    <DatePickers
      customStartDate={startDate}
      customEndDate={endDate}
      myBetsPage={MyBetsPage.ExchangeBets}
      customHandlePeriodDropdown={handlePeriodDropdown}
      customHandleSetStartDate={handleSetStartDate}
      customHandleSetEndDate={handleSetEndDate}
    />
  );
};

export default MobileDatePickers;
