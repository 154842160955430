import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { ExchangeTypes } from 'constants/myBets';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { BetsType } from 'redux/modules/myBets/type';
import { CookieNames } from 'types';

const useAccountPages = () => {
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.All_MY_BETS));

  const isAsianView = !!(accountProducts?.asianView && cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView);

  return { isAsianView };
};

export default useAccountPages;
