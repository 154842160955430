import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import useDevice from 'hooks/useDevice';

import contentItemStyles from '../ContentItem/styles.module.scss';
import styles from './styles.module.scss';

const TableSkeleton = () => {
  const { isDesktop } = useDevice();

  return isDesktop ? (
    <div className={contentItemStyles.tableBody}>
      <div className={classNames(contentItemStyles.cell, contentItemStyles.eventCell)}>
        <Skeleton className={styles.skeleton} />
      </div>
      <div className={classNames(contentItemStyles.cell, contentItemStyles.marketCell)}>
        <Skeleton className={classNames(styles.skeleton, styles.skeleton__market)} />
      </div>
      <div className={classNames(contentItemStyles.cell, contentItemStyles.middleCell)} />
      <div className={classNames(contentItemStyles.cell, contentItemStyles.middleCell)} />
      <div className={classNames(contentItemStyles.cell, contentItemStyles.plCell)} />
    </div>
  ) : (
    <div className={styles.table}>
      <div className={styles.table__row}>
        <div className={styles.table__row__left}>
          <Skeleton className={styles.item__md} />
          <Skeleton className={styles.item__sm} />
          <Skeleton className={styles.item__sm} />
        </div>
        <div className={styles.table__row__right}>
          <Skeleton className={styles.item__xs} />
        </div>
      </div>
    </div>
  );
};
export default TableSkeleton;
