import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import MobileLoginForm from 'components/forms/MobileLoginForm/MobileLoginForm';
import MobileAccountBackBtn from 'components/MobileAccountBackBtn';
import MobileLocaleTimezoneView from 'components/MobileLocaleTimezoneView/MobileLocaleTimezoneView';
import MobilePreferences from 'components/MobilePreferences/MobilePreferences';
import { asianViewMiddleSection } from 'constants/branding';
import useElementSize from 'hooks/useElementSize';
import useMobilePreferences from 'hooks/useMobilePreferences';
import { getMobileSettingsLoginHeaderButtons } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { MobilePreferencesOption } from 'types/mobile';

import styles from './styles.module.scss';

const MobileLogin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoginHeaderButtons = useSelector(getMobileSettingsLoginHeaderButtons);

  const {
    showTimezoneView,
    showLocaleView,
    onSelectTimezone,
    onSelectLocal,
    setShowLocaleView,
    setShowTimezoneView,
    selectedOffset,
    selectedLocale,
    locales,
    timezoneOffsets
  } = useMobilePreferences();

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  if ((showLocaleView || showTimezoneView) && selectedLocale && selectedOffset) {
    return (
      <MobileLocaleTimezoneView
        onGoBack={() => (showTimezoneView ? setShowTimezoneView(false) : setShowLocaleView(false))}
        onChangeOption={showLocaleView ? onSelectLocal : onSelectTimezone}
        label={showLocaleView ? t('locale.label') : t('timezone.label')}
        options={showLocaleView ? locales : (timezoneOffsets as MobilePreferencesOption[])}
        selectedOption={showLocaleView ? selectedLocale : (selectedOffset as MobilePreferencesOption)}
        type={showLocaleView ? 'locale' : 'timezone'}
      />
    );
  }

  return (
    <>
      <div className={classNames(asianViewMiddleSection.ACCOUNT_SUB_HEADER, styles.header)}>
        <MobileAccountBackBtn
          className={styles.header__home}
          hideLabel
          iconName="biab_custom-icon-home"
          iconClassName={styles.header__home__icon}
        />
        <p className={styles.header__label}>{t('header.login')}</p>
        <div className={styles.header__end} />
      </div>
      <div ref={componentRef} className={classNames('biab_login-container', styles.container)}>
        {isLoginHeaderButtons && (
          <div className={styles.mobileLoginFormContainer}>
            <MobileLoginForm />
          </div>
        )}
        <MobilePreferences
          setShowLocaleView={setShowLocaleView}
          setShowTimezoneView={setShowTimezoneView}
          selectedLocale={selectedLocale}
          selectedOffset={selectedOffset as MobilePreferencesOption}
          containerClassName={styles.preferences}
        />
      </div>
    </>
  );
};

export default MobileLogin;
