import { useSelector } from 'react-redux';

import {
  getIsAsianMobileSelectedBet,
  getIsAsianMobileSelectedBetHidden
} from 'redux/modules/asianViewMobileBetslip/selectors';

import AsianViewMobileSelectedBet from './components/MobileSelectedBet';

interface IAsianViewMobileBetslip {
  betslipId: string;
  isMargin?: boolean;
}
const AsianViewMobileBetslip = ({ betslipId, isMargin }: IAsianViewMobileBetslip) => {
  const isSelectedBet = useSelector(getIsAsianMobileSelectedBet(betslipId));
  const isSelectedBetHidden = useSelector(getIsAsianMobileSelectedBetHidden(betslipId));

  if (isSelectedBet && !isSelectedBetHidden) {
    return <AsianViewMobileSelectedBet betslipId={betslipId} isMargin={isMargin} />;
  }

  return null;
};

export default AsianViewMobileBetslip;
