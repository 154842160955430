import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';
import { mobileComponents } from 'constants/branding';
import { TMobileSelectOption } from 'types/myBets';

import styles from './styles.module.scss';

type TMobileSelectProps = {
  title: string;
  defaultOptions: TMobileSelectOption[];
  onChangeCallback: (options: TMobileSelectOption[], isChanged: boolean) => void;
};

const MobileMultiselect = ({ title, defaultOptions, onChangeCallback }: TMobileSelectProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<TMobileSelectOption[]>(defaultOptions);

  const isAllSelected = options.every(({ isSelected }) => isSelected);
  const isChanged = options.some(({ isSelected, defaultIsSelected }) => isSelected !== defaultIsSelected);

  const toggleOptionHandler = (ev: ChangeEvent<HTMLInputElement>, id: string) => {
    setOptions(
      options.map(option => ({ ...option, isSelected: option.id === id ? !option.isSelected : option.isSelected }))
    );
  };

  const toggleAllOptionsHandler = (e: SyntheticEvent) => {
    e.preventDefault();

    setOptions(options.map(option => ({ ...option, isSelected: !isAllSelected })));
  };

  useEffect(() => {
    onChangeCallback(options, isChanged);
  }, [options, isChanged]);

  useEffect(() => {
    if (defaultOptions.length !== options.length) {
      setOptions(prevState => {
        return defaultOptions.map(newOption => {
          const prevOption = prevState.find(prevStateOption => prevStateOption.id === newOption.id);

          if (prevOption) {
            return prevOption;
          }

          return newOption;
        });
      });
    }
  }, [defaultOptions.length]);

  return (
    <section className={styles.wrap}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <button
          className={classNames(styles.selectAllToggle, mobileComponents.BUTTON, mobileComponents.TEXT)}
          onClick={toggleAllOptionsHandler}
        >
          {t(isAllSelected ? 'account.mybets.labels.clearAll' : 'account.mybets.labels.selectAll')}
        </button>
      </div>
      <ul className={styles.list}>
        {options.map(({ id, isSelected, label }) => (
          <li key={id}>
            <Checkbox
              checked={isSelected}
              onChange={ev => toggleOptionHandler(ev, id)}
              className={styles.checkboxRow}
              isMobile
            >
              {label}
            </Checkbox>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default MobileMultiselect;
