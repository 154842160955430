import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { every, groupBy, isEmpty, some } from 'lodash';

import { APPLICATION_TYPE_WEB, EXCHANGE, GAME } from 'constants/app';
import { ERROR_BET_IS_EXPIRED, MSA_INSUFFICIENT_FUNDS } from 'constants/placement';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import {
  getBalanceWsEnabled,
  getBetsStatusesRequestInterval,
  getCurrentBetsRequestInterval,
  getDesktopSettingsNetOfCommissionBetslip,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getMobileSettingsNetOfCommission,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { fetchBetsStatuses, removeBetsStatuses } from 'redux/modules/betsStatuses';
import {
  getBetsStatusesErrorByOffersIds,
  getBetsStatusesValuesByOffersIds,
  getIsLoadingBetsStatusesByOfferIds
} from 'redux/modules/betsStatuses/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { fetchCurrentBets, setCurrentBetActionForAll, setCurrentBetsListCanBeRemoved } from 'redux/modules/currentBets';
import { getCurrentBetsError, getCurrentBetsList, getLoading, getOffers } from 'redux/modules/currentBets/selectors';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { getCurrentGameRound } from 'redux/modules/games/selectors';
import { cancelBets, editBets, placeBets } from 'redux/modules/placement';
import {
  TCancelBet,
  TEditBet,
  TPlaceBetsDataPayload,
  TPlacedBetsByMarket,
  TPlacementBet,
  TPlacementError
} from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { CookieNames, PageBlocks } from 'types';
import { EPersistenceTypes } from 'types/bets';
import { EBetslipTypes, EPlacementStatus, EPlacementType } from 'types/betslip';
import { getBooleanValue } from 'utils';
import { isCancelled as isCancelledOffer } from 'utils/betslip';

export const usePlacementData = ({
  successPlacement = () => {},
  errorPlacement = () => {},
  onCancelledPlacement = () => {},
  onSuccessPlaceBets,
  isMobilePlacement = false,
  isInlinePlacement = false
}: {
  eachWayDivisor?: number | null;
  numberOfWinners?: number | null;
  successPlacement?: (placedBets: TCurrentBet[], cancelledBets: TCurrentBet[], error?: string) => void;
  errorPlacement?: (error?: TPlacementError | string) => void;
  onCancelledPlacement?: () => void;
  onSuccessPlaceBets?: (data: TPlacedBetsByMarket) => void;
  isMobilePlacement?: boolean;
  isInlinePlacement?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const currentBetsLoading = useSelector(getLoading);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const currentBetsRequestInterval = useSelector(getCurrentBetsRequestInterval) || '3000';
  const betsStatusesRequestInterval = useSelector(getBetsStatusesRequestInterval) || '1000';
  const netOfCommissionBetslip = useSelector(getDesktopSettingsNetOfCommissionBetslip);
  const netOfCommission = useSelector(getMobileSettingsNetOfCommission);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const accountSettings = useSelector(getAccountSettings);
  const offers = useSelector(getOffers);
  const currentBets = useSelector(getCurrentBetsList);
  const currentBetsError = useSelector(getCurrentBetsError);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const round = useSelector(getCurrentGameRound);

  const { isMobile, isAsianViewPage } = useDevice();
  const { quickstakeBetslip, confirmBetsBeforePlace } = useDeviceSettings();

  const mobileParams = isMobile ? { openBetsEnabled: getBooleanValue(cookies.BIAB_MOBILE_OPEN_BETS) } : {};
  const params = {
    betType: isAsianViewPage ? EXCHANGE : isGameBetslip ? GAME : EXCHANGE,
    netPLBetslipEnabled: netOfCommissionBetslip && !!accountSettings?.netOfCommissionBetslipEnabledState,
    netPLMarketPageEnabled: netOfCommission && !!accountSettings?.netOfCommissionEnabledState,
    quickStakesEnabled: quickstakeBetslip,
    confirmBetsEnabled: confirmBetsBeforePlace && !!accountSettings?.confirmBetsBeforePlacement,
    applicationType: APPLICATION_TYPE_WEB,
    mobile: isMobile,
    ...mobileParams
  };

  const [placementStatus, setPlacementStatus] = useState(EPlacementStatus.INIT);
  const [placementType, setPlacementType] = useState(EPlacementType.PLACE);
  const [offerIds, setOfferIds] = useState<number[]>([]);
  // Show error after partially success placement
  const [error, setError] = useState('');

  const isLoadingBetsStatuses = useSelector(getIsLoadingBetsStatusesByOfferIds(offerIds));
  const betsStatuses = useSelector(getBetsStatusesValuesByOffersIds(offerIds));
  const betsStatusesError = useSelector(getBetsStatusesErrorByOffersIds(offerIds));

  const getBetsStatusesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const currentBetsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const isPendingCurrentBetsRequest = useRef(false);
  const currentBetsLoaderRef = useRef(currentBetsLoading);
  const isGameBetSlipRef = useRef(isGameBetslip);

  isGameBetSlipRef.current = isGameBetslip;

  const canceledStatuses = isPNCEnabled
    ? [BetsStatusesTypes.CANCELLED]
    : [BetsStatusesTypes.CANCELLED, BetsStatusesTypes.MATCHED];
  const stringifiedBetsStatuses = JSON.stringify(betsStatuses);

  const placeBetsHandler = (data: {
    bets: TPlacementBet[];
    options?: {
      isTakeOffer: boolean;
      round?: number;
      placedUsingEnterKey?: boolean;
      betType?: EBetslipTypes;
      pageBlock?: PageBlocks;
    };
  }) => {
    const { bets, options } = data;
    const betsByMarkets = groupBy(bets, 'marketId');

    const placeBetsByMarket: TPlaceBetsDataPayload = {};

    Object.entries(betsByMarkets).forEach(([marketId, betsByMarket]) => {
      placeBetsByMarket[marketId] = betsByMarket.map(bet => {
        const {
          selectionId,
          handicap,
          price,
          size,
          side,
          page,
          persistenceType,
          eachWayDivisor,
          numberOfWinners,
          betUuid = ''
        } = bet;
        const pageData = page ? { page } : {};

        return {
          ...{ selectionId, handicap, price, size, side, betUuid },
          ...params,
          ...(options?.isTakeOffer ? { pncBestPriceEnabled: false } : {}),
          ...(round && (isGameBetslip || isMobile) ? { round } : {}),
          ...(options?.betType ? { betType: options.betType } : {}),
          ...pageData,
          eachWayData: eachWayDivisor ? { eachWayDivisor: eachWayDivisor, places: numberOfWinners } : {},
          persistenceType: persistenceType || EPersistenceTypes.LAPSE,
          placedUsingEnterKey: !!options?.placedUsingEnterKey
        };
      });
    });

    dispatch(
      placeBets({
        data: placeBetsByMarket,
        isMobilePlacement,
        isInlinePlacement,
        pageBlock: options?.pageBlock,
        successCallback: ({ offerIds: responseOfferIds, response }) => {
          if (responseOfferIds.length) {
            if (onSuccessPlaceBets) {
              onSuccessPlaceBets(response);
            }

            setPlacementStatus(EPlacementStatus.GET_STATUSES);
            setOfferIds(responseOfferIds);
            if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
              dispatch(fetchBalance());
            }
          } else {
            errorPlacement();
          }
        },
        errorCallback: errorPlacement
      })
    );
  };

  const editBetsHandler = ({
    marketId,
    bets,
    options,
    onSuccess
  }: {
    marketId: string;
    bets: TEditBet[];
    options?: {
      isTakeOffer?: boolean;
      round?: number;
      placedUsingEnterKey?: boolean;
      betType?: EBetslipTypes | string;
      pageBlock?: PageBlocks;
    };
    onSuccess?: () => void;
  }) => {
    setPlacementStatus(EPlacementStatus.INIT);

    dispatch(
      setCurrentBetActionForAll(
        bets.map(bet => {
          return {
            offerId: bet.offerId,
            action: ECurrentBetActions.EDITING
          };
        })
      )
    );

    dispatch(
      editBets({
        isMobilePlacement,
        pageBlock: options?.pageBlock,
        data: {
          [marketId]: bets.map(bet => {
            const { price, size, side, selectionId, handicap, offerId, sizeRemaining, persistenceType, page, betUuid } =
              bet;
            const pageData = page ? { page } : {};
            return {
              price,
              size,
              side,
              selectionId,
              handicap,
              offerId,
              sizeRemaining,
              persistenceType,
              betUuid,
              ...params,
              ...(options?.betType ? { betType: options.betType } : {}),
              ...{ pageData }
            };
          })
        },
        successCallback: () => {
          setOfferIds(bets.map(({ offerId }) => offerId));
          setPlacementType(EPlacementType.UPDATE);
          setPlacementStatus(EPlacementStatus.GET_PLACED_BET);

          if (onSuccess) {
            onSuccess();
          }

          if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
            dispatch(fetchBalance());
          }
        },
        errorCallback: errorPlacement
      })
    );
  };

  const cancelBetsHandler = ({
    marketId,
    bets,
    onSuccess,
    pageBlock
  }: {
    marketId: string;
    bets: TCancelBet[];
    onSuccess?: () => void;
    pageBlock?: PageBlocks;
  }) => {
    setPlacementStatus(EPlacementStatus.INIT);

    dispatch(
      setCurrentBetActionForAll(
        bets.map(bet => {
          return {
            offerId: bet.offerId,
            action: ECurrentBetActions.CANCELLING
          };
        })
      )
    );

    dispatch(
      cancelBets({
        isMobilePlacement,
        pageBlock,
        data: {
          [marketId]: bets.map(bet => {
            return { ...bet, betType: bet.betType || EXCHANGE };
          })
        },
        successCallback: () => {
          setOfferIds(bets.map(({ offerId }) => offerId));
          setPlacementType(EPlacementType.CANCEL);
          setPlacementStatus(EPlacementStatus.GET_STATUSES);

          if (onSuccess) {
            onSuccess();
          }

          if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
            dispatch(fetchBalance());
          }
        },
        errorCallback: errorPlacement
      })
    );
  };

  const fetchBetsStatusesHandler = () => {
    if (!isLoadingBetsStatuses) {
      dispatch(
        fetchBetsStatuses({
          offerIds,
          onSuccess: offersStatuses => {
            const cancelledStatuses = Object.entries(offersStatuses)
              .filter(([_, status]) => status === BetsStatusesTypes.CANCELLED)
              .map(([offerId]) => offerId);

            dispatch(
              setCurrentBetsListCanBeRemoved(cancelledStatuses.map(offerId => ({ offerId, canBeRemoved: true })))
            );

            const noPendingStatus = Object.values(offersStatuses).every(status => status !== BetsStatusesTypes.PENDING);

            if (noPendingStatus && isGameBetSlipRef.current && getBetsStatusesInterval.current) {
              clearInterval(getBetsStatusesInterval.current);
              getBetsStatusesInterval.current = null;
            }
          }
        })
      );
    }
  };

  const getBetsStatusesHandler = () => {
    fetchBetsStatusesHandler();
    getBetsStatusesInterval.current = setInterval(fetchBetsStatusesHandler, parseInt(betsStatusesRequestInterval));
  };

  const successCurrentBetsCallback = (bets: TCurrentBet[]) => {
    const placedBets = bets.filter(currentBet =>
      offerIds.includes(placementType === EPlacementType.UPDATE ? currentBet.oldOfferId : currentBet.offerId)
    );

    const cancelledBets = placedBets.filter(currentBet => isCancelledOffer(currentBet));

    if (placementType === EPlacementType.CANCEL) {
      if (cancelledBets.length) {
        clearGetCurrentBetsInterval();
        successPlacement(placedBets, cancelledBets);
        setOfferIds([]);
      }
    } else {
      if (placedBets.length) {
        if (placementType === EPlacementType.UPDATE) {
          setOfferIds(placedBets.map(({ offerId }) => offerId));
        }
        clearGetCurrentBetsInterval();
        successPlacement(placedBets, cancelledBets, error);
        setOfferIds([]);
      } else {
        isPendingCurrentBetsRequest.current = true;
        if (currentBetsInterval.current) {
          clearInterval(currentBetsInterval.current);
          currentBetsInterval.current = null;
        }
        currentBetsInterval.current = setInterval(() => {
          if (!currentBetsLoaderRef.current) {
            dispatch(fetchCurrentBets());
          }
        }, parseInt(currentBetsRequestInterval));
      }
    }
  };

  useEffect(() => {
    if (isPendingCurrentBetsRequest.current) {
      onSuccessPlacement();
    }
  }, [currentBets, currentBetsError]);

  const onSuccessPlacement = () => {
    if (currentBetsError) {
      clearGetCurrentBetsInterval();
      setOfferIds([]);
    } else {
      successCurrentBetsCallback(currentBets);
    }
  };

  const getCurrentBetsHandler = () => {
    if (!isPendingCurrentBetsRequest.current) {
      const isEveryOfferReady = every(offerIds, offerId => {
        const newOffer = (Object.values(offers) || []).filter(offer => offer.oldOfferId === offerId);

        if (placementStatus === EPlacementStatus.GET_PLACED_BET && placementType !== EPlacementType.UPDATE) {
          return (
            !!offers[offerId] &&
            (!offers[offerId].action || (!!newOffer && offers[offerId].offerState === BetsStatusesTypes.CANCELLED))
          );
        }
        if (placementStatus === EPlacementStatus.GET_PLACED_BET && placementType === EPlacementType.UPDATE) {
          if (!!offers[offerId]) {
            return !!newOffer && offers[offerId].offerState === BetsStatusesTypes.CANCELLED;
          }
          return false;
        }
        if (placementStatus === EPlacementStatus.CANCEL) {
          return !!offers[offerId] && offers[offerId].offerState === BetsStatusesTypes.CANCELLED;
        }
      });

      if (isEveryOfferReady) {
        onSuccessPlacement();
      } else {
        isPendingCurrentBetsRequest.current = true;
        dispatch(fetchCurrentBets());
      }
    }
  };

  const clearGetBetsStatusesInterval = () => {
    if (getBetsStatusesInterval.current) {
      clearInterval(getBetsStatusesInterval.current);
      getBetsStatusesInterval.current = null;
    }
  };

  const clearGetCurrentBetsInterval = () => {
    isPendingCurrentBetsRequest.current = false;
    if (currentBetsInterval.current) {
      clearInterval(currentBetsInterval.current);
      currentBetsInterval.current = null;
    }
  };

  useEffect(() => {
    if (!isEmpty(betsStatuses)) {
      const isPlaced = every(betsStatuses, (status: BetsStatusesTypes) =>
        [
          BetsStatusesTypes.PLACED,
          BetsStatusesTypes.MATCHED,
          BetsStatusesTypes.CANCELLED,
          BetsStatusesTypes.EXPIRED
        ].includes(status)
      );

      const isExpired = every(betsStatuses, (status: BetsStatusesTypes) => status === BetsStatusesTypes.EXPIRED);
      const isSomeExpired = some(betsStatuses, (status: BetsStatusesTypes) => status === BetsStatusesTypes.EXPIRED);
      const isCancelled = every(betsStatuses, (status: BetsStatusesTypes) => canceledStatuses.includes(status));

      const isVoided = every(betsStatuses, (status: BetsStatusesTypes) =>
        [BetsStatusesTypes.VOIDED, BetsStatusesTypes.LAPSED].includes(status)
      );

      if ((isPlaced && placementType !== EPlacementType.CANCEL) || isExpired || isCancelled || isVoided) {
        clearGetBetsStatusesInterval();
      }

      if (isVoided || (isPNCEnabled && isCancelled)) {
        setPlacementStatus(EPlacementStatus.INIT);
        onCancelledPlacement();
      } else if (isPlaced && !isExpired && placementType !== EPlacementType.CANCEL) {
        if (isSomeExpired) {
          setError(t(ERROR_BET_IS_EXPIRED));
        }

        setPlacementStatus(EPlacementStatus.GET_PLACED_BET);
      } else if (isCancelled) {
        setPlacementStatus(EPlacementStatus.CANCEL);
      }

      if (betsStatusesError?.error) {
        dispatch(removeBetsStatuses(offerIds));
        setPlacementStatus(EPlacementStatus.INIT);

        if (betsStatusesError?.error?.id === MSA_INSUFFICIENT_FUNDS && betsStatusesError?.error?.message) {
          errorPlacement(betsStatusesError?.error?.message);
        }
      }

      if (isExpired) {
        errorPlacement(t(ERROR_BET_IS_EXPIRED));
        setPlacementStatus(EPlacementStatus.INIT);
      }
    }
  }, [stringifiedBetsStatuses, betsStatusesError?.error, isPNCEnabled, offerIds, placementStatus]);

  useEffect(() => {
    if (placementStatus === EPlacementStatus.GET_STATUSES) {
      getBetsStatusesHandler();
    } else if (placementStatus === EPlacementStatus.GET_PLACED_BET) {
      getCurrentBetsHandler();
    } else if (placementStatus === EPlacementStatus.CANCEL) {
      setPlacementType(EPlacementType.CANCEL);
      getCurrentBetsHandler();
    } else if (placementStatus === EPlacementStatus.INIT) {
      clearGetBetsStatusesInterval();
      clearGetCurrentBetsInterval();
    }

    return () => {
      dispatch(removeBetsStatuses(offerIds));
      clearGetCurrentBetsInterval();

      if (!isGameBetSlipRef.current) {
        clearGetBetsStatusesInterval();
      }
    };
  }, [placementStatus]);

  return {
    placeBetsHandler,
    editBetsHandler,
    cancelBetsHandler
  };
};
