import { useLocation } from 'react-router-dom';

import { ACCOUNT_BASE_URL } from 'constants/locations';
import { BetPeriodsMap, NavigationButtons } from 'constants/myBets';

const useAccountUrlParams = () => {
  const { pathname } = useLocation();
  const pathnameParts = pathname.split(ACCOUNT_BASE_URL);
  const exchangePageType = pathnameParts[0];
  const selectedTab = pathnameParts[1]?.split('/')?.[1] || '';
  const urlWithoutPageIndex = pathname.substring(0, pathname.lastIndexOf('/')).replace(exchangePageType, '');

  const isMyBetsPage = selectedTab === NavigationButtons.MyBets;
  const isProfitLossPage = selectedTab === NavigationButtons.ProfitAndLoss;
  const isStatementPage = selectedTab === NavigationButtons.Statement;

  const activePeriodParam = BetPeriodsMap[urlWithoutPageIndex];

  return {
    exchangePageType,
    selectedTab,
    activePeriodParam,
    urlWithoutPageIndex,
    isMyBetsPage,
    isProfitLossPage,
    isStatementPage
  };
};

export default useAccountUrlParams;
